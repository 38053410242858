import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class EquipmentTypeSelector extends Component {
  render() {
    const { t, onChange, placeholder, value, isMulti } = this.props;
    const options = [
      { value: 'trailer', label: t('equipmentType.trailer') },
      { value: 'box', label: t('equipmentType.box') },
      { value: 'loadCarrier', label: t('equipmentType.loadCarrier') },
      { value: 'pallet', label: t('equipmentType.pallet') },
      { value: 'chassis', label: t('equipmentType.chassis') },
      { value: 'container', label: t('equipmentType.container') },
    ];

    return (
      <Select
        isMulti={isMulti}
        value={
          isMulti
            ? options.filter((option) => value && value.includes(option.value))
            : options.find((option) => option.value === value)
        }
        noOptionsMessage={() => t('noOptions')}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
      />
    );
  }
}

export default withTranslation('translation')(EquipmentTypeSelector);
