import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import moment from 'moment';

import { administrativeReferenceToString } from '@utils/locationUtils';

class ConsignmentCard extends Component {
  render() {
    const { t, consignment, dragStart, dragEnd, isDraggable } = this.props;
    return (
      <div
        id={consignment?.id}
        draggable={isDraggable}
        onDragStart={(event) => isDraggable && dragStart(event)}
        onDragEnd={(event) => isDraggable && dragEnd(event)}
        className={`consignment-card ${isDraggable && 'cursor-drag'}`}
      >
        <h2>{consignment.name ? `${consignment.name}` : null}</h2>

        {!isDraggable && (
          <div className="flex-container value-list">
            {consignment.type && (
              <p className="no-margin-bottom value-list-item">
                <b>{t('consignment.type')}:</b> {consignment.type}
              </p>
            )}
            {consignment.combined !== null && consignment.combined !== undefined && (
              <p className="no-margin-bottom value-list-item">
                <b>{t('consignment.combined')}:</b> {consignment.combined ? t('yes') : t('no')}
              </p>
            )}
          </div>
        )}

        {consignment.description && (
          <p className="no-margin">
            <b>{t('consignment.description')}:</b> {consignment.description}
          </p>
        )}
        {consignment.remark && (
          <p className="no-margin">
            <b>{t('consignment.remark')}:</b> {consignment.remark}
          </p>
        )}
        {(consignment.actions?.length || 0) > 0 && (
          <div className="">
            {[...consignment.actions]
              .map((association) => association.entity)
              .sort((a, b) => a.sequenceNr - b.sequenceNr)
              .map((action) => {
                const startDateTimeConstraint = action.constraints
                  .map((association) => association.entity)
                  .find((constraint) => constraint.value.type === 'startDateTimeConstraint');

                const endDateTimeConstraint = action.constraints
                  .map((association) => association.entity)
                  .find((constraint) => constraint.value.type === 'endDateTimeConstraint');

                return (
                  <div className="block" key={action.id}>
                    <b>{t(`actionType.${action.actions[0]?.entity?.type || 'stop'}`)}</b> -{' '}
                    {administrativeReferenceToString(
                      action?.location?.entity?.administrativeReference,
                      true
                    )}
                    (
                    {startDateTimeConstraint
                      ? `${moment(startDateTimeConstraint.value.startDateTime).format(
                          'DD/MM/YYYY HH:mm'
                        )} - `
                      : ''}
                    {endDateTimeConstraint
                      ? moment(endDateTimeConstraint.value.endDateTime).format('DD/MM/YYYY HH:mm')
                      : ''}
                    )
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('translation')(ConsignmentCard);
