import React, { Component } from 'react';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import ReactSVG from 'react-svg';

import { reactSelectCustomStyle } from '@/constants/dashboard';
import QuoteFormContainer from '@containers/administration/quote/crud/QuoteFormContainer';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';
import PopOver from '@uicomponents/PopOver';

import * as administrationApi from '@api/administrationApi';
import Alert from '@models/Alert';

import { defaultDateFormat } from '@utils/dateUtils';

import { QuotePDFDocument } from './QuotePDF';

class QuoteView extends Component {
  state = {
    popOvers: [],
  };

  getStatus = (status) => {
    const { t } = this.props;
    return t(`quote.status.${status}`);
  };

  render() {
    const {
      t,
      quote,
      platform,
      isFetchingQuote,
      dateFormat,
      addToStack,
      sendQuote,
      isPublic,
      createAlert,
      acceptPublicQuote,
      declinePublicQuote,
      acceptQuote,
      declineQuote,
      getQuotes,
      deleteQuote,
      updateQuote,
      changeValue,
      administration,
      administrations,
    } = this.props;

    if (isFetchingQuote) {
      return <Loader />;
    }

    if (quote) {
      const { targetBusiness } = quote;
      const { business } = quote?.administration;

      const statusOptions = [
        { value: 'open', label: t('quote.status.open') },
        { value: 'accepted', label: t('quote.status.accepted') },
        { value: 'declined', label: t('quote.status.declined') },
        { value: 'cancelled', label: t('quote.status.cancelled') },
      ];

      const extraOptions = [
        { value: 'send_again', label: t('quote.actions.sendAgain') },
        { value: 'create_invoice', label: t('quote.createInvoice') },
      ];

      const declinedExtraOptions = [{ value: 'send_again', label: t('quote.actions.sendAgain') }];

      const conceptExtraOptions = [{ value: 'delete', label: t('quote.delete') }];

      return (
        <>
          {this.state.popOvers.map((popOver) => popOver)}
          <AsideView>
            <main className="quote quote-details-container">
              <div className="information">
                <div className="flex-container justify-between">
                  <h1 className="no-margin with-flair">
                    {t('quote.header')} #{quote.name} <span>{this.getStatus(quote.status)}</span>
                  </h1>
                  <div className="d-flex gap-5 justify-content-end">
                    <Select
                      options={
                        quote.status === 'concept'
                          ? conceptExtraOptions
                          : quote.status === 'declined' || quote.status === 'cancelled'
                          ? declinedExtraOptions
                          : extraOptions
                      }
                      styles={reactSelectCustomStyle({
                        menuWidth: '160px',
                        controlRounded: false,
                        selectionOptionColor: 'var(--white)',
                        border: '1px solid var(--primary-color)',
                        controlBackground: 'var(--white) !important',
                        controlBoxShadow: '0 0 0 1px var(--primary-color)',
                        activeBorderColor: 'var(--primary-color) !important',
                        optionHoverBackgroundColor: 'var(--primary-color)',
                        optionActiveBackgroundColor: 'var(--primary-color)',
                      })}
                      placeholder={t('extra')}
                      className="react-select__control"
                      onChange={(e) => {
                        if (e.value === 'delete') {
                          this.setState({
                            popOvers: [
                              ...this.state.popOvers,
                              <PopOver
                                isActive={true}
                                className="select-pop-over"
                                onClose={() => {
                                  this.setState({
                                    popOvers: [],
                                  });
                                }}
                              >
                                <div className="box">
                                  <div className="title">{t('quote.delete.header')}</div>
                                  <div className="text">{t('quote.delete.description')}</div>

                                  <div className="choice">
                                    <button
                                      href=""
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                          popOvers: [],
                                        });
                                      }}
                                    >
                                      {t('cancel')}
                                    </button>
                                    <div
                                      className="as-link"
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                          popOvers: [],
                                        });
                                        deleteQuote(quote).then((response) => {
                                          if (response.success) {
                                            getQuotes(
                                              administration ||
                                                administrations.find(
                                                  (option) => option.primary === true
                                                )?.id ||
                                                administrations[0]?.id,
                                              0,
                                              30
                                            );
                                            popStack();
                                          }
                                        });
                                      }}
                                    >
                                      {t('delete')}
                                    </div>
                                  </div>
                                </div>
                              </PopOver>,
                            ],
                          });
                        } else if (e.value === 'create_invoice') {
                          administrationApi
                            .createInvoiceFromQuote(quote)
                            .then((response) => {
                              if (response.success) {
                                createAlert(
                                  new Alert(
                                    t('quote.createInvoice.success.title'),
                                    t('quote.createInvoice.success.description'),
                                    'success'
                                  )
                                );
                              }
                            })
                            .catch((error) => {
                              console.error(error);
                              createAlert(
                                new Alert(
                                  t('quote.createInvoice.error.title'),
                                  t('quote.createInvoice.error.description'),
                                  'error'
                                )
                              );
                            });
                        } else if (e.value === 'send_again') {
                          sendQuote(quote)
                            .then((response) => {
                              if (response.success) {
                                createAlert(
                                  new Alert(
                                    t('quote.send.success.title'),
                                    t('quote.send.success.description'),
                                    'success'
                                  )
                                );
                              }
                            })
                            .catch((error) => {
                              console.error(error);
                              createAlert(
                                new Alert(
                                  t('quote.send.error.title'),
                                  t('quote.send.error.description'),
                                  'error'
                                )
                              );
                            });
                        }
                      }}
                    />
                    {quote.status !== 'concept' && (
                      <Select
                        options={statusOptions}
                        styles={reactSelectCustomStyle({
                          controlRounded: false,
                          selectionOptionColor: 'var(--white)',
                          border: '1px solid var(--primary-color)',
                          controlBackground: 'var(--white) !important',
                          controlBoxShadow: '0 0 0 1px var(--primary-color)',
                          activeBorderColor: 'var(--primary-color) !important',
                          optionHoverBackgroundColor: 'var(--primary-color)',
                          optionActiveBackgroundColor: 'var(--primary-color)',
                        })}
                        placeholder={t('form.label.selectStatus')}
                        className="react-select__control"
                        value={statusOptions.filter((option) => quote.status === option.value)}
                        onChange={(e) => {
                          const newQuoteForm = { ...quote };
                          newQuoteForm.status = e.value;
                          changeValue('quoteForm', newQuoteForm);
                          updateQuote && updateQuote(newQuoteForm);
                        }}
                      />
                    )}
                    <PDFDownloadLink
                      document={<QuotePDFDocument quote={quote} />}
                      fileName={`${quote.name}.pdf`}
                    >
                      {({ loading }) =>
                        loading ? (
                          ''
                        ) : (
                          <div className="circle-icon-primary circle-lg svg-lg">
                            <ReactSVG src="/icons/pdf.svg" />
                          </div>
                        )
                      }
                    </PDFDownloadLink>
                    {!isPublic && (
                      <div
                        className="circle-icon-primary circle-lg svg-lg"
                        onClick={(e) => {
                          addToStack({
                            name: quote.name,
                            component: <QuoteFormContainer quoteId={quote.id} isEditQuote={true} />,
                            fullWidth: true,
                          });
                        }}
                      >
                        <ReactSVG src="/icons/edit.svg" />
                      </div>
                    )}
                    <div
                      className="circle-icon-primary circle-lg svg-lg"
                      onClick={(e) => {
                        e.preventDefault();

                        try {
                          navigator.clipboard.writeText(
                            `${platform.hostNames?.[0]?.name || window?.location?.origin}/quotes/${
                              quote.id
                            }/share/${quote.shareSettings.shareCode}`
                          );

                          createAlert(
                            new Alert(
                              t('notification.copy.success.title'),
                              t('notification.copy.success.description'),
                              'success'
                            )
                          );
                        } catch (e) {
                          createAlert(
                            new Alert(
                              t('notification.copy.error.title'),
                              t('notification.copy.error.description'),
                              'error'
                            )
                          );
                        }
                      }}
                    >
                      <ReactSVG src="/icons/link.svg" />
                    </div>
                  </div>
                </div>
              </div>

              <section className="quote-details-administration">
                <div className="logo">{business.logo ? <img src={business.logo.url} /> : null}</div>
                <div>
                  <div className="address">
                    <p className="font-bold">{business.name}</p>
                    {business?.locations.length > 0 && (
                      <>
                        <p>
                          {`${business.locations[0].entity.administrativeReference.street} ${business.locations[0].entity.administrativeReference.houseNumber}`}
                        </p>
                        <p>
                          {business.locations[0].entity.administrativeReference.postalCode}{' '}
                          {business.locations[0].entity.administrativeReference.city}
                        </p>
                        <p>{business.locations[0].entity.administrativeReference.country}</p>
                      </>
                    )}
                  </div>

                  <div className="my-3">
                    {business.coc && (
                      <p>
                        <b>{t('business.coc')}</b>: {business.coc}
                      </p>
                    )}
                    {business.vat && (
                      <p>
                        <b>{t('business.vat')}</b>: {business.vat}
                      </p>
                    )}
                    {business.contactDetails.filter((cd) => cd.type === 'iban').length > 0 && (
                      <p>
                        <b>{t('business.iban')}</b>:{' '}
                        {business.contactDetails.filter((cd) => cd.type === 'iban')[0].value}
                      </p>
                    )}
                  </div>
                </div>
              </section>

              <section className="quote-details-contact">
                <p className="font-bold">{targetBusiness?.name || ''} </p>
                {targetBusiness?.locations.length > 0 && (
                  <>
                    <p>
                      {`${targetBusiness.locations[0].entity.administrativeReference.street} ${
                        targetBusiness.locations[0].entity.administrativeReference.houseNumber || ''
                      }`}
                    </p>
                    <p>
                      {targetBusiness.locations[0].entity.administrativeReference.postalCode}{' '}
                      {targetBusiness.locations[0].entity.administrativeReference.city}
                    </p>
                    <p>{targetBusiness.locations[0].entity.administrativeReference.country}</p>
                  </>
                )}
              </section>

              <section className="quote-details-lines">
                <header>
                  <h1>{quote.name}</h1>
                  <div className="header-dates">
                    <div className="date-item">
                      <p className="font-bold">{t('quote.date')}</p>
                      <p className="text-right">{defaultDateFormat(quote.date, dateFormat)}</p>
                    </div>
                    <div className="date-item">
                      <p className="font-bold">{t('quote.dueDate')}</p>
                      <p className="text-right">{defaultDateFormat(quote.dueAt, dateFormat)}</p>
                    </div>
                  </div>
                </header>
                <main>
                  <table>
                    <thead>
                      <tr>
                        <th>{t('quote.quantity')}</th>
                        <th>{t('quote.price')}</th>
                        <th>{t('quote.description')}</th>
                        <th>{t('quote.period')}</th>
                        <th>{t('quote.vat')}</th>
                        <th>{t('quote.total')}</th>
                      </tr>
                    </thead>

                    <tbody className="border-disable">
                      {quote.lines
                        ? quote.lines.map((line) => (
                            <tr key={`line-${line.id}`}>
                              <td>{line.quantity}</td>
                              <td>
                                {quote?.currency?.sign || '€'}
                                {line.value?.amount}
                              </td>
                              <td>{line.description}</td>
                              <td>
                                {line?.startDateTime && line?.endDateTime
                                  ? defaultDateFormat(line?.startDateTime, this.props?.dateFormat) +
                                    ` ${t('to')} ` +
                                    defaultDateFormat(line?.endDateTime, this.props?.dateFormat)
                                  : t('not selected')}
                              </td>

                              <td>{line.value?.taxRate?.percentage || 0}%</td>
                              <td>
                                {quote?.currency?.sign || '€'}
                                {line.quantity && line.value.amount
                                  ? quote?.tax?.inclusiveOfTax
                                    ? parseFloat(line.quantity) * parseFloat(line.value.amount)
                                    : parseFloat(line.quantity) *
                                      parseFloat(line.value.amount) *
                                      (parseFloat((line.value?.taxRate?.percentage || 0) + 100) /
                                        100)
                                  : 0}
                              </td>
                            </tr>
                          ))
                        : null}

                      <tr className="summary">
                        <td className=""></td>
                        <td className=""></td>
                        <td className=""></td>
                        <td className=""></td>
                        <td className="font-bold">{t('quote.subtotal')}</td>
                        <td>
                          {quote?.currency?.sign || '€'}
                          {quote.subTotal.amount.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="border-none"></td>
                        <td className="border-none"></td>
                        <td className="border-none"></td>
                        <td className="border-none"></td>
                        <td className="font-bold">{t('quote.vat')}</td>
                        <td>
                          {quote?.currency?.sign || '€'}
                          {quote.tax.amount.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="font-bold">{t('quote.total')}</td>
                        <td className="font-bold">
                          {quote?.currency?.sign || '€'}
                          {quote.total.amount.toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {quote.administration.quoteTermsAndConditions && (
                    <div className="terms-and-conditions">
                      <hr />
                      {quote.administration.quoteTermsAndConditions}
                    </div>
                  )}
                </main>
              </section>

              <section className="actions">
                <div className="input-group right">
                  {quote.status === 'concept' && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();

                        sendQuote(quote);
                      }}
                    >
                      {t('form.send')}
                    </button>
                  )}
                  {quote.status === 'open' && (
                    <>
                      <button
                        className="mr-2"
                        onClick={(e) => {
                          e.preventDefault();

                          if (isPublic) {
                            acceptPublicQuote(quote);
                          } else {
                            acceptQuote(quote);
                          }
                        }}
                      >
                        {t('form.accept')}
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();

                          if (isPublic) {
                            declinePublicQuote(quote);
                          } else {
                            declineQuote(quote);
                          }
                        }}
                      >
                        {t('form.decline')}
                      </button>
                    </>
                  )}
                </div>
              </section>
            </main>
          </AsideView>
        </>
      );
    }

    return (
      <div className="not-found-action-box">
        <div className="icon">
          <ReactSVG src="/icons/space.svg" />
        </div>
        <NoData>{t(`administration.notFound`)}</NoData>
      </div>
    );
  }
}

export default withTranslation('translation')(QuoteView);
