import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import Workflow from '@models/workflow/workflow';

import WorkflowForm from './WorkflowForm';

export default class CreateWorkflowPage extends Component {
  componentDidMount() {
    const { onChange, targetName } = this.props;
    const newWorkflow = new Workflow();
    newWorkflow.name = targetName || '';
    onChange('formWorkflow', newWorkflow);
  }

  render() {
    const { t, formWorkflow, isFetchingWorkflow, createWorkflow, popStack } =
      this.props;

    if (!formWorkflow || isFetchingWorkflow) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('workflow.new')}</h1>
        <WorkflowForm
          {...this.props}
          key={formWorkflow.id}
          onSubmit={(workflow) => createWorkflow(workflow)}
        />
      </AsideView>
    );
  }
}
