import React, { Component } from 'react';

import DatePicker from 'react-datepicker';
import { FeatureGroup, MapContainer, Marker, Polyline } from 'react-leaflet';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import ReactSVG from 'react-svg';
import SimpleReactValidator from 'simple-react-validator';

import { latLngBounds } from 'leaflet';
import moment from 'moment';
import PolyUtil from 'polyline-encoded';

import { i18n } from '@/index';
import ActionsInput from '@components/action/crud/ActionsInput';
import CapacityContractInput from '@components/capacityContract/CapacityContractInput';
import ConsignmentSelector from '@components/consignment/crud/ConsignmentSelector';
import ExternalAttributesInput from '@components/general/crud/ExternalAttributesInput';
import PricingElementsInput from '@components/pricing/crud/PricingElementsInput';
import DefaultMarker from '@components/ui/marker/default/DefaultMarker';
import { transformVehicleToValue } from '@components/vehicle/VehicleInput';

import Attachments from '@uicomponents/Attachments';
import Loader from '@uicomponents/Loader';
import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';
import TileLayers from '@uiviews/TileLayers';

import * as routeApi from '@api/routeApi';
import Association from '@models/general/Association';

import { getToBePlannedActions } from '@utils/actionUtils';
import { uuidv4 } from '@utils/commonUtils';
import { defaultDateFormat } from '@utils/dateUtils';
import { momentToDuration } from '@utils/dateUtils';
import { activateInputs, deactivateInputs } from '@utils/formUtils';

import TripBreakdown from '../TripBreakdown';
import TripExpenses from '../TripExpenses';

export default class TripForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      type: this.props.formTrip?.type || 'internal',
      actions: this.props.formTrip.actions ? this.props.formTrip.actions : [],
      calculatingRoute: false,
      route: this.props.updating && this.props.formTrip.route ? this.props.formTrip.route : null,
      actionsHaveChanged: false,
      dateRangePicker: {
        line: null,
        isOpen: false,
      },
      isOpenDatePicker: false,
    };
  }

  componentDidMount() {
    const { formTrip, changeValue } = this.props;
    if (formTrip?.quote) {
      changeValue('quoteForm', formTrip.quote);
    }
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  calculateRoute = ({ name, vehicle, actions }) => {
    const { route } = this.state;
    const { formTrip, onChange } = this.props;

    this.setState({
      calculatingRoute: true,
    });
    routeApi
      .calculate({
        name,
        vehicle,
        actions,
        route,
      })
      .then((data) => {
        this.setState({
          route: data.route,
          calculatingRoute: false,
          actionsHaveChanged: false,
        });
        const newTrip = { ...formTrip };
        newTrip.route = data.route;
        onChange('formTrip', newTrip);
      })
      .catch((e) => {
        this.setState({
          calculatingRoute: false,
        });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { onSubmit, formTrip, quoteForm } = this.props;
    const newTrip = { ...formTrip };
    newTrip.quote = { ...quoteForm };
    onSubmit && onSubmit(newTrip);
  };

  validateForm = () => {
    const { type } = this.state;
    const { t } = this.props;

    let isValid = true;

    if (type !== 'forwarded') {
      isValid = this.validator.fieldValid(t('route.header')) && isValid;
    } else {
      isValid = this.validator.fieldValid(t('form.label.capacity')) && isValid;
    }
    isValid = this.validator.fieldValid(t('form.label.name')) && isValid;

    return isValid;
  };

  pricingCategoriesOptions = () => {
    const { variables } = this.props;
    return variables?.pricingCategories?.length
      ? variables.pricingCategories.map((category) => ({
          label: category.name,
          value: category.id,
        }))
      : [];
  };

  amountInputChange(event, action, value = null) {
    let total = 0;
    let subTotal = 0;
    let taxAmount = 0;
    const { target } = event;
    const { changeValue, quoteForm, variables } = this.props;

    const arr = {
      ...quoteForm,
      tax:
        action === 'inclusiveOfTax' ? { ...quoteForm.tax, inclusiveOfTax: value } : quoteForm.tax,
      lines: quoteForm.lines.map((item) =>
        (item?.nonce || item.id) !== (value?.nonce || value.id)
          ? item
          : action === 'price'
          ? {
              ...item,
              value: { ...item.value, amount: parseFloat(target.value) },
            }
          : action === 'quantity'
          ? {
              ...item,
              quantity: parseFloat(target.value),
            }
          : action === 'tax'
          ? {
              ...item,
              value: {
                ...item.value,
                taxRate: event.taxRate,
              },
              tax: event.taxRate,
            }
          : item
      ),
    };

    const inclusiveOfTax = arr.tax.inclusiveOfTax;

    arr.lines.forEach((item) => {
      if (item.quantity && item.value.amount) {
        const taxRate = variables?.taxRates.filter((d) => d.id === item.value.taxRate?.id)[0] || {
          percentage: 0,
        };

        if (inclusiveOfTax) {
          const rowSubtotal =
            (parseFloat(item.quantity) * parseFloat(item.value.amount)) /
            (((taxRate?.percentage || 0) + 100) / 100);
          const rowTaxamount = rowSubtotal * ((taxRate?.percentage || 0) / 100);
          subTotal += rowSubtotal;
          taxAmount += rowTaxamount;

          total += rowSubtotal + rowTaxamount;
        } else {
          const rowSubtotal = parseFloat(item.quantity) * parseFloat(item.value.amount);
          const rowTaxamount = rowSubtotal * ((taxRate?.percentage || 0) / 100);

          subTotal += rowSubtotal;
          taxAmount += rowTaxamount;

          total += rowSubtotal + rowTaxamount;
        }
      }
    });

    return changeValue('quoteForm', {
      ...arr,
      tax: {
        ...arr.tax,
        amount: parseFloat(taxAmount).toFixed(2),
      },
      total: {
        ...arr.total,
        amount: parseFloat(total).toFixed(2),
      },
      subTotal: { ...arr.subTotal, amount: parseFloat(subTotal).toFixed(2) },
    });
  }

  currenciesOptions = () => {
    const { currencies } = this.props;
    return currencies
      ? currencies.map((item) => ({
          label: item.sign + ' - ' + item.name,
          value: item.id,
        }))
      : [];
  };

  taxRateOptions = () => {
    const { variables } = this.props;
    return variables?.taxRates?.length > 0
      ? variables.taxRates.map((tax) => ({ label: tax.name, value: tax.id }))
      : [];
  };

  pricingCategoriesOptions = () => {
    const { variables } = this.props;
    return variables?.pricingCategories?.length
      ? variables.pricingCategories.map((category) => ({
          label: category.name,
          value: category.id,
        }))
      : [];
  };

  inputChange(event, payload = null) {
    const target = event.target;
    const { changeValue, quoteForm } = this.props;
    switch (payload?.action) {
      case 'vat':
        return changeValue('quoteForm', {
          ...quoteForm,
          tax: { ...quoteForm.tax, inclusiveOfTax: payload.value },
        });

      case 'set-input':
        return changeValue('quoteForm', {
          ...quoteForm,
          [target.name]: target.value,
        });

      case 'set-item-input':
        return changeValue('quoteForm', {
          ...quoteForm,
          lines: quoteForm.lines.map((item) =>
            item.nonce === payload.nonce
              ? {
                  ...item,
                  [target.name]: target.value,
                }
              : item
          ),
        });
    }
  }

  dateRangePicker = (line) => {
    const { dateRangePicker } = this.state;
    const { quoteForm, changeValue } = this.props;

    return (
      <div className="model">
        <div className="model-content bg-white rounded text-center">
          <div className="d-flex-space-between">
            <div />
            <div
              className="circle-icon-primary circle-md svg-md"
              onClick={() =>
                this.setState({
                  dateRangePicker: {
                    line: null,
                    isOpen: false,
                  },
                })
              }
            >
              <ReactSVG src="/icons/cross.svg" />
            </div>
          </div>
          <DatePicker
            selected={dateRangePicker.start || null}
            startDate={dateRangePicker.start || null}
            endDate={dateRangePicker.end || null}
            showWeekNumbers={true}
            locale={i18n.language}
            selectsRange={true}
            inline
            onChange={(dates) => {
              const [start, end] = dates;
              if (start !== null && end !== null) {
                changeValue('quoteForm', {
                  ...quoteForm,
                  lines: quoteForm.lines.map((item) =>
                    (item?.nonce || item.id) === (line?.nonce || line.id)
                      ? {
                          ...item,
                          startDateTime: moment(start),
                          endDateTime: moment(end),
                        }
                      : item
                  ),
                });
                this.setState({
                  dateRangePicker: {
                    line: null,
                    isOpen: false,
                    start,
                    end,
                  },
                });
              } else {
                this.setState({
                  dateRangePicker: {
                    ...dateRangePicker,
                    start,
                    end,
                  },
                });
              }
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      t,
      platform,
      onChange,
      user,
      targetVehicle,
      formTrip,
      searchVehicles,
      updating,
      simpleForms,
      dateFormat,
      changeValue,
      quoteForm,
      variables,
    } = this.props;

    const { type, calculatingRoute, route, actions, actionsHaveChanged, dateRangePicker } =
      this.state;

    const locations = actions
      .filter(
        (action) =>
          action.entity.location &&
          action.entity.location.entity &&
          action.entity.location.entity.geoReference
      )
      .map((action) => action.entity.location.entity);

    const bounds = latLngBounds(
      locations && locations.length > 0
        ? locations.map((location) => [location.geoReference.lat, location.geoReference.lon])
        : [[0, 0]]
    );

    return (
      <div
        className="step-form trip-form"
        key={`${formTrip.id}-${formTrip.versionNumber}-${formTrip.nonce}`}
      >
        {dateRangePicker.isOpen &&
          dateRangePicker.line &&
          this.dateRangePicker(dateRangePicker.line)}

        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          {platform?.features &&
            platform?.features.map((feature) => feature.name).includes('capacity') && (
              <div className="tabs">
                <ul>
                  <li
                    id="internal"
                    className={
                      type === 'internal' ? 'active' : formTrip.capacityContract ? 'disabled' : ''
                    }
                    onClick={() => {
                      this.setState({ type: 'internal' });
                    }}
                  >
                    {t('tripType.internal')}
                  </li>
                  <li
                    id="forwarded"
                    className={type === 'forwarded' ? 'active' : formTrip.vehicle ? 'disabled' : ''}
                    onClick={() => {
                      this.setState({ type: 'forwarded' });
                    }}
                  >
                    {t('tripType.forwarded')}
                  </li>
                  <li
                    id="internal_empty"
                    className={
                      type === 'internal_empty' ? 'active' : formTrip.vehicle ? 'disabled' : ''
                    }
                    onClick={() => {
                      this.setState({ type: 'internal_empty' });
                    }}
                  >
                    {t('tripType.internal_empty')}
                  </li>
                </ul>
              </div>
            )}
          <div className="input-container">
            <div className="text-inputs">
              {!simpleForms && (
                <div className="input-group">
                  <div className="input-group no-margin-top">
                    <FormInput
                      type="text"
                      label={`${t('form.label.name')}*`}
                      value={formTrip.name}
                      onChange={(event) => {
                        const newTrip = { ...formTrip };
                        newTrip.name = event.target.value;

                        onChange('formTrip', newTrip, event);
                      }}
                    />
                  </div>
                  {this.validator.message(t('form.label.name'), formTrip.name, 'required')}
                </div>
              )}
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <FormInput
                    type="text"
                    label={`${t('form.label.startDateTime')}*`}
                    className="has-value"
                    value={moment(formTrip.startDate || moment()).format('YYYY-MM-DDTHH:mm')}
                    onFocus={(e) => (e.currentTarget.type = 'datetime-local')}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        e.currentTarget.type = 'text';
                      }
                    }}
                    onChange={(e) => {
                      const newTrip = { ...formTrip };
                      newTrip.startDate = e.target.value ? moment(e.target.value) : null;

                      onChange('formTrip', newTrip);
                    }}
                  />
                </div>
              </div>
            </div>
            {type === 'internal' || type === 'internal_empty' ? (
              <>
                {platform?.features?.map((feature) => feature.name)?.includes('financial') && (
                  <div className="input-group">
                    <h3 className="no-margin-top">{t('form.label.pricingElements')}</h3>
                    <div className="pricing-element">
                      <PricingElementsInput
                        key={formTrip.pricingElements}
                        pricingElements={formTrip.pricingElements}
                        onChange={(pricingElements) => {
                          const newTrip = { ...formTrip };
                          newTrip.pricingElements = pricingElements;

                          onChange('formTrip', newTrip);
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="input-group">
                  <CapacityContractInput
                    value={formTrip.capacityContract}
                    onChange={(newCapacityContract) => {
                      const newTrip = { ...formTrip };
                      newTrip.spotQuote = newCapacityContract ? true : false;
                      newTrip.capacityContract = newCapacityContract;

                      onChange('formTrip', newTrip);

                      if (newCapacityContract) {
                        if (formTrip.quote) {
                          changeValue('quoteForm', formTrip.quote);
                        } else {
                          const initialQuoteForm = {
                            name: '',
                            description: '',
                            direction: 'in',
                            dueAt: moment().add(
                              newCapacityContract?.administration?.dueDays || 14,
                              'days'
                            ),
                            targetBusiness: newCapacityContract?.business,
                            administration: newCapacityContract?.administration,
                            currency: newCapacityContract?.currencies?.[0],
                            lines: [
                              {
                                ...{
                                  name: '',
                                  value: {
                                    amount: null,
                                    taxRate: this.props?.variables?.taxRates?.[0],
                                  },
                                  sequenceNr: 0,
                                  quantity: 1,
                                  description: '',
                                  nonce: uuidv4(),
                                  pricingCategory: null,
                                  endDateTime: null,
                                  startDateTime: null,
                                },
                              },
                            ],
                            subTotal: {
                              amount: 0,
                            },
                            total: {
                              amount: 0,
                            },
                            tax: {
                              amount: 0,
                              inclusiveOfTax: false,
                            },
                          };
                          changeValue('quoteForm', initialQuoteForm);
                        }
                      } else {
                        changeValue('quoteForm', null);
                      }
                    }}
                  />
                  {this.validator.message(
                    t('form.label.capacity'),
                    formTrip.capacityContract,
                    'required'
                  )}
                </div>
                <div className="input-group">
                  <div>{t('form.label.spotquote')}</div>
                  <Switch
                    checked={formTrip.spotQuote}
                    onChange={(e, newState) => {
                      const newTrip = { ...formTrip };
                      newTrip.spotQuote = newState;

                      onChange('formTrip', newTrip);
                    }}
                  />
                </div>
                {formTrip?.spotQuote && (
                  <>
                    <div className="quote-form mt-20">
                      <div className="d-grid gap-10 quote-2fr-1fr text-left border-bottom mb-2">
                        <div className="quote-form-labels d-grid quote-3fr">
                          <p>{t('quote.quantity')}</p>
                          <p>{t('quote.price')}</p>
                          <p>{t('quote.total')}</p>
                        </div>
                        <div className="quote-form-labels">
                          <p>{t('quote.vat.category')}</p>
                        </div>
                      </div>

                      {quoteForm?.lines &&
                        quoteForm.lines.map((item, index) => {
                          const taxRate = this.props.variables?.taxRates.filter(
                            (d) => d.value === item.taxRate?.id
                          )[0] || {
                            percentage: 0,
                          };

                          return (
                            <div key={item.nonce}>
                              <div className="d-flex justify-content-end gap-5 mb-2">
                                {quoteForm.lines.length !== 1 && (
                                  <div
                                    className="circle-icon-primary circle-sm svg-md"
                                    onClick={(e) =>
                                      changeValue('quoteForm', {
                                        ...quoteForm,
                                        lines: quoteForm.lines.filter(
                                          (line) => line.nonce !== item.nonce
                                        ),
                                      })
                                    }
                                  >
                                    <ReactSVG src="/icons/minus.svg" />
                                  </div>
                                )}
                                {quoteForm.lines.length - 1 === index && (
                                  <div
                                    className="circle-icon-primary circle-sm svg-md"
                                    onClick={(e) =>
                                      changeValue('quoteForm', {
                                        ...quoteForm,
                                        lines: [
                                          ...quoteForm.lines,
                                          {
                                            ...this.itemObject,
                                            nonce: uuidv4(),
                                            sequenceNr: index + 1,
                                          },
                                        ],
                                      })
                                    }
                                  >
                                    <ReactSVG src="/icons/plus.svg" />
                                  </div>
                                )}
                              </div>
                              <div className="d-grid gap-10 quote-2fr-1fr text-left">
                                <div className="quote-form-group">
                                  <div className="d-grid gap-10 quote-3fr mb-15">
                                    <FormInput
                                      name="quantity"
                                      type="number"
                                      label={t('form.label.quantity')}
                                      value={item?.quantity || ''}
                                      onChange={(e) => {
                                        this.amountInputChange(e, 'quantity', item);
                                      }}
                                    />

                                    <FormInput
                                      name="price"
                                      type="number"
                                      label={t('form.label.price')}
                                      value={item?.value?.amount || ''}
                                      onChange={(e) => {
                                        this.amountInputChange(e, 'price', item);
                                      }}
                                    />

                                    <FormInput
                                      name="total"
                                      label={t('form.label.total')}
                                      value={
                                        item?.quantity && item?.value?.amount
                                          ? quoteForm?.tax?.inclusiveOfTax
                                            ? parseFloat(item?.quantity) *
                                              parseFloat(item?.value?.amount)
                                            : parseFloat(item?.quantity) *
                                              parseFloat(item?.value?.amount) *
                                              (parseFloat((taxRate?.percentage || 0) + 100) / 100)
                                          : 0
                                      }
                                      isDisabled={true}
                                      type="number"
                                    />
                                  </div>
                                  <div className="quote-description">
                                    <FormInput
                                      type="textarea"
                                      className="tiny quote-description-field"
                                      name="description"
                                      value={item?.description || ''}
                                      onChange={(e) =>
                                        this.inputChange(e, {
                                          action: 'set-item-input',
                                          nonce: item?.nonce || item?.id,
                                        })
                                      }
                                      placeholder={t('form.label.description')}
                                    />
                                    <div className="quote-description-bottom">
                                      {t('form.label.period')}:{' '}
                                      <span
                                        className="text-underline quote-period"
                                        onClick={() =>
                                          this.setState({
                                            dateRangePicker: {
                                              isOpen: true,
                                              line: item,
                                            },
                                            isOpenDatePicker: false,
                                          })
                                        }
                                      >
                                        {item?.startDateTime && item?.endDateTime
                                          ? defaultDateFormat(
                                              item?.startDateTime,
                                              this.props?.dateFormat
                                            ) +
                                            ` ${t('to')} ` +
                                            defaultDateFormat(
                                              item?.endDateTime,
                                              this.props?.dateFormat
                                            )
                                          : t('not selected')}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="quote-form-labels input-group no-margin-top">
                                  <Select
                                    options={
                                      variables?.taxRates
                                        ? variables.taxRates.map((tax) => ({
                                            label: tax.name,
                                            value: tax.id,
                                            taxRate: tax,
                                          }))
                                        : null
                                    }
                                    value={
                                      this.taxRateOptions().filter(
                                        (d) => d.value === item?.value?.taxRate?.id
                                      )?.[0]
                                    }
                                    placeholder={t('quote.select.taxRate')}
                                    onChange={(event) => this.amountInputChange(event, 'tax', item)}
                                  />
                                  <Select
                                    className="my-10"
                                    options={
                                      variables?.pricingCategories
                                        ? variables.pricingCategories.map((category) => ({
                                            label: category.name,
                                            value: category.id,
                                            pricingCategory: category,
                                          }))
                                        : null
                                    }
                                    value={
                                      this.pricingCategoriesOptions() && item?.pricingCategory?.id
                                        ? this.pricingCategoriesOptions().filter(
                                            (d) => d.value === item?.pricingCategory.id
                                          )
                                        : []
                                    }
                                    placeholder={t('quote.select.pricingCategory')}
                                    onChange={(event) => {
                                      const newQuoteForm = { ...quoteForm };
                                      const newLines = [...newQuoteForm.lines];

                                      const updatedLines = newLines.filter(
                                        (line) => item?.nonce !== line.nonce || line?.id !== item.id
                                      );

                                      updatedLines.push({
                                        ...item,
                                        pricingCategory: event.pricingCategory,
                                      });

                                      newQuoteForm.lines = updatedLines.sort(
                                        (a, b) => a.sequenceNr - b.sequenceNr
                                      );
                                      changeValue('quoteForm', newQuoteForm);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      <div className="d-grid quote-2fr-1fr ">
                        <div className="d-flex gap-10 justify-content-between align-items-center">
                          <div className="d-flex gap-10 align-items-center"></div>
                          <div className="quote-total my-3 text-right">
                            <h3 className="m-0 font-default text-darkgray">
                              {t('quote.subtotal')}:{' '}
                              {`${quoteForm?.currency?.sign || '€'} ${parseFloat(
                                quoteForm?.subTotal.amount
                              ).toFixed(2)}`}
                            </h3>
                            <h3 className="my-10 font-default text-darkgray">
                              {t('quote.vat')}:{' '}
                              {`${quoteForm?.currency?.sign || '€'} ${parseFloat(
                                quoteForm?.tax.amount
                              ).toFixed(2)}`}
                            </h3>
                            <h2 className="m-0 font-default">
                              {t('quote.total')}:{' '}
                              {`${quoteForm?.currency?.sign || '€'} ${parseFloat(
                                quoteForm?.total.amount
                              ).toFixed(2)}`}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {formTrip.capacityContract && (
              <>
                {(formTrip.transportMode === 'maritime' ||
                  formTrip.capaciContract?.contract?.modality === 'maritime') && (
                  <div className="input-group">
                    <ExternalAttributesInput
                      disabledAdd={true}
                      externalAttributes={formTrip.externalAttributes}
                      required={['billOfLadingNumber', 'bookingNumber']}
                      onChange={(externalAttributes) => {
                        const newTrip = { ...formTrip };
                        newTrip.externalAttributes = externalAttributes;

                        onChange('formTrip', newTrip);
                      }}
                    />
                  </div>
                )}
                {(formTrip.transportMode === 'road' ||
                  formTrip.capaciContract?.contract?.modality === 'road') && (
                  <div className="input-group">
                    <ExternalAttributesInput
                      disabledAdd={true}
                      externalAttributes={formTrip.externalAttributes}
                      required={['bookingNumber']}
                      onChange={(externalAttributes) => {
                        const newTrip = { ...formTrip };
                        newTrip.externalAttributes = externalAttributes;

                        onChange('formTrip', newTrip);
                      }}
                    />
                  </div>
                )}
                {(formTrip.transportMode === 'air' ||
                  formTrip.capaciContract?.contract?.modality === 'air') && (
                  <div className="input-group">
                    <ExternalAttributesInput
                      disabledAdd={true}
                      externalAttributes={formTrip.externalAttributes}
                      required={[
                        'airlinePrefix',
                        'serialNumber',
                        'carrierCode',
                        'airwayBillNo',
                        'houseAirwayBillNo',
                      ]}
                      onChange={(externalAttributes) => {
                        const newTrip = { ...formTrip };
                        newTrip.externalAttributes = externalAttributes;

                        onChange('formTrip', newTrip);
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {(type === 'internal' ||
              type === 'internal_empty' ||
              formTrip.capacityContract?.capacity?.subType === 'vehicle') && (
              <div className="input-group vehicle">
                <div className="input-group no-margin-top">
                  <AsyncSelect
                    value={
                      formTrip.vehicle
                        ? formTrip.vehicle.entity
                          ? transformVehicleToValue(formTrip.vehicle.entity)
                          : transformVehicleToValue(formTrip.vehicle)
                        : null
                    }
                    isDisabled={targetVehicle ? true : false}
                    noOptionsMessage={() => t('vehicles.notFound')}
                    placeholder={t('form.label.searchVehicle')}
                    loadingMessage={() => t('loading')}
                    loadOptions={(inputValue, callback) => {
                      if (this.searchTimeout) clearTimeout(this.searchTimeout);
                      this.searchTimeout = setTimeout(() => {
                        searchVehicles({ query: inputValue }, 0, 20).then((response) => {
                          callback(
                            response.vehicles.map((vehicle) => transformVehicleToValue(vehicle))
                          );
                        });
                      }, 400);
                    }}
                    onChange={(event) => {
                      const newTrip = { ...formTrip };
                      newTrip.vehicle = event.vehicle;

                      onChange('formTrip', newTrip);
                    }}
                  />
                </div>
              </div>
            )}

            <div className="input-group upload-files">
              <h3 className="no-margin-top no-margin-bottom">{t('form.label.documents')}</h3>
              <Attachments
                {...this.props}
                key={formTrip.documents.map((document) => document.id).join('-')}
                files={formTrip.documents.map((document) => {
                  return document.entity.file;
                })}
                onChange={(files) => {
                  const newTrip = { ...formTrip };
                  newTrip.documents = files.map((file) => {
                    const newFile = { ...file };
                    return new Association('inline', {
                      name: newFile.originalName,
                      mimeType: newFile.mimeType,
                      content: {
                        contentType: 'uri',
                        uri: newFile.url,
                      },
                      file: newFile,
                    });
                  });
                  onChange('formTrip', newTrip);
                }}
              />
            </div>
            <div className="input-group">
              <div>{t('form.label.linkedToPreviousTrip')}</div>
              <Switch
                checked={formTrip.linkedTrip}
                onChange={(e, newState) => {
                  const newTrip = { ...formTrip };
                  newTrip.linkedTrip = newState;

                  onChange('formTrip', newTrip);
                }}
              />
            </div>

            {type !== 'internal_empty' && (
              <div className="input-group more consignment-selector">
                <ConsignmentSelector
                  {...this.props}
                  selectedConsignments={formTrip.consignments}
                  onChange={(consignments) => {
                    this.setState(
                      {
                        actions: getToBePlannedActions(consignments),
                      },
                      () => {
                        const newTrip = { ...formTrip };
                        newTrip.actions = this.state.actions;

                        onChange('formTrip', newTrip);
                      }
                    );
                  }}
                />
              </div>
            )}
            {(actions.length > 0 || type === 'internal_empty') && (
              <>
                <div className="input-group">
                  <ActionsInput
                    key={
                      actions
                        ? actions
                            .map((action) => `${action.entity.id}-${action.entity.nonce}`)
                            .join('-')
                        : 'no-actions'
                    }
                    actionTypes={
                      type === 'internal_empty'
                        ? [
                            'genericAction',
                            'load',
                            'unload',
                            'attachTransportEquipment',
                            'detachTransportEquipment',
                            'break',
                            'refuel',
                          ]
                        : [
                            'genericAction',
                            'unload',
                            'attachTransportEquipment',
                            'detachTransportEquipment',
                            'break',
                            'refuel',
                          ]
                    }
                    actions={actions}
                    onChange={(changedActions) => {
                      const newTrip = { ...formTrip };

                      const newActions = [
                        ...changedActions
                          .sort((a, b) => a.entity.sequenceNr - b.entity.sequenceNr)
                          .map((action, index) => {
                            const newAction = { ...action };
                            const entity = { ...newAction.entity };
                            entity.sequenceNr = index;
                            newAction.entity = entity;
                            return newAction;
                          }),
                      ];

                      newTrip.actions = newActions;

                      this.setState({
                        actions: newActions,
                        actionsHaveChanged: true,
                      });

                      onChange('formTrip', newTrip);
                    }}
                  />
                </div>
              </>
            )}
            {calculatingRoute ? (
              <Loader />
            ) : (
              <>
                {actions
                  .map((action) => action.entity)
                  .filter((a) => a && a.location && a.location.entity).length > 1 &&
                formTrip.vehicle ? (
                  <>
                    <hr />
                    <div className="input-group center">
                      <button
                        onClick={(e) => {
                          e.preventDefault();

                          this.calculateRoute({
                            name: formTrip.name,
                            vehicle: formTrip.vehicle,
                            actions: [...this.state.actions],
                          });
                        }}
                      >
                        {formTrip.route ? t('form.recalculateRoute') : t('form.calculateRoute')}
                      </button>
                      {formTrip.route && !actionsHaveChanged ? null : (
                        <div>
                          <i>{t('trip.hasToCalculateRoute')}</i>
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
                {route !== null && (
                  <>
                    <div className="trip-info">
                      <div className="trip-info__distance">
                        <div className="trip-info__label">{t('form.label.distance')}</div>
                        <div>{Math.round(route.distance / 1000)}KM</div>
                      </div>
                      <div className="trip-info__time">
                        <div className="trip-info__label">{t('form.label.duration')}</div>
                        <div>{momentToDuration(moment.duration(route.time, 'seconds'))}</div>
                      </div>
                    </div>
                    <h2>{t('trip.expenses')}</h2>
                    <TripExpenses user={user} expenses={route.expenses} />
                    <TripBreakdown
                      trip={{ id: '', actions: actions, route: route }}
                      dateFormat={dateFormat}
                    />
                    <div className="trip-map">
                      <MapContainer
                        className="trip-map__container"
                        bounds={bounds}
                        preferCanvas={false}
                        zoomControl={false}
                        boundsOptions={{ padding: [35, 35] }}
                      >
                        <TileLayers />
                        <FeatureGroup>
                          {locations.map((location, index) => (
                            <Marker
                              key={`${location.id}-${index}`}
                              icon={DefaultMarker}
                              position={[location.geoReference.lat, location.geoReference.lon]}
                            ></Marker>
                          ))}
                        </FeatureGroup>
                        <Polyline
                          key={route.id}
                          positions={PolyUtil.decode(route.lineString)}
                          color={'red'}
                        />
                      </MapContainer>
                    </div>
                  </>
                )}
              </>
            )}
            {this.validator.message(t('route.header'), formTrip.route, 'required')}
            <div className="input-group more right">
              <input
                type="submit"
                disabled={!this.validateForm() || actionsHaveChanged}
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
