import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import { formatAmount } from '@utils/moneyUtils';

class TripExpenses extends Component {
  render() {
    const { t, user, expenses } = this.props;

    if (expenses.length < 1) return null;

    return (
      <div className="trip-expenses">
        {expenses.map((expense) => (
          <div className="trip-expense" key={expense.id}>
            <div className="trip-expense__name">{expense.name}</div>
            <div className="trip-expense__description">
              {expense.description}
            </div>
            <div className="trip-expense__amount">
              {`${formatAmount(
                expense.money.amount,
                2,
                user?.language?.decimalDelimiter || ".",
                user?.language?.thousandDelimiter || ","
              )}`}
            </div>
          </div>
        ))}
        <div className="trip-expense trip-expenses__overview ">
          <div className="trip-expense__name"></div>
          <div className="trip-expense__name">{t('total')}</div>
          <div className="trip-expense__total">{`${formatAmount(
            expenses
              .map((expense) => expense.money.amount)
              .reduce((a, b) => a + b),
            2,
            user?.language?.decimalDelimiter || ".",
            user?.language?.thousandDelimiter || ","
          )}`}</div>
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(TripExpenses);
