import api from '../api';

/**
 * All methods related to the login processes
 */
export const login = (username = '', password = '', token = '') => {
  return api.post(`/user/login`, { username, password, token });
};

export const getLoginMethod = (username = '') => {
  return api.post(`/user/login-method`, { username });
};

export const getUserSession = (token = '') => {
  return api.get(`/user/session?token=${token}`);
};

/**
 * All methods related to the registration process
 */
export function register(
  username = '',
  email = '',
  password = '',
  repeatPassword = '',
  terms = false,
  newsletter = false,
  recaptcha = '',
  billingId = '',
  firstName = '',
  lastName = ''
) {
  return api.post(`/user/register`, {
    type: 'user',
    username: username,
    email: email,
    password: password,
    repeatPassword: repeatPassword,
    termsAndConditions: terms,
    newsletter: newsletter,
    recaptcha: recaptcha,
    billingId: billingId,
    firstName: firstName,
    lastName: lastName,
  });
}

export function developerRegister(
  username = '',
  email = '',
  password = '',
  repeatPassword = '',
  terms = false,
  newsletter = false,
  recaptcha = '',
  language = ''
) {
  return api.post(`/user/register`, {
    type: 'developer',
    username: username,
    email: email,
    password: password,
    repeatPassword: repeatPassword,
    terms: terms,
    newsletter: newsletter,
    recaptcha: recaptcha,
    language: language,
  });
}

export const forgot = (email = '') => {
  return api.post(`/user/forgot`, { email });
};

export const reset = (user, token, password, repeatPassword) => {
  return api.post(`/user/recover`, {
    id: user,
    token: token,
    password: password,
    repeatPassword: repeatPassword,
  });
};

export const updatePassword = (currentPassword, newPassword, repeatPassword) => {
  return api.put(`/user/password`, {
    currentPassword,
    newPassword,
    repeatPassword: repeatPassword,
  });
};

export const update = (user) => {
  return api.put(`/user`, { ...user });
};

export function getActiveSessions() {
  return api.get(`/user/sessions`);
}

export function logout() {
  return api.get(`/user/logout`);
}

export function logoutAllOthers() {
  return api.get(`/user/logout/all`);
}

export function confirmEmail(user = '', token = '') {
  return api.get(`/user/${user}/verify/${token}`);
}

// 2 factor

export const setup2Factor = () => {
  return api.post(`/user/2fa/setup`);
};

export const verify2Factor = (token) => {
  return api.post(`/user/2fa/verify`, { token });
};

export const disable2Factor = () => {
  return api.post(`/user/2fa/disable`);
};

export const recover2Factor = (recoveryCode) => {
  return api.post(`/user/2fa/recover`, { recoveryCode });
};

export const otpLogin = (token) => {
  return api.post(`/user/login:otp`, { token });
};

// Roles CRUD
export const getRole = (id) => {
  return api.get(`/role/${id}`);
};

export const createRole = (role) => {
  return api.post(`/role`, role);
};

export const updateRole = (role) => {
  return api.put(`/role`, role);
};
export const removeRole = (role) => {
  return api.delete(`/role/${role.id}`);
};

// Fetching and finding roles
export const allRoles = (from = 0, amount = 20) => {
  return api.get(`/roles/${from}/${amount}`);
};

export const searchRoles = (searchParameters, from = 0, amount = 20) => {
  return api.post(`/roles/${from}/${amount}`, searchParameters);
};
