import { workflowConstants } from '@constants/workflow.constants';

const initialState = {
  isFetchingWorkflow: false,
  formWorkflow: null,
  workflow: null,

  isFetchingWorkflows: false,
  isFetchingWorkflowsFrom: 0,
  workflowsTotalResults: 0,
  workflowsLoaded: 0,

  workflows: [],
};

export default function workflow(state = initialState, action) {
  switch (action.type) {
    case workflowConstants.WORKFLOW_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case workflowConstants.GET_WORKFLOW_STARTED:
      return { ...state, isFetchingWorkflow: true };
    case workflowConstants.GET_WORKFLOW_FAILURE:
      return {
        ...state,
        isFetchingWorkflow: false,
        error: action.message || '',
      };
    case workflowConstants.GET_WORKFLOW_SUCCESS:
      return {
        ...state,
        isFetchingWorkflow: false,
        workflow: action.workflow,
      };

    case workflowConstants.GET_WORKFLOWS_STARTED:
      return {
        ...state,
        isFetchingWorkflows: true,
        isFetchingWorkflowsFrom: action.from || 0,
      };
    case workflowConstants.GET_WORKFLOWS_FAILURE:
      return {
        ...state,
        isFetchingWorkflows: false,
        error: action.message || '',
      };
    case workflowConstants.GET_WORKFLOWS_SUCCESS:
      return {
        ...state,
        isFetchingWorkflows: false,
        workflows:
          action.from > 0
            ? [...state.workflows, ...action.workflows]
            : action.workflows,
        workflowsLoaded:
          action.from > 0
            ? state.workflows.length + action.workflows.length
            : action.workflows.length,
        workflowsTotalResults: action.totalResults || 0,
      };

    case workflowConstants.SEARCH_WORKFLOWS_STARTED:
      return {
        ...state,
        isFetchingWorkflows: true,
        isFetchingWorkflowsFrom: action.from || 0,
      };
    case workflowConstants.SEARCH_WORKFLOWS_FAILURE:
      return {
        ...state,
        isFetchingWorkflows: false,
        error: action.message || '',
      };
    case workflowConstants.SEARCH_WORKFLOWS_SUCCESS:
      return {
        ...state,
        isFetchingWorkflows: false,
        workflows:
          action.from > 0
            ? [...state.workflows, ...action.workflows]
            : action.workflows,
        workflowsLoaded:
          action.from > 0
            ? state.workflows.length + action.workflows.length
            : action.workflows.length,
        workflowsTotalResults: action.totalResults || 0,
      };

    case workflowConstants.CREATING_WORKFLOW_STARTED:
      return { ...state, isFetchingWorkflow: true };
    case workflowConstants.CREATING_WORKFLOW_FAILURE:
      return {
        ...state,
        isFetchingWorkflow: false,
        error: action.message || '',
      };
    case workflowConstants.CREATING_WORKFLOW_SUCCESS:
      return {
        ...state,
        isFetchingWorkflow: false,
        workflow: action.workflow,
        workflows: [action.workflow, ...state.workflows],
      };

    case workflowConstants.UPDATING_WORKFLOW_STARTED:
      return { ...state, isFetchingWorkflow: true };
    case workflowConstants.UPDATING_WORKFLOW_FAILURE:
      return {
        ...state,
        isFetchingWorkflow: false,
        error: action.message || '',
      };
    case workflowConstants.UPDATING_WORKFLOW_SUCCESS:
      return {
        ...state,
        isFetchingWorkflow: false,
        workflow: action.workflow,
        workflows: [...state.workflows].map((workflow) => {
          if (action.workflow.id === workflow.id) {
            return action.workflow;
          }
          return workflow;
        }),
      };

    case workflowConstants.DELETE_WORKFLOW_STARTED:
      return { ...state, isFetchingWorkflow: true };
    case workflowConstants.DELETE_WORKFLOW_FAILURE:
      return {
        ...state,
        isFetchingWorkflow: false,
        message: action.message || '',
      };
    case workflowConstants.DELETE_WORKFLOW_SUCCESS:
      return {
        ...state,
        isFetchingWorkflow: false,
        workflow: action.workflow,
        workflows: [...state.workflows].filter(
          (workflow) => action.workflow.id !== workflow.id
        ),
      };
    default:
      return state;
  }
}
