import React, { Component } from 'react';

import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import DeliveryTermsSelector from '@components/transportOrder/DeliveryTermsSelector';

import { activateInputs, deactivateInputs } from '@utils/formUtils';
import FormInput from '@uiinputs/FormInput';

export default class ContractForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      formContract: this.props.formContract || {},
    };
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  onChange(name, value, event) {
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmit, setHasChanged } = this.props;
    const { formContract } = this.state;
    onSubmit && onSubmit(formContract);
    setHasChanged(false);
  }

  render() {
    const { t, type, languages, setHasChanged } = this.props;
    const { formContract } = this.state;

    const languageOptions = languages.map((language) => {
      return { label: language.localName, value: language.id, language: language };
    });
    return (
      <div className="step-form" key={formContract.id}>
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div className="input-group">
              <div className="input-group no-margin-top">
                <FormInput
                  type="text"
                  setHasChanged={setHasChanged}
                  label={`${t('form.label.title')}*`}
                  value={formContract.title}
                  onChange={(event) => {
                    const newContract = { ...formContract };
                    newContract.title = event.target.value;

                    this.onChange('formContract', newContract, event);
                  }}
                />
              </div>
              {this.validator.message(t('form.label.title'), formContract.title, 'required')}
            </div>
            {type === 'capacity' && (
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <DeliveryTermsSelector
                    key={formContract.deliveryTerms}
                    value={formContract.deliveryTerms}
                    placeholder={`${t('form.label.selectDeliveryTerms')}*`}
                    onChange={(event) => {
                      const newContract = { ...formContract };
                      newContract.deliveryTerms = event.value;

                      this.onChange('formContract', newContract, event);
                    }}
                  />
                </div>
              </div>
            )}

            <div className="input-group">
              <Select
                placeholder={t('form.label.selectLanguage')}
                options={languageOptions}
                value={languageOptions.find(
                  (languageOption) => languageOption.value === formContract?.language?.id
                )}
                noOptionsMessage={() => t('noOptions')}
                onChange={(e) => {
                  const newContract = { ...formContract };
                  newContract.language = e.language;

                  this.onChange('formContract', newContract);
                }}
              />
            </div>
            <div className="input-group">
              <div className="input-group no-margin-top">
                <FormInput
                  type="textarea"
                  setHasChanged={setHasChanged}
                  label={t('form.label.contract')}
                  value={formContract.description}
                  onChange={(event) => {
                    const newContract = { ...formContract };
                    newContract.description = event.target.value;

                    this.onChange('formContract', newContract, event);
                  }}
                />
              </div>
            </div>
            <div className="input-group more right">
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
