import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateWorkflowContainer from '@containers/workflow/crud/CreateWorkflowContainer';

import * as navigationActions from '@actions/navigationActions';
import * as workflowActions from '@actions/workflowActions';
import { search as searchWorkflows } from '@api/workflowApi';

let searchTimeout = null;

export const transformWorkflowToValue = (workflow) => ({
  value: workflow.id,
  label: workflow.name,
  id: workflow.id,
  workflow: workflow,
});

class WorkflowInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workflow: this.props.value
        ? transformWorkflowToValue(this.props.value)
        : null,
      searchParameters: this.props.searchParameters
        ? this.props.searchParameters
        : {},
    };
  }

  static defaultProps = {
    isDisabled: false,
  };

  onChange = (newWorkflow) => {
    this.setState({
      workflow: newWorkflow,
    });
    const { onChange } = this.props;
    onChange && onChange(newWorkflow ? newWorkflow.workflow : null);
  };

  render() {
    const {
      t,
      placeholder,
      isFetchingWorkflows,
      addToStack,
      isDisabled,
    } = this.props;
    const { workflow, searchParameters } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          placeholder={
            placeholder ? placeholder : `${t('form.label.selectWorkflow')}`
          }
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) =>
            t('form.addCreatable', { value: inputValue })
          }
          isLoading={isFetchingWorkflows}
          isDisabled={isDisabled || isFetchingWorkflows}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              searchWorkflows(
                { query: inputValue, ...searchParameters },
                0,
                40
              ).then((response) => {
                callback(
                  response.workflows.map((workflow) =>
                    transformWorkflowToValue(workflow)
                  )
                );
              });
            }, 800);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('workflow.new'),
              component: (
                <CreateWorkflowContainer
                  targetName={inputValue}
                  callback={(newWorkflow) => {
                    this.onChange(transformWorkflowToValue(newWorkflow));
                  }}
                />
              ),
            });
          }}
          value={workflow}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.workflow,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(workflowActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(WorkflowInput));
