import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { MapContainer, Marker, Polyline, TileLayer } from 'react-leaflet';
import ReactSVG from 'react-svg';

import { latLngBounds } from 'leaflet';
import 'leaflet-rotatedmarker';
import moment from 'moment';
import PolyUtil from 'polyline-encoded';

import BigVehicleMarker from '@uimarker/default/BigVehicleMarker';
import TileLayers from '@uiviews/TileLayers';

import { getLocationUpdateEvents } from '@api/vehicleApi';

class VehicleMapView extends Component {
  constructor(props) {
    super(props);

    const { vehicle } = props;

    this.state = {
      latLonPointGeoReference: vehicle.latestLocation
        ? vehicle.latestLocation.geoReference
        : { lat: 0, lon: 0 },
      selectedDateTime: moment(),
      eventsIsFetching: false,
      events: [],
    };
  }

  async componentDidMount() {
    const { selectedDateTime } = this.state;
    const { vehicle } = this.props;

    this.setState({ eventsIsFetching: true });
    const response = await getLocationUpdateEvents(
      vehicle.id,
      selectedDateTime,
      selectedDateTime
    );
    const events = response.events;

    const minutes = this.getTimeFromPercentage(100);
    const newSelectedDateTime = selectedDateTime
      .clone()
      .startOf('day')
      .add('minute', minutes);

    this.setState({
      eventsIsFetching: false,
      events,
      selectedDateTime: newSelectedDateTime,
    });
  }

  componentWillUnmount() {
    const { map } = this.state;

    if (map) {
      map.off();
      map.remove();
      this.setState({
        map: null,
      });
    }
  }

  getClosestEvent = (dateTime) => {
    const { events } = this.state;

    const selectedDate = dateTime.toDate();

    const sortedEvents = [...events].sort((a, b) => {
      var distancea = Math.abs(selectedDate - moment(a.createdAt).toDate());
      var distanceb = Math.abs(selectedDate - moment(b.createdAt).toDate());
      return distancea - distanceb;
    });

    return sortedEvents[0] || null;
  };

  getTimePercentage = () => {
    const { selectedDateTime } = this.state;
    const now = moment();
    if (selectedDateTime.isSame(now, 'date')) {
      const mmtMidnight = selectedDateTime.clone().startOf('day');
      const mntNowMidnight = now.clone().startOf('day');

      return (
        (selectedDateTime.diff(mmtMidnight, 'minutes') /
          now.diff(mntNowMidnight, 'minutes')) *
        100
      );
    } else {
      const mmtMidnight = selectedDateTime.clone().startOf('day');
      return (selectedDateTime.diff(mmtMidnight, 'minutes') / 1440) * 100;
    }
  };

  getTimeFromPercentage = (percentage) => {
    const { selectedDateTime } = this.state;
    const now = moment();
    if (selectedDateTime.isSame(now, 'date')) {
      const mntNowMidnight = now.clone().startOf('day');
      return (percentage / 100) * now.diff(mntNowMidnight, 'minutes');
    } else {
      return (percentage / 100) * 1440;
    }
  };

  openInGoogleMaps = () => {
    const { latLonPointGeoReference } = this.state;
    window
      .open(
        `https://www.google.com/maps/search/?api=1&query=${latLonPointGeoReference.lat}%2C${latLonPointGeoReference.lon}`,
        '_blank'
      )
      .focus();
  };

  render() {
    const { vehicle } = this.props;
    const {
      events,
      selectedDateTime,
      eventsIsFetching,
      latLonPointGeoReference,
    } = this.state;
    const { trip } = vehicle;

    const moveActions =
      trip && trip.route && trip.route.actions
        ? trip.route.actions
            .map((association) => association.entity)
            .filter((entity) => entity.type === 'move')
            .sort((a, b) => a.sequenceNr - b.sequenceNr)
        : [];

    const latLonArrayPointGeoReference = trip?.route?.lineString
      ? PolyUtil.decode(trip?.route?.lineString)
      : trip?.route?.geoReferenceString
      ? JSON.parse(trip.route.geoReferenceString)?.points?.map((point) => [
          point.lat,
          point.lon,
        ])
      : null;

    const bounds = latLngBounds(
      latLonArrayPointGeoReference
        ? latLonArrayPointGeoReference
        : latLonPointGeoReference
        ? [
            [
              latLonPointGeoReference.lat - 0.005,
              latLonPointGeoReference.lon - 0.005,
            ],
            [
              latLonPointGeoReference.lat + 0.005,
              latLonPointGeoReference.lon + 0.005,
            ],
          ]
        : [[0, 0]]
    );

    return (
      <>
        <MapContainer
          className="vehicle-detail__map"
          preferCanvas={false}
          zoomControl={false}
          boundsOptions={{ padding: [35, 35] }}
          bounds={bounds}
          zoom={10}
          attributionControl={false}
          whenCreated={(map) => this.setState({ map })}
        >
          <TileLayers />
          {latLonPointGeoReference && (
            <Marker
              key={`${latLonPointGeoReference.id}`}
              icon={BigVehicleMarker}
              position={[
                latLonPointGeoReference.lat,
                latLonPointGeoReference.lon,
              ]}
              rotationOrigin="center"
              rotationAngle={-latLonPointGeoReference.heading?.value || 0}
              zIndexOffset={2}
            />
          )}
          {moveActions.reverse().map((moveAction) => {
            const encodedLineString = moveAction?.route?.entity?.lineString;

            if (encodedLineString) {
              return (
                <Polyline
                  positions={PolyUtil.decode(encodedLineString)}
                  color={moveAction.lifeCycle === 'actual' ? 'red' : 'green'}
                />
              );
            }

            const moveActionLatLonArrayPointGeoReference =
              moveAction.route && moveAction.route.entity.geoReferenceString
                ? JSON.parse(moveAction.route.entity.geoReferenceString)
                : null;

            if (
              moveActionLatLonArrayPointGeoReference &&
              moveActionLatLonArrayPointGeoReference.points &&
              moveActionLatLonArrayPointGeoReference.points.length > 0
            ) {
              return (
                <Polyline
                  key={moveActionLatLonArrayPointGeoReference.id}
                  positions={moveActionLatLonArrayPointGeoReference.points.map(
                    (point) => [point.lat, point.lon]
                  )}
                  color={moveAction.lifeCycle === 'actual' ? 'red' : 'green'}
                />
              );
            }
            return null;
          })}
          {events.length > 0 && (
            <Polyline
              positions={events.map((point) => [
                point.geoReference.lat,
                point.geoReference.lon,
              ])}
              color={'black'}
              dashedArray={[12, 12]}
            />
          )}
        </MapContainer>
        <ReactSVG
          src="/icons/maximize.svg"
          className="vehicle-detail__map__openInGoogle"
          onClick={(e) => {
            this.openInGoogleMaps();
          }}
        />
        <div className="vehicle-detail__map__history">
          <div className="time">
            <input
              type="date"
              value={selectedDateTime.format('YYYY-MM-DD')}
              onChange={(e) => {
                const newSelectedDateTime = moment(
                  `${e.target.value} ${selectedDateTime.format('HH:mm')}`,
                  'YYYY-MM-DD HH:mm'
                );
                const { vehicle } = this.props;

                this.setState({ eventsIsFetching: true });
                getLocationUpdateEvents(
                  vehicle.id,
                  newSelectedDateTime,
                  newSelectedDateTime
                ).then((response) => {
                  const events = response.events;

                  const minutes = this.getTimeFromPercentage(100);

                  this.setState({
                    eventsIsFetching: false,
                    events,
                    selectedDateTime: newSelectedDateTime
                      .clone()
                      .startOf('day')
                      .add('minute', minutes),
                  });
                });
              }}
            />
            {selectedDateTime.format('HH:mm')}
          </div>
          <div className="slider">
            <input
              type="range"
              value={this.getTimePercentage()}
              min={0}
              max={100}
              step={0.01}
              disabled={eventsIsFetching}
              onChange={(e) => {
                e.preventDefault();

                const minutes = this.getTimeFromPercentage(e.target.value);
                const newSelectedDateTime = selectedDateTime
                  .clone()
                  .startOf('day')
                  .add('minute', minutes);

                const closestEvent = this.getClosestEvent(newSelectedDateTime);

                this.setState({
                  selectedDateTime: newSelectedDateTime,
                  latLonPointGeoReference: closestEvent
                    ? closestEvent.geoReference
                    : latLonPointGeoReference,
                });
              }}
            />
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation('translation')(VehicleMapView);
