import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import BusinessContainer from '@containers/business/BusinessContainer';

class CustomerView extends Component {
  render() {
    const { t, customer, addToStack } = this.props;
    if (!customer) return null;
    return (
      <>
        {customer.business && (
          <span
            className="customer-view as-link"
            onClick={(e) => {
              addToStack({
                name: customer.business.name,
                component: <BusinessContainer business={customer.business} />,
              });
            }}
          >
            {customer.business.name}
          </span>
        )}
        {customer.customer && (
          <div>
            <b>{`${t('transportOrder.customer')} ${t('of')} ${customer.business.name}`}: </b>
            <CustomerView
              {...this.props}
              customer={customer.customer}
              addToStack={addToStack}
            />{' '}
          </div>
        )}
      </>
    );
  }
}

export default withTranslation('translation')(CustomerView);
