import React, { Component } from 'react';

import DatePicker from 'react-datepicker';
import Select from 'react-select';
import ReactSVG from 'react-svg';

import moment from 'moment';

import { registerLocales } from '@/i18n';
import { i18n } from '@/index';
import BusinessInput from '@components/business/BusinessInput';

import AsideView from '@uicomponents/AsideView';
import Attachments from '@uicomponents/Attachments';
import Loader from '@uicomponents/Loader';
import FormInput from '@uiinputs/FormInput';

import Association from '@models/general/Association';

import { uuidv4 } from '@utils/commonUtils';
import { defaultDateFormat } from '@utils/dateUtils';

import 'react-datepicker/dist/react-datepicker.css';

registerLocales();

export default class QuoteForm extends Component {
  constructor(props) {
    super(props);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      dateRangePicker: {
        line: null,
        isOpen: false,
      },
      isOpenDatePicker: false,
    };
  }

  componentDidMount() {
    const { changeValue, isEditQuote, quoteId, getQuote } = this.props;

    if (isEditQuote && quoteId) {
      getQuote(quoteId).then((response) => {
        changeValue('quoteForm', response.quote);
      });
    } else {
      changeValue('quoteForm', this.initialState);
    }
  }

  itemObject = {
    name: '',
    value: {
      amount: null,
      taxRate: this.props.variables?.taxRates[0],
    },
    sequenceNr: 0,
    quantity: 1,
    description: '',
    nonce: uuidv4(),
    pricingCategory: null,
    endDateTime: null,
    startDateTime: null,
  };

  initialState = {
    name: '',
    description: '',
    direction: this.props.direction,
    dueAt: defaultDateFormat(
      moment().add(this.props.administration?.dueDays || 14, 'days'),
      this.props?.dateFormat
    ),
    targetBusiness: this.props.targetBusiness,
    administration: this.props.administration,
    transportOrder: this.props.transportOrder,
    currency: this.props.currencies[0],
    lines: [{ ...this.itemObject }],
    subTotal: {
      amount: 0,
    },
    total: {
      amount: 0,
    },
    tax: {
      amount: 0,
      inclusiveOfTax: false,
    },
  };

  inputChange(event, payload = null) {
    const target = event.target;
    const { changeValue, quoteForm } = this.props;
    switch (payload?.action) {
      case 'vat':
        return changeValue('quoteForm', {
          ...quoteForm,
          tax: { ...quoteForm.tax, inclusiveOfTax: payload.value },
        });

      case 'set-input':
        return changeValue('quoteForm', {
          ...quoteForm,
          [target.name]: target.value,
        });

      case 'set-item-input':
        return changeValue('quoteForm', {
          ...quoteForm,
          lines: quoteForm.lines.map((item) =>
            item.nonce === payload.nonce
              ? {
                  ...item,
                  [target.name]: target.value,
                }
              : item
          ),
        });
    }
  }

  amountInputChange(event, action, value = null) {
    let total = 0;
    let subTotal = 0;
    let taxAmount = 0;
    const { target } = event;
    const { changeValue, quoteForm, variables } = this.props;

    const arr = {
      ...quoteForm,
      tax:
        action === 'inclusiveOfTax' ? { ...quoteForm.tax, inclusiveOfTax: value } : quoteForm.tax,
      lines: quoteForm.lines.map((item) =>
        (item?.nonce || item.id) !== (value?.nonce || value.id)
          ? item
          : action === 'price'
          ? {
              ...item,
              value: { ...item.value, amount: parseFloat(target.value) },
            }
          : action === 'quantity'
          ? {
              ...item,
              quantity: parseFloat(target.value),
            }
          : action === 'tax'
          ? {
              ...item,
              value: {
                ...item.value,
                taxRate: event.taxRate,
              },
              tax: event.taxRate,
            }
          : item
      ),
    };

    const inclusiveOfTax = arr.tax.inclusiveOfTax;

    arr.lines.forEach((item) => {
      if (item.quantity && item.value.amount) {
        const taxRate = variables?.taxRates.filter((d) => d.id === item.value.taxRate?.id)[0] || {
          percentage: 0,
        };

        if (inclusiveOfTax) {
          const rowSubtotal =
            (parseFloat(item.quantity) * parseFloat(item.value.amount)) /
            (((taxRate?.percentage || 0) + 100) / 100);
          const rowTaxamount = rowSubtotal * ((taxRate?.percentage || 0) / 100);
          subTotal += rowSubtotal;
          taxAmount += rowTaxamount;

          total += rowSubtotal + rowTaxamount;
        } else {
          const rowSubtotal = parseFloat(item.quantity) * parseFloat(item.value.amount);
          const rowTaxamount = rowSubtotal * ((taxRate?.percentage || 0) / 100);

          subTotal += rowSubtotal;
          taxAmount += rowTaxamount;

          total += rowSubtotal + rowTaxamount;
        }
      }
    });

    return changeValue('quoteForm', {
      ...arr,
      tax: {
        ...arr.tax,
        amount: parseFloat(taxAmount).toFixed(2),
      },
      total: {
        ...arr.total,
        amount: parseFloat(total).toFixed(2),
      },
      subTotal: { ...arr.subTotal, amount: parseFloat(subTotal).toFixed(2) },
    });
  }

  createQuote(e) {
    e.preventDefault();
    const { createQuote, quoteForm, popStack, callback } = this.props;
    createQuote?.(quoteForm);
    popStack?.();
    callback?.(quoteForm);
  }

  updateQuote(e) {
    e.preventDefault();
    const { updateQuote, quoteForm, popStack, callback } = this.props;
    updateQuote?.(quoteForm);
    popStack?.();
    callback?.(quoteForm);
  }

  currenciesOptions = () => {
    const { currencies } = this.props;
    return currencies
      ? currencies.map((item) => ({
          label: item.sign + ' - ' + item.name,
          value: item.id,
        }))
      : [];
  };

  taxRateOptions = () => {
    const { variables } = this.props;
    return variables?.taxRates?.length > 0
      ? variables.taxRates.map((tax) => ({ label: tax.name, value: tax.id }))
      : [];
  };

  pricingCategoriesOptions = () => {
    const { variables } = this.props;
    return variables?.pricingCategories?.length
      ? variables.pricingCategories.map((category) => ({
          label: category.name,
          value: category.id,
        }))
      : [];
  };

  dateRangePicker = (line) => {
    const { dateRangePicker } = this.state;
    const { quoteForm, changeValue } = this.props;

    return (
      <div className="model">
        <div className="model-content bg-white rounded text-center">
          <div className="d-flex-space-between">
            <div />
            <div
              className="circle-icon-primary circle-md svg-md"
              onClick={() =>
                this.setState({
                  dateRangePicker: {
                    line: null,
                    isOpen: false,
                  },
                })
              }
            >
              <ReactSVG src="/icons/cross.svg" />
            </div>
          </div>
          <DatePicker
            selected={dateRangePicker.start || null}
            startDate={dateRangePicker.start || null}
            endDate={dateRangePicker.end || null}
            showWeekNumbers={true}
            locale={i18n.language}
            selectsRange={true}
            inline
            onChange={(dates) => {
              const [start, end] = dates;
              if (start !== null && end !== null) {
                changeValue('quoteForm', {
                  ...quoteForm,
                  lines: quoteForm.lines.map((item) =>
                    (item?.nonce || item.id) === (line?.nonce || line.id)
                      ? {
                          ...item,
                          startDateTime: moment(start),
                          endDateTime: moment(end),
                        }
                      : item
                  ),
                });
                this.setState({
                  dateRangePicker: {
                    line: null,
                    isOpen: false,
                    start,
                    end,
                  },
                });
              } else {
                this.setState({
                  dateRangePicker: {
                    ...dateRangePicker,
                    start,
                    end,
                  },
                });
              }
            }}
          />
        </div>
      </div>
    );
  };

  /**
   * clear form inputs
   */
  componentWillUnmount() {
    const { changeValue } = this.props;
    changeValue('quoteForm', null);
    changeValue('isEditQuote', false);
    this.setState({
      isOpenDatePicker: false,
      dateRangePicker: { line: null, isOpen: false },
    });
  }

  render() {
    const { t, isEditQuote, quoteForm, changeValue, variables, isFetchingQuote, currencies } =
      this.props;
    const { dateRangePicker } = this.state;

    if (isFetchingQuote) {
      return (
        <div className="quotes">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        {dateRangePicker.isOpen &&
          dateRangePicker.line &&
          this.dateRangePicker(dateRangePicker.line)}

        <form className="quote-form-container no-padding-top">
          <div className="d-flex  justify-content-between align-items-center mb-2 container">
            <h1 className="no-margin-top">{isEditQuote ? t('quote.update') : t('quote.new')}</h1>

            <div className="d-flex">
              <button
                className={`light left ${!quoteForm?.tax?.inclusiveOfTax && 'active'}`}
                onClick={(e) => {
                  e.preventDefault();

                  this.inputChange(e, { action: 'vat', value: false });
                  this.amountInputChange(e, 'inclusiveOfTax', false);
                }}
              >
                {t('quote.excluding.vat')}
              </button>
              <button
                className={`light right ${quoteForm?.tax?.inclusiveOfTax && 'active'}`}
                onClick={(e) => {
                  e.preventDefault();

                  this.inputChange(e, { action: 'vat', value: true });
                  this.amountInputChange(e, 'inclusiveOfTax', true);
                }}
              >
                {t('quote.including.vat')}
              </button>
            </div>
          </div>

          <div className="d-grid gap-20 quote-1f-1fr text-left font-md mb-2 mt-20">
            <div className="d-grid quote-1f-2fr align-items-center input-group no-margin-top">
              <div className="font-default text-darkgray font-bold">
                {t('form.label.description')}
              </div>
              <FormInput
                type="text"
                name="description"
                value={quoteForm?.description}
                onChange={(e) => this.inputChange(e, { action: 'set-input' })}
              />
            </div>

            <div className="d-grid quote-1f-2fr align-items-center input-group no-margin-top">
              <div className="font-default text-darkgray font-bold">
                {t('form.label.targetBusiness')}
              </div>
              <BusinessInput
                key={quoteForm?.targetBusiness?.id}
                value={quoteForm?.targetBusiness}
                onChange={(e) => {
                  changeValue('quoteForm', {
                    ...quoteForm,
                    targetBusiness: e,
                  });
                }}
              />
            </div>

            <div className="d-grid quote-1f-2fr align-items-center">
              <div className="font-default text-darkgray font-bold">{t('form.label.dueAt')}</div>

              <div
                className="quote-date-input-field"
                onClick={() =>
                  this.setState((state) => ({
                    ...state,
                    isOpenDatePicker: true,
                  }))
                }
              >
                {quoteForm?.dueAt
                  ? defaultDateFormat(quoteForm.dueAt, this.props?.dateFormat)
                  : null}
              </div>

              {this.state.isOpenDatePicker && (
                <div className="model">
                  <div className="model-content bg-white rounded text-center">
                    <div className="d-flex-space-between">
                      <div />
                      <div
                        className="circle-icon-primary circle-md svg-md"
                        onClick={() =>
                          this.setState({
                            isOpenDatePicker: false,
                          })
                        }
                      >
                        <ReactSVG src="/icons/cross.svg" />
                      </div>
                    </div>
                    <DatePicker
                      key={'dateRange'}
                      selected={quoteForm?.dueAt ? moment(quoteForm.dueAt).toDate() : null}
                      showWeekNumbers={true}
                      locale={i18n.language}
                      inline
                      onChange={(date) => {
                        changeValue('quoteForm', {
                          ...quoteForm,
                          dueAt: date,
                        });

                        this.setState({
                          isOpenDatePicker: false,
                        });
                      }}
                      minimumDate={moment().add(1, 'days').toDate()}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="d-grid invoice-1f-2fr align-items-center input-group no-margin-top">
              <div className="font-default text-darkgray font-bold ">
                {t('form.label.documents')}
              </div>
              <Attachments
                {...this.props}
                key={quoteForm?.documents}
                files={quoteForm?.documents?.map((document) => {
                  return document.entity.file;
                })}
                onChange={(files) => {
                  const newInvoiceForm = { ...quoteForm };
                  newInvoiceForm.documents = files.map((file) => {
                    const newFile = { ...file };
                    return new Association('inline', {
                      name: newFile.originalName,
                      mimeType: newFile.mimeType,
                      content: {
                        contentType: 'uri',
                        uri: newFile.url,
                      },
                      file: newFile,
                    });
                  });
                  changeValue('quoteForm', newInvoiceForm);
                }}
              />
            </div>
            <div className="d-grid quote-1f-2fr align-items-center input-group no-margin-top">
              <div className="font-default text-darkgray font-bold ">{t('pricing.currency')}</div>
              <Select
                options={this.currenciesOptions()}
                value={
                  quoteForm?.currency?.id
                    ? this.currenciesOptions()
                        .filter((d) => d.value === quoteForm.currency.id)
                        .shift()
                    : []
                }
                placeholder={t('pricing.currency')}
                onChange={(e) =>
                  changeValue('quoteForm', {
                    ...quoteForm,
                    currency: currencies.filter((d) => d.id === e.value).shift(),
                  })
                }
              />
            </div>
          </div>

          <div className="quote-form mt-20">
            <div className="d-grid gap-10 quote-2fr-1fr text-left border-bottom mb-2">
              <div className="quote-form-labels d-grid quote-3fr">
                <p>{t('quote.quantity')}</p>
                <p>{t('quote.price')}</p>
                <p>{t('quote.total')}</p>
              </div>
              <div className="quote-form-labels">
                <p>{t('quote.vat.category')}</p>
              </div>
            </div>

            {quoteForm?.lines &&
              quoteForm.lines.map((item, index) => {
                const taxRate = variables?.taxRates.filter(
                  (d) => d.value === item.taxRate?.id
                )[0] || {
                  percentage: 0,
                };

                return (
                  <div key={item.nonce}>
                    <div className="d-flex justify-content-end gap-5 mb-2">
                      {quoteForm.lines.length !== 1 && (
                        <div
                          className="circle-icon-primary circle-sm svg-md"
                          onClick={(e) =>
                            changeValue('quoteForm', {
                              ...quoteForm,
                              lines: quoteForm.lines.filter((line) => line.nonce !== item.nonce),
                            })
                          }
                        >
                          <ReactSVG src="/icons/minus.svg" />
                        </div>
                      )}
                      {quoteForm.lines.length - 1 === index && (
                        <div
                          className="circle-icon-primary circle-sm svg-md"
                          onClick={(e) =>
                            changeValue('quoteForm', {
                              ...quoteForm,
                              lines: [
                                ...quoteForm.lines,
                                {
                                  ...this.itemObject,
                                  nonce: uuidv4(),
                                  sequenceNr: index + 1,
                                },
                              ],
                            })
                          }
                        >
                          <ReactSVG src="/icons/plus.svg" />
                        </div>
                      )}
                    </div>
                    <div className="d-grid gap-10 quote-2fr-1fr text-left">
                      <div className="quote-form-group">
                        <div className="d-grid gap-10 quote-3fr mb-15">
                          <FormInput
                            name="quantity"
                            type="number"
                            label={t('form.label.quantity')}
                            value={item?.quantity || ''}
                            onChange={(e) => {
                              this.amountInputChange(e, 'quantity', item);
                            }}
                          />

                          <FormInput
                            name="price"
                            type="number"
                            label={t('form.label.price')}
                            value={item?.value?.amount || ''}
                            onChange={(e) => {
                              this.amountInputChange(e, 'price', item);
                            }}
                          />

                          <FormInput
                            name="total"
                            label={t('form.label.total')}
                            value={
                              item.quantity && item.value.amount
                                ? quoteForm?.tax?.inclusiveOfTax
                                  ? parseFloat(item.quantity) * parseFloat(item.value.amount)
                                  : parseFloat(item.quantity) *
                                    parseFloat(item.value.amount) *
                                    (parseFloat((taxRate?.percentage || 0) + 100) / 100)
                                : 0
                            }
                            isDisabled={true}
                            type="number"
                          />
                        </div>
                        <div className="quote-description">
                          <FormInput
                            type="textarea"
                            className="tiny quote-description-field"
                            name="description"
                            value={item.description || ''}
                            onChange={(e) =>
                              this.inputChange(e, {
                                action: 'set-item-input',
                                nonce: item.nonce || item.id,
                              })
                            }
                            placeholder={t('form.label.description')}
                          />
                          <div className="quote-description-bottom">
                            {t('form.label.period')}:{' '}
                            <span
                              className="text-underline quote-period"
                              onClick={() =>
                                this.setState({
                                  dateRangePicker: {
                                    isOpen: true,
                                    line: item,
                                  },
                                  isOpenDatePicker: false,
                                })
                              }
                            >
                              {item.startDateTime && item.endDateTime
                                ? defaultDateFormat(item.startDateTime, this.props?.dateFormat) +
                                  ` ${t('to')} ` +
                                  defaultDateFormat(item.endDateTime, this.props?.dateFormat)
                                : t('not selected')}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="quote-form-labels input-group no-margin-top">
                        <Select
                          options={
                            variables?.taxRates
                              ? variables.taxRates.map((tax) => ({
                                  label: tax.name,
                                  value: tax.id,
                                  taxRate: tax,
                                }))
                              : null
                          }
                          value={
                            this.taxRateOptions().filter(
                              (d) => d.value === item?.value?.taxRate?.id
                            )?.[0]
                          }
                          placeholder={t('quote.select.taxRate')}
                          onChange={(event) => this.amountInputChange(event, 'tax', item)}
                        />
                        <Select
                          className="my-10"
                          options={
                            variables?.pricingCategories
                              ? variables.pricingCategories.map((category) => ({
                                  label: category.name,
                                  value: category.id,
                                  pricingCategory: category,
                                }))
                              : null
                          }
                          value={
                            this.pricingCategoriesOptions() && item?.pricingCategory?.id
                              ? this.pricingCategoriesOptions().filter(
                                  (d) => d.value === item.pricingCategory.id
                                )
                              : []
                          }
                          placeholder={t('quote.select.pricingCategory')}
                          onChange={(event) => {
                            const newQuoteForm = { ...quoteForm };
                            const newLines = [...newQuoteForm.lines];

                            const updatedLines = newLines.filter(
                              (line) => item?.nonce !== line.nonce || line?.id !== item.id
                            );

                            updatedLines.push({
                              ...item,
                              pricingCategory: event.pricingCategory,
                            });

                            newQuoteForm.lines = updatedLines.sort(
                              (a, b) => a.sequenceNr - b.sequenceNr
                            );
                            changeValue('quoteForm', newQuoteForm);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

            <div className="d-grid quote-2fr-1fr ">
              <div className="d-flex gap-10 justify-content-between align-items-center">
                <div className="d-flex gap-10 align-items-center"></div>
                <div className="quote-total my-3 text-right">
                  <h3 className="m-0 font-default text-darkgray">
                    {t('quote.subtotal')}:{' '}
                    {`${quoteForm?.currency?.sign || '€'} ${parseFloat(
                      quoteForm?.subTotal.amount
                    ).toFixed(2)}`}
                  </h3>
                  <h3 className="my-10 font-default text-darkgray">
                    {t('quote.vat')}:{' '}
                    {`${quoteForm?.currency?.sign || '€'} ${parseFloat(
                      quoteForm?.tax.amount
                    ).toFixed(2)}`}
                  </h3>
                  <h2 className="m-0 font-default">
                    {t('quote.total')}:{' '}
                    {`${quoteForm?.currency?.sign || '€'} ${parseFloat(
                      quoteForm?.total.amount
                    ).toFixed(2)}`}
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="quote-footer-actions mb-2">
            <button onClick={(e) => (isEditQuote ? this.updateQuote(e) : this.createQuote(e))}>
              {t('form.save')}
            </button>
          </div>
        </form>
      </AsideView>
    );
  }
}
