import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ConsignmentView from '@components/consignment/ConsignmentView';

import * as consignmentActions from '@actions/consignmentActions';
import * as navigationActions from '@actions/navigationActions';

class ConsignmentContainer extends PureComponent {
  static defaultProps = {
    notStackedFromTransportOrder: false,
  };

  componentDidMount() {
    const { ownProps, changeValue, getConsignment } = this.props;
    if (ownProps.consignment) {
      changeValue('consignment', ownProps.consignment);
    } else if (ownProps.consignmentId) {
      getConsignment(ownProps.consignmentId);
    }
  }

  render() {
    const {
      getConsignment,
      deleteConsignment,
      notStackedFromTransportOrder,
      dateFormat,
      ...props
    } = this.props;
    return (
      <ConsignmentView
        {...props}
        notStackedFromTransportOrder={notStackedFromTransportOrder}
        getConsignment={(id = '') => getConsignment(id)}
        deleteConsignment={(consignment) => deleteConsignment(consignment)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.consignment,
    ownProps: ownProps,
    platform: state.platform.platform,
    notStackedFromTransportOrder: ownProps.notStackedFromTransportOrder || false,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY',
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(consignmentActions.changeValue(name, value)),

    getConsignment: (id) => dispatch(consignmentActions.getConsignment(id)),
    deleteConsignment: (consignment) => dispatch(consignmentActions.deleteConsignment(consignment)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(ConsignmentContainer));
