import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/workflow/${id}`);
};

export const create = (workflow) => {
  return api.post(`/workflow`, workflow);
};

export const update = (workflow) => {
  return api.put(`/workflow`, workflow);
};

export const remove = (workflow) => {
  return api.delete(`/workflow/${workflow.id}`);
};

// Fetch and Search
export const latest = (offset = 0, amount = 20) => {
  return api.get(`/workflows/${offset}/${amount}`);
};

export const search = (searchParameters = [], offset = 0, amount = 20) => {
  return api.post(`/workflows/${offset}/${amount}`, searchParameters);
};
