import React, { Component } from 'react';

import ReactSVG from 'react-svg';

import ToDoCard from '@components/todo/overview/ToDoCard';
import TodoFormContainer from '@containers/todo/todo/TodoFormContainer';

import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';

export default class EntityTodoSection extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { getEntityTodos, relatedEntityType, relatedEntity } = this.props;

    getEntityTodos(relatedEntityType, relatedEntity.id, 0, 10);

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const {
      getEntityTodos,
      relatedEntityType,
      relatedEntity,
      entityTodosLoaded,
      isFetchingEntityTodos,
      entityTodosTotalResults,
    } = this.props;

    if (
      window.innerHeight + window.scrollY >= document.querySelector('.page').offsetHeight - 50 &&
      !isFetchingEntityTodos &&
      entityTodosTotalResults > entityTodosLoaded
    ) {
      getEntityTodos(relatedEntityType, relatedEntity.id, entityTodosLoaded, 10);
    }
  };

  render() {
    const {
      t,
      addToStack,

      entityTodos,
      relatedEntity,
      relatedEntityType,
      isFetchingEntityTodos,
      isFetchingEntityTodosFrom,
    } = this.props;

    return (
      <div className="todo-wrapper" style={{ minHeight: 'unset' }}>
        <div className="d-flex align-items-center justify-content-between mb-15">
          <div className="flex-container column align-items-start">
            <p className="no-margin">
              {t('todo.entityView.subtitle.one')}{' '}
              {relatedEntity?.name ||
                relatedEntity?.title ||
                `${relatedEntity?.firstName} ${relatedEntity?.lastName}`}{' '}
              {t('todo.entityView.subtitle.two')}
            </p>
          </div>
          <div className="action-icons">
            <div
              className="svg-lg cursor-pointer action-icon"
              onClick={(e) => {
                addToStack({
                  component: (
                    <TodoFormContainer
                      relatedEntity={relatedEntity}
                      relatedEntityType={relatedEntityType}
                    />
                  ),
                });
              }}
            >
              <ReactSVG src="/icons/plus.svg" />
            </div>
          </div>
        </div>

        {(isFetchingEntityTodos && isFetchingEntityTodosFrom === 0) || !entityTodos ? (
          <Loader />
        ) : (
          <div key={`entity-todos-${entityTodos?.length}`}>
            {entityTodos?.length === 0 ? (
              <NoData>{t('todo.todos.notFound')}</NoData>
            ) : (
              entityTodos?.map((todo, index) => (
                <ToDoCard {...this.props} key={`entity-todo-${index}`} toDo={todo} />
              ))
            )}
          </div>
        )}
      </div>
    );
  }
}
