import React, { Component } from 'react';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

class FormInput extends Component {
  static defaultProps = {
    type: 'text',
  };

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleChange = (e) => {
    const { onChange, setHasChanged } = this.props;
    if (setHasChanged) {
      setHasChanged(true);
    }
    if (onChange) {
      onChange(e);
    } else {
      console.log('missing onChange');
    }
  };

  render() {
    const {
      type,
      name,
      placeholder,
      label,
      className,
      id,
      disabled,
      isDisabled,
      value,
      max,
      min,
      inputRef,
      maxLength,
      onBlur,
      onChange,
    } = this.props;

    return (
      <div className="input-group no-margin-top">
        {type === 'textarea' ? (
          <textarea
            ref={inputRef}
            name={name}
            className={`${className || ''}`}
            placeholder={placeholder || ''}
            id={id}
            value={value}
            disabled={isDisabled || disabled}
            maxLength={maxLength}
            onChange={(e, payload) =>
              onChange ? onChange(e, payload) : console.log('missing onChange')
            }
            onBlur={onBlur}
          ></textarea>
        ) : (
          <input
            ref={inputRef}
            type={type || 'text'}
            name={name}
            className={`${className || ''}`}
            placeholder={placeholder || ''}
            id={id}
            value={value}
            max={max}
            min={min}
            disabled={isDisabled || disabled}
            maxLength={maxLength}
            onChange={(e, payload) =>
              onChange ? onChange(e, payload) : console.log('missing onChange')
            }
            onBlur={onBlur}
          />
        )}
        {label && <label>{label}</label>}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => <FormInput {...props} inputRef={ref} />);
