import React, { Component } from 'react';

import ReactSVG from 'react-svg';

import moment from 'moment';

import SelectConsignmentsView from '@components/consignment/SelectConsignmentsView';

import FilePreview from '@uicomponents/FilePreview';
import FileViewer from '@uicomponents/FileViewer';
import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';
import OptionsMenu from '@uicomponents/OptionsMenu';

import * as chatApi from '@api/chatApi';
import Association from '@models/general/Association';
import { defaultDateFormat } from '@utils/dateUtils';

class MessageRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: props.group,
      translateMessage: false,
      isTranslationInProgress: false,
    };
  }

  translateMessage = (group) => {
    const translatedMessages = [];

    group.forEach((message, index) => {
      if (message.translatedMessage.length === 0) {
        chatApi
          .translateMessage(message.id)
          .then((result) => {
            if (result.success) {
              translatedMessages.push(result.message);
              this.setState({
                group: translatedMessages
                  .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                  .reverse(),
              });
              if (index === group.length - 1) {
                this.setState({ isTranslationInProgress: false });
              }
            }
          })
          .catch((error) => console.error(error));
      } else {
        translatedMessages.push(message);
        this.setState({
          group: translatedMessages
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .reverse(),
        });
        if (index === group.length - 1) {
          this.setState({ isTranslationInProgress: false });
        }
      }
    });
  };

  render() {
    const { group, translateMessage, isTranslationInProgress } = this.state;
    const {
      t,
      chat,
      trip,
      isMe,
      addToStack,
      getChatUser,
      updateTripAddDocuments,
      updateConsignmentAddDocuments,
    } = this.props;

    return (
      <>
        {group.map((gMessage) => {
          return (
            <div
              className={`message-row${gMessage.replyTo ? ' message-row--has-reply' : ''}${
                gMessage.error ? 'message-row--error' : ''
              }`}
              key={`${gMessage.id}-${gMessage.nonce}`}
            >
              <div className="message-row-content">
                {gMessage.replyTo && (
                  <div
                    className={`message message--referenced`}
                    dangerouslySetInnerHTML={{
                      __html: gMessage.replyTo.message,
                    }}
                  ></div>
                )}
                {gMessage.message && (
                  <div
                    className={`message${gMessage.isOffline ? ' offline' : ''}${
                      gMessage.error ? 'message--error' : ''
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: translateMessage ? gMessage.translatedMessage : gMessage.message,
                    }}
                  ></div>
                )}
                {gMessage.files && gMessage.files.length > 0 ? (
                  <div className="files">
                    {gMessage.files.map((file) => (
                      <div
                      key={`file-${file.id}`}
                        className="file"
                        onClick={(e) => {
                          console.log()
                          this.setState({
                            filePreviewOpen: true,
                            files: gMessage.files,
                            focussedFile: file,
                          });
                        }}
                      >
                        <FilePreview file={file} />
                      </div>
                    ))}
                  </div>
                ) : null}
                {gMessage.error ? (
                  <a
                    onClick={(e) => {
                      e.preventDefault();

                      this.retry();
                    }}
                  >
                    {t('retry')}
                  </a>
                ) : null}
              </div>
              <div className="flex-container self-center">
                <OptionsMenu
                  className="self-center"
                  items={
                    trip &&
                    gMessage.files.filter(
                      (file) =>
                        !trip.documents.map((doc) => doc.entity.file.uuid).includes(file.uuid)
                    ).length > 0
                      ? [
                          {
                            name: t('chat.messages.files.attachToTrip'),
                            onClick: (e) => {
                              e.preventDefault();
                              const newTrip = { ...trip };

                              const newDocuments = [
                                ...gMessage.files
                                  .filter(
                                    (file) =>
                                      !trip.documents.map((doc) => doc.uuid).includes(file.uuid)
                                  )
                                  .map((file) => {
                                    const newFile = { ...file };
                                    return new Association('inline', {
                                      uuid: newFile.uuid,
                                      name: newFile.originalName,
                                      mimeType: newFile.mimeType,
                                      content: {
                                        contentType: 'uri',
                                        uri: newFile.url,
                                      },
                                      file: newFile,
                                    });
                                  }),
                              ];

                              const consignments = [...(trip?.consignments || [])];
                              if (consignments.length === 1) {
                                // auto select consignment
                                updateConsignmentAddDocuments(
                                  consignments[0].entity,
                                  newDocuments
                                ).then(() => {
                                  updateTripAddDocuments(newTrip, newDocuments);
                                });
                              } else if (consignments.length > 0) {
                                // show selector
                                addToStack({
                                  name: trip?.name,
                                  component: (
                                    <SelectConsignmentsView
                                      header={t('chat.selectConsignments')}
                                      consignments={newTrip.consignments.map(
                                        (association) => association.entity
                                      )}
                                      onSubmit={(consignments) => {
                                        consignments
                                          .map((association) => association.entity)
                                          .forEach((consignment) => {
                                            updateConsignmentAddDocuments(
                                              consignment,
                                              newDocuments
                                            );
                                          });
                                      }}
                                    />
                                  ),
                                });

                                // meanwhile execute trip update
                                updateTripAddDocuments(newTrip, newDocuments);
                              } else {
                                updateTripAddDocuments(newTrip, newDocuments);
                              }
                            },
                          },
                        ]
                      : null
                  }
                />
                <div className="checks">
                  {chat.users?.every((u) =>
                    gMessage.received?.map((r) => r.user)?.includes(u.id)
                  ) ? (
                    <>
                      <div
                        className={`check${
                          gMessage.received.every((r) =>
                            gMessage.read?.map((u) => u.user)?.includes(r.user)
                          )
                            ? ' check--all'
                            : ''
                        }`}
                      >
                        <ReactSVG src="/icons/checkmark.svg" />
                      </div>
                      <div
                        className={`check${
                          gMessage.received.every((r) =>
                            gMessage.read?.map((u) => u.user)?.includes(r.user)
                          )
                            ? ' check--all'
                            : ''
                        }`}
                      >
                        <ReactSVG src="/icons/checkmark.svg" />
                      </div>
                    </>
                  ) : (
                    <div
                      className={`check${
                        gMessage.received?.every((r) =>
                          gMessage.read?.map((u) => u.user)?.includes(r.user)
                        )
                          ? ' check--all'
                          : ''
                      }`}
                    >
                      <ReactSVG src="/icons/checkmark.svg" />
                    </div>
                  )}
                  {gMessage?.read?.some((r) => getChatUser(r.user)?.vehicle) && (
                    <div className={`check check--all`}>
                      <ReactSVG src="/icons/profile.svg" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {!isMe && (
          <div
            className={`translation-button ${isTranslationInProgress ? 'disable' : 'enable'}`}
            onClick={(e) => {
              this.setState({ translateMessage: !this.state.translateMessage }, () => {
                if (this.state.translateMessage) {
                  this.setState({ isTranslationInProgress: true });
                  this.translateMessage(group);
                }
              });
            }}
          >
            <span className="font-sm text-gray cursor-pointer">
              {translateMessage ? t('chat.seeOriginal') : t('chat.seeTranslation')}
            </span>
          </div>
        )}
      </>
    );
  }
}

export default class Messages extends Component {
  constructor(props) {
    super(props);

    this.messageListRef = null;

    this.state = {
      isAtBottom: true,
      filePreviewOpen: false,
      fileList: [],
      fileFocus: 0,
    };
  }

  componentDidMount() {
    const { getMessages } = this.props;
    getMessages(0, 40);
  }

  componentDidUpdate(prevProps) {
    const { messages, readMessages } = this.props;

    if (
      (prevProps.messages.length !== messages.length && messages.length > 0) ||
      (prevProps.messages.length > 0 &&
        messages.length > 0 &&
        prevProps.messages[0][0].id !== messages[0][0].id)
    ) {
      readMessages();
    }
  }

  componentWillUnmount() {
    this.messageListRef = null;
  }

  getChatUser = (id) => {
    const { chat } = this.props;
    return chat?.users?.find((user) => user.id === id);
  };

  onScroll = (e) => {
    const scrollHeight = e.target.scrollHeight;
    const height = this.messageListRef.clientHeight;
    const maxScrollTop = scrollHeight - height;
    const { getMessages, messagesLoaded, messagesIsFetching, messagesTotalResults } = this.props;

    if (
      e.target.scrollTop + scrollHeight - e.target.offsetHeight < 300 &&
      !messagesIsFetching &&
      messagesLoaded < messagesTotalResults
    ) {
      getMessages(messagesLoaded, 40);
    }

    if (e.target.scrollTop !== maxScrollTop && this.state.isAtBottom) {
      this.setState({ isAtBottom: false });
    }
  };

  _renderMessageGroup(group, previousGroup) {
    const { t, user, messagesLoaded, messagesTotalResults } = this.props;
    const message = group[0];
    const chatUser = this.getChatUser(message.user?.id || message.user);

    if (!chatUser) return null;
    const isMe = user?.id === chatUser?.user?.id;

    return (
      <div
        className={`msg-container ${isMe ? 'me' : ''}`}
        key={`${message.id}-${message.nonce}-${group.length}`}
      >
        {previousGroup &&
          defaultDateFormat(previousGroup[0].updatedAt, this.props?.dateFormat) !==
            defaultDateFormat(message.updatedAt, this.props?.dateFormat) ? (  
          <div>
            <div className="day-seperator">
              <div className="date">{moment(message.updatedAt).format('DD MMM')}</div>
            </div>
          </div>
        ) : !previousGroup && messagesLoaded >= messagesTotalResults ? (
          <div>
            <div className="day-seperator">
              <div className="date">
                {t('chat.startedAt')} {moment(message.updatedAt).format('DD MMM')}
              </div>
            </div>
          </div>
        ) : !previousGroup ? (
          <Loader />
        ) : null}
        <div className="flex-container msg">
          <img
            src={
              chatUser?.user && chatUser?.user?.avatar
                ? chatUser.user.avatar.url
                : '/images/user.svg'
            }
            alt={`avatar ${chatUser.user ? chatUser.user.firstName : ''}`}
          />
          <div className="msg-container">
            <div className={`name${isMe ? '' : ' name--highlighted'}`}>
              {chatUser.user
                ? `${chatUser.user.firstName} ${chatUser.user.lastName}`
                : chatUser.vehicle
                ? chatUser.vehicle.name
                : chatUser.chauffeur
                ? `${chatUser.chauffeur.firstName} ${chatUser.chauffeur.lastName}`
                : t('chat.user.noname')}
              <span>{moment(message.updatedAt).format('HH:mm')}</span>
            </div>
            <MessageRow
              {...this.props}
              group={group}
              isMe={isMe}
              getChatUser={(id) => this.getChatUser(id)}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { t, chat, messagesIsFetching, messagesIsFetchingFrom, messages, dateFormat } = this.props;
    const { filePreviewOpen, files, focussedFile } = this.state;

    if (messagesIsFetching && messagesIsFetchingFrom === 0) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }
    return (
      <div
        className="messages scrollable"
        ref={(div) => (this.messageListRef = div)}
        onScroll={(e) => this.onScroll(e)}
        key={`${chat.id}-${chat.nonce}`}
      >
        <FileViewer
          t={t}
          isActive={filePreviewOpen}
          files={files}
          file={focussedFile}
          onClose={() => {
            this.setState({
              files: [],
              focussedFile: null,
              filePreviewOpen: false,
            });
          }}
        />

        {messages.length > 0 ? (
          messages.map((groupedMessage, index) =>
            this._renderMessageGroup(groupedMessage, messages[index + 1])
          )
        ) : (
          <div className="msg-container">
            <div className="day-seperator">
              <div className="date">{moment(chat.createdAt).format('DD MMM YYYY')}</div>
            </div>
            <div>
              <NoData>{t('chat.noMessages')}</NoData>
            </div>
          </div>
        )}
      </div>
    );
  }
}
