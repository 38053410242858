import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

export const communicationMethods = [
  { label: 'workflow', value: 'workflow' },
  { label: 'integration', value: 'integration' },
];
class CapacityContractCommunicationMethodSelector extends Component {
  render() {
    const { t, onChange, placeholder, value } = this.props;
    const options = communicationMethods.map((communicationMethod) => ({
      value: communicationMethod.value,
      label: t(`communicationMethod.${communicationMethod.label}`),
    }));
    return (
      <Select
        value={options.find((option) => option.value === value)}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
      />
    );
  }
}

export default withTranslation('translation')(
  CapacityContractCommunicationMethodSelector
);
