import React, { Component } from 'react';

import SimpleReactValidator from 'simple-react-validator';

import ScheduleRouteInput from '@components/scheduleRoute/ScheduleRouteInput';
import TransportModeSelector from '@components/trip/TransportModeSelector';

import FormInput from '@uiinputs/FormInput';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import CapacitySubTypeSelector from './CapacitySubTypeSelector';

export default class CapacityForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      formCapacity: this.props.formCapacity || {},
    };
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  onChange(name, value, event) {
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { formCapacity } = this.state;
    onSubmit && onSubmit(formCapacity);
  }

  render() {
    const { t } = this.props;
    const { formCapacity } = this.state;

    return (
      <div className="step-form">
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div className="input-group">
              <div className="input-group no-margin-top">
                <FormInput
                  type="text"
                  label={`${t('form.label.name')}*`}
                  value={formCapacity?.name || ''}
                  onChange={(event) => {
                    const newCapacity = { ...formCapacity };
                    newCapacity.name = event.target.value;

                    this.onChange(
                      'formCapacity',
                      {
                        ...newCapacity,
                      },
                      event
                    );
                  }}
                />
              </div>
              {this.validator.message(t('form.label.name'), formCapacity?.name, 'required')}
            </div>

            <div className="input-group">
              <div className="input-group no-margin-top">
                <FormInput
                  type="textarea"
                  label={t('form.label.description')}
                  className="small"
                  value={formCapacity?.description}
                  onChange={(event) => {
                    const newCapacity = { ...formCapacity };
                    newCapacity.description = event.target.value;

                    this.onChange(
                      'formCapacity',
                      {
                        ...newCapacity,
                      },
                      event
                    );
                  }}
                />
              </div>
            </div>

            <div className="input-group">
              <TransportModeSelector
                key={formCapacity.modality}
                value={formCapacity.modality || ''}
                placeholder={t('form.label.selectCapacityModalityType')}
                onChange={(e) => {
                  const newCapacity = { ...formCapacity };
                  newCapacity.modality = e.value;

                  this.onChange('formCapacity', newCapacity, e);
                }}
              />
            </div>

            <div className="input-group">
              <CapacitySubTypeSelector
                key={formCapacity.subType}
                value={formCapacity.subType || ''}
                placeholder={t('form.label.selectCapacitySubType')}
                onChange={(e) => {
                  const newCapacity = { ...formCapacity };
                  newCapacity.subType = e.value;

                  this.onChange('formCapacity', newCapacity, e);
                }}
              />
            </div>
            <div className="input-group">
              <div className="input-group no-margin-top">
                <FormInput
                  type="number"
                  label={`${t('form.label.totalUnits')}*`}
                  value={formCapacity?.totalUnits || ''}
                  onChange={(event) => {
                    const newCapacity = { ...formCapacity };
                    newCapacity.totalUnits = event.target.value;

                    this.onChange(
                      'formCapacity',
                      {
                        ...newCapacity,
                      },
                      event
                    );
                  }}
                />
              </div>
              {this.validator.message(
                t('form.label.totalUnits'),
                formCapacity?.totalUnits,
                'required'
              )}
            </div>
            <div className="input-group">
              <div className="input-group no-margin-top">
                <ScheduleRouteInput
                  value={formCapacity.scheduleRoute}
                  onChange={(e) => {
                    const newCapacity = { ...formCapacity };
                    newCapacity.scheduleRoute = e;

                    this.onChange('formCapacity', newCapacity, e);
                  }}
                />
              </div>
            </div>

            <div className="input-group more right">
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
