import React, { Component } from 'react';

import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import IntegrationInput from '@components/integration/crud/IntegrationInput';

import Switch from '@uicomponents/Switch';

import { activateInputs, deactivateInputs } from '@utils/formUtils';
import FormInput from '@uiinputs/FormInput';

export default class BoardComputerForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {};
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { onSubmit, formBoardComputer, setHasChanged } = this.props;

    onSubmit && onSubmit(formBoardComputer);
    setHasChanged(false);
  }

  render() {
    const { onChange, t, formBoardComputer, setHasChanged } = this.props;

    const boardComputerTypes = [
      { label: t('boardComputerType.vehicle'), value: 'vehicle' },
      {
        label: t('boardComputerType.meansOfTransport'),
        value: 'meansOfTransport',
      },
      { label: t('boardComputerType.mobile'), value: 'mobile' },
    ];

    return (
      <>
        <div
          className="step-form "
          key={`${formBoardComputer.id}${formBoardComputer.nonce}`}
        >
          <form
            className="form active no-padding-top"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <div className="input-container">
              <div className="input-group">
                <div className="input-group">
                  <FormInput
                    type="text"
                    setHasChanged={setHasChanged}
                    label={`${t('form.label.name')}*`}
                    value={formBoardComputer.name}
                    onChange={(event) => {
                      const newBoardComputer = { ...formBoardComputer };
                      newBoardComputer.name = event.target.value;

                      onChange('formBoardComputer', newBoardComputer, event);
                    }}
                  />
                </div>
                {this.validator.message(
                  t('form.label.name'),
                  formBoardComputer.name,
                  'required'
                )}
              </div>
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <Select
                    placeholder={t('form.label.selectBoardComputerType')}
                    value={boardComputerTypes.find(
                      (type) => type.value === formBoardComputer.type
                    )}
                    options={boardComputerTypes}
                    onChange={(event) => {
                      const newBoardComputer = { ...formBoardComputer };
                      newBoardComputer.type = event.value;

                      onChange('formBoardComputer', newBoardComputer, event);
                    }}
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <IntegrationInput
                    value={formBoardComputer.integration}
                    searchParameters={{ sectorType: 'boardComputer' }}
                    onChange={(newIntegration) => {
                      const newBoardComputer = { ...formBoardComputer };
                      newBoardComputer.integration = newIntegration;

                      onChange('formBoardComputer', newBoardComputer);
                    }}
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="input-group">
                  <FormInput
                    type="text"
                    setHasChanged={setHasChanged}
                    label={`${t('form.label.boardComputerId')}`}
                    value={formBoardComputer.boardComputerId}
                    onChange={(event) => {
                      const newBoardComputer = { ...formBoardComputer };
                      newBoardComputer.boardComputerId = event.target.value;

                      onChange('formBoardComputer', newBoardComputer, event);
                    }}
                  />
                </div>
              </div>
              <div className="input-group">
                <div>{t('form.label.offOn')}</div>
                <Switch
                  checked={formBoardComputer.active}
                  onChange={(e, newState) => {
                    const newBoardComputer = { ...formBoardComputer };
                    newBoardComputer.active = newState;

                    onChange('formBoardComputer', newBoardComputer, e);
                  }}
                />
              </div>
              <div className="input-group more right">
                <input
                  type="submit"
                  disabled={!this.validator.allValid()}
                  value={t('form.save')}
                />
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
