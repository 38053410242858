import React, { Component, Suspense, lazy } from 'react';

import { withTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import ReactSVG from 'react-svg';

import UpdateBusinessContainer from '@containers/business/crud/UpdateBusinessContainer';
import LocationContainer from '@containers/location/LocationContainer';

import Collapsible from '@uicomponents/Collapsible';
import Loader from '@uicomponents/Loader';

import ContactDetailsView from '../general/ContactDetailsView';
import NotesView from '../note/NotesView';
import InformationComponent from '../ui/components/InformationComponent';

const BansContainer = lazy(() => import('@containers/ban/BansContainer'));

class BusinessView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'business',
    };
  }

  render() {
    const { tab } = this.state;
    const { t, business, isFetchingBusiness, onChange, addToStack } = this.props;

    if (!business || isFetchingBusiness) {
      return <Loader />;
    }

    const fieldsToRender = [
      { name: 'coc', type: 'text', entityType: 'business' },
      { name: 'vat', type: 'text', entityType: 'business' },
      { name: 'website', type: 'link', entityType: 'business' },
      { name: 'description', type: 'text', entityType: 'business' },
      ...business.locations.map((association, index) => ({
        name: `location_${index}`,
        type: 'businessLocation',
        entityType: 'business',
        index,
        onClick: (location) => {
          addToStack({
            name: location.name,
            className: 'lightgray',
            component: <LocationContainer location={{ ...location }} />,
          });
        },
        value: association.entity,
      })),
    ];
    return (
      <div className="business-view">
        <div className="flex-container justify-between no-wrap">
          <div>
            <div className="flex-container justify-between">
              <h1 className="no-margin">{business.name}</h1>
            </div>
            <h3 className="no-margin-top">#{business.id}</h3>
          </div>
          <div className="self-center flex-container action-icons">
            <div
              className="action-icon"
              onClick={(e) => {
                e.preventDefault();
                addToStack({
                  name: t('business.update.header'),
                  component: (
                    <UpdateBusinessContainer
                      targetBusiness={{ ...business }}
                      callback={(result) => {
                        onChange('business', result.business || {});
                      }}
                    />
                  ),
                });
              }}
            >
              <ReactSVG src="/icons/pencil.svg" />
            </div>
          </div>
        </div>
        <InformationComponent entity={business} fields={fieldsToRender} />
        <Collapsible
          name={`${t('notes.header')}${
            business.notes.length > 0 ? ` (${business.notes.length})` : ''
          }`}
          className="collapsible-element"
          defaultIsOpen={false}
        >
          <NotesView notes={business.notes} />
        </Collapsible>
        <Collapsible
          name={`${t('contactDetails.header')}${
            business.contactDetails.length > 0 ? ` (${business.contactDetails.length})` : ''
          }`}
          className="collapsible-element"
          defaultIsOpen={false}
        >
          <ContactDetailsView contactDetails={business.contactDetails} />
        </Collapsible>
        <Collapsible
          defaultIsOpen={false}
          name={`${t('bans.header')}`}
          className="collapsible-element"
        >
          <Suspense fallback={<>{<Loader />}</>}>
            <BansContainer entityType="business" entity={business} />
          </Suspense>
        </Collapsible>
      </div>
    );
  }
}
export default withTranslation('translation')(BusinessView);
