import React, { PureComponent } from 'react';

import { useTranslation, withTranslation } from 'react-i18next';
import Select from 'react-select';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import ReactSVG from 'react-svg';

import moment from 'moment';

import TransportEquipmentInput from '@components/goods/TransportEquipmentInput';
import LocationInput from '@components/location/crud/LocationInput';

import CloseButton from '@uicomponents/CloseButton';
import FormInput from '@uiinputs/FormInput';

import Constraint from '@models/constraint/Constraint';
import Association from '@models/general/Association';

import { basicAction } from '@utils/actionUtils';
import { swapArrayLocs } from '@utils/arrayUtils';

export const actionTypes = [
  {
    value: 'genericAction',
    label: 'actionType.genericAction',
  },
  {
    value: 'load',
    label: 'actionType.load',
  },
  {
    value: 'unload',
    label: 'actionType.unload',
  },
  {
    value: 'attachTransportEquipment',
    label: 'actionType.attachTransportEquipment',
  },
  {
    value: 'detachTransportEquipment',
    label: 'actionType.detachTransportEquipment',
  },
  {
    value: 'break',
    label: 'actionType.break',
  },
  {
    value: 'refuel',
    label: 'actionType.refuel',
  },
];

const timesWithinOpeningsTimes = (action) => {
  if (action?.location && action.constraints && action.constraints.length > 1) {
    const startDate = moment(
      action.constraints?.filter((c) => c.entity.value.type === 'startDateTimeConstraint')[0]
        ?.entity?.value?.startDateTime || new Date()
    );

    const endDate = moment(
      action.constraints?.filter((c) => c.entity.value.type === 'endDateTimeConstraint')[0]?.entity
        ?.value?.endDateTime || new Date()
    );

    const timeFormat = 'hh:mm';
    const startDayOfTheWeek = startDate.format('dddd');
    const endDayOfTheWeek = endDate.format('dddd');

    if (action?.location?.entity?.openingTimes) {
      const startOpeningTimes =
        action.location.entity.openingTimes[startDayOfTheWeek.toLowerCase()];
      const endOpeningTimes = action.location.entity.openingTimes[endDayOfTheWeek.toLowerCase()];

      if (startOpeningTimes !== undefined && endOpeningTimes !== undefined) {
        if (startOpeningTimes.start !== '' && startOpeningTimes.end !== '') {
          if (
            !startOpeningTimes.open ||
            !startDate.isBetween(
              moment(startOpeningTimes.start, timeFormat),
              moment(startOpeningTimes.end, timeFormat),
              null,
              '[]'
            )
          ) {
            return false;
          }
        } else {
          return true;
        }

        if (endOpeningTimes.start !== '' && endOpeningTimes.end !== '') {
          if (
            !endOpeningTimes.open ||
            !endDate.isBetween(
              moment(endOpeningTimes.start, timeFormat),
              moment(endOpeningTimes.end, timeFormat),
              null,
              '[]'
            )
          ) {
            return false;
          }
        } else {
          return true;
        }
      }
    }
  }
  return true;
};

const DragHandle = sortableHandle(() => (
  <div className="draggable">
    <ReactSVG src="/icons/drag.svg" />
  </div>
));

const SortableItem = sortableElement(
  ({
    key,
    index,
    n,
    actions,
    updateAction,
    action,
    setState,
    onChange,
    enabledActionTypes,
    context,
  }) => {
    const { t } = useTranslation('translation');

    const actionTypeOptions = actionTypes
      .filter((type) => enabledActionTypes.includes(type.value))
      .map((actionType) => ({
        value: actionType.value,
        label: t(actionType.label),
      }));
    const allActionTypeOptions = actionTypes.map((actionType) => ({
      value: actionType.value,
      label: t(actionType.label),
    }));

    const isEditeable =
      action.lifeCycle === 'planned' ||
      action.lifeCycle === 'projected' ||
      action.lifeCycle === 'requested';
    return (
      <div
        className="list-sector sortable"
        key={`item-action-${action.sequenceNr}-${action.id}-${action.updatedAt}-${action.nonce}`}
        index={action.sequenceNr}
        style={{ zIndex: 1001 }}
      >
        {timesWithinOpeningsTimes(action) ? null : (
          <div className="notify-banner warning">{t('action.notWithinOpeningTimes')}</div>
        )}
        <div className="list-actions">
          <h2>#{action.sequenceNr || 1}.</h2>
          <div className="flex-container">
            {isEditeable && (
              <>
                <DragHandle />
                <span style={{ width: 10 }} />
                <CloseButton
                  onClick={() => {
                    const newActions = [...actions].sort(
                      (a, b) => a.entity.sequenceNr - b.entity.sequenceNr
                    );

                    newActions.splice(n, 1);

                    const updatedActions = newActions.map((action, index) => {
                      if (index >= n) {
                        return {
                          ...action,
                          entity: {
                            ...action.entity,
                            sequenceNr: action.entity.sequenceNr - 1,
                          },
                        };
                      }
                      return action;
                    });

                    setState({
                      actions: updatedActions,
                    });

                    onChange(updatedActions);
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className="input-group no-margin-top">
          <div className="input-group no-margin-top ">
            <Select
              key={action.nonce}
              isMulti={true}
              isDisabled={!isEditeable || action.id}
              options={actionTypeOptions}
              placeholder={t('form.label.type')}
              value={allActionTypeOptions.filter((option) =>
                action.actions.map((a) => a.entity.type).includes(option.value)
              )}
              onChange={(event) => {
                const newAction = { ...action };

                const newChildActions = [];
                if (event && event.length > 0) {
                  event.forEach((event, eIndex) => {
                    const toBePlacedIndex =
                      eIndex === 1 && newAction.actions[0]?.entity?.type === 'genericAction'
                        ? 0
                        : eIndex;
                    const childAction = basicAction();
                    childAction.type = event.value;
                    newChildActions[toBePlacedIndex] = new Association('inline', childAction);
                  });
                } else {
                  const childAction = basicAction();
                  childAction.type = 'genericAction';
                  newChildActions[0] = new Association('inline', childAction);
                }

                newAction.actions = newChildActions;

                updateAction(newAction, index);
              }}
            />
          </div>
        </div>
        <div className="input-group">
          <LocationInput
            key={action.nonce}
            location={action.location ? action.location.entity : null}
            searchParameters={
              action?.actions[0]?.entity?.type === 'refuel' ? { types: ['fuelStation'] } : {}
            }
            isDisabled={!isEditeable}
            onChange={(newLocation) => {
              const newAction = { ...action };
              newAction.location = new Association('inline', newLocation);

              const newChildActions = [...newAction.actions];
              newChildActions.forEach((childAction, caIndex) => {
                const newChildAction = { ...childAction.entity };
                newChildAction.location = new Association('inline', newLocation);
                newChildActions[caIndex] = new Association('inline', newChildAction);
              });
              newAction.actions = newChildActions;

              updateAction(newAction, index);
            }}
          />
        </div>
        {action.actions.some((subAction) =>
          ['attachTransportEquipment', 'detachTransportEquipment'].includes(subAction.entity.type)
        ) &&
          context !== 'transportEquipment' && (
            <>
              {action.actions
                .filter((a) =>
                  ['attachTransportEquipment', 'detachTransportEquipment'].includes(a.entity.type)
                )
                .map((association) => association.entity)
                .map((subAction) => (
                  <div className="input-group">
                    <TransportEquipmentInput
                      key={
                        subAction.transportEquipment ? subAction.transportEquipment.entity : null
                      }
                      transportEquipment={
                        subAction.transportEquipment ? subAction.transportEquipment.entity : null
                      }
                      isDisabled={!isEditeable}
                      onChange={(newTransportEquipment) => {
                        const newAction = { ...action };

                        const newChildActions = [...newAction.actions];
                        newChildActions.forEach((childAction, caIndex) => {
                          const newChildAction = { ...childAction.entity };
                          newChildAction.transportEquipment = new Association(
                            'inline',
                            newTransportEquipment
                          );
                          newChildActions[caIndex] = new Association('inline', newChildAction);
                        });
                        newAction.actions = newChildActions;

                        updateAction(newAction, index);
                      }}
                    />
                  </div>
                ))}
            </>
          )}

        {action.location && action.location?.entity?.terminal && (
          <>
            <div className="input-group">
              <FormInput
                type="text"
                label={t('form.label.scacCode')}
                value={action?.actions[0]?.entity?.shipmentLine?.scacCode || ''}
                onChange={(event) => {
                  const newAction = { ...action };

                  const newChildActions = [...newAction.actions];
                  newChildActions.forEach((childAction, caIndex) => {
                    if (
                      [
                        'load',
                        'unload',
                        'attachTransportEquipment',
                        'detachTransportEquipment',
                      ].includes(childAction.entity.type)
                    ) {
                      const newChildAction = { ...childAction.entity };
                      newChildAction.shipmentLine = {
                        scacCode: event.target.value,
                      };
                      newChildActions[caIndex] = new Association('inline', newChildAction);
                    }
                  });
                  newAction.actions = newChildActions;
                  updateAction(newAction, index);
                }}
              />
            </div>
          </>
        )}
        <div className="input-group">
          <div>
            <b>{t('form.label.timeWindow')}</b>
          </div>
          <input
            type="datetime-local"
            disabled={!isEditeable}
            onChange={(e) => {
              const newAction = { ...action };

              const newConstraint = new Constraint();
              newConstraint.value.type = 'startDateTimeConstraint';
              newConstraint.value.startDateTime = moment(e.target.value);

              const newChildActions = [...newAction.actions];
              newChildActions.forEach((childAction, caIndex) => {
                const newChildAction = { ...childAction.entity };

                newChildAction.constraints = [...(newChildAction.constraints || [])];
                newChildAction.constraints[0] = new Association('inline', newConstraint);
                newChildActions[caIndex] = new Association('inline', newChildAction);
              });
              newAction.actions = newChildActions;

              newAction.constraints = [...newAction.constraints];
              newAction.constraints[0] = new Association('inline', newConstraint);
              updateAction(newAction, index);
            }}
            value={
              moment(
                action.constraints?.filter(
                  (c) => c.entity.value.type === 'startDateTimeConstraint'
                )?.[0]?.entity?.value?.startDateTime || +moment()
              ).format('YYYY-MM-DDTHH:mm') || ''
            }
          />
        </div>
        <div className="input-group">
          <input
            type="datetime-local"
            disabled={!isEditeable}
            onChange={(e) => {
              const newAction = { ...action };

              const newConstraint = new Constraint();
              newConstraint.value.type = 'endDateTimeConstraint';
              newConstraint.value.endDateTime = e.target.value ? moment(e.target.value) : null;

              const newChildActions = [...newAction.actions];
              newChildActions.forEach((childAction, caIndex) => {
                const newChildAction = { ...childAction.entity };

                newChildAction.constraints = [...(newChildAction.constraints || [])];
                newChildAction.constraints[1] = new Association('inline', newConstraint);
                newChildActions[caIndex] = new Association('inline', newChildAction);
              });
              newAction.actions = newChildActions;

              newAction.constraints = [...newAction.constraints];
              newAction.constraints[1] = new Association('inline', newConstraint);
              updateAction(newAction, index);
            }}
            value={
              moment(
                action.constraints?.filter(
                  (c) => c.entity.value.type === 'endDateTimeConstraint'
                )?.[0]?.entity?.value?.endDateTime || +moment()
              ).format('YYYY-MM-DDTHH:mm') || ''
            }
          />
        </div>
        {action.actions
          .map((a) => a.entity.type)
          .some((type) =>
            ['load', 'unload', 'attachTransportEquipment', 'detachTransportEquipment'].includes(
              type
            )
          ) && (
          <div className="input-group">
            <input
              type="text"
              value={action?.actions[0]?.entity?.pin || ''}
              onChange={(event) => {
                const newAction = { ...action };

                const newChildActions = [...newAction.actions];
                newChildActions.forEach((childAction, caIndex) => {
                  if (
                    [
                      'load',
                      'unload',
                      'attachTransportEquipment',
                      'detachTransportEquipment',
                    ].includes(childAction.entity.type)
                  ) {
                    const newChildAction = { ...childAction.entity };
                    newChildAction.pin = event.target.value;
                    newChildActions[caIndex] = new Association('inline', newChildAction);
                  }
                });
                newAction.actions = newChildActions;
                updateAction(newAction, index);
              }}
            />
            <label>{t('form.label.pin')}</label>
          </div>
        )}

        {!(action.remarksVisible || (action.remarks && action.remarks.length > 0)) &&
        action.actions[0]?.entity?.type !== 'genericAction' ? (
          <div className="input-group">
            <div
              className="as-link"
              disabled={!isEditeable}
              onClick={(e) => {
                e.preventDefault();
                updateAction({ ...action, remarksVisible: true }, index);
              }}
            >
              {t('form.label.addRemark')}
            </div>
          </div>
        ) : null}
        {action.remarksVisible ||
        (action.remark && action.remark.length > 0) ||
        action.actions[0]?.entity?.type === 'genericAction' ? (
          <div className="input-group">
            <div className="input-group no-margin-top">
              <FormInput
                type="textarea"
                label={t('form.label.remarks')}
                className="small"
                value={action.remark || ''}
                disabled={!isEditeable}
                onChange={(event) => {
                  const newAction = { ...action };
                  newAction.remark = event.target.value;

                  const newChildActions = [...newAction.actions];
                  newChildActions.forEach((childAction, caIndex) => {
                    const newChildAction = { ...childAction.entity };
                    newChildAction.remark = event.target.value;
                    newChildActions[caIndex] = new Association('inline', newChildAction);
                  });
                  newAction.actions = newChildActions;

                  updateAction(newAction, index);
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
);

const SortableContainer = sortableContainer(({ children, className }) => (
  <div className={className}>{children}</div>
));

class ActionsInput extends PureComponent {
  state = {
    actionCreated: false,
    actions: this.props.actions
      ? this.props.actions
      : this.props.defaultEnabled
      ? [basicAction(this.props.initialSequenceNr || 1)]
      : [],
  };

  static defaultProps = {
    actionTypes: [
      'genericAction',
      'load',
      'unload',
      'attachTransportEquipment',
      'detachTransportEquipment',
    ],
  };

  onChange = (newActions) => {
    const { onChange } = this.props;

    onChange && onChange(newActions);
  };

  componentDidUpdate() {
    const { createAction } = this.props;

    if (createAction && !this.state.actionCreated) {
      this.addNewAction();
      this.setState({ actionCreated: true });
    }
  }

  addNewAction = () => {
    const { actions } = this.state;
    this.setState(
      {
        actions: [...actions, basicAction((this.props.initialSequenceNr || 0) + 1)],
      },
      () => this.props.onChange?.(this.state.actions)
    );
  };

  updateAction = (newAction, index) => {
    const { actions } = this.state;
    const newActions = [...actions].sort((a, b) => a.entity.sequenceNr - b.entity.sequenceNr);
    const newAssociation = { ...newActions[index] };

    const action = { ...newAction };
    action.sequenceNr = action.sequenceNr || index;
    newAssociation.entity = action;

    newActions[index] = newAssociation;

    this.setState({
      actions: newActions,
    });

    this.onChange(newActions);
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { actions } = this.state;
    let newActions = [...actions];

    newActions = [...swapArrayLocs(newActions, oldIndex, newIndex)]
      .map((action, index) => {
        let newAction = { ...action };
        let newEntity = { ...newAction.entity };
        newEntity.sequenceNr = index + 1;
        newAction.entity = newEntity;
        return newAction;
      })
      .sort((a, b) => a.sequenceNr - b.sequenceNr);

    this.setState({
      actions: newActions,
    });

    this.onChange(newActions);
  };

  render() {
    const { t, actionTypes, context } = this.props;
    const { actions } = this.state;

    return (
      <div className="actions-input">
        <SortableContainer
          onSortEnd={this.onSortEnd}
          useDragHandle
          axis="xy"
          className="flex-container justify-between"
        >
          {[...actions]
            .map((association) => association.entity)
            .sort((a, b) => a.sequenceNr - b.sequenceNr)
            .map((action, index) => (
              <SortableItem
                key={`item-action-${action.nonce}-${index}`}
                index={index}
                n={index}
                action={action}
                context={context || 'consignment'}
                enabledActionTypes={actionTypes}
                actions={[...actions]}
                onChange={(newActions) => this.onChange(newActions)}
                updateAction={(newAction) => this.updateAction(newAction, index)}
                setState={(newState) => this.setState(newState)}
              />
            ))}
        </SortableContainer>

        <div className={`input-group left${actions.length > 0 ? '' : ' no-margin-top'}`}>
          <button type="button" onClick={(e) => this.addNewAction(e)}>
            {t('form.label.addAction')}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(ActionsInput);
