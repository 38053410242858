import React, { Component } from 'react';

import ReactSVG from 'react-svg';

import ChauffeurContainer from '@containers/chauffeur/ChauffeurContainer';
import ConsignmentContainer from '@containers/consignment/ConsignmentContainer';
import TransportEquipmentContainer from '@containers/goods/TransportEquipmentContainer';
import CommentFormContainer from '@containers/todo/todo/CommentFormContainer';
import TodoFormContainer from '@containers/todo/todo/TodoFormContainer';
import TransportOrderContainer from '@containers/transportOrder/TransportOrderContainer';
import TripContainer from '@containers/trip/TripContainer';
import VehicleContainer from '@containers/vehicle/VehicleContainer';

import AsideView from '@uicomponents/AsideView';
import FileViewer from '@uicomponents/FileViewer';
import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';
import PopOver from '@uicomponents/PopOver';

import { get as getAction } from '@api/actionApi';
import { get as getChauffeur } from '@api/chauffeurApi';
import { get as getConsignment } from '@api/consignmentApi';
import { get as getTransportEquipment } from '@api/transportEquipmentApi';
import { get as getTransportOrder } from '@api/transportOrderApi';
import { get as getTrip } from '@api/tripApi';
import { get as getVehicle } from '@api/vehicleApi';

import { defaultDateTimeFormat } from '@utils/dateUtils';

import CommentCard from './CommentCard';

export default class TodoDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popOvers: [],
      addComment: false,

      entity: null,
      focussedFile: null,
      filePreviewOpen: false,
    };
    this.handleAddToStackTodo = this.handleAddToStackTodo.bind(this);
  }

  getEntity(relatedEntityType, relatedEntityId) {
    switch (relatedEntityType) {
      case 'action':
        getAction(relatedEntityId).then((response) => this.setState({ entity: response.action }));
        return null;
      case 'chauffeur':
        getChauffeur(relatedEntityId).then((response) =>
          this.setState({ entity: response.chauffeur })
        );
        return null;
      case 'consignment':
        getConsignment(relatedEntityId).then((response) =>
          this.setState({ entity: response.consignment })
        );
        return null;
      case 'transportEquipment':
        getTransportEquipment(relatedEntityId).then((response) =>
          this.setState({ entity: response.transportEquipment })
        );
        return null;
      case 'transportOrder':
        getTransportOrder(relatedEntityId).then((response) =>
          this.setState({ entity: response.transportOrder })
        );
        return null;
      case 'trip':
        getTrip(relatedEntityId).then((response) => this.setState({ entity: response.trip }));
        return null;
      case 'vehicle':
        getVehicle(relatedEntityId).then((response) => this.setState({ entity: response.vehicle }));
        return null;
      default:
        return null;
    }
  }

  componentDidMount() {
    const { todo, getComments } = this.props;
    todo && getComments(todo, 0, 20);

    if (todo?.relatedEntityId) {
      this.getEntity(todo?.relatedEntityType, todo?.relatedEntityId);
    }

    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    const { todo, getComments } = this.props;
    if (prevProps.todo !== todo) {
      todo && getComments(todo, 0, 20);
    }
    if (
      prevProps.todo?.relatedEntityType !== todo?.relatedEntityType ||
      prevProps.todo?.relatedEntityId !== todo?.relatedEntityId
    ) {
      this.getEntity(todo?.relatedEntityType, todo.relatedEntityId);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { todo, getComments, commentsLoaded, isFetchingComments, commentsTotalResults } =
      this.props;

    if (
      window.innerHeight + window.scrollY >= document.querySelector('.page').offsetHeight - 50 &&
      !isFetchingComments &&
      commentsTotalResults > commentsLoaded
    ) {
      getComments(todo, commentsLoaded, 20);
    }
  };

  handleAddToStackTodo(addToStack, todo) {
    const { changeValue, isEdit, getTodo } = this.props;

    isEdit(!!todo?.id);
    changeValue('todoForm', todo);

    return addToStack({
      component: (
        <TodoFormContainer
          todoCallback={() => {
            getTodo(todo);
            this.props?.todoCallback?.();
          }}
        />
      ),
    });
  }

  entityRedirect = () => {
    const { todo, addToStack } = this.props;

    switch (todo.relatedEntityType) {
      case 'vehicle':
        return addToStack({
          name: todo.relatedEntityName,
          className: 'lightgray',
          component: <VehicleContainer vehicleId={todo.relatedEntityId} />,
        });
      case 'transportOrder':
        return addToStack({
          name: todo.relatedEntityName,
          className: 'lightgray',
          component: <TransportOrderContainer transportOrderId={todo.relatedEntityId} />,
        });
      case 'consignment':
        return addToStack({
          name: todo.relatedEntityName,
          component: <ConsignmentContainer consignmentId={todo.relatedEntityId} />,
        });
      case 'trip':
        return addToStack({
          name: todo.relatedEntityName,
          component: <TripContainer tripId={todo.relatedEntityId} />,
        });
      case 'chauffeur':
        return addToStack({
          name: todo.relatedEntityName,
          className: 'lightgray',
          component: <ChauffeurContainer chauffeurId={todo.relatedEntityId} />,
        });
      case 'transportEquipment':
        return addToStack({
          name: todo.relatedEntityName,
          className: 'lightgray',
          component: <TransportEquipmentContainer transportEquipmentId={todo.relatedEntityId} />,
        });
    }
  };

  render() {
    const {
      t,
      user,
      todo,
      deleteTodo,
      isFetchingTodo,

      popStack,
      addToStack,
      dateFormat,
      getBoardById,

      comments,
      isFetchingComments,
      isFetchingCommentsFrom,
    } = this.props;

    const { entity, addComment, files, filePreviewOpen, focussedFile } = this.state;

    return (
      <>
        {this.state.popOvers.map((popOver) => popOver)}

        <FileViewer
          t={t}
          files={files}
          file={focussedFile}
          isActive={filePreviewOpen}
          onClose={() => {
            this.setState({
              files: [],
              focussedFile: null,
              filePreviewOpen: false,
            });
          }}
        />

        <AsideView>
          <div className="todo-details">
            {isFetchingTodo ? (
              <Loader />
            ) : (
              <>
                <h1 className="my-1">{t('todo.details.heading')}</h1>
                <div className="text-left">
                  <div className="basic-detail my-3">
                    <div className="flex-container align-items-center justify-content-between">
                      <div className="d-flex align-items-center gap-10">
                        <h2>{todo?.name}</h2>
                        {todo?.statuses?.map((status) => (
                          <span
                            className={`tag text-capitalize`}
                            style={{
                              backgroundColor:
                                status === 'overDue' ? 'var(--red)' : todo?.lane?.color,
                              color: 'var(--white)',
                            }}
                          >
                            {t(`${status}`)}
                          </span>
                        ))}
                      </div>
                      <div className="board-card__actions d-flex gap-10">
                        <div
                          className="circle-icon-primary circle-md svg-md"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            this.setState({
                              popOvers: [
                                ...this.state.popOvers,
                                <PopOver
                                  isActive={true}
                                  className="select-pop-over"
                                  onClose={() => {
                                    this.setState({
                                      popOvers: [],
                                    });
                                  }}
                                >
                                  <div className="box">
                                    <div className="title">{t('todo.todo.delete.header')}</div>
                                    <div className="text">{t('todo.todo.delete.description')}</div>

                                    <div className="choice">
                                      <button
                                        href=""
                                        onClick={(e) => {
                                          e.preventDefault();

                                          this.setState({
                                            popOvers: [],
                                          });
                                        }}
                                      >
                                        {t('cancel')}
                                      </button>
                                      <div
                                        className="as-link"
                                        onClick={(e) => {
                                          e.preventDefault();

                                          this.setState({
                                            popOvers: [],
                                          });

                                          deleteTodo(todo).then((response) => {
                                            if (response.success) {
                                              const { todoCallback } = this.props;
                                              todoCallback && todoCallback();
                                              popStack();
                                            }
                                          });
                                        }}
                                      >
                                        {t('delete')}
                                      </div>
                                    </div>
                                  </div>
                                </PopOver>,
                              ],
                            });
                          }}
                        >
                          <ReactSVG src="/icons/trash.svg" />
                        </div>
                        <div
                          className="circle-icon-primary circle-md svg-md"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.handleAddToStackTodo(addToStack, todo);
                          }}
                        >
                          <ReactSVG src="/icons/edit.svg" />
                        </div>
                      </div>
                    </div>
                    <span className="text-darkgray font-sm">
                      {defaultDateTimeFormat(todo?.deadline, dateFormat)}
                    </span>
                  </div>
                  <hr />
                  <div className="information my-3">
                    <h4 className="my-1">{t('todo.details.info')}</h4>

                    <table className="w-100">
                      <tbody>
                        <tr className="flex-container no-wrap align-items-baseline">
                          <td style={{ flex: '0 0 140px' }}>
                            <span className="text-darkgray font-sm">
                              {t('form.label.description')}
                            </span>
                          </td>
                          <td>
                            <p
                              className="font-medium my-10"
                              dangerouslySetInnerHTML={{
                                __html: todo?.description || t('na'),
                              }}
                            ></p>
                          </td>
                        </tr>
                        <tr className="flex-container no-wrap align-items-baseline">
                          <td style={{ flex: '0 0 140px' }}>
                            <span className="text-darkgray font-sm">
                              {t('todo.board.createdAt')}{' '}
                            </span>
                          </td>
                          <td>
                            <p className="font-medium my-10">
                              {defaultDateTimeFormat(todo?.createdAt, dateFormat)}
                            </p>
                          </td>
                        </tr>
                        <tr className="flex-container no-wrap align-items-baseline">
                          <td style={{ flex: '0 0 140px' }}>
                            <span className="text-darkgray font-sm">{t('todo.lane.due')} </span>
                          </td>
                          <td>
                            <p className="font-medium my-10">
                              {defaultDateTimeFormat(todo?.deadline, dateFormat)}
                            </p>
                          </td>
                        </tr>
                        <tr className="flex-container no-wrap align-items-baseline">
                          <td style={{ flex: '0 0 140px' }}>
                            <span className="text-darkgray font-sm my-10 d-block">
                              {t('todo.form.label.boardName')}{' '}
                            </span>
                          </td>
                          <td>
                            <a
                              className="font-medium text-underline"
                              onClick={() => {
                                getBoardById(todo?.lane?.board?.id);
                                popStack();
                              }}
                            >
                              {todo?.lane?.board?.title}
                            </a>
                          </td>
                        </tr>
                        <tr className="flex-container no-wrap align-items-baseline">
                          <td style={{ flex: '0 0 140px' }}>
                            <span className="text-darkgray font-sm">
                              {t('form.label.assignee')}
                            </span>
                          </td>
                          <td>
                            <span className="d-flex align-items-center gap-5">
                              <img
                                className="rounded"
                                style={{
                                  width: 20,
                                  height: 20,
                                  marginRight: 5,
                                  objectFit: 'cover',
                                }}
                                src={
                                  todo?.assignee?.avatar
                                    ? todo?.assignee?.avatar.url
                                    : 'images/user.svg'
                                }
                                alt=""
                              />
                              <p className="font-medium">
                                {todo?.assignee?.firstName} {todo?.assignee?.lastName}
                              </p>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <h4 className="my-1">{t('form.label.attachments')}</h4>
                    <div className="files w-100">
                      {todo?.documents?.length > 0 ? (
                        <div className="w-100" style={{ overflowX: 'scroll' }}>
                          {todo?.documents.map((document) => {
                            const file = document?.entity?.file;

                            return (
                              <div
                                key={`${file.url}-${file.nonce}`}
                                className="d-flex align-items-center gap-20 mt-20 w-100 attachment cursor-pointer"
                                onClick={() => {
                                  this.setState({
                                    focussedFile: file,
                                    filePreviewOpen: true,
                                    files: todo?.documents.map((document) => document.entity.file),
                                  });
                                }}
                              >
                                <ReactSVG className="attachment-icon" src="/icons/attachment.svg" />
                                <div>
                                  <div className="name d-flex font-sm align-items-center text-darkgray font-medium">
                                    {file.error
                                      ? file.error
                                      : file.originalName || file.name || file.path}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <NoData className="left">{t('transportOrder.attachments.notFound')}</NoData>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="relation my-3">
                    <h4 className="my-1">{t('todo.lane.relatedTo')}</h4>
                    {todo?.relatedEntityType ? (
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td colSpan={4}>
                              <a
                                className="text-darkgray font-sm cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.entityRedirect();
                                }}
                              >
                                {t(todo?.relatedEntityType)}
                              </a>
                              {/* <span className="text-darkgray font-sm">
                                {t(todo?.relatedEntityType)}
                              </span> */}
                            </td>
                            <td style={{ width: '80%' }}>
                              <div
                                className="entity-wrapper cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.entityRedirect();
                                }}
                              >
                                {todo?.relatedEntityType === 'action' && <div></div>}
                                {todo?.relatedEntityType === 'chauffeur' && (
                                  <div className="entity flex-container row no-wrap gap-10">
                                    <img
                                      src="/images/map.png"
                                      style={{
                                        width: 40,
                                        height: 40,
                                        marginRight: 5,
                                        objectFit: 'cover',
                                      }}
                                    />
                                    <div className="flex-container align-items-center no-wrap gap-5">
                                      <p className="font-sm font-semibold no-margin">
                                        {entity?.firstName} {entity?.lastName}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {todo?.relatedEntityType === 'consignment' && (
                                  <div className="entity">
                                    <p className="font-sm font-semibold no-margin-top mb-5">
                                      {entity?.name}
                                    </p>
                                    <p className="font-sm text-darkgray no-margin">
                                      {t('consignment.type')}: {entity?.type}
                                      &nbsp;&nbsp;·&nbsp;&nbsp;
                                      {t('consignment.relatedTransportOrder')}:{' '}
                                      {entity?.transportOrder?.name}
                                    </p>
                                  </div>
                                )}
                                {todo?.relatedEntityType === 'transportEquipment' && (
                                  <div className="entity">
                                    <p className="font-sm font-semibold no-margin-top mb-5">
                                      {entity?.name}
                                    </p>
                                    <p className="font-sm text-darkgray no-margin">
                                      {t('consignment.type')}: {entity?.equipmentType}
                                      &nbsp;&nbsp;·&nbsp;&nbsp;
                                      {t('form.label.licensePlate')}: {entity?.licensePlate || '-'}
                                    </p>
                                  </div>
                                )}
                                {todo?.relatedEntityType === 'transportOrder' && (
                                  <div className="entity">
                                    <p className="font-sm font-semibold no-margin-top mb-5">
                                      {entity?.name}
                                    </p>
                                    <p className="font-sm no-margin text-darkgray">
                                      {t('form.label.startDateTime')}:{' '}
                                      {defaultDateTimeFormat(entity?.startDate, dateFormat)}
                                      &nbsp;&nbsp;·&nbsp;&nbsp;
                                      {t('support.ticket.status')}:{' '}
                                      <span className="text-capitalize">{entity?.status}</span>
                                    </p>
                                  </div>
                                )}
                                {todo?.relatedEntityType === 'trip' && (
                                  <div className="entity flex-container row no-wrap gap-10">
                                    <img
                                      src="/images/map.png"
                                      style={{
                                        width: 40,
                                        height: 40,
                                        marginRight: 5,
                                        objectFit: 'cover',
                                      }}
                                    />
                                    <div className="flex-container column no-wrap gap-5">
                                      <p className="font-sm font-semibold no-margin-top mb-5">
                                        {entity?.name}
                                      </p>
                                      <p className="font-sm no-margin text-darkgray">
                                        {t('form.label.startDateTime')}:{' '}
                                        {defaultDateTimeFormat(entity?.startDate, dateFormat)}
                                        &nbsp;&nbsp;·&nbsp;&nbsp;{entity?.actions?.length}{' '}
                                        {t('support.actions.header')}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {todo?.relatedEntityType === 'vehicle' && (
                                  <div className="entity flex-container column no-wrap gap-5">
                                    <p className="font-sm font-semibold no-margin-top mb-5">
                                      {entity?.name}
                                    </p>
                                    <p className="font-sm no-margin text-darkgray">
                                      {t('form.label.licensePlate')}: {entity?.licensePlate}
                                      &nbsp;&nbsp;·&nbsp;&nbsp;{t('form.label.vehicleType')}:{' '}
                                      {entity?.vehicleType}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <NoData className="left">{t('todo.relation.notFound')}</NoData>
                    )}

                    <h4 className="mt-30 mb-2">{t('todo.details.comments')}</h4>

                    {addComment ? (
                      <>
                        <div className="d-flex align-items-center gap-10">
                          <img
                            className="rounded"
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 5,
                              objectFit: 'cover',
                            }}
                            src={user?.avatar ? user?.avatar?.path : 'images/user.svg'}
                            alt=""
                          />
                          <p className="font-medium">
                            {user?.firstName} {user?.lastName}
                          </p>
                        </div>
                        <CommentFormContainer
                          {...this.props}
                          isEdit={false}
                          closeForm={() => this.setState({ addComment: false })}
                        />
                      </>
                    ) : (
                      <div className="add-comment-wrapper w-100">
                        <div className="d-flex align-items-center gap-10">
                          <img
                            className="rounded"
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 5,
                              objectFit: 'cover',
                            }}
                            src={user?.avatar ? user?.avatar?.path : 'images/user.svg'}
                            alt=""
                          />
                          <div
                            className="add-comment d-flex align-items-center w-100 cursor-pointer"
                            onClick={() => {
                              this.setState({ addComment: true });
                            }}
                          >
                            <span className="font-sm text-darkgray">
                              {t('todo.todoDetail.addComment')}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="comments">
                      {(isFetchingCommentsFrom === 0 && isFetchingComments) || !comments ? (
                        <Loader />
                      ) : (
                        comments?.map((comment, index) => {
                          return (
                            <CommentCard
                              key={`comment-card-${index}`}
                              {...this.props}
                              addComment={addComment}
                              closeAddComment={() => {
                                this.setState({ addComment: false });
                              }}
                              comment={comment}
                              todo={todo}
                            />
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </AsideView>
      </>
    );
  }
}
