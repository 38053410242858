import React, { Component, Suspense, lazy } from 'react';

import { withTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import ReactSVG from 'react-svg';

import MessagesContainer from '@containers/chat/MessagesContainer';
import CreateMessageContainer from '@containers/chat/crud/CreateMessageContainer';
import EntityTodosContainer from '@containers/todo/EntityTodosContainer';
import VehicleChauffeursContainer from '@containers/vehicle/VehicleChauffeursContainer';
import VehicleEventsContainer from '@containers/vehicle/VehicleEventsContainer';
import VehicleTransportEquipmentsContainer from '@containers/vehicle/VehicleTransportEquipmentsContainer';
import VehicleTripsContainer from '@containers/vehicle/VehicleTripsContainer';

import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';

import { prefixZeroFormat } from '@utils/numberUtils';

import VehicleCamerasView from './VehicleCamerasView';
import VehicleMapView from './VehicleMapView';
import VehicleView from './VehicleView';

const ComplaintsContainer = lazy(() => import('@containers/complaint/ComplaintsContainer'));
const TiresContainer = lazy(() => import('@containers/tire/TiresContainer'));

class VehicleDetailView extends Component {
  constructor(props) {
    super(props);

    this.messagesRef = React.createRef();
    this.state = {
      tab: 'trips',
    };
  }

  componentWillUnmount() {
    this.messagesRef = null;
  }

  render() {
    const {
      t,
      user,
      platform,
      vehicle,
      isFetchingVehicle,
      chat,
      chatIsFetching,
      noHeader,
      standalone,
    } = this.props;
    const { tab } = this.state;

    if (!vehicle || isFetchingVehicle) return <Loader />;

    return (
      <div className={`vehicle-detail${standalone ? ' vehicle-detail--standalone' : ''}`}>
        {!noHeader && (
          <div>
            <div className="flex-container justify-between">
              <h1 className="no-margin">
                {vehicle.name}{' '}
                {vehicle.fleets && vehicle.fleets.length > 0
                  ? `- ${vehicle.fleets
                      .map(
                        (fleetVehicle) =>
                          `${fleetVehicle.fleet.number}${prefixZeroFormat(fleetVehicle.order + 1)}`
                      )
                      .join(', ')}`
                  : null}
              </h1>
            </div>
            <h3 className="no-margin-top">#{vehicle.id}</h3>
          </div>
        )}
        <ScrollContainer
          verticle={false}
          hideScrollbars={false}
          className="align-items-center d-flex gap-10 tab-links-container"
        >
          <div
            className={`tab-links${tab === 'trips' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'trips' })}
          >
            {t('vehicle.tabs.trips')}
          </div>
          {user.accountType !== 'customer' && (
            <div
              className={`tab-links${tab === 'messages' ? ' tab-active' : ''}`}
              onClick={() => this.setState({ tab: 'messages' })}
            >
              {t('vehicle.tabs.messages')}
            </div>
          )}
          <div
            className={`tab-links${tab === 'map' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'map' })}
          >
            {t('vehicle.tabs.map')}
          </div>

          {user.accountType !== 'customer' && (
            <>
              <div
                className={`tab-links${tab === 'chauffeurs' ? ' tab-active' : ''}`}
                onClick={() => this.setState({ tab: 'chauffeurs' })}
              >
                {t('vehicle.tabs.chauffeurs')}
              </div>
              <div
                className={`tab-links${tab === 'transportEquipments' ? ' tab-active' : ''}`}
                onClick={() => this.setState({ tab: 'transportEquipments' })}
              >
                {t('vehicle.tabs.transportEquipments')}
              </div>
              <div
                className={`tab-links${tab === 'events' ? ' tab-active' : ''}`}
                onClick={() => this.setState({ tab: 'events' })}
              >
                {t('vehicle.tabs.events')}
              </div>
              {platform?.features &&
                platform?.features?.map((feature) => feature.name)?.includes('camera') && (
                  <div
                    className={`tab-links${tab === 'cameras' ? ' tab-active' : ''}`}
                    onClick={() => this.setState({ tab: 'cameras' })}
                  >
                    {t('vehicle.tabs.cameras')}
                  </div>
                )}

              <div
                className={`tab-links${tab === 'information' ? ' tab-active' : ''}`}
                onClick={() => this.setState({ tab: 'information' })}
              >
                {t('vehicle.tabs.information')}
              </div>
              {this.props.platform?.features?.filter((feature) => feature.name === 'todo').length >
                0 && (
                <div
                  className={`tab-links ${tab === 'todo' ? ' tab-active' : ''}`}
                  onClick={() => this.setState({ tab: 'todo' })}
                >
                  {t('todo.entityView.title')}
                </div>
              )}
              <div
                className={`tab-links${tab === 'complaints' ? ' tab-active' : ''}`}
                onClick={() => this.setState({ tab: 'complaints' })}
              >
                {t('complaints.header')}
              </div>
              <div
                className={`tab-links${tab === 'tires' ? ' tab-active' : ''}`}
                onClick={() => this.setState({ tab: 'tires' })}
              >
                {t('tires.header')}
              </div>
            </>
          )}
        </ScrollContainer>
        <section
          className={`vehicle-detail__box activity${tab === 'trips' ? ' active' : ''}${
            standalone ? ' scrollable' : ''
          }`}
        >
          {tab === 'trips' ? <VehicleTripsContainer key={vehicle.id} vehicle={vehicle} /> : null}
        </section>
        <section
          className={`vehicle-detail__box vehicle-detail__box--messages activity${
            tab === 'messages' ? ' active' : ''
          }`}
        >
          {tab === 'messages' &&
          vehicle.chat &&
          chat &&
          vehicle.chat.id === chat.id &&
          !chatIsFetching ? (
            <div className="chat">
              <div className="main">
                <MessagesContainer trip={vehicle.trip} />
                <CreateMessageContainer
                  onMessageCreate={() =>
                    (this.messagesRef.current.scrollTop = this.messagesRef.current.scrollHeigh)
                  }
                />
              </div>
            </div>
          ) : chatIsFetching ? (
            <Loader />
          ) : (
            <div className="not-found-action-box">
              <div className="icon">
                <ReactSVG src="/icons/space.svg" />
              </div>
              <NoData>{t('chats.notFound')}</NoData>
            </div>
          )}
        </section>

        <section
          className={`vehicle-detail__box vehicle-detail__box--map activity${
            tab === 'map' ? ' active' : ''
          }`}
        >
          {tab === 'map' ? (
            <VehicleMapView key={`detail-map-${vehicle.id}`} vehicle={vehicle} />
          ) : null}
        </section>
        <section
          className={`vehicle-detail__box activity${
            tab === 'transportEquipments' ? ' active' : ''
          }`}
        >
          {tab === 'transportEquipments' ? (
            <VehicleTransportEquipmentsContainer
              key={vehicle.transportEquipments}
              transportEquipments={vehicle.transportEquipments}
              {...this.props}
            />
          ) : null}
        </section>
        <section className={`vehicle-detail__box activity${tab === 'chauffeurs' ? ' active' : ''}`}>
          {tab === 'chauffeurs' ? (
            <VehicleChauffeursContainer chauffeurs={vehicle.chauffeurs} {...this.props} />
          ) : null}
        </section>
        <section className={`vehicle-detail__box activity${tab === 'events' ? ' active' : ''}`}>
          {tab === 'events' ? <VehicleEventsContainer vehicle={vehicle} /> : null}
        </section>
        <section className={`vehicle-detail__box activity${tab === 'cameras' ? ' active' : ''}`}>
          {tab === 'cameras' ? <VehicleCamerasView vehicle={vehicle} /> : null}
        </section>
        <section
          className={`vehicle-detail__box activity${tab === 'information' ? ' active' : ''}`}
        >
          {tab === 'information' ? <VehicleView vehicle={vehicle} {...this.props} /> : null}
        </section>

        <section className={`vehicle-detail__box activity${tab === 'complaints' ? ' active' : ''}`}>
          {tab === 'complaints' ? (
            <Suspense fallback={<>{<Loader />}</>}>
              <ComplaintsContainer entityType="vehicle" entity={vehicle} />
            </Suspense>
          ) : null}
        </section>

        <section className={`vehicle-detail__box activity${tab === 'tires' ? ' active' : ''}`}>
          {tab === 'tires' ? (
            <Suspense fallback={<>{<Loader />}</>}>
              <TiresContainer entityType="vehicle" entity={vehicle} />
            </Suspense>
          ) : null}
        </section>

        {tab === 'todo' ? (
          <section className={`vehicle-detail__box ${tab === 'todo' ? 'active' : ''}`}>
            <EntityTodosContainer
              {...this.props}
              relatedEntity={vehicle}
              relatedEntityType="vehicle"
            />
          </section>
        ) : null}
      </div>
    );
  }
}
export default withTranslation('translation')(VehicleDetailView);
