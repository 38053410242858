import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateVehicleContainer from '@containers/vehicle/crud/CreateVehicleContainer';

import { search } from '@api/vehicleApi';

import * as navigationActions from '@actions/navigationActions';
import * as vehicleActions from '@actions/vehicleActions';

import { prefixZeroFormat } from '@utils/numberUtils';

let searchTimeout = null;

export const transformVehicleToValue = (vehicle) => ({
  label: vehicle.fleets
    ? `${vehicle.fleets
        .map(
          (fleetVehicle) =>
            `${fleetVehicle.fleet.number}${prefixZeroFormat(fleetVehicle.order + 1)}`
        )
        .join(', ')} - ${vehicle.name}`
    : vehicle.name,
  vehicle: vehicle,
  value: vehicle.id,
});

class VehicleInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicle: this.props.vehicle ? transformVehicleToValue(this.props.vehicle) : null,
      vehicles: [],
    };
  }

  componentDidMount() {
    search({}, 0, 40).then((response) => {
      this.setState({
        vehicles: response.vehicles,
      });
    });
  }

  onChange = (newVehicle) => {
    this.setState({
      vehicle: newVehicle,
    });
    const { onChange } = this.props;
    onChange && onChange(newVehicle ? newVehicle.vehicle : null);
  };

  render() {
    const { t, isFetchingVehicles, addToStack, placeholder } = this.props;
    const { vehicle, vehicles } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          defaultOptions={vehicles.map((vehicle) => transformVehicleToValue(vehicle))}
          placeholder={placeholder ? placeholder : `${t('form.label.searchVehicle')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          isLoading={isFetchingVehicles}
          isDisabled={isFetchingVehicles}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              search({ query: inputValue }, 0, 40).then((response) => {
                callback(response.vehicles.map((vehicle) => transformVehicleToValue(vehicle)));
              });
            }, 800);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('vehicle.new'),
              component: (
                <CreateVehicleContainer
                  targetName={inputValue}
                  callback={(newVehicle) => {
                    this.onChange(transformVehicleToValue(newVehicle));
                  }}
                />
              ),
            });
          }}
          value={vehicle}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(vehicleActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(VehicleInput));
