import React, { Component } from 'react';

import SimpleReactValidator from 'simple-react-validator';

import Attachments from '@uicomponents/Attachments';
import Loader from '@uicomponents/Loader';
import FormInput from '@uiinputs/FormInput';
import MoneyInput from '@uiinputs/MoneyInput';

import Association from '@models/general/Association';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import AdministrationInput from '../../administration/crud/AdministrationInput';
import ConsignmentsInput from '../../consignment/crud/ConsignmentsInput';
import CustomerInput from '../../customer/crud/CustomerInput';
import ContactDetailsInput from '../../general/crud/ContactDetailsInput';
import NotesInput from '../../note/crud/NotesInput';
import TeamInput from '../../team/TeamInput';
import DeliveryTermsSelector from '../DeliveryTermsSelector';

export default class TransportOrderForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { onSubmit, formTransportOrder } = this.props;

    onSubmit && onSubmit(formTransportOrder);
  }

  onChange = (value, property) => {
    const { onChange } = this.props;
    const newTransportOrder = { ...formTransportOrder };
    newTransportOrder[property] = value;

    onChange('formTransportOrder', newTransportOrder, event);
  };
  render() {
    const {
      t,
      user,
      onChange,
      formTransportOrder,
      currencies,
      isFetchingCurrencies,
      platform,
      simpleForms,
    } = this.props;

    if (currencies.length < 1 && isFetchingCurrencies) {
      return <Loader />;
    }

    return (
      <div
        className="step-form transport-order-form"
        key={`${formTransportOrder.id}-${formTransportOrder.versionNumber}-${formTransportOrder.nonce}`}
      >
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div className="text-inputs">
              {!simpleForms && (
                <>
                  <FormInput
                    type="text"
                    className="input-group no-margin-top"
                    label={`${t('form.label.name')}*`}
                    value={formTransportOrder.name}
                    onChange={(event) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.name = event.target.value;

                      onChange('formTransportOrder', newTransportOrder, event);
                    }}
                  />
                  {this.validator.message(
                    t('form.label.name'),
                    formTransportOrder.name,
                    'required'
                  )}
                </>
              )}
              <div className="input-group">
                <FormInput
                  type="text"
                  label={t('form.label.referenceDescription')}
                  value={formTransportOrder.referenceDescription}
                  onChange={(event) => {
                    const newTransportOrder = { ...formTransportOrder };
                    newTransportOrder.referenceDescription = event.target.value;

                    onChange('formTransportOrder', newTransportOrder, event);
                  }}
                />
              </div>
              <div className="input-group">
                <CustomerInput
                  {...this.props}
                  key={`${
                    formTransportOrder.customer && formTransportOrder.customer.business
                      ? formTransportOrder.customer.business.id
                      : ''
                  }`}
                  placeholder={`${t('form.label.selectCustomer')}*`}
                  customer={formTransportOrder.customer ? { ...formTransportOrder.customer } : null}
                  onChange={(customer) => {
                    const newTransportOrder = { ...formTransportOrder };
                    newTransportOrder.customer = customer;

                    onChange('formTransportOrder', newTransportOrder);
                  }}
                />
              </div>
              {this.validator.message(
                t('form.label.customer'),
                formTransportOrder.customer,
                'required'
              )}

              <div className="input-group">
                <div className="input-group no-margin-top">
                  <DeliveryTermsSelector
                    key={formTransportOrder.deliveryTerms}
                    value={formTransportOrder.deliveryTerms}
                    defaultValue={platform.planningSettings?.defaultDeliveryTerms}
                    placeholder={`${t('form.label.selectDeliveryTerms')}*`}
                    onChange={(event) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.deliveryTerms = event.value;

                      onChange('formTransportOrder', newTransportOrder, event);
                    }}
                  />
                </div>
                {this.validator.message(
                  t('form.label.deliveryTerms'),
                  formTransportOrder.deliveryTerms,
                  'required'
                )}
              </div>
              {user.accountType !== 'customer' && (
                <>
                  <div className="input-group">
                    <div className="input-group no-margin-top">
                      <TeamInput
                        key={formTransportOrder?.team}
                        value={formTransportOrder.team}
                        placeholder={t('form.label.selectTeam')}
                        onChange={(newTeam) => {
                          const newTransportOrder = { ...formTransportOrder };
                          newTransportOrder.team = { ...newTeam };

                          onChange('formTransportOrder', newTransportOrder);
                        }}
                      />
                    </div>
                  </div>
                  {platform?.features?.map((feature) => feature.name)?.includes('financial') && (
                    <div className="input-group">
                      <AdministrationInput
                        key={formTransportOrder.administration?.id || ''}
                        administration={formTransportOrder.administration}
                        onChange={(newAdministration) => {
                          const newTransportOrder = { ...formTransportOrder };
                          newTransportOrder.administration = newAdministration;

                          onChange('formTransportOrder', newTransportOrder, event);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
              <div className="input-group">
                <MoneyInput
                  currencies={currencies}
                  user={user}
                  value={formTransportOrder.value}
                  label={t('form.label.transportAmount')}
                  onChange={(newValue) => {
                    const newTransportOrder = { ...formTransportOrder };
                    newTransportOrder.value = newValue;

                    onChange('formTransportOrder', newTransportOrder);
                  }}
                />
              </div>
            </div>
            {!simpleForms && (
              <>
                <div className="input-group">
                  <div className="input-group no-margin-top">
                    <FormInput
                      type="textarea"
                      label={t('form.label.description')}
                      value={formTransportOrder.description}
                      onChange={(event) => {
                        const newTransportOrder = { ...formTransportOrder };
                        newTransportOrder.description = event.target.value;

                        onChange('formTransportOrder', newTransportOrder, event);
                      }}
                    />
                  </div>
                </div>
                <div className="input-group upload-files">
                  <h3 className="no-margin-top no-margin-bottom">{t('form.label.documents')}</h3>
                  <Attachments
                    {...this.props}
                    key={formTransportOrder.documents}
                    files={formTransportOrder.documents.map((document) => {
                      return document.entity.file;
                    })}
                    onChange={(files) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.documents = files.map((file) => {
                        const newFile = { ...file };
                        return new Association('inline', {
                          name: newFile.originalName,
                          mimeType: newFile.mimeType,
                          content: {
                            contentType: 'uri',
                            uri: newFile.url,
                          },
                          file: newFile,
                        });
                      });
                      onChange('formTransportOrder', newTransportOrder);
                    }}
                  />
                </div>
              </>
            )}
            <div className="input-group consignments-input">
              <h3>{t('form.label.consignments')}</h3>
              <ConsignmentsInput
                {...this.props}
                key={formTransportOrder.consignments}
                consignments={
                  formTransportOrder.consignments ? [...formTransportOrder.consignments] : []
                }
                onChange={(newConsignments) => {
                  const newTransportOrder = { ...formTransportOrder };
                  newTransportOrder.consignments = newConsignments;

                  onChange('formTransportOrder', newTransportOrder);
                }}
              />
            </div>
            {!simpleForms && (
              <>
                <div className="input-group notes">
                  <h3>{t('form.label.notes')}</h3>
                  <NotesInput
                    key={formTransportOrder.notes ? [...formTransportOrder.notes] : []}
                    notes={formTransportOrder.notes ? [...formTransportOrder.notes] : []}
                    onChange={(notes) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.notes = notes;

                      onChange('formTransportOrder', newTransportOrder);
                    }}
                  />
                </div>
                <div className="input-group">
                  <h3>{t('form.label.contactDetails')}</h3>
                  <ContactDetailsInput
                    key={
                      formTransportOrder.contactDetails
                        ? [...formTransportOrder.contactDetails]
                        : []
                    }
                    contactDetails={
                      formTransportOrder.contactDetails
                        ? [...formTransportOrder.contactDetails]
                        : []
                    }
                    onChange={(contactDetails) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.contactDetails = contactDetails;

                      onChange('formTransportOrder', newTransportOrder);
                    }}
                  />
                </div>
              </>
            )}

            <div className="input-group more right buttons">
              {!formTransportOrder.updatedAt && (
                <button
                  disabled={!this.validator.allValid()}
                  onClick={(e) => {
                    const newTransportOrder = { ...formTransportOrder };
                    newTransportOrder.status = 'requested';

                    onChange('formTransportOrder', newTransportOrder);
                  }}
                >
                  {t('form.saveAsRequested')}
                </button>
              )}
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
