import React, { Component } from 'react';

import SimpleReactValidator from 'simple-react-validator';

import Loader from '@uicomponents/Loader';
import MoneyInput from '@uiinputs/MoneyInput';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import ContractInput from '../../contract/ContractInput';
import TransportOrderInput from '../../transportOrder/TransportOrderInput';
import FormInput from '@uiinputs/FormInput';

export default class TenderForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {};
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { onSubmit, formTender } = this.props;
    onSubmit && onSubmit(formTender);
  }

  render() {
    const { t, user, onChange, formTender, currencies, isFetchingCurrencies } = this.props;
    const { selectedCurrency, currency } = this.state;

    if (currencies.length < 1 && isFetchingCurrencies) {
      return <Loader />;
    }
    return (
      <div className="step-form" key={formTender.id}>
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div className="input-group">
              <div className="input-group no-margin-top">
                <FormInput
                  type="text"
                  label={`${t('form.label.name')}*`}
                  value={formTender.name}
                  onChange={(event) => {
                    const newTender = { ...formTender };
                    newTender.name = event.target.value;

                    onChange('formTender', newTender, event);
                  }}
                />
              </div>
              {this.validator.message(t('form.label.name'), formTender.name, 'required')}
            </div>
            <div className="input-group">
              <div className="input-group no-margin-top">
                <FormInput
                  type="textarea"
                  label={t('form.label.description')}
                  className="small"
                  value={formTender.description}
                  onChange={(event) => {
                    const newTender = { ...formTender };
                    newTender.description = event.target.value;

                    onChange('formTender', newTender, event);
                  }}
                />
              </div>
            </div>
            <div className="input-group">
              <MoneyInput
                currencies={currencies}
                user={user}
                value={formTender.value}
                onChange={(newValue) => {
                  const newTender = { ...formTender };
                  newTender.targetPrice = newValue;
                  onChange('formTender', newTender);
                }}
              />
            </div>
            <div className="input-group">
              <ContractInput
                key={formTender.contract}
                value={formTender.contract}
                onChange={(newContract) => {
                  const newTender = { ...formTender };
                  newTender.contract = newContract;

                  onChange('formTender', newTender);
                }}
              />
            </div>

            <div className="input-group">
              <TransportOrderInput
                key={formTender.transportOrder}
                value={formTender.transportOrder}
                onChange={(newTransportOrder) => {
                  const newTender = { ...formTender };
                  newTender.transportOrder = newTransportOrder;

                  onChange('formTender', newTender);
                }}
              />
            </div>
            <div className="input-group more right">
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
