import React, { PureComponent, Suspense, lazy } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import Select from 'react-select';
import ReactSVG from 'react-svg';

import 'leaflet-rotatedmarker';
import moment from 'moment';

import {
  FINANCIAL_VIEW,
  INCOMING,
  INCOMING_LINK,
  INSIGHTS,
  INSIGHTS_LINK,
  INVOICES,
  INVOICES_LINK,
  MANAGEMENT_FINANCIAL,
  MANAGEMENT_FINANCIAL_LINK,
  MANAGEMENT_OPERATIONAL,
  MANAGEMENT_OPERATIONAL_LINK,
  MANAGEMENT_VIEW,
  OPTIMIZER,
  OPTIMIZER_LINK,
  PLANNER,
  PLANNER_LINK,
  PLANNING_VIEW,
  PLAN_BOARD,
  PLAN_BOARD_LINK,
  QUOTES,
  QUOTES_LINK,
  ROUTE_PLANNER,
  ROUTE_PLANNER_LINK,
  TENDERS,
  TENDERS_LINK,
  TODO,
  TODO_LINK,
  TRANSACTIONS,
  WATCHTOWER,
  WATCHTOWER_LINK,
  reactSelectCustomStyle,
} from '@/constants/dashboard';
import WelcomeStatsPage from '@components/insights/WelcomeStatsPage';

import Loader from '@uicomponents/Loader';

import DashboardTabLink from './includes/DashboardTabLink';

const MapPlannerContainer = lazy(() => import('@containers/planning/MapPlannerContainer'));

const PlanBoardContainer = lazy(() => import('@containers/planning/planBoard/PlanBoardContainer'));
const OperationalDashboardContainer = lazy(() =>
  import('@containers/management/OperationalDashboardContainer')
);
const FinancialDashboardContainer = lazy(() =>
  import('@containers/management/FinancialDashboardContainer')
);
const PlannerContainer = lazy(() => import('@containers/planning/planner/PlannerContainer'));
const WatchtowerContainer = lazy(() => import('@containers/planning/WatchtowerContainer'));
const OptimizerContainer = lazy(() => import('@containers/planning/OptimizerContainer'));
const TendersContainer = lazy(() => import('@containers/tender/TendersContainer'));
const RoutePlannerContainer = lazy(() => import('@containers/planning/RoutePlannerContainer'));
const InsightsContainer = lazy(() => import('@containers/insights/InsightsContainer'));

const InvoicesContainer = lazy(() =>
  import('@containers/administration/invoice/InvoicesContainer')
);
const QuotesContainer = lazy(() => import('@containers/administration/quote/QuotesContainer'));
const TransactionsContainer = lazy(() =>
  import('@containers/administration/transaction/TransactionsContainer')
);

const TransportOrderListContainer = lazy(() =>
  import('@containers/transportOrder/TransportOrderListContainer')
);
const MailsContainer = lazy(() => import('@containers/mail/MailsContainer'));
const TodoContainer = lazy(() => import('@containers/todo/TodoMainContainer'));

let timeout = null;

const plannerTabs = [
  {
    view: PLAN_BOARD,
    enabled: (mappedFeatures) =>
      mappedFeatures.filter((feature) => feature.includes('PlanBoard')).length > 0,
  },
  {
    view: PLANNER,
    enabled: (mappedFeatures) =>
      mappedFeatures.filter((feature) => feature.includes('-planner')).length > 0,
  },
  { view: WATCHTOWER, enabled: (mappedFeatures) => mappedFeatures.includes(WATCHTOWER) },
  { view: ROUTE_PLANNER, enabled: (mappedFeatures) => mappedFeatures.includes(ROUTE_PLANNER) },
  { view: OPTIMIZER, enabled: (mappedFeatures) => mappedFeatures.includes(OPTIMIZER) },
  { view: TODO, enabled: (mappedFeatures) => mappedFeatures.includes(TODO) },
  { view: TENDERS, enabled: (mappedFeatures) => mappedFeatures.includes(TENDERS) },
  { view: INSIGHTS, enabled: (mappedFeatures) => mappedFeatures.includes(INSIGHTS) },
];

export default class DashboardPage extends PureComponent {
  constructor(props) {
    super(props);

    const { t, match, platform, user } = this.props;

    const mappedFeatures = platform?.features?.map((feature) => feature.name);

    this.state = {
      tab: match.params.tab
        ? match.params.tab
        : user.accountType === FINANCIAL_VIEW
        ? INVOICES
        : plannerTabs.filter((tab) => tab.enabled(mappedFeatures))?.[0]?.view || PLAN_BOARD,
      map: null,
      view: user.accountType === 'customer' ? PLANNING_VIEW : user.accountType || PLANNING_VIEW,
      searchParams: {},
      renderMap: false,
    };

    this.viewOptions = [
      {
        value: PLANNING_VIEW,
        label: t('dashboard.view.planning'),
      },
    ];

    // adding financial view if enabled in platform settings
    if (mappedFeatures?.includes(FINANCIAL_VIEW) && user.accountType !== 'customer') {
      this.viewOptions.push({
        value: FINANCIAL_VIEW,
        label: t('dashboard.view.financial'),
      });
    }

    // adding management view if enabled in platform settings
    if (mappedFeatures?.includes(MANAGEMENT_VIEW) && user.accountType !== 'customer') {
      this.viewOptions.push({
        value: MANAGEMENT_VIEW,
        label: t('dashboard.view.management'),
      });
    }
  }

  componentDidMount() {
    const { view } = this.state;
    const { match, getAdministrations, changeAdministrationValue, getInvoices } = this.props;

    if (match.params.tab) {
      switch (match.params.tab) {
        case PLAN_BOARD:
        default:
          this.setState({ tab: PLAN_BOARD, view: PLANNING_VIEW });
          break;
        case PLANNER:
          this.setState({ tab: PLANNER, view: PLANNING_VIEW });
          break;
        case WATCHTOWER:
          this.setState({ tab: WATCHTOWER, view: PLANNING_VIEW });
          break;
        case ROUTE_PLANNER:
          this.setState({ tab: ROUTE_PLANNER, view: PLANNING_VIEW });
          break;
        case OPTIMIZER:
          this.setState({ tab: OPTIMIZER, view: PLANNING_VIEW });
          break;
        case TENDERS:
          this.setState({ tab: TENDERS, view: PLANNING_VIEW });
          break;
        case TODO:
          this.setState({ tab: TODO, view: PLANNING_VIEW });
          break;
        case INSIGHTS:
          this.setState({ tab: INSIGHTS, view: PLANNING_VIEW });
          break;
        case INVOICES:
          this.setState({ tab: INVOICES, view: FINANCIAL_VIEW });
          break;
        case QUOTES:
          this.setState({ tab: QUOTES, view: FINANCIAL_VIEW });
          break;
        case INCOMING:
          this.setState({ tab: INCOMING, view: FINANCIAL_VIEW });
          break;
        case TRANSACTIONS:
          this.setState({ tab: TRANSACTIONS, view: FINANCIAL_VIEW });
          break;
        case MANAGEMENT_OPERATIONAL:
          this.setState({ tab: MANAGEMENT_OPERATIONAL, view: MANAGEMENT_VIEW });
          break;
        case MANAGEMENT_FINANCIAL:
          this.setState({ tab: MANAGEMENT_FINANCIAL, view: MANAGEMENT_VIEW });
          break;
      }
    }

    timeout = setTimeout(() => {
      this.setState({
        renderMap: true,
      });
    }, 500);
  }

  componentWillUnmount() {
    this.setState({
      renderMap: false,
    });

    clearTimeout(timeout);
  }

  renderAdministrationsSelector() {
    const {
      administration,
      administrations,
      isFetchingAdministrations,
      changeAdministrationValue,
      getInvoices,
    } = this.props;

    if (!isFetchingAdministrations && administrations.length > 0) {
      const options = administrations.map((administration) => ({
        value: administration.id,
        label: administration.name,
      }));
      return (
        <div className="action">
          <div className="action-input action-input--small form no-padding">
            <div className="input-group no-margin">
              <Select
                options={options}
                styles={reactSelectCustomStyle({
                  controlWidth: '200px',
                  controlRounded: false,
                  controlBackground: 'var(--white) !important',
                })}
                className="react-select__control"
                value={options.filter((option) => administration === option.value)}
                onChange={(e) => {
                  changeAdministrationValue('administration', e.value);
                  getInvoices(e.value, 0, 30);
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    const { t, user, platform } = this.props;
    const { view, tab } = this.state;

    const mappedFeatures = platform?.features?.map((feature) => feature.name) || [];
    const hasWelcome = mappedFeatures.includes('welcome');
    const hasEmail = mappedFeatures.includes('email');
    const hasOpenTransportOrders = mappedFeatures.includes('hasOpenTransportOrders');
    const currentHour = parseInt(moment().format('HH'));

    let welcomeType = 'goodMorning';
    if (currentHour >= 12 && currentHour < 18) {
      welcomeType = 'goodAfternoon';
    } else if (currentHour >= 18 && currentHour < 24) {
      welcomeType = 'goodEvening';
    } else if (currentHour >= 0 && currentHour < 6) {
      welcomeType = 'goodNight';
    }

    return (
      <div className="wrapper top minimal main dashboard">
        {view === 'planning' && <MapPlannerContainer className="map-overview" />}

        {user.accountType !== 'customer' && (
          <div className="container">
            {hasWelcome && (
              <div className="dashboard__welcome top">
                <div className="dashboard__welcome__header">
                  <ReactSVG
                    className="icon"
                    src={`/icons/welcome/${
                      currentHour >= 18 || currentHour < 6 ? 'moon' : 'sun'
                    }.svg`}
                  />
                  <h1>{`${t(`dashboard.welcome.${welcomeType}`)} ${user.firstName}!`}</h1>
                </div>
                <WelcomeStatsPage key={'welcome'} />
              </div>
            )}
            <div className="flex-container justify-between align-items-start">
              {hasEmail && (
                <div className={`half emails dash-box ${hasWelcome ? '' : 'top'}`}>
                  <h2 className="no-margin-top">{t('mails.header')}</h2>
                  <MailsContainer />
                </div>
              )}

              {view === PLANNING_VIEW && hasOpenTransportOrders && (
                <div
                  className={`${hasEmail ? 'half' : 'full'} orders dash-box ${
                    hasWelcome ? '' : 'top'
                  }`}
                >
                  <div className="flex-container">
                    <h2 className="no-margin-top">{t('transportOrders.header')}</h2>
                  </div>
                  <TransportOrderListContainer
                    status={'accepted'}
                    type={hasEmail ? 'half' : 'full'}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <div className="container mt-10">
          <div className="d-flex justify-content-between align-items-center my-1">
            {/*------ dashboard tabs top- ------*/}
            <ScrollContainer
              className="d-flex gap-10 tab-links-container"
              vertical={true}
              hideScrollbars={false}
            >
              {view === PLANNING_VIEW && (
                <>
                  <DashboardTabLink
                    tab={tab}
                    display={
                      mappedFeatures.filter((feature) => feature.includes('PlanBoard')).length > 0
                    }
                    label={t('dashboard.tabs.planBoard')}
                    name={PLAN_BOARD}
                    link={PLAN_BOARD_LINK}
                    platformName={platform.name}
                    setState={() => this.setState({ tab: PLAN_BOARD })}
                  />

                  <DashboardTabLink
                    tab={tab}
                    display={
                      mappedFeatures.filter((feature) => feature.includes('-planner')).length > 0
                    }
                    label={t('dashboard.tabs.planner')}
                    name={PLANNER}
                    link={PLANNER_LINK}
                    platformName={platform.name}
                    setState={() => this.setState({ tab: PLANNER })}
                  />

                  <DashboardTabLink
                    tab={tab}
                    display={mappedFeatures.includes(WATCHTOWER)}
                    label={t('dashboard.tabs.watchtower')}
                    name={WATCHTOWER}
                    link={WATCHTOWER_LINK}
                    platformName={platform.name}
                    setState={() => this.setState({ tab: WATCHTOWER })}
                  />
                  {user.accountType !== 'customer' && (
                    <>
                      <DashboardTabLink
                        tab={tab}
                        display={mappedFeatures.includes(ROUTE_PLANNER)}
                        label={t('dashboard.tabs.routePlanner')}
                        name={ROUTE_PLANNER}
                        link={ROUTE_PLANNER_LINK}
                        platformName={platform.name}
                        setState={() => this.setState({ tab: ROUTE_PLANNER })}
                      />
                      <DashboardTabLink
                        tab={tab}
                        display={mappedFeatures.includes(OPTIMIZER)}
                        label={t('dashboard.tabs.optimizer')}
                        name={OPTIMIZER}
                        link={OPTIMIZER_LINK}
                        platformName={platform.name}
                        setState={() => this.setState({ tab: OPTIMIZER })}
                      />
                      <DashboardTabLink
                        tab={tab}
                        display={mappedFeatures.includes(TENDERS)}
                        label={t('dashboard.tabs.tenders')}
                        name={TENDERS}
                        link={TENDERS_LINK}
                        platformName={platform.name}
                        setState={() => this.setState({ tab: TENDERS })}
                      />
                      <DashboardTabLink
                        tab={tab}
                        display={mappedFeatures.includes(TODO)}
                        label={t('dashboard.tabs.todo')}
                        name={TODO}
                        link={TODO_LINK}
                        platformName={platform.name}
                        setState={() => this.setState({ tab: TODO })}
                      />
                    </>
                  )}
                </>
              )}

              {view === FINANCIAL_VIEW && (
                <>
                  <DashboardTabLink
                    tab={tab}
                    display={true}
                    label={t('dashboard.tabs.invoices')}
                    name={INVOICES}
                    link={INVOICES_LINK}
                    platformName={platform.name}
                    setState={() => this.setState({ tab: INVOICES })}
                  />

                  <DashboardTabLink
                    tab={tab}
                    display={true}
                    label={t('dashboard.tabs.incoming')}
                    name={INCOMING}
                    link={INCOMING_LINK}
                    platformName={platform.name}
                    setState={() => this.setState({ tab: INCOMING })}
                  />

                  <DashboardTabLink
                    tab={tab}
                    display={true}
                    label={t('dashboard.tabs.quotes')}
                    name={QUOTES}
                    link={QUOTES_LINK}
                    platformName={platform.name}
                    setState={() => this.setState({ tab: QUOTES })}
                  />
                </>
              )}
              {view === MANAGEMENT_VIEW && (
                <>
                  <DashboardTabLink
                    tab={tab}
                    display={true}
                    label={t('dashboard.tabs.operational')}
                    name={MANAGEMENT_OPERATIONAL}
                    link={MANAGEMENT_OPERATIONAL_LINK}
                    platformName={platform.name}
                    setState={() => this.setState({ tab: MANAGEMENT_OPERATIONAL })}
                  />
                  <DashboardTabLink
                    tab={tab}
                    display={true}
                    label={t('dashboard.tabs.financial')}
                    name={MANAGEMENT_FINANCIAL}
                    link={MANAGEMENT_FINANCIAL_LINK}
                    platformName={platform.name}
                    setState={() => this.setState({ tab: MANAGEMENT_FINANCIAL })}
                  />
                </>
              )}
              {user.accountType !== 'customer' && (
                <DashboardTabLink
                  tab={tab}
                  display={mappedFeatures.includes(INSIGHTS)}
                  label={t('dashboard.tabs.insights')}
                  name={INSIGHTS}
                  link={INSIGHTS_LINK}
                  platformName={platform.name}
                  setState={() => this.setState({ tab: INSIGHTS })}
                />
              )}
            </ScrollContainer>

            {/*------ dashboard tab content ------*/}

            <div className="side-dropdown d-flex gap-10 align-items-center justify-content-end">
              {view === 'financial' && this.renderAdministrationsSelector()}
              <div className="action">
                <div className="action-input action-input--small form no-padding">
                  <div className="input-group no-margin">
                    <Select
                      options={this.viewOptions}
                      styles={reactSelectCustomStyle({
                        controlWidth: '200px',
                        controlRounded: false,
                        controlBackground: 'var(--white) !important',
                      })}
                      className="react-select__control dashboard-view-selector"
                      value={this.viewOptions.filter((option) => view === option.value)}
                      onChange={(e) => {
                        this.setState(
                          {
                            view: e.value,
                          },
                          () => {
                            switch (this.state.view) {
                              case 'planning':
                                this.setState({
                                  tab:
                                    plannerTabs.filter((tab) => tab.enabled(mappedFeatures))?.[0]
                                      ?.view || PLAN_BOARD,
                                });
                                window.history.replaceState({}, platform.name, PLAN_BOARD_LINK);

                                break;
                              case 'financial':
                                window.history.replaceState({}, platform.name, INVOICES_LINK);
                                this.setState({ tab: INVOICES });
                                break;
                              case 'management':
                                window.history.replaceState(
                                  {},
                                  platform.name,
                                  MANAGEMENT_OPERATIONAL_LINK
                                );
                                this.setState({ tab: MANAGEMENT_OPERATIONAL });
                                break;
                            }
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tabs-content-container bg-white rounded">
            <Suspense fallback={<>{<Loader />}</>}>{_renderTabContent(tab, this.props)}</Suspense>{' '}
          </div>
        </div>
      </div>
    );
  }
}

const _renderTabContent = (tab, props) => {
  switch (tab) {
    case PLAN_BOARD:
      return <PlanBoardContainer {...props} />;
    case PLANNER:
      return <PlannerContainer {...props} />;
    case TRANSACTIONS:
      return <TransactionsContainer {...props} />;
    case WATCHTOWER:
      return <WatchtowerContainer {...props} />;
    case ROUTE_PLANNER:
      return <RoutePlannerContainer {...props} />;
    case INVOICES:
      return <InvoicesContainer {...props} direction="out" className="outgoing-invoices" />;
    case INCOMING:
      return <InvoicesContainer {...props} direction="in" className="incoming-invoices" />;
    case QUOTES:
      return <QuotesContainer {...props} className="quotes" />;
    case OPTIMIZER:
      return <OptimizerContainer {...props} />;
    case TENDERS:
      return <TendersContainer {...props} />;
    case INSIGHTS:
      return <InsightsContainer {...props} />;
    case TODO:
      return <TodoContainer />;
    case MANAGEMENT_OPERATIONAL:
      return <OperationalDashboardContainer />;
    case MANAGEMENT_FINANCIAL:
      return <FinancialDashboardContainer />;
    default:
      return null;
  }
};
