import React, { Component } from 'react';

import { TileLayer } from 'react-leaflet';
import { connect } from 'react-redux';

class TileLayers extends Component {
  static defaultProps = {
    mapTypeSelection: true,
    mapTypeOptions: [
      {
        name: 'transport',
        previewUrl: '/images/maps/transport-map.png',
      },
      {
        name: 'satellite',
        previewUrl: '/images/maps/satellite-map.jpeg',
      },
    ],
  };

  state = {
    type: this.props.type || 'transport',
  };
  render() {
    const { type } = this.state;
    const { mapTypeSelection, mapTypeOptions, darkMode } = this.props;

    return (
      <>
        {type === 'transport' && (
          <TileLayer
            className={darkMode ? 'map-layer-dark' : ''}
            url="https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=c3eba7b209034239a58c3fbe19b5e4fc"
          />
        )}
        {type === 'beauty' && (
          <TileLayer
            className={darkMode ? 'map-layer-dark' : ''}
            url="https://api.mapbox.com/styles/v1/thomas-n0name/ckgtd8z7521u919paxtu0q5m1/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidGhvbWFzLW4wbmFtZSIsImEiOiJja2d0ZDVrM2owYXBoMnFwMGJ5Mm93amM1In0.NgqXPSaGRLuK-OhI_2_6eQ"
          />
        )}
        {type === 'satellite' && (
          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
        )}

        {mapTypeSelection && (
          <div className="layer-selector">
            <div className="current-layer">
              <img src={mapTypeOptions.find((option) => option.name === type).previewUrl} />
            </div>
            <div className="layer-options">
              {mapTypeOptions
                .filter((option) => option.name !== type)
                .map((mapTypeOption) => (
                  <div
                    key={mapTypeOption}
                    className="layer-option"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        type: mapTypeOption.name,
                      });
                    }}
                  >
                    <img src={mapTypeOption.previewUrl} alt={mapTypeOption.name} />
                    {mapTypeOption.name && <div>{mapTypeOption.name}</div>}
                  </div>
                ))}
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    darkMode: state.settings.darkMode,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(TileLayers);
