import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import FormInput from '@uiinputs/FormInput';
import SelectFieldpathInput from '@uiinputs/SelectFieldpathInput';

const pricingStartValueTypeOptions = [
  {
    value: 'CONTEXT_VALUE',
    label: 'CONTEXT_VALUE',
  },
  {
    value: 'PREVIOUS_AMOUNT',
    label: 'PREVIOUS_AMOUNT',
  },
];
class PricingStartValueInput extends Component {
  render() {
    const { t, entityType, value, onChange } = this.props;

    console.log(value);
    return (
      <>
        <div className="input-group">
          <div className="input-group more">
            <Select
              options={pricingStartValueTypeOptions}
              label={`${t('form.label.type')}*`}
              value={pricingStartValueTypeOptions.find((o) => o?.value === value?.type)}
              onChange={(e) => {
                const newValue = { ...(value || {}) };
                newValue.type = e.value;

                onChange?.(newValue);
              }}
            />
          </div>
        </div>
        {value?.type === 'CONTEXT_VALUE' && (
          <div className="input-group">
            <div className="input-group no-margin-top">
              <SelectFieldpathInput
                type="text"
                value={value?.selector}
                label={`${t('form.label.selector')}*`}
                entityType={entityType || 'trip'}
                onChange={(e) => {
                  const newValue = { ...(value || {}) };
                  newValue.selector = e;

                  onChange?.(newValue);
                }}
              />
            </div>
          </div>
        )}

        <div className="input-group">
          <div className="input-group no-margin-top">
            <FormInput
              type="number"
              label={t('form.label.factor')}
              value={value?.factor}
              onChange={(e) => {
                const newValue = { ...(value || {}) };
                newValue.factor = e.target.value;

                onChange?.(newValue);
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(PricingStartValueInput);
