import React, { Component, createRef } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import { BasicType, BlockManager, JsonToMjml } from 'easy-email-core';
import { EmailEditor, EmailEditorProvider } from 'easy-email-editor';
import { StandardLayout } from 'easy-email-extensions';
import mjml from 'mjml-browser';

import TriggerEventSelector from '@components/general/TriggerEventSelector';
import WorkflowTypeSelector from '@components/general/WorkflowTypeSelector';
import ChannelSelector from '@components/integration/crud/ChannelSelector';
import IntegrationInput from '@components/integration/crud/IntegrationInput';
import TriggerTypeSelector from '@components/integration/crud/TriggerTypeSelector';
import IntegrationFiltersInput from '@components/integration/filter/IntegrationFiltersInput';
import TQLInput from '@components/tql/TQLInput';

import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';
import VariableContentEditable from '@uiinputs/VariableContentEditable';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import '@/resources/assets/easy-email-editor.css';
import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';

export default class WorkflowForm extends Component {
  constructor(props) {
    super(props);

    this.emailEditorRef = createRef();

    this.validator = new SimpleReactValidator();
    this.state = {
      tql: false,
      localizedMessages: {},
      tab: this.props.settings?.user?.language?.localName,
      workflowLanguages: [this.props.settings?.user?.language?.code],
    };
  }

  componentDidMount() {
    const { formWorkflow, updateTopStack, entityStructure, getEntityStructure, triggerEvents } =
      this.props;
    const { channel } = formWorkflow?.workflowConfig || {};
    this.setLocalizedTexts();

    switch (channel) {
      case 'email':
        updateTopStack({ fullWidth: true });
        break;
      default:
        updateTopStack({ fullWidth: false });
        break;
    }

    if (formWorkflow?.workflowConfig?.triggerEvent) {
      const selectedEntity = triggerEvents.find(
        (option) => option.event === formWorkflow?.workflowConfig?.triggerEvent
      );
      getEntityStructure(selectedEntity.entityType);
    } else if (formWorkflow?.workflowConfig?.query) {
      const { entityType } = formWorkflow?.workflowConfig?.query;
      getEntityStructure(entityType);
    }
    activateInputs();
  }

  componentDidUpdate(prevProps) {
    const { formWorkflow, updateTopStack } = this.props;
    const { channel } = formWorkflow?.workflowConfig || {};

    if (prevProps.formWorkflow?.workflowConfig?.channel !== channel && channel === 'email') {
      updateTopStack({ fullWidth: true });
    } else if (
      prevProps.formWorkflow?.workflowConfig?.channel !== channel &&
      prevProps.formWorkflow?.workflowConfig?.channel === 'email'
    ) {
      updateTopStack({ fullWidth: false });
    }

    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(emailEditorValues) {
    const { tab, localizedMessages } = this.state;
    const { onSubmit, formWorkflow, setHasChanged, languages } = this.props;

    const newLocalizedMessages = { ...localizedMessages };

    if (formWorkflow?.workflowConfig?.channel === 'email') {
      const lngCode = languages.filter((lng) => lng.localName === tab)[0]?.code;
      if (newLocalizedMessages?.lngCode) {
        newLocalizedMessages[lngCode].content = this.exportHtml(emailEditorValues);
        newLocalizedMessages[lngCode].messageTemplate = emailEditorValues;
      } else {
        newLocalizedMessages[lngCode] = {
          ...newLocalizedMessages[lngCode],
          content: this.exportHtml(emailEditorValues),
          messageTemplate: emailEditorValues,
        };
      }
    }

    const localizedTexts = this.getLocalizedTexts(newLocalizedMessages);

    const newWorkflow = { ...formWorkflow };
    const newWorkflowConfig = { ...formWorkflow.workflowConfig };

    newWorkflowConfig.localizedTexts = localizedTexts;

    newWorkflow.workflowConfig = newWorkflowConfig;

    onSubmit && onSubmit(newWorkflow);
    setHasChanged(false);
  }

  exportHtml = (values) => {
    return mjml(
      JsonToMjml({
        data: values.content,
        mode: 'production',
        context: values.content,
        // dataSource: mergeTags,
      }),
      {
        validationLevel: 'soft',
      }
    ).html;
  };

  exportMJML = (values) => {
    return JsonToMjml({
      data: values.content,
      mode: 'production',
      context: values.content,
      // dataSource: mergeTags,
    });
  };

  getVariableContentEditableValue = (language) => {
    const { languages } = this.props;
    const { localizedMessages } = this.state;

    const lngCode = languages.filter((lng) => lng.localName === language)[0]?.code;

    return localizedMessages?.[lngCode]?.content;
  };

  getEmailContent = () => {
    const { languages } = this.props;

    const { tab, localizedMessages } = this.state;
    const lngCode = languages.filter((lng) => lng.localName === tab)[0]?.code;

    return localizedMessages?.[lngCode]?.messageTemplate
      ? typeof localizedMessages?.[lngCode]?.messageTemplate === 'string'
        ? JSON.parse(localizedMessages?.[lngCode]?.messageTemplate)
        : localizedMessages?.[lngCode]?.messageTemplate
      : { content: BlockManager.getBlockByType(BasicType.PAGE).create({}) };
  };

  getSubject = (language) => {
    const { languages } = this.props;
    const { localizedMessages } = this.state;

    const lngCode = languages.filter((lng) => lng.localName === language)[0]?.code;

    return localizedMessages?.[lngCode]?.subject;
  };

  getLocalizedTexts = (localizedMessages) => {
    const { languages } = this.props;
    const localizedTexts = [];

    Object.keys(localizedMessages).forEach((key) => {
      const language = languages.find((lng) => lng.code === key);
      localizedTexts.push({
        language: language,
        subject: localizedMessages[key]?.subject,
        content: localizedMessages[key]?.content,
        messageTemplate: localizedMessages[key]?.messageTemplate
          ? JSON.stringify(localizedMessages[key]?.messageTemplate)
          : JSON.stringify({ content: BlockManager.getBlockByType(BasicType.PAGE).create({}) }),
      });
    });

    return localizedTexts;
  };

  setLocalizedTexts = () => {
    const localizedMessages = {};
    const { formWorkflow } = this.props;
    const localizedTextsLanguages = [];

    const localizedTexts = formWorkflow?.workflowConfig?.localizedTexts;

    localizedTexts?.map((text) => {
      localizedTextsLanguages.push(text?.language?.code);
      localizedMessages[text?.language?.code] = {
        subject: text?.subject,
        content: text?.content,
        messageTemplate: text?.messageTemplate
          ? JSON.parse(text?.messageTemplate)
          : { content: BlockManager.getBlockByType(BasicType.PAGE).create({}) },
      };
    });

    if (localizedTextsLanguages.length === 0) {
      localizedTextsLanguages.push(this.props.settings?.user?.language?.code);
    }

    this.setState({
      localizedMessages: localizedMessages,
      workflowLanguages: localizedTextsLanguages,
    });
  };

  render() {
    const {
      t,
      darkMode,
      onChange,
      formWorkflow,
      workflowChannels,
      dataTypes,
      fields,
      isFetchingFields,
      getFieldsRequest,
      searchFieldsRequest,
      entityStructure,
      getEntityStructure,
      languages,
      setHasChanged,
    } = this.props;

    const { tql, tab, localizedMessages } = this.state;

    const integrationSchedulerScheduleTypeOptions = [
      {
        value: 'seconds',
        label: t('schedulerScheduleType.seconds'),
      },
      {
        value: 'minutes',
        label: t('schedulerScheduleType.minutes'),
      },
      {
        value: 'hours',
        label: t('schedulerScheduleType.hours'),
      },
      {
        value: 'days',
        label: t('schedulerScheduleType.days'),
      },
    ];

    const languageOptions = languages
      ? languages?.map((language) => {
          return {
            label: language.localName,
            value: language.id,
            code: language.code,
          };
        })
      : [];

    const width = window.fullWidth;
    const smallScene = width < 1450;

    return (
      <div
        className={`step-form${formWorkflow?.workflowConfig?.channel === 'email' ? ' wide' : ''}`}
        key={formWorkflow.id}
      >
        <EmailEditorProvider
          key={tab}
          autoComplete
          dashed={false}
          mergeTags={entityStructure}
          data={this.getEmailContent()}
          height={`calc(100vh - 100px)`}
          onSubmit={(values) => {
            this.handleSubmit(values);
          }}
        >
          {({ values }, { submit }) => {
            return (
              <form
                className="form active no-padding-top"
                onSubmit={(e) => {
                  e.preventDefault();

                  submit();
                }}
              >
                <div
                  className={`input-container${
                    formWorkflow?.workflowConfig?.channel === 'email' ? ' wide' : ''
                  }`}
                >
                  <div className="input-group">
                    <div className="input-group no-margin-top">
                      <FormInput
                        type="text"
                        setHasChanged={setHasChanged}
                        label={`${t('form.label.name')}*`}
                        value={formWorkflow.name}
                        onChange={(event) => {
                          const newWorkflow = { ...formWorkflow };
                          newWorkflow.name = event.target.value;

                          onChange('formWorkflow', newWorkflow, event);
                        }}
                      />
                    </div>
                    {this.validator.message(t('form.label.name'), formWorkflow.name, 'required')}
                  </div>

                  <div className="input-group">
                    <div className="input-group no-margin-top">
                      <FormInput
                        type="textarea"
                        setHasChanged={setHasChanged}
                        label={`${t('form.label.description')}*`}
                        className="small"
                        value={formWorkflow.description}
                        onChange={(event) => {
                          const newWorkflow = { ...formWorkflow };
                          newWorkflow.description = event.target.value;

                          onChange('formWorkflow', newWorkflow, event);
                        }}
                      />
                    </div>
                  </div>

                  <div className="input-group">
                    <WorkflowTypeSelector
                      key={formWorkflow.type}
                      value={formWorkflow.type}
                      placeholder={t('form.label.selectWorkflowType')}
                      onChange={(e) => {
                        const newWorkflow = { ...formWorkflow };
                        newWorkflow.type = e.value;

                        onChange('formWorkflow', newWorkflow, e);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <TriggerTypeSelector
                      key={formWorkflow?.workflowConfig?.triggerType}
                      value={formWorkflow?.workflowConfig?.triggerType}
                      placeholder={t('form.label.selectTriggerType')}
                      onChange={(event) => {
                        const newWorkflowConfig = {
                          ...formWorkflow.workflowConfig,
                        };
                        newWorkflowConfig.triggerType = event.value;
                        onChange('formWorkflow', {
                          ...formWorkflow,
                          workflowConfig: newWorkflowConfig,
                        });
                      }}
                    />
                  </div>
                  {['trigger', 'scheduler'].includes(formWorkflow?.workflowConfig?.triggerType) && (
                    <div className="input-group no-margin-top">
                      <div className="input-group half">
                        <FormInput
                          type="number"
                          setHasChanged={setHasChanged}
                          label={t('form.label.value')}
                          value={
                            formWorkflow?.workflowConfig?.schedule
                              ? formWorkflow.workflowConfig.schedule
                                  .integrationSchedulerScheduleValue
                              : null
                          }
                          onChange={(event) => {
                            let newWorkflowConfig = {
                              ...formWorkflow.workflowConfig,
                            };
                            newWorkflowConfig = {
                              ...newWorkflowConfig,
                              schedule: {
                                ...newWorkflowConfig.schedule,
                                integrationSchedulerScheduleValue: event.target.value,
                              },
                            };

                            onChange('formWorkflow', {
                              ...formWorkflow,
                              workflowConfig: newWorkflowConfig,
                            });
                          }}
                        />
                      </div>
                      <div className="input-group half">
                        <Select
                          placeholder={t('form.label.selectScheduleUnitType')}
                          options={integrationSchedulerScheduleTypeOptions}
                          value={integrationSchedulerScheduleTypeOptions.find(
                            (option) =>
                              option.value ===
                              (formWorkflow?.workflowConfig?.schedule || {})
                                .integrationSchedulerScheduleType
                          )}
                          onChange={(event) => {
                            let newWorkflowConfig = {
                              ...formWorkflow.workflowConfig,
                            };
                            newWorkflowConfig = {
                              ...newWorkflowConfig,
                              schedule: {
                                ...newWorkflowConfig.schedule,
                                integrationSchedulerScheduleType: event.value,
                              },
                            };

                            onChange('formWorkflow', {
                              ...formWorkflow,
                              workflowConfig: newWorkflowConfig,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {formWorkflow?.workflowConfig?.triggerType === 'trigger' && (
                    <div className="input-group">
                      <TriggerEventSelector
                        key={formWorkflow?.workflowConfig?.triggerEvent}
                        value={formWorkflow?.workflowConfig?.triggerEvent}
                        placeholder={t('form.label.selectTriggerEvent')}
                        onChange={(event) => {
                          const newWorkflowConfig = {
                            ...formWorkflow.workflowConfig,
                          };
                          newWorkflowConfig.triggerEvent = event.value;
                          event.entity && getEntityStructure(event.entity);
                          onChange('formWorkflow', {
                            ...formWorkflow,
                            workflowConfig: newWorkflowConfig,
                          });
                        }}
                      />
                    </div>
                  )}
                  {['trigger', 'listener'].includes(formWorkflow?.workflowConfig?.triggerType) && (
                    <div className="input-group">
                      <IntegrationFiltersInput
                        key={formWorkflow.nonce}
                        value={
                          formWorkflow.workflowConfig.filters
                            ? formWorkflow.workflowConfig.filters
                            : []
                        }
                        dataTypes={[...dataTypes]}
                        onChange={(newFilters) => {
                          const newWorkflowConfig = {
                            ...formWorkflow.workflowConfig,
                          };
                          newWorkflowConfig.filters = [...newFilters];
                          onChange('formWorkflow', {
                            ...formWorkflow,
                            workflowConfig: newWorkflowConfig,
                          });
                        }}
                      />
                    </div>
                  )}

                  <div className={`input-group${tql ? ' list-sector' : ''}`}>
                    <div className={`flex-container justify-between`}>
                      <h2>{t('tql.module.header')}</h2>
                      <Switch
                        checked={tql}
                        onChange={() => {
                          this.setState({
                            tql: !tql,
                          });

                          const newWorkflowConfig = {
                            ...formWorkflow.workflowConfig,
                          };
                          newWorkflowConfig.query = null;
                          onChange('formWorkflow', {
                            ...formWorkflow,
                            workflowConfig: newWorkflowConfig,
                          });
                        }}
                      />
                    </div>
                    {tql && (
                      <TQLInput
                        value={formWorkflow?.workflowConfig?.query}
                        getFieldsRequest={getFieldsRequest}
                        isFetchingFields={isFetchingFields}
                        onChange={(newQuery) => {
                          const newWorkflowConfig = {
                            ...formWorkflow.workflowConfig,
                          };
                          newWorkflowConfig.query = newQuery;
                          newWorkflowConfig.query.entityType &&
                            getEntityStructure(newWorkflowConfig.query.entityType);
                          onChange('formWorkflow', {
                            ...formWorkflow,
                            workflowConfig: newWorkflowConfig,
                          });
                        }}
                      />
                    )}
                  </div>
                  <div className="flex-container justify-between input-group ">
                    <h2 className="self-center">{t('recipient.module.header')}</h2>
                    <div className="flex-container">
                      <div style={{ lineHeight: '34px', marginRight: '10px' }}>
                        {t('form.label.toggleForAll')}
                      </div>
                      <Switch
                        style={{ float: 'right' }}
                        checked={formWorkflow?.workflowConfig?.toggleForAll}
                        onChange={(e, value) => {
                          const newWorkflowConfig = {
                            ...formWorkflow.workflowConfig,
                          };
                          newWorkflowConfig.toggleForAll = value;

                          onChange('formWorkflow', {
                            ...formWorkflow,
                            workflowConfig: newWorkflowConfig,
                          });
                        }}
                      />
                    </div>
                  </div>
                  {formWorkflow?.workflowConfig?.toggleForAll ? (
                    <div className="input-group">
                      <div className="input-group no-margin-top">
                        <FormInput
                          type="text"
                          setHasChanged={setHasChanged}
                          label={t('form.label.recipients')}
                          value={formWorkflow.workflowConfig?.recipients?.join(',')}
                          onChange={(event) => {
                            const newWorkflowConfig = {
                              ...formWorkflow.workflowConfig,
                            };
                            newWorkflowConfig.recipients = event.target.value.split(',');

                            onChange('formWorkflow', {
                              ...formWorkflow,
                              workflowConfig: newWorkflowConfig,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="input-group">
                      <div className="input-group no-margin-top">
                        <FormInput
                          type="text"
                          setHasChanged={setHasChanged}
                          label={t('form.label.recipientSelector')}
                          value={formWorkflow.workflowConfig?.recipientSelector}
                          onChange={(event) => {
                            const newWorkflowConfig = {
                              ...formWorkflow.workflowConfig,
                            };
                            newWorkflowConfig.recipientSelector = event.target.value;

                            onChange('formWorkflow', {
                              ...formWorkflow,
                              workflowConfig: newWorkflowConfig,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="input-group">
                    <ChannelSelector
                      key={formWorkflow?.workflowConfig?.channel}
                      value={formWorkflow?.workflowConfig?.channel}
                      placeholder={t('form.label.channel')}
                      onChange={(event) => {
                        const newWorkflowConfig = {
                          ...formWorkflow.workflowConfig,
                        };
                        newWorkflowConfig.channel = event.value;
                        newWorkflowConfig.message = '';
                        newWorkflowConfig.subject = '';
                        onChange('formWorkflow', {
                          ...formWorkflow,
                          workflowConfig: newWorkflowConfig,
                        });
                      }}
                    />
                  </div>

                  <div className="input-group">
                    <div className="input-group no-margin-top">
                      <FormInput
                        type="text"
                        setHasChanged={setHasChanged}
                        label={t('form.label.languageSelector')}
                        value={formWorkflow.workflowConfig?.languageSelector}
                        onChange={(event) => {
                          const newWorkflowConfig = {
                            ...formWorkflow.workflowConfig,
                          };
                          newWorkflowConfig.languageSelector = event.target.value;

                          onChange('formWorkflow', {
                            ...formWorkflow,
                            workflowConfig: newWorkflowConfig,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="input-group">
                    <Select
                      isMulti={false}
                      value={languageOptions.find(
                        (lng) => lng.value === formWorkflow.workflowConfig?.defaultLanguageId
                      )}
                      options={languageOptions}
                      onChange={(event) => {
                        const newWorkflowConfig = {
                          ...formWorkflow.workflowConfig,
                        };
                        newWorkflowConfig.defaultLanguageId = event.value;

                        onChange('formWorkflow', {
                          ...formWorkflow,
                          workflowConfig: newWorkflowConfig,
                        });
                      }}
                      placeholder={t('form.label.defaultWorkflowLanguage')}
                    />
                  </div>

                  <div className="input-group">
                    <Select
                      isMulti={true}
                      value={languageOptions.filter(
                        (option) =>
                          this.state.workflowLanguages &&
                          this.state.workflowLanguages.includes(option.code)
                      )}
                      options={languageOptions}
                      onChange={(event) => {
                        this.setState({ workflowLanguages: event.map((lng) => lng.code) });
                      }}
                      placeholder={t('form.label.languages')}
                    />
                  </div>

                  {formWorkflow?.workflowConfig?.channel &&
                    !['chat', 'notification'].includes(formWorkflow?.workflowConfig?.channel) && (
                      <div className="input-group">
                        <IntegrationInput
                          value={formWorkflow?.workflowConfig?.manualIntegration}
                          searchParameters={{ types: ['manual'] }}
                          onChange={(newIntegration) => {
                            const newWorkflowConfig = {
                              ...formWorkflow.workflowConfig,
                            };
                            newWorkflowConfig.manualIntegration = newIntegration;
                            onChange('formWorkflow', {
                              ...formWorkflow,
                              workflowConfig: newWorkflowConfig,
                            });
                          }}
                        />
                      </div>
                    )}

                  <>
                    <div className="input-group">
                      <b>{t('form.label.messageTemplate')}</b>
                    </div>
                    <ScrollContainer
                      verticle={false}
                      hideScrollbars={false}
                      className="align-items-center d-flex gap-10 tab-links-container my-10"
                    >
                      {this.state.workflowLanguages.map((language) => {
                        const lngName = languages.filter((lng) => lng.code === language)?.[0]
                          ?.localName;

                        return (
                          <div
                            className={`tab-links dark ${tab === lngName ? 'tab-active' : ''}`}
                            onClick={() => {
                              this.setState({ tab: lngName });

                              if (formWorkflow?.workflowConfig?.channel === 'email') {
                                const newLocalizedMessages = { ...localizedMessages };

                                const lngCode = languages.filter((lng) => lng.localName === tab)[0]
                                  ?.code;
                                if (newLocalizedMessages?.lngCode) {
                                  newLocalizedMessages[lngCode].content = this.exportHtml(values);
                                  newLocalizedMessages[lngCode].messageTemplate =
                                    JSON.stringify(values);
                                } else {
                                  newLocalizedMessages[lngCode] = {
                                    ...newLocalizedMessages[lngCode],
                                    content: this.exportHtml(values),
                                    messageTemplate: JSON.stringify(values),
                                  };
                                }
                                this.setState({ localizedMessages: newLocalizedMessages });
                              }
                            }}
                          >
                            {lngName}
                          </div>
                        );
                      })}
                    </ScrollContainer>
                    {workflowChannels.find((wc) => wc.type === formWorkflow.workflowConfig?.channel)
                      ?.needsSubject && (
                      <div className="input-group">
                        <div className="input-group no-margin-top">
                          <FormInput
                            key={tab}
                            type="text"
                            setHasChanged={setHasChanged}
                            label={t('form.label.subject')}
                            value={this.getSubject(tab)}
                            onChange={(event) => {
                              const newLocalizedMessages = { ...localizedMessages };

                              const lngCode = languages.filter((lng) => lng.localName === tab)[0]
                                ?.code;
                              if (newLocalizedMessages?.lngCode) {
                                newLocalizedMessages[lngCode].subject = event.target.value;
                              } else {
                                newLocalizedMessages[lngCode] = {
                                  ...newLocalizedMessages[lngCode],
                                  subject: event.target.value,
                                };
                              }
                              this.setState({ localizedMessages: newLocalizedMessages });
                            }}
                          />
                        </div>
                        {this.validator.message(
                          t('form.label.subject'),
                          this.getSubject(tab),
                          'required'
                        )}
                      </div>
                    )}
                    {formWorkflow?.workflowConfig?.channel === 'email' ? (
                      <div className={`arco${darkMode ? 'arco-dark' : ''} input-group`}>
                        <StandardLayout compact={!smallScene} showSourceCode={false}>
                          <EmailEditor />
                        </StandardLayout>
                      </div>
                    ) : (
                      <div className="input-group">
                        <VariableContentEditable
                          key={tab}
                          value={this.getVariableContentEditableValue(tab)}
                          fields={fields}
                          entityType={formWorkflow?.workflowConfig?.query?.entityType}
                          formWorkflow={formWorkflow}
                          searchFieldsRequest={searchFieldsRequest}
                          onChange={(newValue) => {
                            const newLocalizedMessages = { ...localizedMessages };

                            const lngCode = languages.filter((lng) => lng.localName === tab)[0]
                              ?.code;
                            if (newLocalizedMessages?.lngCode) {
                              newLocalizedMessages[lngCode].content = newValue;
                            } else {
                              newLocalizedMessages[lngCode] = {
                                ...newLocalizedMessages[lngCode],
                                content: newValue,
                              };
                            }
                            this.setState({ localizedMessages: newLocalizedMessages });
                          }}
                        />
                      </div>
                    )}
                  </>

                  {/* {formWorkflow?.workflowConfig?.channel === 'email' ? (
                    <>
                      <div className="input-group">
                        <b>{t('form.label.messageTemplate')}</b>
                      </div>
                      <ScrollContainer
                        verticle={false}
                        hideScrollbars={false}
                        className="align-items-center d-flex gap-10 tab-links-container my-10"
                      >
                        {this.state.workflowLanguages.map((language) => {
                          const lngName = languages.filter((lng) => lng.code === language)?.[0]
                            ?.localName;

                          return (
                            <div
                              className={`tab-links dark ${tab === lngName ? 'tab-active' : ''}`}
                              onClick={() => {
                                this.setState({ tab: lngName });

                                const newLocalizedMessages = { ...localizedMessages };

                                const lngCode = languages.filter((lng) => lng.localName === tab)[0]
                                  ?.code;
                                if (newLocalizedMessages?.lngCode) {
                                  newLocalizedMessages[lngCode].content = this.exportHtml(values);
                                  newLocalizedMessages[lngCode].messageTemplate =
                                    JSON.stringify(values);
                                } else {
                                  newLocalizedMessages[lngCode] = {
                                    ...newLocalizedMessages[lngCode],
                                    content: this.exportHtml(values),
                                    messageTemplate: JSON.stringify(values),
                                  };
                                }
                                this.setState({ localizedMessages: newLocalizedMessages });
                              }}
                            >
                              {lngName}
                            </div>
                          );
                        })}
                      </ScrollContainer>
                      {workflowChannels.find(
                        (wc) => wc.type === formWorkflow.workflowConfig?.channel
                      )?.needsSubject && (
                        <div className="input-group">
                          <div className="input-group no-margin-top">
                            <FormInput
                              key={tab}
                              type="text"
                              setHasChanged={setHasChanged}
                              label={t('form.label.subject')}
                              value={this.getSubject(tab)}
                              onChange={(event) => {
                                const newLocalizedMessages = { ...localizedMessages };

                                const lngCode = languages.filter((lng) => lng.localName === tab)[0]
                                  ?.code;
                                if (newLocalizedMessages?.lngCode) {
                                  newLocalizedMessages[lngCode].subject = event.target.value;
                                } else {
                                  newLocalizedMessages[lngCode] = {
                                    ...newLocalizedMessages[lngCode],
                                    subject: event.target.value,
                                  };
                                }
                                this.setState({ localizedMessages: newLocalizedMessages });
                              }}
                            />
                          </div>
                          {this.validator.message(
                            t('form.label.subject'),
                            this.getSubject(tab),
                            'required'
                          )}
                        </div>
                      )}
                      <div className={`arco${darkMode ? 'arco-dark' : ''} input-group`}>
                        <StandardLayout compact={!smallScene} showSourceCode={false}>
                          <EmailEditor />
                        </StandardLayout>
                      </div>
                    </>
                  ) : (
                    <div className="input-group">
                      <ScrollContainer
                        verticle={false}
                        hideScrollbars={false}
                        className="align-items-center d-flex gap-10 tab-links-container my-10"
                      >
                        {this.state.workflowLanguages.map((language) => {
                          const lngName = languages.filter((lng) => lng.code === language)?.[0]
                            ?.localName;

                          return (
                            <div
                              className={`tab-links dark ${tab === lngName ? 'tab-active' : ''}`}
                              onClick={() => this.setState({ tab: lngName })}
                            >
                              {lngName}
                            </div>
                          );
                        })}
                      </ScrollContainer>
                      {workflowChannels.find(
                        (wc) => wc.type === formWorkflow.workflowConfig?.channel
                      )?.needsSubject && (
                        <div className="input-group">
                          <div className="input-group no-margin-top">
                            <FormInput
                              key={tab}
                              type="text"
                              setHasChanged={setHasChanged}
                              label={t('form.label.subject')}
                              value={this.getSubject(tab)}
                              onChange={(event) => {
                                const newLocalizedMessages = { ...localizedMessages };

                                const lngCode = languages.filter((lng) => lng.localName === tab)[0]
                                  ?.code;
                                if (newLocalizedMessages?.lngCode) {
                                  newLocalizedMessages[lngCode].subject = event.target.value;
                                } else {
                                  newLocalizedMessages[lngCode] = {
                                    ...newLocalizedMessages[lngCode],
                                    subject: event.target.value,
                                  };
                                }
                                this.setState({ localizedMessages: newLocalizedMessages });
                              }}
                            />
                          </div>
                          {this.validator.message(
                            t('form.label.subject'),
                            this.getSubject(tab),
                            'required'
                          )}
                        </div>
                      )}
                      <div className="input-group">
                        <b>{t('form.label.messageTemplate')}</b>
                        <VariableContentEditable
                          key={tab}
                          value={this.getVariableContentEditableValue(tab)}
                          fields={fields}
                          entityType={formWorkflow?.workflowConfig?.query?.entityType}
                          formWorkflow={formWorkflow}
                          searchFieldsRequest={searchFieldsRequest}
                          onChange={(newValue) => {
                            const newLocalizedMessages = { ...localizedMessages };

                            const lngCode = languages.filter((lng) => lng.localName === tab)[0]
                              ?.code;
                            if (newLocalizedMessages?.lngCode) {
                              newLocalizedMessages[lngCode].content = newValue;
                            } else {
                              newLocalizedMessages[lngCode] = {
                                ...newLocalizedMessages[lngCode],
                                content: newValue,
                              };
                            }
                            this.setState({ localizedMessages: newLocalizedMessages });
                          }}
                        />
                      </div>
                    </div>
                  )} */}

                  <div className="input-group">
                    <div>{t('form.label.offOn')}</div>
                    <Switch
                      checked={formWorkflow.enabled}
                      onChange={(e, newState) => {
                        const newWorkflow = { ...formWorkflow };
                        newWorkflow.enabled = newState;

                        onChange('formWorkflow', newWorkflow, e);
                      }}
                    />
                  </div>
                  <div className="input-group more right">
                    <input
                      type="submit"
                      disabled={!this.validator.allValid()}
                      onClick={(e) => {
                        e.preventDefault();

                        submit();
                      }}
                      value={t('form.save')}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        </EmailEditorProvider>
      </div>
    );
  }
}
