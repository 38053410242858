import { registerLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';

import dateDE from 'date-fns/locale/de';
import dateNL from 'date-fns/locale/nl';
import datePL from 'date-fns/locale/pl';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/pl';

import de from './resources/languages/de';
import en from './resources/languages/en';
import nl from './resources/languages/nl';
import pl from './resources/languages/pl';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: { nl, en, pl, de },
    lng: 'en',
    fallbackLng: ['en', 'nl'],

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', (e) => {
  moment?.locale?.(e)
});

export const getJsonFile = (language) => {
  switch (language.toLowerCase()) {
    case 'en':
      return en;
    case 'nl':
      return nl;
    case 'pl':
      return pl;
    case 'de':
      return de;
  }
};

export const registerLocales = () => {
  registerLocale('nl', dateNL);
  registerLocale('de', dateDE);
  registerLocale('pl', datePL);
};
export default i18n;
