import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator';

import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';
import { Form } from 'react-final-form';

class ADRInput extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      ADR: this.props.ADR || { language: 'English' },
    };
  }

  updateADR = (newADR) => {
    const { onChange } = this.props;
    this.setState({
      ADR: newADR,
    });
    onChange && onChange(newADR);
  };

  render() {
    const { ADR } = this.state;
    const { t } = this.props;

    return (
      <>
        <div className="input-group no-margin-top">
          <div className="input-group">
            <div className="input-group no-margin-top">
              <FormInput
                type="text"
                label={`${t('form.label.UNNumber')}*`}
                value={ADR.UNNumber}
                onChange={(event) => {
                  const newADR = { ...ADR };
                  newADR.UNNumber = event.target.value;

                  this.updateADR(newADR);
                }}
              />
            </div>
            {this.validator.message(
              t('form.label.UNNumber'),
              ADR.UNNumber,
              'required|string'
            )}
          </div>
          <div className="input-group">
            <div className="input-group no-margin-top">
              <FormInput
                type="text"
                label={`${t('form.label.language')}*`}
                value={ADR.language}
                onChange={(event) => {
                  const newADR = { ...ADR };
                  newADR.language = event.target.value;

                  this.updateADR(newADR);
                }}
              />
            </div>
            {this.validator.message(
              t('form.label.language'),
              ADR.language,
              'required|string'
            )}
          </div>
          <div className="input-group">
            <div className="input-group no-margin-top">
              <FormInput
                type="textarea"
                label={`${t('form.label.description')}*`}
                className="small"
                value={ADR.description}
                onChange={(event) => {
                  const newADR = { ...ADR };
                  newADR.description = event.target.value;

                  this.updateADR(newADR);
                }}
              />
            </div>
            {this.validator.message(
              t('form.label.description'),
              ADR.description,
              'required|string'
            )}
          </div>
          <div className="input-group">
            <div className="input-group no-margin-top">
              <FormInput
                type="text"
                label={`${t('form.label.technicalName')}*`}
                value={ADR.technicalName}
                onChange={(event) => {
                  const newADR = { ...ADR };
                  newADR.technicalName = event.target.value;

                  this.updateADR(newADR);
                }}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-group half">
              <div className="input-group no-margin-top">
                <div>{t('form.label.waste')}</div>
                <Switch
                  checked={ADR.waste}
                  onChange={(e, newValue) => {
                    const newADR = { ...ADR };
                    newADR.waste = newValue;

                    this.updateADR(newADR);
                  }}
                />
              </div>
            </div>
            <div className="input-group half">
              <div className="input-group no-margin-top">
                <div>{t('form.label.environmentallyHazardous')}</div>
                <Switch
                  checked={ADR.environmentallyHazardous}
                  onChange={(e, newValue) => {
                    const newADR = { ...ADR };
                    newADR.environmentallyHazardous = newValue;

                    this.updateADR(newADR);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="input-group">
            <div className="input-group no-margin-top">
              <FormInput
                type="text"
                label={t('form.label.class')}
                value={ADR.class}
                onChange={(event) => {
                  const newADR = { ...ADR };
                  newADR.class = event.target.value;

                  this.updateADR(newADR);
                }}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-group no-margin-top">
              <FormInput
                type="text"
                label={t('form.label.classificationCode')}
                value={ADR.classificationCode}
                onChange={(event) => {
                  const newADR = { ...ADR };
                  newADR.classificationCode = event.target.value;

                  this.updateADR(newADR);
                }}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-group no-margin-top">
              <FormInput
                type="text"
                label={`${t('form.label.packagingGroup')}*`}
                value={ADR.packagingGroup}
                onChange={(event) => {
                  const newADR = { ...ADR };
                  newADR.packagingGroup = event.target.value;

                  this.updateADR(newADR);
                }}
              />
              {this.validator.message(
                t('form.label.description'),
                ADR.packagingGroup,
                'required|string'
              )}
            </div>
          </div>
          <div className="input-group">
            <div className="input-group no-margin-top">
              <FormInput
                type="text"
                label={t('form.label.dangerNumber')}
                value={ADR.dangerNumber}
                onChange={(event) => {
                  const newADR = { ...ADR };
                  newADR.dangerNumber = event.target.value;

                  this.updateADR(newADR);
                }}
              />
            </div>
          </div>
          <div className="input-group">
            <div className="input-group no-margin-top">
              <FormInput
                type="text"
                label={t('form.label.tunnelCode')}
                value={ADR.tunnelCode}
                onChange={(event) => {
                  const newADR = { ...ADR };
                  newADR.tunnelCode = event.target.value;

                  this.updateADR(newADR);
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(ADRInput);
