export const workflowConstants = {
  WORKFLOW_CHANGE_VALUE: 'WORKFLOW_CHANGE_VALUE',

  GET_WORKFLOW_STARTED: 'GET_WORKFLOW_STARTED',
  GET_WORKFLOW_SUCCESS: 'GET_WORKFLOW_SUCCESS',
  GET_WORKFLOW_FAILURE: 'GET_WORKFLOW_FAILURE',

  GET_WORKFLOWS_STARTED: 'GET_WORKFLOWS_STARTED',
  GET_WORKFLOWS_SUCCESS: 'GET_WORKFLOWS_SUCCESS',
  GET_WORKFLOWS_FAILURE: 'GET_WORKFLOWS_FAILURE',

  SEARCH_WORKFLOWS_STARTED: 'SEARCH_WORKFLOWS_STARTED',
  SEARCH_WORKFLOWS_SUCCESS: 'SEARCH_WORKFLOWS_SUCCESS',
  SEARCH_WORKFLOWS_FAILURE: 'SEARCH_WORKFLOWS_FAILURE',

  CREATING_WORKFLOW_STARTED: 'CREATING_WORKFLOW_STARTED',
  CREATING_WORKFLOW_SUCCESS: 'CREATING_WORKFLOW_SUCCESS',
  CREATING_WORKFLOW_FAILURE: 'CREATING_WORKFLOW_FAILURE',

  UPDATING_WORKFLOW_STARTED: 'UPDATING_WORKFLOW_STARTED',
  UPDATING_WORKFLOW_SUCCESS: 'UPDATING_WORKFLOW_SUCCESS',
  UPDATING_WORKFLOW_FAILURE: 'UPDATING_WORKFLOW_FAILURE',

  DELETE_WORKFLOW_STARTED: 'DELETE_WORKFLOW_STARTED',
  DELETE_WORKFLOW_SUCCESS: 'DELETE_WORKFLOW_SUCCESS',
  DELETE_WORKFLOW_FAILURE: 'DELETE_WORKFLOW_FAILURE',
};
