import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import ValueTypeSelector from '../crud/ValueTypeSelector';
import IntegrationFiltersInput from '../filter/IntegrationFiltersInput';
import FieldMappingsInput from './FieldMappingsInput';
import FormInput from '@uiinputs/FormInput';

class FieldMappingInput extends PureComponent {
  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  componentWillUnmount() {
    clearTimeout(this.onChangeTimeout);
  }

  onChange = (valueObject) => {
    const { value, onChange } = this.props;

    onChange && onChange({ ...value, ...valueObject });
  };

  render() {
    const { t, value, onDelete, disabledIncoming, disabledOutgoing } = this.props;
    const {
      incomingPath,
      outgoingPath,
      type,
      regex,
      format,
      locale,
      zoneId,
      fields,
      options,
      filters,
    } = value;
    const fieldOptionOptions = [
      { value: 'identifier', label: t('fieldOption.identifier') },
      { value: 'to_lower_case', label: t('fieldOption.to_lower_case') },
      { value: 'to_upper_case', label: t('fieldOption.to_upper_case') },
      { value: 'capitalize', label: t('fieldOption.capitalize') },
      { value: 'trim', label: t('fieldOption.trim') },
      {
        value: 'remove_all_whitespace',
        label: t('fieldOption.remove_all_whitespace'),
      },
    ];
    return (
      <>
        <div className="input-group flex-container no-wrap">
          <div className="input-group fourth no-margin-top">
            <FormInput
              type="text"
              label={t('form.label.incomingPath')}
              value={incomingPath}
              disabled={disabledIncoming}
              onChange={(e) => {
                this.onChange({
                  incomingPath: e.target.value,
                });
              }}
            />
          </div>
          <div className="input-group fourth no-margin-top">
            <ValueTypeSelector
              value={type}
              placeholder={t('form.label.selectValueType')}
              onChange={(e) => {
                this.onChange({
                  type: e.value,
                });
              }}
            />
          </div>

          <div className="input-group fourth no-margin-top">
            <FormInput
              type="text"
              label={t('form.label.outgoingPath')}
              value={outgoingPath}
              disabledOutgoing={disabledOutgoing}
              onChange={(e) => {
                this.onChange({
                  outgoingPath: e.target.value,
                });
              }}
            />
          </div>
          {(type === 'datetime' || type === 'date' || type === 'time') && (
            <>
              <div className="input-group fourth no-margin-top">
                <FormInput
                  type="text"
                  label={t('form.label.format')}
                  value={format}
                  onChange={(e) => {
                    this.onChange({
                      format: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="input-group fourth no-margin-top">
                <FormInput
                  type="text"
                  label={t('form.label.locale')}
                  value={locale}
                  onChange={(e) => {
                    this.onChange({
                      locale: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="input-group fourth no-margin-top">
                <FormInput
                  type="text"
                  label={t('form.label.zoneId')}
                  value={zoneId}
                  onChange={(e) => {
                    this.onChange({
                      zoneId: e.target.value,
                    });
                  }}
                />
              </div>
            </>
          )}
          <div className="input-group fourth no-margin-top">
            <FormInput
              type="text"
              label={t('form.label.regex')}
              value={regex}
              onChange={(e) => {
                this.onChange({
                  regex: e.target.value,
                });
              }}
            />
          </div>
          <div className="input-group fourth no-margin-top">
            <Select
              isMulti={true}
              value={fieldOptionOptions.filter((option) => options?.includes(option.value))}
              options={fieldOptionOptions}
              onChange={(e) => {
                this.onChange({
                  options: e ? e?.map((foo) => foo.value) : [],
                });
              }}
            />
          </div>
          {!disabledIncoming && (
            <div className="input-group fifth no-margin-top">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onDelete(e);
                }}
              >
                {t('form.delete')}
              </button>
            </div>
          )}
        </div>
        {!disabledIncoming && (type === 'array' || type === 'object' || type === 'filters') && (
          <div className="list-sector">
            {type === 'filters' && (
              <IntegrationFiltersInput
                value={filters ? filters : []}
                onChange={(newFilters) => {
                  this.onChange({
                    filters: newFilters,
                  });
                }}
              />
            )}

            <FieldMappingsInput
              key={`field-${value.sequenceNr}-fields`}
              value={fields}
              onChange={(newFieldSelectors) => {
                this.onChange({
                  fields: newFieldSelectors.fields,
                });
              }}
            />
          </div>
        )}
      </>
    );
  }
}

export default withTranslation('translation')(FieldMappingInput);
