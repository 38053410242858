import L, { divIcon } from 'leaflet';


export default divIcon({
  html: `
  <svg x="0px" y="0px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1">
  <g>
    <path id="path" d="m255,-1c-102.245,0 -185.427,83.182 -185.427,185.426c0,126.888 165.939,313.167 173.004,321.035c6.636,7.391 18.222,7.378 24.846,0c7.065,-7.868 173.004,-194.147 173.004,-321.035c-0.002,-102.244 -83.183,-185.426 -185.427,-185.426z"/>
    <text transform="matrix(1 0 0 1 0 0)" xml:space="preserve" text-anchor="start" font-size="384" font-style="bold" stroke-width="0" id="number" y="345" x="155">${
      this?.props?.number || 1
    }</text>
  </g>
 </svg>
    `,
  iconSize: new L.Point(32, 32),
  iconAnchor: [16, 32],
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  className: 'number-marker',
});