import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import BusinessContainer from '@containers/business/BusinessContainer';
import ChauffeurContainer from '@containers/chauffeur/ChauffeurContainer';
import LocationContainer from '@containers/location/LocationContainer';
import TransportOrderContainer from '@containers/transportOrder/TransportOrderContainer';
import VehicleDetailContainer from '@containers/vehicle/VehicleDetailContainer';

import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';
import FormInput from '@uiinputs/FormInput';

import * as generalApi from '@api/generalApi';

import { groupBy } from '@utils/arrayUtils';
import { hasParentNode } from '@utils/commonUtils';
import { inputElements } from '@utils/keyBindings';
import { administrativeReferenceToString } from '@utils/locationUtils';

let searchTimeout = null;

class HeaderSearch extends Component {
  constructor(props) {
    super(props);
    this.searchInput = React.createRef();

    this.state = {
      isActive: false,
      filters: null,
      isSearching: false,
      searchResults: [],
      searchTotalResults: 0,
      searchResultsLoaded: 0,
    };
  }

  handleDocumentClick = (e) => {
    const target = e.target;
    const { isActive } = this.state;
    if (isActive && target.tagName !== 'BUTTON') {
      if (!hasParentNode(target, 'header')) {
        this.setState({ isActive: false });
      }
    }
  };

  onScroll(e) {
    const { searchResultsLoaded, searchTotalResults } = this.state;
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && searchResultsLoaded < searchTotalResults) {
      this.search(searchResultsLoaded, 20);
    }
  }

  search = (searchQuery, offset = 0, amount = 20) => {
    const { searchResults, searchResultsLoaded } = this.state;
    this.setState({
      isSearching: true,
      isActive: true,
    });
    generalApi.search(searchQuery, offset, amount).then((data) => {
      this.setState({
        isSearching: false,
        searchResults: offset > 0 ? [...searchResults, ...data.entities] : data.entities,
        searchTotalResults: data.totalResults,
        searchResultsLoaded:
          offset > 0 ? searchResultsLoaded + data.entities.length : data.entities.length,
      });
    });
  };

  handleKeyBindings = (e) => {
    e.preventDefault();

    const { popStack } = this.props;
    const { isActive } = this.state;

    if (!e.ctrlKey && !e.altKey) {
      switch (e.which) {
        case 27:
          if (isActive) {
            this.searchInput.current.blur();
            this.setState({
              isActive: false,
            });
          } else {
            popStack?.();
          }
          break;
      }
    }

    const activeElement = document.activeElement;
    if (inputElements.includes(activeElement.tagName)) return;

    if (!e.ctrlKey && !e.altKey) {
      switch (e.which) {
        case 191:
          if (e.key !== '?') {
            this.searchInput.current.focus();
          }
          break;
      }
    }
  };

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
    document.addEventListener('keyup', this.handleKeyBindings);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
    document.removeEventListener('keyup', this.handleKeyBindings);
  }

  render() {
    const { t, addToStack } = this.props;
    const {
      isActive,
      searchQuery,
      isSearching,
      searchResults,
      searchResultsLoaded,
      searchTotalResults,
    } = this.state;

    const entities = groupBy(searchResults, 'entityType');
    return (
      <>
        <div className="header__search">
          <FormInput
            type="text"
            ref={this.searchInput}
            onFocus={(e) => {
              this.setState({ isActive: true });
            }}
            onChange={(e) => {
              const searchQuery = e.target.value;
              if (searchTimeout) clearTimeout(searchTimeout);
              searchTimeout = setTimeout(() => {
                this.setState({
                  searchQuery,
                });
                this.search(searchQuery);
              }, 400);
            }}
            placeholder={t('search.placeholder')}
          />
          <ReactSVG src="/icons/search.svg" className="header__search__icon" />
        </div>
        {isActive && searchQuery !== '' ? (
          <div className="header__search-result-container">
            <div className="header__search-result scrollable" onScroll={(e) => this.onScroll(e)}>
              {searchResults.length > 0 ? (
                <>
                  {Object.keys(entities).map((key) => {
                    return (
                      <div className="result-group" key={`${key}`}>
                        <b>{t(`entityType.${key}`)}</b>
                        {entities[key].map((value) => {
                          let entityResponse;
                          switch (value.entityType) {
                            case 'vehicle':
                              entityResponse = (
                                <div
                                  key={value.entity.id}
                                  className="result"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    addToStack &&
                                      addToStack({
                                        name: value.entity.name,
                                        className: 'lightgray',
                                        component: (
                                          <VehicleDetailContainer vehicleId={value.entity.id} />
                                        ),
                                      });
                                    this.setState({
                                      isActive: false,
                                    });
                                  }}
                                >
                                  <div>{value.entity.name}</div>
                                  <div>{value.entity.licensePlate}</div>
                                </div>
                              );
                              break;
                            case 'location':
                              entityResponse = (
                                <div
                                  key={value.entity.id}
                                  className="result"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    addToStack &&
                                      addToStack({
                                        name: '',
                                        className: 'lightgray',
                                        component: (
                                          <LocationContainer locationId={value.entity.id} />
                                        ),
                                      });
                                    this.setState({
                                      isActive: false,
                                    });
                                  }}
                                >
                                  <div>{value.entity.name}</div>
                                  <div className="two">
                                    {administrativeReferenceToString(
                                      value.entity.administrativeReference
                                    )}
                                  </div>
                                  <div>
                                    {`${value.entity.geoReference.lat}, ${value.entity.geoReference.lon}`}
                                  </div>
                                </div>
                              );
                              break;
                            case 'transportOrder':
                              entityResponse = (
                                <div
                                  key={value.entity.id}
                                  className="result"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    addToStack &&
                                      addToStack({
                                        name: value.entity.name,
                                        className: 'lightgray',
                                        component: (
                                          <TransportOrderContainer
                                            transportOrderId={value.entity.id}
                                          />
                                        ),
                                      });
                                    this.setState({
                                      isActive: false,
                                    });
                                  }}
                                >
                                  <div className="">{value.entity.name}</div>
                                  <div className="">{value.entity.orderId}</div>
                                </div>
                              );
                              break;
                            case 'business':
                              entityResponse = (
                                <div
                                  key={value.entity.id}
                                  className="result"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    addToStack &&
                                      addToStack({
                                        name: value.entity.name,
                                        component: (
                                          <BusinessContainer businessId={value.entity.id} />
                                        ),
                                      });
                                    this.setState({
                                      isActive: false,
                                    });
                                  }}
                                >
                                  <div className="">{value.entity.name}</div>
                                  <div className="">{value.entity.coc}</div>
                                </div>
                              );
                              break;
                            case 'chauffeur':
                              entityResponse = (
                                <div
                                  key={value.entity.id}
                                  className="result"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    addToStack &&
                                      addToStack({
                                        name: `${value.entity.firstName} ${value.entity.lastName}`,
                                        className: 'lightgray',
                                        component: (
                                          <ChauffeurContainer chauffeurId={value.entity.id} />
                                        ),
                                      });
                                    this.setState({
                                      isActive: false,
                                    });
                                  }}
                                >
                                  <div className="">{`${value.entity.firstName} ${value.entity.lastName}`}</div>
                                </div>
                              );
                              break;
                            default:
                              entityResponse = null;
                          }
                          return <>{entityResponse}</>;
                        })}
                      </div>
                    );
                  })}
                  {searchResultsLoaded < searchTotalResults && !isSearching && (
                    <div className="center">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.search(searchResultsLoaded, 20);
                        }}
                      >
                        {t('load')}
                      </button>
                    </div>
                  )}

                  {isSearching && <Loader />}
                </>
              ) : isSearching ? (
                <Loader />
              ) : (
                <NoData>{t('search.notFound')}</NoData>
              )}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
export default withTranslation('translation')(HeaderSearch);
