import React, { Component, Suspense, lazy } from 'react';
import moment from 'moment'; 

import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import { i18n } from '@/index';
import LocationInput from '@components/location/crud/LocationInput';

import AsideView from '@uicomponents/AsideView';
import Collapsible from '@uicomponents/Collapsible';
import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

class FilterView extends Component {
  static defaultProps = {
    filters: [],
    // onChange: () => null,
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: props.filters,
    };
  }

  onChange = (newFilters) => {
    this.setState({
      filters: newFilters,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { onChange } = this.props;
    const { filters } = this.state;
    onChange(filters);
  };

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  render() {
    const { t, popStack } = this.props;
    const { filters } = this.state;

    return (
      <div className="filter-view">
        <AsideView>
          <h1 className="center">{t('filters.header')}</h1>
          <div className="step-form">
            <form
              className="form active no-padding-top"
              onSubmit={(e) => {
                this.onSubmit(e);
              }}
            >
              {filters.map((filter, index) => {
                const newFilter = { ...filter };
                const newFilters = [...filters];

                switch (filter.type) {
                  case 'query':
                  case 'string':
                    return (
                      <div className="input-group" key={`${index}-${filter.name}`}>
                        <div className="input-group no-margin-top">
                          <FormInput
                            type="text"
                            label={filter.label ? filter.label : t('search.placeholder')}
                            value={filter.value || filter.defaultValue}
                            onChange={(event) => {
                              const query = event.target.value;

                              newFilter.value = query;
                              if (query == '') {
                                newFilter.value = null;
                              }
                              newFilters[index] = newFilter;

                              this.onChange(newFilters);
                            }}
                          />
                        </div>
                      </div>
                    );
                  case 'date-time':
                    return (
                      <div className="input-group" key={`${index}-${filter.name}`}>
                        <div className="input-group no-margin-top">
                          <input
                            type="datetime-local"
                            className="has-value"
                            value={filter.value || filter.defaultValue}
                            onChange={(event) => {
                              const query = event.target.value;

                              newFilter.value = query;
                              if (query == '') {
                                newFilter.value = null;
                              }
                              newFilters[index] = newFilter;

                              this.onChange(newFilters);
                            }}
                          />
                          <label>{filter.label ? filter.label : t('form.label.date')}</label>
                        </div>
                      </div>
                    );
                  case 'date':
                    return (
                      <div className="input-group" key={`${index}-${filter.name}`}>
                        <div className="input-group no-margin-top">
                          <input
                            type="date"
                            className="has-value"
                            value={filter.value || filter.defaultValue}
                            onChange={(event) => {
                              const query = event.target.value;

                              newFilter.value = query;
                              if (query == '') {
                                newFilter.value = null;
                              }
                              newFilters[index] = newFilter;

                              this.onChange(newFilters);
                            }}
                          />
                          <label>{filter.label ? filter.label : t('form.label.date')}</label>
                        </div>
                      </div>
                    );
                  case 'date-range':
                    const [start, end] = filter.value || [];

                    return (
                      <div className="input-group" key={`${index}-${filter.name}`}>
                        <div className="input-group no-margin-top">
                          <DatePicker
                            startDate={start ? new Date(start) : start}
                            endDate={end ? new Date(end) : end}
                            showWeekNumbers={true}
                            locale={i18n.language}
                            selectsRange
                            isClearable
                            placeholderText={filter.label ? filter.label : t('form.label.date')}
                            onChange={(dates) => {
                              const [newStart, newEnd] = dates || [];

                              newFilter.value =
                                dates[0] === null && dates[1] === null
                                  ? null
                                  : [moment(newStart).startOf('day').toISOString(), moment(newEnd).endOf('day').toISOString()];
                              newFilters[index] = newFilter;

                              this.onChange(newFilters);
                            }}
                          />
                        </div>
                      </div>
                    );
                  case 'boolean':
                    return (
                      <div className="input-group" key={`${index}-${filter.name}`}>
                        <div className="input-group no-margin-top">
                          <div>{filter.label}</div>
                          <Switch
                            checked={filter.value || filter.defaultValue ? true : false}
                            onChange={(e, newValue) => {
                              newFilter.value = newValue ? true : null;
                              newFilters[index] = newFilter;

                              this.onChange(newFilters);
                            }}
                          />
                        </div>
                      </div>
                    );
                  case 'list':
                    return (
                      <div className="input-group" key={`${index}-${filter.name}`}>
                        <div className="input-group no-margin-top">
                          <Select
                            placeholder={filter.label ? filter.label : null}
                            value={
                              filter.options.filter((option) => {
                                if (filter.value instanceof Array) {
                                  return filter.value.includes(option.value);
                                } else if (filter.value) {
                                  return option.value === filter.value;
                                }
                                return false;
                              }) || filter.defaultValue
                            }
                            isMulti={filter.isMulti || false}
                            options={filter.options}
                            isClearable={true}
                            onChange={(event) => {
                              if (event instanceof Array) {
                                newFilter.value = event.map((event) => event.value);
                              } else if (event && event.value) {
                                newFilter.value = event.value;
                              } else {
                                newFilter.value = null;
                              }
                              newFilters[index] = newFilter;

                              this.onChange(newFilters);
                            }}
                          />
                        </div>
                      </div>
                    );
                  case 'radius':
                    return (
                      <div className="input-group" key={`${index}-${filter.name}`}>
                        <Collapsible
                          key={`${index}-${filter.name}`}
                          name={filter.label ? filter.label : t('filters.location')}
                          className="collapsible-element"
                          isOpen={filter.value ? true : false}
                          onOpenChange={(newValue) => {
                            newFilter.value = newValue ? { radius: 10 } : null;
                            newFilters[index] = newFilter;

                            this.onChange(newFilters);
                          }}
                        >
                          <div className="input-group">
                            <LocationInput
                              key={filter?.value?.location}
                              location={filter?.value?.location}
                              onChange={(location) => {
                                newFilter.value = { ...newFilter.value, location: location };
                                newFilters[index] = newFilter;

                                this.onChange(newFilters);
                              }}
                            />
                          </div>
                          <div className="input-group">
                            <input
                              type="range"
                              min="1"
                              max="100"
                              value={filter.value?.radius || 10}
                              className="range-slider"
                              onChange={(e) => {
                                const { value } = e.target;

                                newFilter.value = { ...newFilter.value, radius: value };
                                newFilters[index] = newFilter;

                                this.onChange(newFilters);
                              }}
                            />
                            <div>
                              {t('filters.radius')}: {filter.value?.radius}km
                            </div>
                          </div>
                        </Collapsible>
                      </div>
                    );
                  case 'component':
                    const LoadedComponent = lazy(() => import(`./../../${filter.componentPath}`));

                    return (
                      <div className="input-group" key={`${index}-${filter.name}`}>
                        <div className="input-group no-margin-top">
                          <Suspense
                            fallback={
                              <>
                                <FormInput type="text" disable={true} />
                              </>
                            }
                          >
                            <LoadedComponent
                              placeholder={filter.label}
                              value={filter.value || filter.defaultValue}
                              onChange={(event) => {
                                if (event instanceof Array) {
                                  if (event.length > 0) {
                                    newFilter.value = event;
                                  } else {
                                    newFilter.value = [];
                                  }
                                } else if (event && event.value) {
                                  newFilter.value = event.value;
                                } else {
                                  newFilter.value = null;
                                }
                                newFilters[index] = newFilter;

                                this.onChange(newFilters);
                              }}
                            />
                          </Suspense>
                        </div>
                      </div>
                    );
                }
              })}
              <div className="input-group more right">
                <input type="submit" onClick={(e) => this.onSubmit(e)} value={t('form.apply')} />
              </div>
            </form>
          </div>
        </AsideView>
      </div>
    );
  }
}
export default withTranslation('translation')(FilterView);
