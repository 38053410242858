import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import PopOver from '@uicomponents/PopOver';

class HeaderKeyBindingsView extends Component {
  render() {
    const { t, user, isActive, onClose, platform } = this.props;

    return (
      <PopOver isActive={isActive} onClose={onClose}>
        <div className="help-view container scrollable scrollable-y">
          <h1>{t('shortcut.header')}</h1>
          <hr />
          <div className="flex-container">
            <div className="one">
              <div>
                <span className="key">?</span> {t('shortcut.openHelpDialog')}
              </div>
              <div>
                <span className="key">esc</span> {t('shortcut.quitOrClose')}
              </div>
              <div>
                <span className="key">/</span> {t('shortcut.openSearchbox')}
              </div>
              <div>
                <span className="key">n</span> {t('shortcut.new')}
              </div>
              <div>
                <span className="key">alt</span> +{' '}
                <span className="key">d</span>
                {t('shortcut.goto')} {t('dasboard')}
              </div>
              <div>
                <span className="key">alt</span> +{' '}
                <span className="key">o</span> {t('shortcut.goto')}{' '}
                {t('transportOrders')}
              </div>
              {user?.accountType !== 'customer' && (
                <>
                  <div>
                    <span className="key">alt</span> +{' '}
                    <span className="key">l</span> {t('shortcut.goto')}{' '}
                    {t('locations')}
                  </div>
                  <div>
                    <span className="key">alt</span> +{' '}
                    <span className="key">v</span> {t('shortcut.goto')}{' '}
                    {t('vehicles')}
                  </div>
                  <div>
                    <span className="key">alt</span> +{' '}
                    <span className="key">f</span> {t('shortcut.goto')}{' '}
                    {t('fleets')}
                  </div>
                  <div>
                    <span className="key">alt</span> +{' '}
                    <span className="key">m</span> {t('shortcut.goto')}{' '}
                    {t('chats')}
                  </div>
                  <div>
                    <span className="key">alt</span> +{' '}
                    <span className="key">t</span> {t('trip.new')}
                  </div>
                </>
              )}

              <div>
                <span className="key">alt</span> +{' '}
                <span className="key">n</span> {t('transportOrder.new')}
              </div>
            </div>
            <div className="one">
              <div>
                <span className="key">1</span> {t('shortcut.first')}
              </div>
              <div>
                <span className="key">2</span> {t('shortcut.second')}
              </div>
              <div>
                <span className="key">3</span> {t('shortcut.third')}
              </div>
              <div>
                <span className="key">4</span> {t('shortcut.fourth')}
              </div>
              <div>
                <span className="key">5</span> {t('shortcut.fifth')}
              </div>
              <div>
                <span className="key">6</span> {t('shortcut.sixth')}
              </div>
              <div>
                <span className="key">7</span> {t('shortcut.seventh')}
              </div>
              <div>
                <span className="key">8</span> {t('shortcut.eigth')}
              </div>
              <div>
                <span className="key">9</span> {t('shortcut.ninth')}
              </div>
            </div>
          </div>
        </div>
      </PopOver>
    );
  }
}
export default withTranslation('translation')(HeaderKeyBindingsView);
