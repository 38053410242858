import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import TransportOrderForm from './TransportOrderForm';

export default class UpdateTransportOrderPage extends Component {
  render() {
    const {
      t,
      formTransportOrder,
      isFetchingTransportOrder,
      updateTransportOrder,
    } = this.props;

    if (!formTransportOrder || isFetchingTransportOrder) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('transportOrder.update')}</h1>
        <TransportOrderForm
          {...this.props}
          key={formTransportOrder.id}
          onSubmit={(transportOrder) => updateTransportOrder(transportOrder)}
        />
      </AsideView>
    );
  }
}
