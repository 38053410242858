import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

export const consignmentTypes = [
  { label: 'FCL', value: 'FCL' },
  { label: 'LCL', value: 'LCL' },
  { label: 'AIR', value: 'AIR' },
  { label: 'FTL', value: 'FTL' },
  { label: 'LTL', value: 'LTL' },
  { label: 'RAIL', value: 'RAIL' },
];

class ConsignmentTypeSelector extends Component {
  render() {
    const { value, onChange, placeholder } = this.props;
    return (
      <Select
        value={consignmentTypes.find((option) => option.value === value)}
        options={consignmentTypes}
        onChange={onChange}
        placeholder={placeholder}
      />
    );
  }
}

export default withTranslation('translation')(ConsignmentTypeSelector);
