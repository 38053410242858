import { i18n } from '@/index';

import * as authApi from '@api/authApi';
import Alert from '@models/Alert';

import { authConstants } from '../constants';
import * as navigationActions from './navigationActions';

export const changeValue = (name, value) => ({
  type: authConstants.CHANGE_VALUE,
  name,
  value,
});

export const startGetLoginMethod = () => ({
  type: authConstants.LOGIN_METHOD_STARTED,
});

export const loginMethodSuccess = (result) => ({
  type: authConstants.LOGIN_METHOD_SUCCESS,
  ...result,
});

export const loginMethodFailure = (error) => ({
  type: authConstants.LOGIN_METHOD_FAILURE,
  ...error,
});

export const loginMethod = (email) => {
  return (dispatch) => {
    dispatch(startGetLoginMethod());

    const promise = authApi.getLoginMethod(email);

    promise
      .then((result) => {
        dispatch(loginMethodSuccess(result));
      })
      .catch((error) => {
        dispatch(loginMethodFailure(error));
      });

    return promise;
  };
};

/**
 * Login actions
 */
export const changeLoginValue = (name, value) => ({
  type: authConstants.LOGIN_FORM_CHANGE_VALUE,
  name,
  value,
});

export const loginTwoFactorStarted = (result) => ({
  type: authConstants.LOGIN_TWO_FACTOR_STARTED,
  ...result,
});

export const startLogin = () => ({
  type: authConstants.LOGIN_FORM_STARTED,
});

export const loginSuccess = (result) => ({
  type: authConstants.LOGIN_FORM_SUCCESS,
  ...result,
});

export const loginFailure = (error) => ({
  type: authConstants.LOGIN_FORM_FAILURE,
  ...error,
});

export const login = (email, password, token) => {
  return (dispatch) => {
    dispatch(startLogin());

    const promise = authApi.login(email, password, token);

    promise
      .then((result) => {
        if (result.session.user.twoFactorAuthentication) {
          dispatch(loginTwoFactorStarted(result));
        } else dispatch(loginSuccess(result));

        i18n.changeLanguage(result.session?.user?.language?.code?.toLowerCase() || 'en');
      })
      .catch((error) => {
        dispatch(loginFailure(error));
        dispatch(
          navigationActions.createAlert(
            new Alert(
              i18n.t('notification.login.error.title'),
              i18n.t('notification.login.error.description'),
              'error'
            )
          )
        );
      });

    return promise;
  };
};

export const startGetUserSession = () => ({
  type: authConstants.GET_USER_SESSION_STARTED,
});

export const setUserSessionSuccess = (result) => ({
  type: authConstants.GET_USER_SESSION_SUCCESS,
  ...result,
});

export const getUserSessionFailure = (error) => ({
  type: authConstants.GET_USER_SESSION_FAILURE,
  ...error,
});

export const getUserSession = (token) => {
  return (dispatch) => {
    dispatch(startGetUserSession());

    const promise = authApi.getUserSession(token);

    promise
      .then((result) => {
        if (result.session.user.twoFactorAuthentication) {
          dispatch(loginTwoFactorStarted(result));
        } else dispatch(setUserSessionSuccess(result));

        i18n.changeLanguage(result.session?.user?.language?.code?.toLowerCase() || 'en');
      })
      .catch((error) => {
        dispatch(getUserSessionFailure(error));
        dispatch(
          navigationActions.createAlert(
            new Alert(
              i18n.t('notification.login.error.title'),
              i18n.t('notification.login.error.description'),
              'error'
            )
          )
        );
      });

    return promise;
  };
};
export const startOtpLogin = () => ({
  type: authConstants.OTP_LOGIN_STARTED,
});

export const otpLoginSuccess = (result) => ({
  type: authConstants.OTP_LOGIN_SUCCESS,
  ...result,
});

export const otpLoginFailure = (error) => ({
  type: authConstants.OTP_LOGIN_FAILURE,
  ...error,
});

export const otpLogin = (otp, loginSession) => {
  return (dispatch) => {
    dispatch(startVerify());

    const promise = authApi.otpLogin(otp);

    promise
      .then((result) => {
        dispatch(loginSuccess({ ...result, session: loginSession }));
      })
      .catch((error) => {
        dispatch(otpLoginFailure(error));
        dispatch(
          navigationActions.createAlert(
            new Alert(
              i18n.t('notification.login.error.title'),
              i18n.t('notification.login.error.description'),
              'error'
            )
          )
        );
      });

    return promise;
  };
};

/**
 * Register actions
 */
export const changeRegisterValue = (name, value) => ({
  type: authConstants.REGISTER_FORM_CHANGE_VALUE,
  name,
  value,
});

export const startRegister = () => ({
  type: authConstants.REGISTER_FORM_STARTED,
});

export const registerSuccess = (result) => ({
  type: authConstants.REGISTER_FORM_SUCCESS,
  ...result,
});

export const registerFailure = (error) => ({
  type: authConstants.REGISTER_FORM_FAILURE,
  ...error,
});

export const register = (
  username,
  email,
  password,
  repeatPassword,
  terms,
  newsletter,
  recaptcha,
  language,
  businessInformation,
  billingInformation
) => {
  return (dispatch) => {
    dispatch(startRegister());

    const promise = authApi.register(
      username,
      email,
      password,
      repeatPassword,
      terms,
      newsletter,
      recaptcha,
      language,
      businessInformation,
      billingInformation
    );

    promise
      .then((result) => {
        dispatch(registerSuccess(result));
      })
      .catch((error) => {
        dispatch(registerFailure(error));
      });

    return promise;
  };
};

export const developerRegister = (
  username,
  email,
  password,
  repeatPassword,
  terms,
  newsletter,
  recaptcha,
  language
) => {
  return (dispatch) => {
    dispatch(startRegister());

    const promise = authApi.developerRegister(
      username,
      email,
      password,
      repeatPassword,
      terms,
      newsletter,
      recaptcha,
      language
    );

    promise
      .then((result) => {
        dispatch(registerSuccess(result));
      })
      .catch((error) => {
        dispatch(registerFailure(error));
      });

    return promise;
  };
};

/**
 * Confirm actions
 */
export const startEmailConfirmation = () => ({
  type: authConstants.CONFIRM_EMAIL_STARTED,
});

export const emailConfirmationSuccess = (result) => ({
  type: authConstants.CONFIRM_EMAIL_SUCCESS,
  ...result,
});

export const emailConfirmationFailure = (error) => ({
  type: authConstants.CONFIRM_EMAIL_FAILURE,
  ...error,
});

export const confirmEmail = (user, token) => {
  return (dispatch) => {
    dispatch(startEmailConfirmation());

    const promise = authApi.confirmEmail(user, token);

    promise
      .then((result) => {
        dispatch(emailConfirmationSuccess(result));
        dispatch(
          navigationActions.createAlert(
            new Alert(
              i18n.t('notification.confirm.success.title'),
              i18n.t('notification.confirm.success.description'),
              'success'
            )
          )
        );
      })
      .catch((error) => {
        dispatch(emailConfirmationFailure(error));
        dispatch(
          navigationActions.createAlert(
            new Alert(
              i18n.t('notification.confirm.error.title'),
              i18n.t('notification.confirm.error.description'),
              'error'
            )
          )
        );
      });

    return promise;
  };
};

/**
 * Forgot actions
 */
export const changeForgotValue = (name, value) => ({
  type: authConstants.FORGOT_FORM_CHANGE_VALUE,
  name,
  value,
});

export const startForgot = () => ({
  type: authConstants.FORGOT_FORM_STARTED,
});

export const forgotSuccess = (result) => ({
  type: authConstants.FORGOT_FORM_SUCCESS,
  ...result,
});

export const forgotFailure = (error) => ({
  type: authConstants.FORGOT_FORM_FAILURE,
  ...error,
});

export const forgot = (email) => {
  return (dispatch) => {
    dispatch(startForgot());

    const promise = authApi.forgot(email);

    promise
      .then((result) => {
        dispatch(forgotSuccess(result));
        dispatch(
          navigationActions.createAlert(
            new Alert(
              i18n.t('notification.forgot.success.title'),
              i18n.t('notification.forgot.success.description'),
              'success'
            )
          )
        );
      })
      .catch((error) => {
        dispatch(forgotFailure(error));
        navigationActions.createAlert(
          new Alert(
            i18n.t('notification.forgot.success.title'),
            i18n.t('notification.forgot.success.description'),
            'success'
          )
        );
      });

    return promise;
  };
};

/**
 * Reset actions
 */
export const changeResetValue = (name, value) => ({
  type: authConstants.RESET_FORM_CHANGE_VALUE,
  name,
  value,
});

export const startReset = () => ({
  type: authConstants.RESET_FORM_STARTED,
});

export const resetSuccess = (result) => ({
  type: authConstants.RESET_FORM_SUCCESS,
  ...result,
});

export const resetFailure = (error) => ({
  type: authConstants.RESET_FORM_FAILURE,
  ...error,
});

export const reset = (user, token, password, repeatPassword) => {
  return (dispatch) => {
    dispatch(startForgot());

    const promise = authApi.reset(user, token, password, repeatPassword);

    promise
      .then((result) => {
        dispatch(forgotSuccess(result));
        dispatch(
          navigationActions.createAlert(
            new Alert(
              i18n.t('notification.reset.success.title'),
              i18n.t('notification.reset.success.description'),
              'success'
            )
          )
        );
      })
      .catch((error) => {
        dispatch(forgotFailure(error));
        dispatch(
          navigationActions.createAlert(
            new Alert(
              i18n.t('notification.reset.error.title'),
              i18n.t('notification.reset.error.description'),
              'error'
            )
          )
        );
      });

    return promise;
  };
};

/**
 * Update user actions
 */
export const updateValue = (name, value) => ({
  type: authConstants.UPDATE_USER_FORM_VALUE,
  name,
  value,
});

export const startUpdate = () => ({
  type: authConstants.UPDATE_USER_STARTED,
});

export const updateSuccess = (result) => ({
  type: authConstants.UPDATE_USER_SUCCESS,
  ...result,
});

export const updateFailure = (error) => ({
  type: authConstants.UPDATE_USER_FAILURE,
  ...error,
});

export const update = (t, user) => {
  return (dispatch) => {
    dispatch(startUpdate());

    const promise = authApi.update(user);

    promise
      .then((result) => {
        dispatch(updateSuccess(result));
      })
      .catch((error) => {
        dispatch(updateFailure(error));
      });

    return promise;
  };
};

/**
 * Setup 2 Factor
 */

export const startSetup2Factor = () => ({
  type: authConstants.SETUP_2FACTOR_STARTED,
});

export const setup2FactorSuccess = (result) => ({
  type: authConstants.SETUP_2FACTOR_SUCCESS,
  ...result,
});

export const setup2FactorFailure = (error) => ({
  type: authConstants.SETUP_2FACTOR_FAILURE,
  ...error,
});

export const setup2Factor = () => {
  return (dispatch) => {
    dispatch(startSetup2Factor());

    const promise = authApi.setup2Factor();

    promise
      .then((result) => {
        dispatch(setup2FactorSuccess(result));
      })
      .catch((error) => {
        dispatch(setup2FactorFailure(error));
      });

    return promise;
  };
};

/**
 * Verify 2 Factor actions
 */

export const startVerify = () => ({
  type: authConstants.VERIFY_2FACTOR_STARTED,
});

export const verifySuccess = (result) => ({
  type: authConstants.VERIFY_2FACTOR_SUCCESS,
  ...result,
});

export const verifyFailure = (error) => ({
  type: authConstants.VERIFY_2FACTOR_FAILURE,
  ...error,
});

export const verify2Factor = (token) => {
  return (dispatch) => {
    dispatch(startVerify());

    const promise = authApi.verify2Factor(token);

    promise
      .then((result) => {
        dispatch(verifySuccess(result));
      })
      .catch((error) => {
        dispatch(verifyFailure(error));
      });

    return promise;
  };
};

/**
 * Disable 2 Factor actions
 */

export const startDisable2Factor = () => ({
  type: authConstants.DISABLE_2FACTOR_STARTED,
});

export const disable2FactorSuccess = (result) => ({
  type: authConstants.DISABLE_2FACTOR_SUCCESS,
  ...result,
});

export const disable2FactorFailure = (error) => ({
  type: authConstants.DISABLE_2FACTOR_FAILURE,
  ...error,
});

export const disable2Factor = () => {
  return (dispatch) => {
    dispatch(startDisable2Factor());

    const promise = authApi.disable2Factor();

    promise
      .then((result) => {
        dispatch(disable2FactorSuccess(result));
      })
      .catch((error) => {
        dispatch(disable2FactorFailure(error));
      });

    return promise;
  };
};

/**
 * recover 2 Factor
 */

export const startRecover2Factor = () => ({
  type: authConstants.RECOVER_2FACTOR_STARTED,
});

export const recover2FactorSuccess = (result) => ({
  type: authConstants.RECOVER_2FACTOR_SUCCESS,
  ...result,
});

export const recover2FactorFailure = (error) => ({
  type: authConstants.RECOVER_2FACTOR_FAILURE,
  ...error,
});

export const recover2Factor = (recoveryCode, loginSession) => {
  return (dispatch) => {
    dispatch(startRecover2Factor());

    const promise = authApi.recover2Factor(recoveryCode);

    promise
      .then((result) => {
        dispatch(loginSuccess({ ...result, session: loginSession }));
      })
      .catch((error) => {
        dispatch(recover2FactorFailure(error));
      });

    return promise;
  };
};

/**
 * Update password actions
 */
export const changeUpdatePassword = (name, value) => ({
  type: authConstants.UPDATE_PASSWORD_FORM_VALUE,
  name,
  value,
});

export const startUpdatePassword = () => ({
  type: authConstants.UPDATE_PASSWORD_STARTED,
});

export const updatePasswordSuccess = (result) => ({
  type: authConstants.UPDATE_PASSWORD_SUCCESS,
  ...result,
});

export const updatePasswordFailure = (error) => ({
  type: authConstants.UPDATE_PASSWORD_FAILURE,
  ...error,
});

export const updatePassword = (currentPassword, newPassword, repeatPassword) => {
  return (dispatch) => {
    dispatch(startUpdatePassword());

    const promise = authApi.updatePassword(currentPassword, newPassword, repeatPassword);

    promise
      .then((result) => {
        dispatch(updatePasswordSuccess(result));
      })
      .catch((error) => {
        dispatch(updatePasswordFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching sessions
 */
export const startGetSessions = () => ({
  type: authConstants.GET_SESSIONS_STARTED,
});

export const getSessionsSuccess = (result) => ({
  type: authConstants.GET_SESSIONS_SUCCESS,
  ...result,
});

export const getSessionsFailure = (error) => ({
  type: authConstants.GET_SESSIONS_FAILURE,
  ...error,
});

export const getSessions = () => {
  return (dispatch) => {
    dispatch(startGetSessions());

    const promise = authApi.getActiveSessions();

    promise
      .then((result) => {
        dispatch(getSessionsSuccess(result));
      })
      .catch((error) => {
        dispatch(getSessionsFailure(error));
      });

    return promise;
  };
};

/**
 * Logout actions
 */
export const startLogout = () => ({
  type: authConstants.LOGOUT_STARTED,
});

export const logoutSuccess = (result) => ({
  type: authConstants.LOGOUT_SUCCESS,
  ...result,
});

export const logoutFailure = (error) => ({
  type: authConstants.LOGOUT_FAILURE,
  ...error,
});

export const logout = () => {
  return (dispatch) => {
    dispatch(startLogout());

    const promise = authApi.logout();

    promise
      .then((result) => {
        dispatch(logoutSuccess(result));
      })
      .catch((error) => {
        dispatch(logoutFailure(error));
      });

    return promise;
  };
};

/**
 * Logout all actions
 */
export const startLogoutAllOthers = () => ({
  type: authConstants.LOGOUT_ALL_OTHERS_STARTED,
});

export const logoutAllOthersSuccess = (result) => ({
  type: authConstants.LOGOUT_ALL_OTHERS_SUCCESS,
  ...result,
});

export const logoutAllOthersFailure = (error) => ({
  type: authConstants.LOGOUT_ALL_OTHERS_FAILURE,
  ...error,
});

export const logoutAllOthers = () => {
  return (dispatch) => {
    dispatch(startLogoutAllOthers());

    const promise = authApi.logoutAllOthers();

    promise
      .then((result) => {
        dispatch(logoutAllOthersSuccess(result));
      })
      .catch((error) => {
        dispatch(logoutAllOthersFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching role
 */
export const startGetRole = () => ({
  type: authConstants.GET_ROLE_STARTED,
});

export const getRoleSuccess = (result) => ({
  type: authConstants.GET_ROLE_SUCCESS,
  ...result,
});

export const getRoleFailure = (error) => ({
  type: authConstants.GET_ROLE_FAILURE,
  ...error,
});

export const getRole = (id = '') => {
  return (dispatch) => {
    dispatch(startGetRole());

    const promise = authApi.getRole(id);

    promise
      .then((result) => {
        dispatch(getRoleSuccess(result));
      })
      .catch((error) => {
        dispatch(getRoleFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching roles
 */
export const startGetRoles = (from) => ({
  type: authConstants.GET_ROLES_STARTED,
  from: from,
});

export const getRolesSuccess = (result, from) => ({
  type: authConstants.GET_ROLES_SUCCESS,
  from,
  ...result,
});

export const getRolesFailure = (error) => ({
  type: authConstants.GET_ROLES_FAILURE,
  ...error,
});

export const getRoles = (from, amount) => {
  return (dispatch) => {
    dispatch(startGetRoles(from));

    const promise = authApi.allRoles(from, amount);

    promise
      .then((result) => {
        dispatch(getRolesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getRolesFailure(error));
      });

    return promise;
  };
};

export const searchRoles = (searchParameters, from, amount) => {
  return (dispatch) => {
    dispatch(startGetRoles(from));

    const promise = authApi.searchRoles(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getRolesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getRolesFailure(error));
      });

    return promise;
  };
};

/**
 * Creating role
 */
export const startCreateRole = () => ({
  type: authConstants.CREATE_ROLE_STARTED,
});

export const createRoleSuccess = (result) => ({
  type: authConstants.CREATE_ROLE_SUCCESS,
  ...result,
});

export const createRoleFailure = (error) => ({
  type: authConstants.CREATE_ROLE_FAILURE,
  ...error,
});

export const createRole = (role) => {
  return (dispatch) => {
    dispatch(startCreateRole());

    const promise = authApi.createRole(role);

    promise
      .then((result) => {
        dispatch(createRoleSuccess(result));
      })
      .catch((error) => {
        dispatch(createRoleFailure(error));
      });

    return promise;
  };
};

/**
 * Updating role
 */
export const startUpdateRole = () => ({
  type: authConstants.UPDATE_ROLE_STARTED,
});

export const updateRoleSuccess = (result) => ({
  type: authConstants.UPDATE_ROLE_SUCCESS,
  ...result,
});

export const updateRoleFailure = (error) => ({
  type: authConstants.UPDATE_ROLE_FAILURE,
  ...error,
});
export const updateRole = (role) => {
  return (dispatch) => {
    dispatch(startUpdateRole());

    const promise = authApi.updateRole(role);

    promise
      .then((result) => {
        dispatch(updateRoleSuccess(result));
      })
      .catch((error) => {
        dispatch(updateRoleFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting role
 */
export const startDeleteRole = () => ({
  type: authConstants.DELETE_ROLE_STARTED,
});

export const deleteRoleSuccess = (result) => ({
  type: authConstants.DELETE_ROLE_SUCCESS,
  ...result,
});

export const deleteRoleFailure = (error) => ({
  type: authConstants.DELETE_ROLE_FAILURE,
  ...error,
});
export const deleteRole = (role) => {
  return (dispatch) => {
    dispatch(startDeleteRole());

    const promise = authApi.removeRole(role);

    promise
      .then((result) => {
        dispatch(deleteRoleSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteRoleFailure(error));
      });

    return promise;
  };
};
