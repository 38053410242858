import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class VehicleTypeSelector extends Component {
  static defaultProps = {
    isMulti: false,
  };
  render() {
    const { t, value, onChange, placeholder, isMulti } = this.props;

    const options = [
      { label: t('vehicleType.boxTruck'), value: 'boxTruck' },
      { label: t('vehicleType.truck'), value: 'truck' },
      { label: t('vehicleType.tractor'), value: 'tractor' },
      { label: t('vehicleType.airplane'), value: 'airplane' },
      { label: t('vehicleType.deepSeaVessel'), value: 'deepSeaVessel' },
      { label: t('vehicleType.barge'), value: 'barge' },
      { label: t('vehicleType.passengerCar'), value: 'passengerCar' },
      { label: t('vehicleType.motorBike'), value: 'motorBike' },
      { label: t('vehicleType.bike'), value: 'bike' },
      { label: t('vehicleType.bus'), value: 'bus' },
      // { label: t('Coach'), value: 'coach' },
      { label: t('vehicleType.train'), value: 'train' },
      // { label: t('Tram'), value: 'tram' },
      { label: t('vehicleType.unknown'), value: 'unknown' },
    ];

    return (
      <Select
        isMulti={isMulti}
        value={
          isMulti
            ? options.filter((option) => value && value.includes(option.value))
            : options.find((option) => option.value === value)
        }
        options={options}
        onChange={onChange}
        placeholder={placeholder}
      />
    );
  }
}

export default withTranslation('translation')(VehicleTypeSelector);
