import moment from 'moment';

import { distinct, flatten } from '@utils/arrayUtils';

import { chatConstants, socketConstants } from '../constants';

const initialState = {
  chatsIsFetching: false,
  chatsIsFetchingFrom: 0,
  chats: [],
  chatsTotalResults: 0,

  chatIsFetching: false,
  chat: null,
  formChat: null,

  messagesIsFetching: false,
  messagesIsFetchingFrom: 0,
  message: null,
  messages: [],
  messagesLoaded: 0,
  messagesTotalResults: 0,
  unreadMessages: 0,

  newChat: false,
  maximizeNewChat: true,

  activeChats: [],
  maximizeChatList: false,

  messagesWithError: [],
  messagesInConcept: {},
};

export default function chat(state = initialState, action) {
  switch (action.type) {
    case chatConstants.CHAT_CHANGE_VALUE:
      if (action.name === 'message') {
        return {
          ...state,
          error: '',
          [action.name]: action.value,
          messagesInConcept: {
            ...state.messagesInConcept,
            [state.chat.id]: action.value,
          },
        };
      }

      return {
        ...state,
        error: '',
        [action.name]: action.value,
      };

    case chatConstants.CREATE_CHAT_STARTED:
      return { ...state, chatIsFetching: true };
    case chatConstants.CREATE_CHAT_FAILURE:
      return { ...state, chatIsFetching: false, message: action.message || '' };
    case chatConstants.CREATE_CHAT_SUCCESS:
      return { ...state, chatIsFetching: false, chat: action.chat };

    case chatConstants.GET_CHATS_STARTED:
      return {
        ...state,
        chatsIsFetching: true,
        chatsIsFetchingFrom: action.from || 0,
      };
    case chatConstants.GET_CHATS_FAILURE:
      return {
        ...state,
        chatsIsFetching: false,
        chats: [],
        message: action.message || '',
      };
    case chatConstants.GET_CHATS_SUCCESS:
      return {
        ...state,
        chatsIsFetching: false,
        chats:
          action.from > 0 ? [...state.chats, ...action.chats] : action.chats,
        chatsLoaded:
          action.from > 0
            ? state.chats.length + action.chats.length
            : action.chats.length,
        chatsTotalResults: action.totalResults,
        unreadMessages: action.messagesUnread,
      };

    case chatConstants.GET_CHAT_STARTED:
      return {
        ...state,
        chatIsFetching: true,
        messages: [],
        messagesLoaded: 0,
        messagesTotalResults: 0,
      };
    case chatConstants.GET_CHAT_FAILURE:
      return { ...state, chatIsFetching: false, message: action.message || '' };
    case chatConstants.GET_CHAT_SUCCESS:
      return { ...state, chatIsFetching: false, chat: action.chat };

    case chatConstants.GET_MESSAGES_STARTED:
      return {
        ...state,
        messagesIsFetching: true,
        messagesIsFetchingFrom: action.from || 0,
      };
    case chatConstants.GET_MESSAGES_FAILURE:
      return {
        ...state,
        messagesIsFetching: false,
        message: action.message || '',
      };
    case chatConstants.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messagesIsFetching: false,
        messages: groupMessages(
          action.from > 0
            ? [...action.messages, ...flatten([...state.messages].reverse())]
            : action.messages || []
        ),
        messagesLoaded:
          action.from > 0
            ? [...action.messages, ...flatten([...state.messages].reverse())]
                .length
            : action.messages.length,
        messagesTotalResults: action.totalResults,
        messagesInConcept: {
          ...state.messagesInConcept,
          [state.chat.id]: null,
        },
      };

    case chatConstants.CREATE_MESSAGE_STARTED:
    case chatConstants.RETRY_CREATE_MESSAGE_STARTED:
      return {
        ...state,
        messages: groupMessages([
          ...flatten([...state.messages].reverse()),
          action.message,
        ]),
      };
    case chatConstants.CREATE_MESSAGE_FAILURE:
    case chatConstants.RETRY_CREATE_MESSAGE_FAILURE:
      const newMessage = { ...action.message };
      newMessage.error = 'Did not send';
      return {
        ...state,
        messages: groupMessages([
          ...flatten([...state.messages].reverse()).map((message) =>
            action.message.nonce === message.nonce ? action.message : message
          ),
        ]),
        messagesWithError: [...state.messages].map((message) =>
          action.message.nonce === message.nonce ? action.message : message
        ),
      };
    case chatConstants.CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: groupMessages([
          ...flatten([...state.messages].reverse()).map((message) =>
            action.message.nonce === message.nonce ? action.message : message
          ),
        ]),
        chats: [...state.chats].map((chat) => {
          if (chat.id === action.message.chat) {
            const newChat = { ...chat };
            newChat.latestMessage = action.message;
            return newChat;
          } else return chat;
        }),
      };

    case chatConstants.READ_MESSAGES_SUCCESS:
      return {
        ...state,
        unreadMessages: state.unreadMessages - action.messages.length,
        chat:
          action.messages.length > 0 &&
          action.messages[0].chat === state.chat.id
            ? { ...state.chat, unreadMessages: 0 }
            : state.chat,
        chats: state.chats.map((chat) =>
          action.messages.length > 0 && action.messages[0].chat === chat.id
            ? { ...chat, unreadMessages: 0 }
            : chat
        ),
      };
    case socketConstants.NEW_CHAT_MESSAGE:
      if (
        state.chat &&
        (state.chat.id === action.value.chat.id ||
          state.chat.id === action.value.chat)
      ) {
        return {
          ...state,
          messages: groupMessages([
            ...flatten([...state.messages].reverse()),
            action.value,
          ]),
          chats: [...state.chats].map((chat) => {
            if (
              chat.id === action.value.chat.id ||
              chat.id === action.value.chat
            ) {
              const newChat = { ...chat };
              newChat.unreadMessages = action.value.chat.unreadMessages;
              newChat.latestMessage = action.value;
              return newChat;
            } else return chat;
          }),
        };
      } else {
        return {
          ...state,
          chats: [...state.chats].map((chat) => {
            if (
              chat.id === action.value.chat.id ||
              chat.id === action.value.chat
            ) {
              const newChat = { ...chat };
              newChat.unreadMessages = action.value.chat.unreadMessages;
              newChat.latestMessage = action.message;
              return newChat;
            } else return chat;
          }),
          unreadMessages: state.unreadMessages + 1,
        };
      }

    default:
      return state;
  }
}

const groupMessages = (messages) => {
  let groups = [];
  messages.forEach((message) => {
    const previousGroup = groups[groups.length - 1];

    if (
      previousGroup instanceof Array &&
      previousGroup.length > 0 &&
      previousGroup[previousGroup.length - 1].user &&
      message.user &&
      previousGroup[previousGroup.length - 1].user ===
        (message.user?.id || message.user) &&
      moment(message.createdAt) -
        moment(previousGroup[previousGroup.length - 1].createdAt) <
        60000
    ) {
      previousGroup.push(message);
    } else {
      groups.push([message]);
    }
  });
  return groups.reverse();
};
