import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import moment from 'moment';

import CloseButton from '@uicomponents/CloseButton';
import FormInput from '@uiinputs/FormInput';

import Association from '@models/general/Association';
import ContactDetail from '@models/general/ContactDetail';

class ContactDetailsInput extends Component {
  state = {
    contactDetails: this.props.contactDetails
      ? this.props.contactDetails
      : this.props.defaultEnabled
      ? [new Association('inline', new ContactDetail())]
      : [],
  };

  onChange = (contactDetails) => {
    const { onChange } = this.props;
    onChange?.(contactDetails);
  };

  addNewContactDetail = () => {
    const { contactDetails } = this.state;
    this.setState({
      contactDetails: [...contactDetails, new Association('inline', new ContactDetail())],
    });
  };

  updateContactDetail = (newContactDetail, index) => {
    const { contactDetails } = this.state;
    const newContactDetails = [...contactDetails].sort((a, b) =>
      moment.utc(a.updatedAt).diff(moment.utc(b.updatedAt))
    );

    const newAssociation = { ...newContactDetails[index] };

    newAssociation.entity = newContactDetail;
    newContactDetails[index] = newAssociation;

    this.setState({
      contactDetails: newContactDetails,
    });

    this.onChange(newContactDetails);
  };

  render() {
    const { t } = this.props;
    const { contactDetails } = this.state;

    const contactTypeOptions = [
      {
        value: 'iban',
        label: t('form.label.iban'),
      },
      {
        value: 'gln',
        label: t('form.label.gln'),
      },
      {
        value: 'vatCode',
        label: t('form.label.vatCode'),
      },
      {
        value: 'name',
        label: t('form.label.name'),
      },
      {
        value: 'email',
        label: t('form.label.email'),
      },
      {
        value: 'phone',
        label: t('form.label.phoneNumber'),
      },
      {
        value: 'other',
        label: t('form.label.other'),
      },
    ];

    return (
      <>
        {[...contactDetails]
          .map((association) => association.entity)
          .sort((a, b) => moment.utc(a.updatedAt).diff(moment.utc(b.updatedAt)))
          .map((contactDetail, index) => (
            <div
              className="list-sector"
              key={`input-contactDetail-${contactDetail.id}-${contactDetail.nonce}`}
            >
              <div className="list-actions">
                <div></div>
                <CloseButton
                  onClick={() => {
                    const newContactDetails = [...contactDetails];
                    newContactDetails.splice(index, 1);

                    this.setState({
                      contactDetails: newContactDetails,
                    });
                    this.onChange(newContactDetails);
                  }}
                />
              </div>
              <div className="input-group no-margin-top">
                <div className="input-group third no-margin-top">
                  <div className="input-group no-margin-top ">
                    <Select
                      options={contactTypeOptions}
                      placeholder={t('form.label.type')}
                      value={
                        contactTypeOptions.find((option) => option.value === contactDetail.type) ||
                        contactTypeOptions[0]
                      }
                      noOptionsMessage={() => t('noOptions')}
                      onChange={(event) => {
                        const newContactDetail = { ...contactDetail };
                        newContactDetail.type = event.value;

                        this.updateContactDetail(newContactDetail, index);
                      }}
                    />
                  </div>
                </div>
                <div className="input-group twothird no-margin-top">
                  <FormInput
                    className="small"
                    type="text"
                    label={t('form.label.value')}
                    value={contactDetail.value}
                    onChange={(event) => {
                      const newContactDetail = { ...contactDetail };
                      newContactDetail.value = event.target.value;

                      this.updateContactDetail(newContactDetail, index);
                    }}
                  />
                </div>
              </div>
              {!(
                contactDetail.remarksVisible ||
                (contactDetails.remarks && contactDetail.remarks.length > 0)
              ) && (
                <div className="input-group">
                  <div
                    className="as-link"
                    onClick={(e) => {
                      e.preventDefault();
                      this.updateContactDetail({ ...contactDetail, remarksVisible: true }, index);
                    }}
                  >
                    {t('form.label.addRemark')}
                  </div>
                </div>
              )}
              {(contactDetail.remarksVisible ||
                (contactDetails.remarks && contactDetail.remarks.length > 0)) && (
                <div className="input-group">
                  <FormInput
                    type="textarea"
                    label={t('form.label.remarks')}
                    className="small"
                    value={contactDetail.remarks}
                    onChange={(event) => {
                      const newContactDetail = { ...contactDetail };
                      newContactDetail.remarks = event.target.value;

                      this.updateContactDetail(newContactDetail, index);
                    }}
                  />
                </div>
              )}
            </div>
          ))}
        <div className={`input-group left${contactDetails.length > 0 ? '' : ' no-margin-top'}`}>
          <button type="button" onClick={(e) => this.addNewContactDetail(e)}>
            {t('form.label.addContactDetail')}
          </button>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(ContactDetailsInput);
