import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import FormInput from '@uiinputs/FormInput';

class CellsOverwriteInput extends Component {
  state = {
    cells: [[]],
  };

  render() {
    const { t, onChange } = this.props;
    const { cells } = this.state;
    return (
      <>
        <div className="">
          {cells.map((cell, index) => (
            <div className="input-group flex-container">
              <div className="input-group one no-margin-top">
                <FormInput
                  type="text"
                  label={t('form.label.field')}
                  value={cell[0]}
                  onChange={(e) => {
                    e.preventDefault();
                    const newPostalCodeRanges = [...cells];
                    const newPostalCodeRange = [...cells[index]];
                    newPostalCodeRange[0] = e.target.value;
                    newPostalCodeRanges[index] = newPostalCodeRange;

                    this.setState(
                      {
                        cells: newPostalCodeRanges,
                      },
                      () => {
                        onChange(newPostalCodeRanges);
                      }
                    );
                  }}
                />
              </div>
              <div className="input-group one no-margin-top">
                <FormInput
                  type="text"
                  label={t('form.label.value')}
                  value={cell[1]}
                  onChange={(e) => {
                    e.preventDefault();
                    const newPostalCodeRanges = [...cells];
                    const newPostalCodeRange = [...cells[index]];
                    newPostalCodeRange[1] = e.target.value;
                    newPostalCodeRanges[index] = newPostalCodeRange;

                    this.setState(
                      {
                        cells: newPostalCodeRanges,
                      },
                      () => {
                        onChange(newPostalCodeRanges);
                      }
                    );
                  }}
                />
              </div>
            </div>
          ))}
          <div className="input-group left">
            <button
              type="button"
              onClick={(e) => {
                this.setState({
                  cells: [...cells, []],
                });
              }}
            >
              {t('form.label.addCell')}
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(CellsOverwriteInput);
