import React, { Component, Fragment } from 'react';

import { MapContainer, Marker, Polyline } from 'react-leaflet';

import L, { divIcon, latLngBounds } from 'leaflet';
import moment from 'moment';
import PolyUtil from 'polyline-encoded';

import ActionCards from '@components/action/ActionCards';
import EntityTodosContainer from '@containers/todo/EntityTodosContainer';
import VehicleDetailContainer from '@containers/vehicle/VehicleDetailContainer';

import FilePreview from '@uicomponents/FilePreview';
import FileViewer from '@uicomponents/FileViewer';
import NoData from '@uicomponents/NoData';
import PopOver from '@uicomponents/PopOver';
import BigVehicleMarker from '@uimarker/default/BigVehicleMarker';
import NumberMarker from '@uimarker/default/NumberMarker';
import TileLayers from '@uiviews/TileLayers';

import Alert from '@models/Alert';

import { momentToDuration } from '@utils/dateUtils';
import { defaultDateTimeFormat } from '@utils/dateUtils';

import TripBreakdown from './TripBreakdown';
import TripExpenses from './TripExpenses';

export default class TripContent extends Component {
  static defaultProps = {
    isPublic: false,
    tabbedView: false,

    standalone: false,
  };

  state = {
    finishCheckPopOver: false,
    startNextTrip: false,
    tab: 'information',
  };

  render() {
    const {
      t,
      user,
      trip,
      trips,
      isPublic,
      tab,
      tabbedView,
      standalone,
      confirmTrip,
      startTrip,
      finishTrip,
      finishAndStartNewTrip,
      getExtendedVehicle,
      latLonPointGeoReference,

      dateFormat,
      addToStack,
    } = this.props;
    const { finishCheckPopOver, filePreviewOpen, files, focussedFile } = this.state;

    const moveActions = trip?.route?.actions
      ? trip.route.actions
          .map((association) => association.entity)
          .filter((entity) => entity.type === 'move')
          .sort((a, b) => a.sequenceNr - b.sequenceNr)
      : [];

    const sortedActions = trip?.actions
      ?.map((association) => association.entity)
      .sort((a, b) => a.sequenceNr - b.sequenceNr);

    const latLonArrayPointGeoReference = trip?.route?.lineString
      ? PolyUtil.decode(trip?.route?.lineString)
      : null;

    const bounds = latLngBounds(
      latLonArrayPointGeoReference
        ? latLonArrayPointGeoReference
        : latLonPointGeoReference
        ? [
            [latLonPointGeoReference.lat - 0.005, latLonPointGeoReference.lon - 0.005],
            [latLonPointGeoReference.lat + 0.005, latLonPointGeoReference.lon + 0.005],
          ]
        : [[0, 0]]
    );

    const isActive = trip.status === 'in_transit';

    return (
      <>
        <FileViewer
          t={t}
          isActive={filePreviewOpen}
          files={files}
          file={focussedFile}
          onClose={() => {
            this.setState({
              files: [],
              focussedFile: null,
              filePreviewOpen: false,
            });
          }}
        />
        <PopOver
          isActive={finishCheckPopOver}
          className="select-pop-over"
          onClose={() => {
            this.setState({
              finishCheckPopOver: false,
            });
          }}
        >
          <div className="box">
            <div className="title">{t('trip.finish.sure.header')}</div>
            <div className="text">{t('trip.finish.sure.description')}</div>

            <div className="choice">
              <button
                href=""
                onClick={(e) => {
                  e.preventDefault();

                  this.setState({
                    finishCheckPopOver: false,
                  });
                }}
              >
                {t('no')}
              </button>
              <div
                className="as-link"
                onClick={(e) => {
                  e.preventDefault();

                  const { trip, startNextTrip } = this.state;

                  if (startNextTrip) {
                    finishAndStartNewTrip(trip, trips[1]);
                  } else {
                    finishTrip(trip);
                  }

                  this.setState({
                    finishCheckPopOver: false,
                    startNextTrip: false,
                  });
                }}
              >
                {t('yes')}
              </div>
            </div>
          </div>
        </PopOver>
        {(!tabbedView || tab === 'information') && (
          <>
            <div className="flex-container justify-between"></div>
            <div className="trip-info">
              <div className="trip-info__block trip-info__status">
                <div className="trip-info__label">{t('trip.status')}</div>
                <span className={`trip-status trip-status--${trip.status}`}>{trip.status}</span>
              </div>
              <div className="trip-info__block trip-info__distance">
                <div className="trip-info__label">{t('trip.distance')}</div>
                <div>{Math.round(trip.route.distance / 1000)}KM</div>
              </div>

              <div className="trip-info__block trip-info__time">
                <div className="trip-info__label">{t('trip.duration')}</div>
                <div>{momentToDuration(moment.duration(trip.route.time, 'seconds'))}</div>
              </div>
              <div className="trip-info__block">
                <div className="trip-info__label">{t('trip.linkedTrip')}</div>
                <div>{trip.linkedTrip ? t('yes') : t('no')}</div>
              </div>
              {trip.startDate && (
                <div className="trip-info__block trip-info__startTime">
                  <div className="trip-info__label">{t('form.label.startTime')}</div>
                  <div>{defaultDateTimeFormat(trip.startDate, dateFormat)}</div>
                </div>
              )}

              {trip.endDate && (
                <div className="trip-info__block trip-endTime">
                  <div className="trip-info__label">{t('form.label.endTime')}</div>
                  <div>{defaultDateTimeFormat(trip.endDate, dateFormat)}</div>
                </div>
              )}
              {trip.vehicle && (
                <div className="trip-info__block trip-endTime">
                  <div className="trip-info__label">{t('trip.vehicle')}</div>
                  <div
                    className="as-link"
                    onClick={(e) => {
                      e.preventDefault();

                      addToStack?.({
                        name: trip.vehicle.entity.name,
                        className: 'lightgray',
                        component: <VehicleDetailContainer vehicleId={trip.vehicle.entity.id} />,
                      });
                    }}
                  >
                    {trip.vehicle.entity.name || trip.vehicle.entity.licensePlate}
                  </div>
                </div>
              )}
              {trip.CO2eWTW && (
                <div className="trip-info__block trip-info__distance">
                  <div className="trip-info__label">{t('trip.co2')}</div>
                  <div>{trip.CO2eWTW}g</div>
                </div>
              )}
            </div>
            <h2 className="no-margin-bottom">{t('trip.expenses')}</h2>
            <TripExpenses user={user} expenses={trip.route.expenses} />
          </>
        )}

        {!standalone && (
          <MapContainer
            className="vehicle-trips__map"
            preferCanvas={false}
            zoomControl={false}
            attributionControl={false}
            boundsOptions={{ padding: [35, 35] }}
            bounds={bounds}
          >
            <TileLayers />
            {latLonPointGeoReference && (
              <Marker
                key={`${latLonPointGeoReference.id}`}
                icon={BigVehicleMarker}
                position={[latLonPointGeoReference.lat, latLonPointGeoReference.lon]}
                rotationOrigin="center"
                rotationAngle={-latLonPointGeoReference.heading?.value || 0}
              />
            )}

            {sortedActions?.map((action, actionIndex) => (
              <Marker
                key={`${action.id}`}
                icon={divIcon({
                  html: `
                  <svg x="0px" y="0px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1">
                  <g>
                    <path id="path" d="m255,-1c-102.245,0 -185.427,83.182 -185.427,185.426c0,126.888 165.939,313.167 173.004,321.035c6.636,7.391 18.222,7.378 24.846,0c7.065,-7.868 173.004,-194.147 173.004,-321.035c-0.002,-102.244 -83.183,-185.426 -185.427,-185.426z"/>
                    <text transform="matrix(1 0 0 1 0 0)" xml:space="preserve" text-anchor="start" font-size="325" font-style="bold" stroke-width="0" id="number" y="345" x="170">${
                      actionIndex + 1
                    }</text>
                  </g>
                </svg>
                    `,
                  iconSize: new L.Point(32, 32),
                  iconAnchor: [16, 32],
                  popupAnchor: null,
                  shadowUrl: null,
                  shadowSize: null,
                  shadowAnchor: null,
                  className: 'number-marker',
                })}
                position={[
                  action.location.entity.geoReference.lat,
                  action.location.entity.geoReference.lon,
                ]}
              />
            ))}

            {moveActions.reverse().map((moveAction) => {
              const encodedLineString = moveAction?.route?.entity?.lineString;

              return encodedLineString ? (
                <Polyline
                  key={`moveAction-${moveAction?.id}`}
                  positions={PolyUtil.decode(encodedLineString)}
                  color={moveAction.lifeCycle === 'actual' ? 'red' : 'green'}
                />
              ) : null;
            })}
          </MapContainer>
        )}
        {(!tabbedView || tab === 'actions') && (
          <section className="margin-top">
            {!tabbedView && <h2>{t('trip.actions')}</h2>}
            <ActionCards
              key={trip.id}
              actions={trip.actions}
              moveActions={trip.route.actions}
              active={isActive}
              trip={trip}
            />
          </section>
        )}
        {(!tabbedView || tab === 'route-breakdown') && (
          <section className="margin-top">
            {!tabbedView && <h2>{t('trip.breakdown')}</h2>}
            <TripBreakdown trip={trip} dateFormat={dateFormat} />
          </section>
        )}
        {(!tabbedView || tab === 'information') && (
          <>
            <section className="margin-top">
              <h2>{t('trip.attachments')}</h2>
              <div className="files">
                {trip.documents.length > 0 ? (
                  trip.documents.map((attachment) => (
                    <div
                      key={`attachment-${attachment.entity.id}`}
                      className="file"
                      onClick={() => {
                        this.setState({
                          filePreviewOpen: true,
                          files: trip.documents.map((document) => document.entity.file),
                          focussedFile: attachment.entity.file,
                        });
                      }}
                    >
                      <FilePreview file={attachment.entity.file} />
                    </div>
                  ))
                ) : (
                  <NoData className="left">{t('trip.attachments.notFound')}</NoData>
                )}
              </div>
            </section>
          </>
        )}
        {(!tabbedView || tab === 'todo') &&
          this.props.platform?.features?.filter((feature) => feature.name === 'todo').length > 0 && (
            <EntityTodosContainer {...this.props} relatedEntity={trip} relatedEntityType="trip" />
          )}
        {!isPublic && (
          <section>
            <div className="actions-bar">
              {(trip.status === 'accepted' || trip.status === 'requested') &&
                trip.vehicle !== null && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      confirmTrip(trip);
                    }}
                  >
                    {t('trip.confirm')}
                  </button>
                )}
              {trip.status === 'confirmed' && !trips.find((t) => t.status === 'in_transit') && (
                <button
                  onClick={(e) => {
                    e.preventDefault();

                    startTrip(trip);
                  }}
                >
                  {t('trip.start')}
                </button>
              )}
              {isActive && (
                <div className={`${trips.length > 1 ? 'buttons' : ''}`}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      this.setState({
                        finishCheckPopOver: true,
                        trip: trip,
                      });
                    }}
                  >
                    {t('trip.finish')}
                  </button>
                  {trips.length > 1 && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();

                        this.setState({
                          finishCheckPopOver: true,
                          startNextTrip: true,
                          trip: trip,
                        });
                      }}
                    >
                      {t('trip.finishAndStartNext')}
                    </button>
                  )}
                </div>
              )}
            </div>
          </section>
        )}
      </>
    );
  }
}
