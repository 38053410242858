import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import IntegrationFiltersInput from '@components/integration/filter/IntegrationFiltersInput';

import { activateInputs, deactivateInputs } from '@utils/formUtils';
import FormInput from '@uiinputs/FormInput';

class PricingTaxRateForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();

    this.state = {
      selectedCurrency: this.props.currency,
      taxRate: this.props.taxRate,
    };
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  onChange = (newTaxRate) => {
    this.setState({
      taxRate: newTaxRate,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { onChange, popStack } = this.props;
    const { taxRate } = this.state;

    onChange?.(taxRate);
    popStack?.();
  };

  render() {
    const { t, taxRates, entityType, dataTypes, pricingTaxRateTypes, user } = this.props;
    const { taxRate, selectedCurrency } = this.state;

    if (!taxRate) {
      return <Loader />;
    }

    const taxRateOptions = taxRates.map((tax) => ({
      label: tax.name,
      value: tax.id,
      taxRate: tax,
    }));

    return (
      <div className="pricing-taxRate-form">
        <div className="input-group name">
          <div className="input-group no-margin-top">
            <FormInput
              type="text"
              label={`${t('form.label.name')}*`}
              value={taxRate.name}
              onChange={(event) => {
                const newTaxRate = { ...taxRate };
                newTaxRate.name = event.target.value;

                this.onChange(newTaxRate);
              }}
            />
          </div>
          {this.validator.message(t('form.label.name'), taxRate.name, 'required')}
        </div>

        <div className="input-group tax-rate">
          <div className="input-group no-margin-top ">
            <Select
              options={taxRateOptions}
              placeholder={t('form.label.select.taxRate')}
              value={taxRateOptions.find((option) => option.value === taxRate?.taxRate?.id)}
              noOptionsMessage={() => t('noOptions')}
              onChange={(event) => {
                const newTaxRate = { ...taxRate };
                newTaxRate.taxRate = event.taxRate;

                this.onChange(newTaxRate);
              }}
            />
          </div>
          {this.validator.message(t('form.label.select.taxRate'), taxRate.taxRate, 'required')}
        </div>
        <div className="input-group">
          <div className="input-group no-margin-top">
            <FormInput
              type="textarea"
              label={t('form.label.description')}
              className="small"
              value={taxRate.description}
              onChange={(event) => {
                const newTaxRate = { ...taxRate };
                newTaxRate.description = event.target.value;

                this.onChange(newTaxRate);
              }}
            />
          </div>
        </div>
        <div className="input-group more filters">
          <IntegrationFiltersInput
            key={`${taxRate.nonce}-${taxRate.id}`}
            value={taxRate.filters ? taxRate.filters : []}
            entityType={entityType}
            dataTypes={[...dataTypes]}
            onChange={(newFilters) => {
              const newTaxRate = { ...taxRate };
              newTaxRate.filters = [...newFilters];

              this.onChange(newTaxRate);
            }}
          />
        </div>
        <div className="input-group more right">
          <input
            type="submit"
            disabled={!this.validator.allValid()}
            onClick={(e) => this.handleSubmit(e)}
            value={t('form.save')}
          />
        </div>
      </div>
    );
  }
}
export default withTranslation('translation')(PricingTaxRateForm);
