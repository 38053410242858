import React, { Component } from 'react';

import { Form } from 'react-final-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import SimpleReactValidator from 'simple-react-validator';

import AttributesInput from '@components/attribute/AttributesInput';
import BoardComputersInput from '@components/boardComputer/crud/BoardComputersInput';
import ContactDetailsInput from '@components/general/crud/ContactDetailsInput';
import PricingModulesInput from '@components/pricing/crud/PricingModulesInput';
import VehicleInput from '@components/vehicle/VehicleInput';

import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';

import PricingModule from '@models/pricing/PricingModule';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

const chauffeurTypes = ['driver', 'conductor', 'captain', 'pilot'];

export default class ChauffeurForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {};
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmit, formChauffeur, setHasChanged } = this.props;
    onSubmit && onSubmit(formChauffeur);
    setHasChanged(false);
  }

  render() {
    const { platform, onChange, t, formChauffeur, setHasChanged } = this.props;

    const chauffeurTypeOptions = chauffeurTypes.map((type) => ({
      value: type,
      label: t(`chauffeurType.${type}`),
    }));

    return (
      <div className="step-form" key={`${formChauffeur.id}${formChauffeur.nonce}`}>
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div className="input-group">
              <div className="input-group half">
                <FormInput
                  type="text"
                  label={`${t('form.label.firstName')}*`}
                  setHasChanged={setHasChanged}
                  value={formChauffeur.firstName}
                  onChange={(event) => {
                    const newChauffeur = { ...formChauffeur };
                    newChauffeur.firstName = event.target.value;

                    onChange('formChauffeur', newChauffeur, event);
                  }}
                />
                {this.validator.message(
                  t('form.label.firstName'),
                  formChauffeur.firstName,
                  'required'
                )}
              </div>
              <div className="input-group half">
                <FormInput
                  type="text"
                  label={`${t('form.label.lastName')}*`}
                  value={formChauffeur.lastName}
                  setHasChanged={setHasChanged}
                  onChange={(event) => {
                    const newChauffeur = { ...formChauffeur };
                    newChauffeur.lastName = event.target.value;

                    onChange('formChauffeur', newChauffeur, event);
                  }}
                />
                {this.validator.message(
                  t('form.label.lastName'),
                  formChauffeur.lastName,
                  'required'
                )}
              </div>
            </div>
            <div className="input-group">
              <Select
                placeholder={`${t('form.label.selectChauffeurType')}`}
                options={chauffeurTypeOptions}
                value={
                  formChauffeur.type
                    ? chauffeurTypeOptions.find((option) => option.value === formChauffeur.type)
                    : null
                }
                onChange={(e) => {
                  const newChauffeur = { ...formChauffeur };
                  newChauffeur.type = e.value;

                  onChange('formChauffeur', newChauffeur);
                }}
              />
            </div>
            <div className="input-group">
              <FormInput
                type="text"
                label={t('form.label.nationality')}
                setHasChanged={setHasChanged}
                value={formChauffeur.nationality}
                onChange={(event) => {
                  const newChauffeur = { ...formChauffeur };
                  newChauffeur.nationality = event.target.value;

                  onChange('formChauffeur', newChauffeur, event);
                }}
              />
            </div>
            <div className="input-group">
              <FormInput
                type="text"
                label={t('form.label.birthPlace')}
                setHasChanged={setHasChanged}
                value={formChauffeur.birthPlace}
                onChange={(event) => {
                  const newChauffeur = { ...formChauffeur };
                  newChauffeur.birthPlace = event.target.value;

                  onChange('formChauffeur', newChauffeur, event);
                }}
              />
            </div>
            <div className="input-group">
              <FormInput
                type="text"
                label={t('form.label.dateOfBirth')}
                setHasChanged={setHasChanged}
                onFocus={(e) => (e.currentTarget.type = 'date')}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.currentTarget.type = 'text';
                  }
                }}
                value={formChauffeur.dateOfBirth}
                onChange={(event) => {
                  const newChauffeur = { ...formChauffeur };
                  newChauffeur.dateOfBirth = event.target.value;

                  onChange('formChauffeur', newChauffeur, event);
                }}
              />
            </div>
            <div className="input-group">
              <FormInput
                type="number"
                label={t('form.label.upcomingRestingTime')}
                setHasChanged={setHasChanged}
                value={formChauffeur.upcomingRestingTimes?.upcomingRestingTime / 60 / 60}
                onChange={(event) => {
                  const newChauffeur = { ...formChauffeur };
                  newChauffeur.upcomingRestingTimes = {
                    ...(newChauffeur.upcomingRestingTimes || {}),
                  };
                  newChauffeur.upcomingRestingTimes.upcomingRestingTime =
                    event.target.value * 60 * 60;

                  onChange('formChauffeur', newChauffeur, event);
                }}
              />
            </div>
            <div className="input-group">
              <VehicleInput
                vehicle={formChauffeur.preferredVehicle}
                placeholder={t('form.label.selectPreferredVehicle')}
                onChange={(newVehicle) => {
                  const newChauffeur = { ...formChauffeur };
                  newChauffeur.preferredVehicle = newVehicle;

                  onChange('formChauffeur', newChauffeur, event);
                }}
              />
            </div>
            <div
              className={`${
                formChauffeur.adr && formChauffeur.adr.hasADR ? 'list-sector more' : ''
              }`}
            >
              <div
                className={`${
                  formChauffeur.adr && formChauffeur.adr.hasADR ? '' : 'input-group more'
                }`}
              >
                <div>{t('form.label.adr')}</div>
                <Switch
                  checked={formChauffeur.adr && formChauffeur.adr.hasADR ? true : false}
                  onChange={(e, newValue) => {
                    const newChauffeur = { ...formChauffeur };
                    newChauffeur.adr = newValue ? { hasADR: true } : null;

                    onChange('formChauffeur', newChauffeur, event);
                  }}
                />
              </div>

              {formChauffeur.adr && formChauffeur.adr.hasADR && (
                <>
                  <div className="input-group">
                    <FormInput
                      type="text"
                      setHasChanged={setHasChanged}
                      label={t('form.label.ADRNumber')}
                      value={formChauffeur.adr.ADRNumber}
                      onChange={(event) => {
                        const newChauffeur = { ...formChauffeur };
                        const newADR = { ...newChauffeur.adr };

                        newADR.ADRNumber = event.target.value;
                        newChauffeur.adr = newADR;

                        onChange('formChauffeur', newChauffeur, event);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <FormInput
                      type="text"
                      label={t('form.label.ADRValidFromDate')}
                      setHasChanged={setHasChanged}
                      onFocus={(e) => (e.currentTarget.type = 'date')}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          e.currentTarget.type = 'text';
                        }
                      }}
                      value={formChauffeur.adr.ADRValidFromDate}
                      onChange={(event) => {
                        const newChauffeur = { ...formChauffeur };
                        const newADR = { ...newChauffeur.adr };

                        newADR.ADRValidFromDate = event.target.value;
                        newChauffeur.adr = newADR;

                        onChange('formChauffeur', newChauffeur, event);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <FormInput
                      type="text"
                      label={t('form.label.ADRExpirationDate')}
                      setHasChanged={setHasChanged}
                      onFocus={(e) => (e.currentTarget.type = 'date')}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          e.currentTarget.type = 'text';
                        }
                      }}
                      value={formChauffeur.adr.ADRExpirationDate}
                      onChange={(event) => {
                        const newChauffeur = { ...formChauffeur };
                        const newADR = { ...newChauffeur.adr };

                        newADR.ADRExpirationDate = event.target.value;
                        newChauffeur.adr = newADR;

                        onChange('formChauffeur', newChauffeur, event);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div
              className={`${
                formChauffeur.driverLicense && formChauffeur.driverLicense.hasDriverLicense
                  ? 'list-sector more'
                  : ''
              }`}
            >
              <div
                className={`${
                  formChauffeur.driverLicense && formChauffeur.driverLicense.hasDriverLicense
                    ? ''
                    : 'input-group more'
                }`}
              >
                <div>{t('form.label.driverLicense')}</div>
                <Switch
                  checked={
                    formChauffeur.driverLicense && formChauffeur.driverLicense.hasDriverLicense
                      ? true
                      : false
                  }
                  onChange={(e, newValue) => {
                    const newChauffeur = { ...formChauffeur };
                    newChauffeur.driverLicense = newValue ? { hasDriverLicense: true } : null;

                    onChange('formChauffeur', newChauffeur, event);
                  }}
                />
              </div>

              {formChauffeur.driverLicense && formChauffeur.driverLicense.hasDriverLicense && (
                <>
                  <div className="input-group">
                    <FormInput
                      type="text"
                      label={t('form.label.driverLicenseNumber')}
                      setHasChanged={setHasChanged}
                      value={formChauffeur.driverLicense.driverLicenseNumber}
                      onChange={(event) => {
                        const newChauffeur = { ...formChauffeur };
                        const newDriverLicense = {
                          ...newChauffeur.driverLicense,
                        };

                        newDriverLicense.driverLicenseNumber = event.target.value;
                        newChauffeur.driverLicense = newDriverLicense;

                        onChange('formChauffeur', newChauffeur, event);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <FormInput
                      type="text"
                      label={t('form.label.driverLicenseValidFromDate')}
                      setHasChanged={setHasChanged}
                      onFocus={(e) => (e.currentTarget.type = 'date')}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          e.currentTarget.type = 'text';
                        }
                      }}
                      value={formChauffeur.driverLicense.driverLicenseValidFromDate}
                      onChange={(event) => {
                        const newChauffeur = { ...formChauffeur };
                        const newDriverLicense = {
                          ...newChauffeur.driverLicense,
                        };

                        newDriverLicense.driverLicenseValidFromDate = event.target.value;
                        newChauffeur.driverLicense = newDriverLicense;

                        onChange('formChauffeur', newChauffeur, event);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <FormInput
                      type="text"
                      label={t('form.label.driverLicenseExpirationDate')}
                      setHasChanged={setHasChanged}
                      onFocus={(e) => (e.currentTarget.type = 'date')}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          e.currentTarget.type = 'text';
                        }
                      }}
                      value={formChauffeur.driverLicense.driverLicenseExpirationDate}
                      onChange={(event) => {
                        const newChauffeur = { ...formChauffeur };
                        const newDriverLicense = {
                          ...newChauffeur.driverLicense,
                        };

                        newDriverLicense.driverLicenseExpirationDate = event.target.value;
                        newChauffeur.driverLicense = newDriverLicense;

                        onChange('formChauffeur', newChauffeur, event);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <FormInput
                      type="text"
                      label={t('form.label.driverLicenseObtainedOn')}
                      setHasChanged={setHasChanged}
                      onFocus={(e) => (e.currentTarget.type = 'date')}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          e.currentTarget.type = 'text';
                        }
                      }}
                      value={formChauffeur.driverLicense.driverLicenseObtainedOn}
                      onChange={(event) => {
                        const newChauffeur = { ...formChauffeur };
                        const newDriverLicense = {
                          ...newChauffeur.driverLicense,
                        };

                        newDriverLicense.driverLicenseObtainedOn = event.target.value;
                        newChauffeur.driverLicense = newDriverLicense;

                        onChange('formChauffeur', newChauffeur, event);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <FormInput
                      type="text"
                      label={t('form.label.driverLicenseCountryOfIssue')}
                      setHasChanged={setHasChanged}
                      value={formChauffeur.driverLicense.driverLicenseCountryOfIssue}
                      onChange={(event) => {
                        const newChauffeur = { ...formChauffeur };
                        const newDriverLicense = {
                          ...newChauffeur.driverLicense,
                        };

                        newDriverLicense.driverLicenseCountryOfIssue = event.target.value;
                        newChauffeur.driverLicense = newDriverLicense;

                        onChange('formChauffeur', newChauffeur, event);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <FormInput
                      type="text"
                      label={t('form.label.driverLicenseIssuingAuthority')}
                      setHasChanged={setHasChanged}
                      value={formChauffeur.driverLicense.driverLicenseIssuingAuthority}
                      onChange={(event) => {
                        const newChauffeur = { ...formChauffeur };
                        const newDriverLicense = {
                          ...newChauffeur.driverLicense,
                        };

                        newDriverLicense.driverLicenseIssuingAuthority = event.target.value;
                        newChauffeur.driverLicense = newDriverLicense;

                        onChange('formChauffeur', newChauffeur, event);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="input-group">
              <div className="input-group no-margin-top">
                <CreatableSelect
                  placeholder={`${t('form.label.languages')}*`}
                  isClearable
                  isMulti={true}
                  noOptionsMessage={() => t('noOption')}
                  formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    const newChauffeur = { ...formChauffeur };
                    if (e && e.length) {
                      const newLanguages = [...(newChauffeur.languages || [])];
                      newLanguages.push(e.label);
                      newChauffeur.languages = newLanguages;
                    } else {
                      newChauffeur.languages = [];
                    }

                    onChange('formChauffeur', newChauffeur);
                  }}
                  onCreateOption={(e) => {
                    const newChauffeur = { ...formChauffeur };
                    const languages = [...(newChauffeur.languages || [])];
                    languages.push(e);
                    newChauffeur.languages = languages;

                    onChange('formChauffeur', newChauffeur);
                  }}
                  value={
                    formChauffeur.languages && formChauffeur.languages.length
                      ? formChauffeur.languages.map((language) => ({
                          value: language,
                          label: language,
                        }))
                      : []
                  }
                />
              </div>
            </div>
            <div className="input-group">
              <AttributesInput
                key={formChauffeur.attributes ? formChauffeur.attributes : null}
                attributes={formChauffeur.attributes ? formChauffeur.attributes : null}
                onChange={(newAttributes) => {
                  const newChauffeur = { ...formChauffeur };
                  newChauffeur.attributes = newAttributes ? newAttributes : null;

                  onChange('formChauffeur', newChauffeur);
                }}
              />
            </div>
            <div className="input-group more">
              <h3>{t('form.label.contactDetails')}</h3>
              <ContactDetailsInput
                key={formChauffeur.contactDetails}
                contactDetails={formChauffeur.contactDetails}
                onChange={(contactDetails) => {
                  const newChauffeur = { ...formChauffeur };
                  newChauffeur.contactDetails = contactDetails;

                  onChange('formChauffeur', newChauffeur);
                }}
              />
            </div>

            {platform?.features?.map((feature) => feature.name)?.includes('financial') && (
              <div className="input-group">
                <h3>{t('form.label.costModules')}</h3>
                <PricingModulesInput
                  {...this.props}
                  key={formChauffeur.pricingModules}
                  entityType={formChauffeur.targetEntityType}
                  modules={
                    formChauffeur.pricingModules
                      ? [...formChauffeur.pricingModules]
                      : [new PricingModule()]
                  }
                  onChange={(newModules) => {
                    const newChauffeur = { ...formChauffeur };
                    newChauffeur.pricingModules = newModules;

                    onChange('formChauffeur', newChauffeur, event);
                  }}
                />
              </div>
            )}
            <div className="input-group">
              <h3 className="no-margin-top">{t('form.label.selectBoardComputer')}</h3>
              <BoardComputersInput
                key={formChauffeur.boardComputers ? formChauffeur.boardComputers : null}
                boardComputers={formChauffeur.boardComputers ? formChauffeur.boardComputers : null}
                onChange={(newBoardComputers) => {
                  const newChauffeur = { ...formChauffeur };
                  newChauffeur.boardComputers = newBoardComputers ? newBoardComputers : null;

                  onChange('formChauffeur', newChauffeur, event);
                }}
              />
            </div>
            <div className="input-group more right">
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => e.stopPropagation()}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
