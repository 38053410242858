import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import moment from 'moment';

import CloseButton from '@uicomponents/CloseButton';

import Note from '@models/note/Note';

import { activateInputs, deactivateInputs } from '@utils/formUtils';
import FormInput from '@uiinputs/FormInput';

class NotesInput extends Component {
  state = {
    notes: this.props.notes
      ? this.props.notes.sort((a, b) =>
          moment.utc(b.updatedAt).diff(moment.utc(a.updatedAt))
        )
      : this.props.defaultEnabled
      ? [new Note()]
      : [],
  };

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  onChange = (newNotes) => {
    const { onChange } = this.props;

    onChange && onChange(newNotes);
  };

  addNote = () => {
    this.setState({
      notes: [...this.state.notes, new Note()],
    });
  };

  render() {
    const { t, defaultEnabled } = this.props;
    const { notes } = this.state;
    return (
      <>
        {notes.map((note, index) => (
          <div className="list-sector">
            {((defaultEnabled && index !== 0) || !defaultEnabled) && (
              <div className="list-actions">
                <div></div>
                <CloseButton
                  onClick={() => {
                    const newNotes = [...notes];
                    newNotes.splice(index, 1);

                    this.setState({
                      notes: newNotes,
                    });
                    this.onChange(newNotes);
                  }}
                />
              </div>
            )}
            <div className="input-group no-margin-top">
              <div className="input-group no-margin-top">
                <FormInput
                  type="text"
                  label={t('form.label.title')}
                  value={note.name}
                  onChange={(event) => {
                    const newNotes = [...notes];
                    const newNote = { ...note };
                    newNote.name = event.target.value;

                    newNotes[index] = newNote;

                    this.setState({
                      notes: newNotes,
                    });

                    this.onChange(newNotes);
                  }}
                />
              </div>
            </div>
            <div className="input-group">
              <div className="input-group no-margin-top">
                <FormInput
                  type="textarea"
                  label={t('form.label.note')}
                  className="small"
                  value={note.text}
                  onChange={(event) => {
                    const newNotes = [...notes];
                    const newNote = { ...note };
                    newNote.text = event.target.value;

                    newNotes[index] = newNote;

                    this.setState({
                      notes: newNotes,
                    });

                    this.onChange(newNotes);
                  }}
                />
              </div>
            </div>
          </div>
        ))}
        <div
          className={`input-group left${
            notes.length > 0 ? '' : ' no-margin-top'
          }`}
        >
          <button type="button" onClick={(e) => this.addNote(e)}>
            {t('form.label.addNote')}
          </button>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(NotesInput);
