import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import FormInput from '@uiinputs/FormInput';
class PostalCodeRangeInput extends Component {
  state = {
    postalCodeRanges: this.props.value || [{}],
  };

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  render() {
    const { t, onChange } = this.props;
    const { postalCodeRanges } = this.state;
    return (
      <>
        <div className="">
          {postalCodeRanges.map((postalCodeRange, index) => (
            <div className="input-group flex-container">
              <div className="input-group one no-margin-top">
                <FormInput
                  type="text"
                  label={t('form.label.postalCodeFrom')}
                  value={postalCodeRange.from}
                  onChange={(e) => {
                    e.preventDefault();
                    const newPostalCodeRanges = [...postalCodeRanges];
                    const newPostalCodeRange = { ...postalCodeRanges[index] };
                    newPostalCodeRange.from = e.target.value;
                    newPostalCodeRanges[index] = newPostalCodeRange;

                    this.setState(
                      {
                        postalCodeRanges: newPostalCodeRanges,
                      },
                      () => {
                        onChange(newPostalCodeRanges);
                      }
                    );
                  }}
                />
              </div>
              <div className="input-group one no-margin-top">
                <FormInput
                  type="text"
                  label={t('form.label.postalCodeTo')}
                  value={postalCodeRange.to}
                  onChange={(e) => {
                    e.preventDefault();
                    const newPostalCodeRanges = [...postalCodeRanges];
                    const newPostalCodeRange = { ...postalCodeRanges[index] };
                    newPostalCodeRange.to = e.target.value;
                    newPostalCodeRanges[index] = newPostalCodeRange;

                    this.setState(
                      {
                        postalCodeRanges: newPostalCodeRanges,
                      },
                      () => {
                        onChange(newPostalCodeRanges);
                      }
                    );
                  }}
                />
              </div>
              <div className="input-group one no-margin-top">
                <input
                  type="text"
                  value={postalCodeRange.country}
                  onChange={(e) => {
                    e.preventDefault();
                    const newPostalCodeRanges = [...postalCodeRanges];
                    const newPostalCodeRange = { ...postalCodeRanges[index] };
                    newPostalCodeRange.country = e.target.value;
                    newPostalCodeRanges[index] = newPostalCodeRange;

                    this.setState(
                      {
                        postalCodeRanges: newPostalCodeRanges,
                      },
                      () => {
                        onChange(newPostalCodeRanges);
                      }
                    );
                  }}
                />
                <label>{t('form.label.country')}</label>
              </div>
            </div>
          ))}
          <div className="input-group left">
            <button
              type="button"
              onClick={(e) => {
                this.setState({
                  postalCodeRanges: [...postalCodeRanges, {}],
                });
              }}
            >
              {t('form.label.addPostalCode')}
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(PostalCodeRangeInput);
