import React, { Component } from 'react';

import { Circle, FeatureGroup, MapContainer, Polygon, Marker } from 'react-leaflet';
import DefaultMarker from '@uimarker/default/DefaultMarker';
import { EditControl } from 'react-leaflet-draw';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import PricingElementsInput from '@components/pricing/crud/PricingElementsInput';

import Collapsible from '@uicomponents/Collapsible';
import Loader from '@uicomponents/Loader';
import PopOver from '@uicomponents/PopOver';
import TileLayers from '@uiviews/TileLayers';

import * as locationApi from '@api/locationApi';
import GeoReference from '@models/location/GeoReference';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import AddressInput from '../../general/crud/AddressInput';
import ContactDetailsInput from '../../general/crud/ContactDetailsInput';
import LocationTypeSelector from '../LocationTypeSelector';
import PostalCodeRangeInput from '../PostalCodeRangeInput';
import OpeningTimesInput from './OpeningTimesInput';
import FormInput from '@uiinputs/FormInput';
import DraggableMarker from '@uicomponents/DraggableMarker';

export default class LocationForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      geocoding: false,
      showCoordinates: false,
      fetchingDuplicateLocations: false,
      hasDuplicate: false,
      duplicateLocation: null,
      showFence: false,
      showCreateToolbar: true,
    };
  }

  componentDidMount() {
    const { formLocation } = this.props;
    activateInputs();
    if (
      formLocation?.id &&
      formLocation?.geoReference?.type === 'Feature' &&
      formLocation.geoReference?.geometry &&
      formLocation.geoReference?.geometry?.coordinates?.length > 0
    ) {
      this.setState({ showCreateToolbar: false, showFence: true });
    }
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    this.setState({ showCreateToolbar: true });
    deactivateInputs();
  }

  savePosition = (newPosition) => {
    const { lat, lng } = newPosition;
    const { formLocation, onChange } = this.props;
  
    const newLocation = { ...formLocation };
    const newGeoReference = {
      ...(newLocation.geoReference || {}),
      lat,
      lon: lng,
    };
    newLocation.geoReference = newGeoReference;
  
    onChange('formLocation', newLocation);
  
  };
  

  geocoding(addressGeoReference) {
    const { onChange, formLocation } = this.props;
    this.setState({
      geocoding: true,
    });
    locationApi.geocodeAddressGeoReference(addressGeoReference).then((response) => {
      const newLocation = { ...formLocation };
      const newGeoReference = {
        ...(newLocation.geoReference || new GeoReference('latLonPointGeoReference')),
      };
      newGeoReference.lat = response.latLonPointGeoReference.lat;
      newGeoReference.lon = response.latLonPointGeoReference.lon;
      newLocation.geoReference = newGeoReference;

      onChange('formLocation', newLocation);

      this.setState({
        geocoding: false,
      });
    });
  }

  getCoordinates = (latLonPoints) => {
    let coordinates = [];
    latLonPoints?.forEach((point) => coordinates.push([point[0], point[1]]));
    return coordinates;
  };

  getCenter = (center) => {
    return [center.centerX, center.centerY];
  };

  handleSubmit(e) {
    e.preventDefault();
    const { onSubmit, formLocation, setHasChanged } = this.props;

    if (formLocation.id) {
      onSubmit && onSubmit(formLocation);
      setHasChanged(false); 
    } else {
      this.setState({
        fetchingDuplicateLocations: true,
      });

      locationApi.getDuplicateLocations(formLocation).then((response) => {
        const locations = response.locations;
        if (locations.length > 0) {
          this.setState({
            hasDuplicate: true,
            duplicateLocation: locations[0],
            fetchingDuplicateLocations: false,
          });
        } else {
          this.setState({ fetchingDuplicateLocations: false });
          onSubmit && onSubmit(formLocation);
          setHasChanged(false); 
        }
      });
    }
  }

  _created = (e) => {
    const { layerType, layer } = e;
    const { formLocation, onChange } = this.props;

    const newLocation = { ...formLocation };
    const newGeoReference = {
      ...(newLocation.geoReference || new GeoReference('Feature')),
    };
    newGeoReference.geometry = { type: 'Point', coordinates: [] };
    newGeoReference.properties = {};
    const fenceCenter = layer.getBounds().getCenter();
    newGeoReference.properties.centerX = fenceCenter.lat;
    newGeoReference.properties.centerY = fenceCenter.lng;

    if (layerType === 'circle') {
      newGeoReference.geometry.type = 'Point';
      const latLngPoint = layer.getLatLng();
      newGeoReference.geometry.coordinates = [latLngPoint.lat, latLngPoint.lng];
      newGeoReference.properties.radius = layer.getRadius();
    } else {
      let latLonPoints = [];
      newGeoReference.geometry.type = 'Polygon';
      layer.getLatLngs()[0].forEach((point) => latLonPoints.push([point.lat, point.lng]));
      newGeoReference.geometry.coordinates = [latLonPoints];
    }

    newLocation.geoReference = newGeoReference;
    onChange('formLocation', newLocation);
    this.setState({ showCreateToolbar: false });
  };

  _edited = (e) => {
    const { layers } = e;
    const { formLocation, onChange } = this.props;

    let leafletId;
    for (const key in layers._layers) {
      leafletId = key;
      break;
    }

    const newLocation = { ...formLocation };
    const newGeoReference = {
      ...(newLocation.geoReference || new GeoReference('Feature')),
    };
    newGeoReference.geometry = { type: 'Point', coordinates: [] };
    newGeoReference.properties = {};
    const fenceCenter = layers._layers[leafletId].getBounds().getCenter();
    newGeoReference.properties.centerX = fenceCenter.lat;
    newGeoReference.properties.centerY = fenceCenter.lng;

    if (formLocation.geoReference?.geometry?.type === 'Point') {
      newGeoReference.geometry.type = 'Point';
      const latLngPoint = layers._layers[leafletId]?._latlng;
      newGeoReference.geometry.coordinates = [latLngPoint.lat, latLngPoint.lng];
      newGeoReference.properties.radius = layers._layers[leafletId].getRadius();
    } else {
      let latLonPoints = [];
      newGeoReference.geometry.type = 'Polygon';
      layers._layers[leafletId]._latlngs[0]?.forEach((point) =>
        latLonPoints.push([point.lat, point.lng])
      );
      newGeoReference.geometry.coordinates = [latLonPoints];
    }

    newLocation.geoReference = newGeoReference;
    onChange('formLocation', newLocation);
  };

  _deleted = (e) => {
    const { layers } = e;
    const { formLocation, onChange } = this.props;

    const newLocation = { ...formLocation };

    if (layers._layers) {
      const newGeoReference = new GeoReference('Feature');
      newGeoReference.geometry = {
        ...(newLocation.geoReference.geometry || { type: 'Point', coordinates: [] }),
      };
      newGeoReference.geometry.type = 'Point';
      newGeoReference.geometry.coordinates = [];
      newGeoReference.properties = {};
      newLocation.geoReference = newGeoReference;
      onChange('formLocation', newLocation);
    }
    this.setState({ showCreateToolbar: true });
  };

  render() {
    const { t, platform, onChange, formLocation, setHasChanged } = this.props;    
    const {
      geocoding,
      showCoordinates,
      hasDuplicate,
      fetchingDuplicateLocations,
      duplicateLocation,
      showFence,
      showCreateToolbar,
    } = this.state;
    const latLonGeoReference = formLocation?.geoReference;
    const locationTypes = [
      {
        value: 'latLonPointGeoReference',
        label: t('locationType.address'),
      },
      {
        value: 'Feature',
        label: t('locationType.feature'),
      },
      {
        value: 'zoneGeoReference',
        label: t('locationType.postalCodeZone'),
      },
    ];

    if (fetchingDuplicateLocations) {
      return <Loader text={t('form.label.checkingDuplicates')} />;
    }

    return (
      <>
        {latLonGeoReference?.type === 'Feature' ? (
          <MapContainer
            center={
              latLonGeoReference?.properties &&
              Object.keys(latLonGeoReference?.properties).length > 1
                ? this.getCenter(latLonGeoReference.properties)
                : [52.0907, 5.1214]
            }
            zoom={11}
            zoomControl={false}
            preferCanvas={false}
            attributionControl={false}
            className="location-view__map"
            boundsOptions={{ padding: [35, 35] }}
          >
            <TileLayers />
            {latLonGeoReference.geometry &&
              latLonGeoReference.geometry?.coordinates &&
              latLonGeoReference.geometry.coordinates?.length > 0 && (
                <>
                  {latLonGeoReference.geometry?.type === 'Point' ? (
                    <Circle
                      center={{
                        lat: latLonGeoReference.geometry.coordinates[0],
                        lng: latLonGeoReference.geometry.coordinates[1],
                      }}
                      radius={latLonGeoReference.properties?.radius || 100}
                    />
                  ) : (
                    <>
                      <Polygon
                        positions={this.getCoordinates(latLonGeoReference.geometry.coordinates[0])}
                      />
                    </>
                  )}
                </>
              )}
          </MapContainer>
        ) : latLonGeoReference?.lat && latLonGeoReference.lon ? (
          <MapContainer
            className="location-view__map"
            preferCanvas={false}
            zoomControl={false}
            boundsOptions={{ padding: [35, 35] }}
            center={latLonGeoReference
              ? [latLonGeoReference.lat, latLonGeoReference.lon]
              : [52.0907, 5.1214]}
            zoom={12}
            attributionControl={false}
          >
            <TileLayers />
            {latLonGeoReference && <DraggableMarker latLonGeoReference={latLonGeoReference} savePosition={this.savePosition} />}
          </MapContainer>
        ) : null}
        <PopOver
          isActive={hasDuplicate}
          className="select-pop-over"
          onClose={() => {
            this.setState({
              hasDuplicate: false,
            });
          }}
        >
          <div className="box">
            <div className="title">{t('location.duplicate.header')}</div>
            <div className="text">
              {t('location.duplicate.description', {
                name: `"${duplicateLocation ? duplicateLocation.name : ''}"`,
              })}
            </div>

            <div className="choice">
              <button
                href=""
                onClick={(e) => {
                  e.preventDefault();

                  this.setState({
                    hasDuplicate: false,
                  });
                }}
              >
                {t('no')}
              </button>
              <div
                className="as-link"
                onClick={(e) => {
                  e.preventDefault();

                  const { onSubmit, formLocation } = this.props;
                  onSubmit && onSubmit(formLocation);
                }}
              >
                {t('yes')}
              </div>
            </div>
          </div>
        </PopOver>
        <div className="step-form location-form" key={`${formLocation.id}${formLocation.nonce}`}>
          <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
            <div className="input-container">
              <div className="input-group location-geoReference-type">
                <div className="input-group no-margin-top">
                  <Select
                    className="location-type-selector"
                    placeholder={t('form.label.selectLocationType')}
                    value={locationTypes.filter(
                      (type) => type.value === formLocation?.geoReference?.type
                    )}
                    options={locationTypes}
                    onChange={(event) => {
                      const newLocation = { ...formLocation };
                      newLocation.geoReference = { ...newLocation.geoReference } || {};
                      newLocation.geoReference.type = event.value;
                      onChange('formLocation', newLocation, event);
                    }}
                  />
                </div>
              </div>

              {formLocation?.geoReference?.type === 'Feature' && (
                <div id="map-wrapper">
                  <MapContainer
                    zoom={12}
                    preferCanvas={false}
                    scrollWheelZoom={false}
                    center={
                      formLocation.id &&
                      formLocation.geoReference?.properties &&
                      Object.keys(formLocation.geoReference?.properties).length > 1
                        ? this.getCenter(formLocation.geoReference.properties)
                        : [52.0907, 5.1214]
                    }
                    attributionControl={false}
                    className="location-view__map"
                    boundsOptions={{ padding: [35, 35] }}
                  >
                    <FeatureGroup>
                      <EditControl
                        key={showCreateToolbar}
                        position="topright"
                        onCreated={this._created}
                        onEdited={this._edited}
                        onDeleted={this._deleted}
                        draw={{
                          marker: false,
                          polyline: false,
                          circlemarker: false,
                          polygon: showCreateToolbar,
                          circle: showCreateToolbar,
                          rectangle: showCreateToolbar,
                        }}
                      />
                      {showFence &&
                        formLocation.id &&
                        formLocation.geoReference?.geometry &&
                        formLocation.geoReference.geometry?.coordinates?.length > 0 && (
                          <>
                            {formLocation.geoReference.geometry?.type === 'Point' ? (
                              <Circle
                                center={{
                                  lat: formLocation.geoReference.geometry?.coordinates[0],
                                  lng: formLocation.geoReference.geometry?.coordinates[1],
                                }}
                                radius={formLocation.geoReference?.properties?.radius || 100}
                              />
                            ) : (
                              <>
                                <Polygon
                                  positions={this.getCoordinates(
                                    formLocation.geoReference.geometry?.coordinates[0]
                                  )}
                                />
                              </>
                            )}
                          </>
                        )}
                    </FeatureGroup>
                    <TileLayers />
                  </MapContainer>
                </div>
              )}

              <div className="input-group">
                <div className="input-group">
                  <FormInput
                    type="text"
                    label={`${t('form.label.name')}*`}
                    value={formLocation.name}
                    setHasChanged={setHasChanged}
                    onChange={(event) => {
                      const newLocation = { ...formLocation };
                      newLocation.name = event.target.value;

                      const newAdministrativeReference = {
                        ...newLocation.administrativeReference,
                      };
                      newAdministrativeReference.name = newLocation.name;
                      newLocation.administrativeReference = newAdministrativeReference;

                      onChange('formLocation', newLocation, event);
                    }}
                  />
                </div>
                {this.validator.message(t('form.label.name'), formLocation.name, 'required')}
              </div>
              <div className="input-group location-type">
                <LocationTypeSelector
                  key={formLocation.type}
                  value={formLocation.type}
                  placeholder={`${t('form.label.selectLocationType')}*`}
                  onChange={(newType) => {
                    const newLocation = { ...formLocation };
                    newLocation.type = newType.value;

                    onChange('formLocation', newLocation);
                  }}
                />
                {this.validator.message(t('form.label.type'), formLocation.type, 'required')}
              </div>
              {formLocation?.geoReference?.type === 'zoneGeoReference' ? (
                <>
                  <div className="input-group">
                    <PostalCodeRangeInput
                      value={formLocation.geoReference?.postalCodeRanges}
                      onChange={(newPostalCodes) => {
                        const newLocation = { ...formLocation };
                        const newGeoReference = {
                          ...(newLocation.geoReference || new GeoReference('zoneGeoReference')),
                        };
                        newGeoReference.postalCodeRanges = [...newPostalCodes];
                        newLocation.geoReference = newGeoReference;

                        onChange('formLocation', newLocation, event);
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="input-group address">
                    <AddressInput
                      key={`${formLocation?.id}-${formLocation?.nonce}`}
                      address={formLocation.administrativeReference}
                      onChange={(address) => {
                        const newLocation = { ...formLocation };
                        const newAdministrativeReference = { ...address };
                        newAdministrativeReference.name = newLocation.name;
                        newLocation.administrativeReference = newAdministrativeReference;

                        onChange('formLocation', newLocation);
                      }}
                    />
                  </div>

                  {showCoordinates ? (
                    <>
                      <div className="input-group">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            this.geocoding(formLocation.administrativeReference);
                          }}
                        >
                          {t('form.label.geocodeAddress')}
                        </button>
                      </div>
                      {geocoding ? (
                        <Loader />
                      ) : (
                        <>
                          <div className="input-group more flex-container">
                            <div className="input-group half">
                              <div className="input-group no-margin-top">
                                <FormInput
                                  type="text"
                                  label={`${t('form.label.latitude')}*`}
                                  value={formLocation.geoReference.lat}
                                  setHasChanged={setHasChanged}
                                  onChange={(event) => {
                                    const newLocation = { ...formLocation };
                                    const newGeoReference = {
                                      ...(newLocation.geoReference ||
                                        new GeoReference('latLonPointGeoReference')),
                                    };
                                    newGeoReference.lat = event.target.value;
                                    newLocation.geoReference = newGeoReference;

                                    onChange('formLocation', newLocation, event);
                                  }}
                                />
                              </div>
                              {this.validator.message(
                                t('form.label.latitude'),
                                formLocation.geoReference.lat,
                                'required'
                              )}
                            </div>
                            <div className="input-group half">
                              <div className="input-group no-margin-top">
                                <FormInput
                                  type="text"
                                  label={`${t('form.label.longitude')}*`}
                                  value={formLocation.geoReference.lon}
                                  setHasChanged={setHasChanged}
                                  onChange={(event) => {
                                    const newLocation = { ...formLocation };
                                    const newGeoReference = {
                                      ...(newLocation.geoReference ||
                                        new GeoReference('latLonPointGeoReference')),
                                    };
                                    newGeoReference.lon = event.target.value;
                                    newLocation.geoReference = newGeoReference;

                                    onChange('formLocation', newLocation, event);
                                  }}
                                />
                              </div>
                              {this.validator.message(
                                t('form.label.latitude'),
                                formLocation.geoReference.lon,
                                'required'
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                  <div className="input-group more">
                    <div className="input-group no-margin-top">
                      <FormInput
                        type="textarea"
                        label={t('form.label.remarks')}
                        className="small"
                        value={formLocation.remark}
                        setHasChanged={setHasChanged}
                        onChange={(event) => {
                          const newLocation = { ...formLocation };
                          newLocation.remark = event.target.value;

                          onChange('formLocation', newLocation, event);
                        }}
                      />
                    </div>
                    {this.validator.message(t('form.label.remarks'), formLocation.remark, 'string')}
                  </div>
                  <div className="input-group opening-times">
                    <Collapsible
                      name={t('form.label.openingTimes')}
                      className="collapsible-element"
                      defaultIsOpen={false}
                    >
                      <div className="input-group">
                        <OpeningTimesInput
                          key={formLocation.openingTimes}
                          value={formLocation.openingTimes}
                          onChange={(newOpeningTimes) => {
                            const newLocation = { ...formLocation };
                            newLocation.openingTimes = newOpeningTimes;

                            onChange('formLocation', newLocation);
                          }}
                        />
                      </div>
                    </Collapsible>
                  </div>
                  <div className="input-group contact-details">
                    <h3>{t('form.label.contactDetails')}</h3>
                    <ContactDetailsInput
                      key={formLocation.contactDetails}
                      contactDetails={formLocation.contactDetails}
                      onChange={(contactDetails) => {
                        const newLocation = { ...formLocation };
                        newLocation.contactDetails = contactDetails;

                        onChange('formLocation', newLocation);
                      }}
                    />
                  </div>
                </>
              )}

              {platform?.features?.map((feature) => feature.name)?.includes('financial') && (
                <div className="input-group pricing">
                  <h3>{t('form.label.pricingElements')}</h3>
                  <PricingElementsInput
                    key={formLocation.pricingElements}
                    pricingElements={formLocation.pricingElements}
                    onChange={(pricingElements) => {
                      const newLocation = { ...formLocation };
                      newLocation.pricingElements = pricingElements;

                      onChange('formLocation', newLocation);
                    }}
                  />
                </div>
              )}
              <div className="input-group more right location-save">
                <input type="submit" disabled={!this.validator.allValid()} value={t('form.save')} />
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
