import React, { Component } from 'react';

import ReactSVG from 'react-svg';

import moment from 'moment';

import ChauffeurContainer from '@containers/chauffeur/ChauffeurContainer';
import ConsignmentContainer from '@containers/consignment/ConsignmentContainer';
import TransportEquipmentContainer from '@containers/goods/TransportEquipmentContainer';
import TodoDetailsContainer from '@containers/todo/todo/TodoDetailsContainer';
import TransportOrderContainer from '@containers/transportOrder/TransportOrderContainer';
import TripContainer from '@containers/trip/TripContainer';
import VehicleContainer from '@containers/vehicle/VehicleContainer';

import { defaultDateTimeFormat } from '@utils/dateUtils';
import { truncate } from '@utils/stringUtils';

export default class ToDoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t, getTodo, toDo, settings, addToStack } = this.props;

    return (
      <div className="todo-card d-flex justify-content-between">
        <div className="todo-card__header flex-container justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span>{toDo?.name}</span>&nbsp;&nbsp;·&nbsp;&nbsp;
            <span>{toDo?.lane?.board?.title}</span>
            &nbsp;&nbsp;
            {toDo?.relatedEntityName && (
              <>
                ·&nbsp;&nbsp;
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    switch (toDo.relatedEntityType) {
                      case 'vehicle':
                        return addToStack({
                          name: toDo.relatedEntityName,
                          className: 'lightgray',
                          component: <VehicleContainer vehicleId={toDo.relatedEntityId} />,
                        });
                      case 'transportOrder':
                        return addToStack({
                          name: toDo.relatedEntityName,
                          className: 'lightgray',
                          component: (
                            <TransportOrderContainer transportOrderId={toDo.relatedEntityId} />
                          ),
                        });
                      case 'consignment':
                        return addToStack({
                          name: toDo.relatedEntityName,
                          component: <ConsignmentContainer consignmentId={toDo.relatedEntityId} />,
                        });
                      case 'trip':
                        return addToStack({
                          name: toDo.relatedEntityName,
                          component: <TripContainer tripId={toDo.relatedEntityId} />,
                        });
                      case 'chauffeur':
                        return addToStack({
                          name: toDo.relatedEntityName,
                          className: 'lightgray',
                          component: <ChauffeurContainer chauffeurId={toDo.relatedEntityId} />,
                        });
                      case 'transportEquipment':
                        return addToStack({
                          name: toDo.relatedEntityName,
                          className: 'lightgray',
                          component: (
                            <TransportEquipmentContainer
                              transportEquipmentId={toDo.relatedEntityId}
                            />
                          ),
                        });
                    }
                  }}
                >
                  {toDo?.relatedEntityName}
                </a>
              </>
            )}
          </div>

          <div className="d-flex align-items-center justify-content-end gap-5">
            {toDo?.statuses.map((status, index) => (
              <span
                key={`todo-${toDo.id}-status-${index}`}
                className="tag text-capitalize"
                style={{
                  backgroundColor: status === 'overDue' ? 'var(--red)' : toDo?.lane?.color,
                  color: 'var(--white)',
                }}
              >
                {t(`${status}`)}
              </span>
            ))}
          </div>
        </div>

        <div className="todo-card__content">
          <div
            dangerouslySetInnerHTML={{
              __html: truncate(toDo?.description, 100),
            }}
          ></div>
          <div className="d-flex justify-content-between align-items-center my-1">
            <span className="d-flex align-items-center gap-5">
              <img
                className="rounded"
                style={{
                  width: 30,
                  height: 30,
                  marginRight: 5,
                  objectFit: 'cover',
                }}
                src={
                  settings?.user?.avatar && settings?.user?.avatar?.url
                    ? settings.user.avatar.url
                    : 'images/user.svg'
                }
                alt=""
              />
              {toDo?.assignee?.firstName} {toDo?.assignee?.lastName}
            </span>
            <div
              onClick={() => {
                getTodo(toDo);
                return addToStack({
                  component: (
                    <TodoDetailsContainer
                      todo={toDo}
                      todoCallback={() => {
                        this.props?.todoCallback?.();
                      }}
                    />
                  ),
                });
              }}
              className="redirect"
            >
              <ReactSVG src="/icons/arrow-down.svg" />
            </div>
          </div>
        </div>
        <div className="todo-card__footer flex-container justify-content-between align-items-center">
          <div className="stats d-flex">
            <div className="d-flex gap-5 align-items-center">
              <ReactSVG className="attachment-icon" src="/icons/attachment.svg" />
              <span className="text-darkgray font-sm">{toDo?.statistics?.attachmentCount}</span>
            </div>
            <div className="d-flex align-items-center gap-5">
              <ReactSVG src="/icons/comment.svg" />
              <span className="text-darkgray font-sm">{toDo?.statistics?.commentsCount}</span>
            </div>
          </div>
          <span
            className={`${
              moment().isSameOrAfter(moment(toDo?.deadline, 'YYYY-MM-DD')) ? 'text-danger' : ''
            }`}
          >
            {t('todo.lane.due')}: {defaultDateTimeFormat(toDo?.deadline, this.props?.dateFormat)}
          </span>
        </div>
      </div>
    );
  }
}
