import {
  AlwaysAccessibleRoute,
  AuthRoutes,
  DashboardRoutes,
  DefaultRoute,
  DeveloperRoutes,
  IntegrationRoutes,
  MessageRoutes,
  PageShell,
  PlanBoardRoutes,
  PlanRoutes,
  PlannerRoutes,
  PortalRoute,
  QuoteRoutes,
  SupportRoutes,
  TransportOrderRoutes,
  TripRoutes,
} from '@routes';
import React, { PureComponent, Suspense, lazy } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ShepherdTour, TourMethods } from 'react-shepherd';

import axios from 'axios';
import { createGlobalStyle } from 'styled-components';

import { PLANNER, WATCHTOWER } from '@/constants/dashboard';

/* Components */
import NotFound from '@components/defaults/404';
import AlertStackContainer from '@containers/AlertStackContainer';
import ComponentStackContainer from '@containers/ComponentStackContainer';
import PopOverStackContainer from '@containers/PopOverStackContainer';
import Dashboard from '@containers/dashboard/DashboardContainer';
import DeveloperDashboardContainer from '@containers/developer/DeveloperDashboardContainer';
import Header from '@containers/header/HeaderContainer';

import Loader from '@uicomponents/Loader';
import Tour from '@uicomponents/Tour';

import * as generalActions from '@actions/generalActions';
import * as settingsActions from '@actions/settingsActions';

import { shadeColor } from '@utils/colorUtils';

import { i18n } from './index';
import * as authActions from './state/actions/authActions';
import * as navigationActions from './state/actions/navigationActions';
import * as platformActions from './state/actions/platformActions';

const Login = lazy(() => import('@containers/authentication/LoginContainer'));
const RegisterPlatformContainer = lazy(() =>
  import('@containers/authentication/RegisterPlatformContainer')
);

const Notifications = lazy(() => import('@containers/notification/NotificationsContainer'));

const PlatformsContainer = lazy(() => import('@containers/platform/PlatformsContainer'));
const UsersContainer = lazy(() => import('@containers/user/UsersContainer'));
const RolesContainer = lazy(() => import('@containers/role/RolesContainer'));

const VehiclesContainer = lazy(() => import('@containers/vehicle/VehiclesContainer'));
const TransportEquipmentsContainer = lazy(() =>
  import('@containers/goods/TransportEquipmentsContainer')
);

const VehicleGroupsContainer = lazy(() =>
  import('@containers/vehicleGroup/VehicleGroupsContainer')
);
const FleetsContainer = lazy(() => import('@containers/fleet/FleetsContainer'));

const BusinessesContainer = lazy(() => import('@containers/business/BusinessesContainer'));
const LocationsContainer = lazy(() => import('@containers/location/LocationsContainer'));
const SensorsContainer = lazy(() => import('@containers/sensor/SensorsContainer'));
const MessageTemplateContainer = lazy(() =>
  import('@containers/messageTemplate/MessageTemplateContainer')
);
const WorkflowsContainer = lazy(() => import('@containers/workflow/WorkflowsContainer'));
const ChauffeursContainer = lazy(() => import('@containers/chauffeur/ChauffeursContainer'));
const CapacityContractsContainer = lazy(() =>
  import('@containers/capacityContract/CapacityContractsContainer')
);
const WidgetsContainer = lazy(() => import('@containers/widget/WidgetsContainer'));
const WidgetPublicContainer = lazy(() => import('@containers/widget/WidgetPublicContainer'));
const ContractsContainer = lazy(() => import('@containers/contract/ContractsContainer'));
const TerminalsContainer = lazy(() => import('@containers/terminal/TerminalsContainer'));
const BoardComputersContainer = lazy(() =>
  import('@containers/boardComputer/BoardComputersContainer')
);
const TeamsContainer = lazy(() => import('@containers/team/TeamsContainer'));
const ScheduleRoutesContainer = lazy(() =>
  import('@containers/scheduleRoute/ScheduleRoutesContainer')
);
const ChauffeursPlannerContainer = lazy(() =>
  import('@containers/chauffeur/ChauffeursPlannerContainer')
);

const WatchtowerContainer = lazy(() => import('@containers/planning/WatchtowerContainer'));

const AdministrationsContainer = lazy(() =>
  import('@containers/administration/AdministrationsContainer')
);
const PricingsContainer = lazy(() => import('@containers/pricing/PricingsContainer'));
const PricingVariableIndexesContainer = lazy(() =>
  import('@containers/pricingVariableIndex/PricingVariableIndexesContainer')
);
const InvoicePDFContainer = lazy(() =>
  import('@containers/administration/invoice/InvoicePDFContainer')
);
const QuotePDFContainer = lazy(() => import('@containers/administration/quote/QuotePDFContainer'));

const Settings = lazy(() => import('@containers/settings/SettingsContainer'));

class IndexContainer extends PureComponent {
  constructor(props) {
    super(props);
    const classScope = this;
    this.state = {
      showWelcome: false,
      developerTutorialSteps: [
        {
          id: 'welcome',
          arrow: false,
          classes: 'welcome-popup',
          text: `
        <div class="payment-welcome">
          <div class="image-wrapper">
            <img
              src="/images/welcome-popup-bg.jpg"
              style="width: 100%; height: 100%;"
              frameBorder="0"
              allowFullScreen
            ></img>
          </div>
          <div class="content">
            <div>
              <span class="intro">${classScope.props.t('tutorials.popup.welcomeIntro')}</span>
              <span class="title">${classScope.props.t('tutorials.popup.welcomeTitle')}</span>
              <p>
              ${classScope.props.t('tutorials.popup.welcomeContent')}
              </p>
            </div>
          </div>
        </div>
        `,
          attachTo: { element: '.page' },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.start'),
              action() {
                return this.complete();
              },
            },
          ],
        },
      ],
      tutorialSteps: [
        {
          id: 'payment',
          arrow: false,
          classes: 'payment-popup',
          attachTo: { element: '.page' },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              const popupText = document.querySelector('.payment-welcome .price');
              popupText.innerHTML = classScope.props.tutorialErrorMessage;
            },
          },
          text: `
            <div class="payment-welcome">
              <div class="image-wrapper">
                <span class="intro">${classScope.props.t('tutorials.popup.paymentIntro')}</span>
                <span class="title">${classScope.props.t('tutorials.popup.paymentTitle')}</span>
              </div>
              <div class="content">
                <p>
                  ${classScope.props.t('tutorials.popup.paymentContent')}<span class="price"></span>
                </p>
              </div>
            </div>
          `,
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.continueToPay'),
              action() {
                axios.request(classScope.props.retryPaymentConfig);
                classScope.props.popStack();
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.close'),
              action() {
                classScope.props.popStack();
                return this.complete();
              },
            },
          ],
        },
        {
          id: 'welcome',
          arrow: false,
          classes: 'welcome-popup',
          text: `
          <div class="payment-welcome">
            <div class="image-wrapper">
              <img
                src="/images/welcome-popup-bg.jpg"
                style="width: 100%; height: 100%;"
                frameBorder="0"
                allowFullScreen
              ></img>
            </div>
            <div class="content">
              <div>
                <span class="intro">${classScope.props.t('tutorials.popup.welcomeIntro')}</span>
                <span class="title">${classScope.props.t('tutorials.popup.welcomeTitle')}</span>
                <p>
                ${classScope.props.t('tutorials.popup.welcomeContent')}
                </p>
              </div>
            </div>
          </div>
          `,
          attachTo: { element: '.page' },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.start'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'welcome-2',
          arrow: false,
          classes: 'footer-text',
          title: classScope.props.t('tutorials.popup.welcome'),
          text: classScope.props.t('tutorials.popup.welcomeText'),
          attachTo: { element: '.page' },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.maybeLater'),
              action() {
                const { settings, changeValue, updateSettings, t } = classScope.props;
                const newSettings = { ...settings };
                const newUserInterfaceSettings = { ...settings.userInterface };
                newUserInterfaceSettings.tutorials = false;
                newSettings.userInterface = newUserInterfaceSettings;
                changeValue('settings', newSettings);
                updateSettings(t, newSettings);
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.startTour'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'header-search',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.headerSearch'),
          attachTo: { element: '.header__search input', on: 'bottom' },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
          when: {
            show() {
              let total = 3;
              classScope.props.platform?.features.forEach((x) => {
                if (x.name === 'welcome') {
                  total += 1;
                }
                if (x.name === 'dashboard-transport-orders') {
                  total += 1;
                }
              });
              classScope.addPagination(this, `1/${total}`);
            },
          },
        },
        {
          id: 'map-search',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.mapSearch'),
          attachTo: { element: '.map-planner__filters', on: 'bottom' },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              let total = 3;
              classScope.props.platform?.features.forEach((x) => {
                if (x.name === 'welcome') {
                  total += 1;
                }
                if (x.name === 'dashboard-transport-orders') {
                  total += 1;
                }
              });
              classScope.addPagination(this, `2/${total}`);
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
        },
        {
          id: 'top-menu',
          highlightClass: 'tutorial-highlight',
          arrow: false,
          text: classScope.props.t('tutorials.popup.topMenu'),
          attachTo: { element: '.top-menu', on: 'bottom' },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              let total = 3;
              classScope.props.platform?.features.forEach((x) => {
                if (x.name === 'welcome') {
                  total += 1;
                }
                if (x.name === 'dashboard-transport-orders') {
                  total += 1;
                }
              });
              classScope.addPagination(this, `3/${total}`);
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                if (
                  classScope.props.platform?.features?.find((feature) => feature.name === 'welcome')
                ) {
                  return this.show('dashboard-welcome');
                } else if (
                  classScope.props.platform?.features?.find(
                    (feature) => feature.name === 'hasOpenTransportOrders'
                  )
                ) {
                  return this.show('dashboard-transport-orders');
                } else if (
                  classScope.props.platform?.features?.find(
                    (feature) => feature.name === 'tripPlanBoard'
                  )
                ) {
                  document.querySelector('.planBoard-button').click();
                  return this.show('plan-board');
                } else if (
                  classScope.props.platform?.features?.find((feature) => feature.name === PLANNER)
                ) {
                  document.querySelector('.planner-button').click();
                  return this.show('combination-planner');
                } else if (
                  classScope.props.platform?.features?.find(
                    (feature) => feature.name === WATCHTOWER
                  )
                ) {
                  document.querySelector('.watchtower-button').click();
                  return this.show('watch-tower');
                } else {
                  return this.show('highlight-side-menu');
                }
              },
            },
          ],
        },
        {
          id: 'dashboard-welcome',
          highlightClass: 'tutorial-highlight',
          arrow: false,
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.dashboardWelcome'),
          attachTo: { element: '.dashboard__welcome', on: 'top' },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              let total = 3;
              classScope.props.platform?.features.forEach((x) => {
                if (x.name === 'welcome') {
                  total += 1;
                }
                if (x.name === 'dashboard-transport-orders') {
                  total += 1;
                }
              });
              classScope.addPagination(this, `4/${total}`);
            },
          },
          beforeShowPromise: () => {
            return classScope.waitForElm('.dashboard__welcome');
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                if (
                  classScope.props.platform?.features?.find(
                    (feature) => feature.name === 'hasOpenTransportOrders'
                  )
                ) {
                  return this.show('dashboard-transport-orders');
                } else if (
                  classScope.props.platform?.features?.find(
                    (feature) => feature.name === 'tripPlanBoard'
                  )
                ) {
                  document.querySelector('.planBoard-button').click();
                  return this.show('plan-board');
                } else if (
                  classScope.props.platform?.features?.find((feature) => feature.name === PLANNER)
                ) {
                  document.querySelector('.planner-button').click();
                  return this.show('combination-planner');
                } else if (
                  classScope.props.platform?.features?.find(
                    (feature) => feature.name === WATCHTOWER
                  )
                ) {
                  document.querySelector('.watchtower-button').click();
                  return this.show('watch-tower');
                } else {
                  return this.show('highlight-side-menu');
                }
              },
            },
          ],
        },
        {
          id: 'dashboard-transport-orders',
          highlightClass: 'tutorial-highlight',
          arrow: false,
          classes: 'move-left',
          text: classScope.props.t('tutorials.popup.dashboardOrders'),
          attachTo: { element: '.orders', on: 'left' },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              let total = 3;
              classScope.props.platform?.features.forEach((x) => {
                if (x.name === 'welcome') {
                  total += 1;
                }
                if (x.name === 'dashboard-transport-orders') {
                  total += 1;
                }
              });
              classScope.addPagination(this, `5/${total}`);
            },
          },
          beforeShowPromise: () => {
            return classScope.waitForElm('.orders');
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.nextTutorial'),
              action() {
                if (
                  classScope.props.platform?.features?.find(
                    (feature) => feature.name === 'tripPlanBoard'
                  )
                ) {
                  document.querySelector('.planBoard-button').click();
                  return this.show('plan-board');
                } else if (
                  classScope.props.platform?.features?.find((feature) => feature.name === PLANNER)
                ) {
                  document.querySelector('.planner-button').click();
                  return this.show('combination-planner');
                } else if (
                  classScope.props.platform?.features?.find(
                    (feature) => feature.name === WATCHTOWER
                  )
                ) {
                  document.querySelector('.watchtower-button').click();
                  return this.show('watch-tower');
                } else {
                  return this.show('highlight-side-menu');
                }
              },
            },
          ],
        },
        {
          id: 'plan-board',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.planBoard'),
          attachTo: { element: '.tabs-content-container', on: 'top' },
          // scrollTo: { behavior: 'smooth', block: 'start' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container');
          },
          when: {
            show() {
              classScope.addPagination(this, '1/7');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
        },
        {
          id: 'plan-board-vehicle-dropdown',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          scrollTo: { behavior: 'smooth', block: 'center' },
          text: classScope.props.t('tutorials.popup.planBoardVehicle'),
          attachTo: {
            element: '.tabs-content-container .flex-container .flex-container',
            on: 'top',
          },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container .flex-container .flex-container');
          },
          when: {
            show() {
              classScope.addPagination(this, '2/7');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
        },
        {
          id: 'plan-board-search',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.planBoardSearch'),
          attachTo: { element: '.tabs-content-container .plan-board-search', on: 'top' },
          when: {
            show() {
              classScope.addPagination(this, '3/7');
            },
          },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container .plan-board-search');
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
        },
        {
          id: 'plan-board-filter',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.planBoardFilter'),
          attachTo: { element: '.tabs-content-container .plan-board-filter', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container .plan-board-filter');
          },
          when: {
            show() {
              classScope.addPagination(this, '4/7');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
        },
        {
          id: 'plan-board-setting',
          arrow: false,
          classes: 'move-top-left',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.planBoardSetting'),
          attachTo: { element: '.tabs-content-container .plan-board-fields', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container .plan-board-fields');
          },
          when: {
            show() {
              classScope.addPagination(this, '5/7');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
        },
        {
          id: 'plan-board-sort',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.planBoardSort'),
          attachTo: { element: '.tabs-content-container .plan-board-sort', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container .plan-board-sort');
          },
          when: {
            show() {
              classScope.addPagination(this, '6/7');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
        },

        {
          id: 'plan-board-export',
          arrow: false,
          classes: 'move-top-left',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.planBoardExport'),
          attachTo: { element: '.tabs-content-container .plan-board-export', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container .plan-board-export');
          },
          when: {
            show() {
              classScope.addPagination(this, '7/7');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.nextTutorial'),
              action() {
                if (
                  classScope.props.platform?.features?.find((feature) => feature.name === PLANNER)
                ) {
                  document.querySelector('.planner-button').click();
                  return this.show('combination-planner');
                } else if (
                  classScope.props.platform?.features?.find(
                    (feature) => feature.name === WATCHTOWER
                  )
                ) {
                  document.querySelector('.watchtower-button').click();
                  return this.show('watch-tower');
                } else {
                  return this.show('highlight-side-menu');
                }
              },
            },
          ],
        },
        {
          id: 'combination-planner',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.planner'),
          attachTo: { element: '.tabs-content-container', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container');
          },
          // scrollTo: { behavior: 'smooth', block: 'nearest' },
          when: {
            show() {
              classScope.addPagination(this, '1/6');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
        },
        {
          id: 'planner-calendar',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.plannerCalendar'),
          attachTo: { element: '.tabs-content-container .calendar__content', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container .calendar__content');
          },
          when: {
            show() {
              classScope.addPagination(this, '2/6');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
        },
        {
          id: 'planner-calendar-side-panel',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          scrollTo: { behavior: 'smooth', block: 'center' },
          text: classScope.props.t('tutorials.popup.plannerSidePanel'),
          attachTo: {
            element: '.tabs-content-container .calendar__header-lane div',
            on: 'top',
          },
          when: {
            show() {
              classScope.addPagination(this, '3/6');
            },
          },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container .calendar__header-lane div');
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
        },
        {
          id: 'planner-week/day-switch',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.plannerSwitch'),
          attachTo: { element: '.tabs-content-container .header__view-switcher', on: 'top' },
          when: {
            show() {
              classScope.addPagination(this, '4/6');
            },
          },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container .header__view-switcher');
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
        },
        {
          id: 'planner-dropdown',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.plannerDropdown'),
          attachTo: { element: '.tabs-content-container .calendar div[class*=control]', on: 'top' },
          when: {
            show() {
              classScope.addPagination(this, '5/6');
            },
          },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container .calendar div[class*=control]');
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
        },
        // {
        //   id: 'planner-search',
        //   arrow: false,
        //   classes: 'move-top',
        //   highlightClass: 'tutorial-highlight',
        //   text: classScope.props.t('tutorials.popup.plannerSearch'),
        //   attachTo: {
        //     element: '.tabs-content-container .plan-board-search',
        //     on: 'top',
        //   },
        //   when: {
        //     show() {
        //       classScope.addPagination(this, '6/7');
        //     },
        //   },
        //   beforeShowPromise: () => {
        //     return classScope.waitForElm('.tabs-content-container .plan-board-search');
        //   },
        //   buttons: [
        //     {
        //       text: classScope.props.t('tutorials.popup.exit'),
        //       action() {
        //         return this.complete();
        //       },
        //     },
        //     {
        //       text: classScope.props.t('tutorials.popup.next'),
        //       type: 'next',
        //     },
        //   ],
        // },
        {
          id: 'planner-action',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.plannerAction'),
          attachTo: { element: '.tabs-content-container .calendar .action-icons', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container .calendar .action-icons');
          },
          when: {
            show() {
              classScope.addPagination(this, '6/6');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.nextTutorial'),
              action() {
                if (
                  classScope.props.platform?.features?.find(
                    (feature) => feature.name === WATCHTOWER
                  )
                ) {
                  document.querySelector('.watchtower-button').click();
                  return this.show('watch-tower');
                } else {
                  return this.show('highlight-side-menu');
                }
              },
            },
          ],
        },
        {
          id: 'watch-tower',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.watchtower'),
          attachTo: { element: '.tabs-content-container', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container');
          },
          scrollTo: { behavior: 'smooth', block: 'nearest' },
          when: {
            show() {
              classScope.addPagination(this, '1/3');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                this.next();
              },
            },
          ],
        },
        {
          id: 'watch-tower-search',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.watchtowerSearch'),
          attachTo: { element: '.tabs-content-container .plan-board-search', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container .plan-board-search');
          },
          when: {
            show() {
              classScope.addPagination(this, '2/3');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                this.next();
              },
            },
          ],
        },
        {
          id: 'watch-tower-actions',
          arrow: false,
          classes: 'move-top-left',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.watchtowerActions'),
          attachTo: { element: '.tabs-content-container .actions', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.tabs-content-container .actions');
          },
          when: {
            show() {
              classScope.addPagination(this, '3/3');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.nextTutorial'),
              action() {
                this.next();
              },
            },
          ],
        },
        {
          id: 'highlight-side-menu-create-order',
          arrow: false,
          classes: 'move-left',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.orderMenu'),
          attachTo: { element: '.side-menu', on: 'bottom' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.side-menu');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '1/12');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document.querySelector('.side-menu').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'highlight-create-aside-view',
          arrow: false,
          classes: 'move-left',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.orderAside'),
          attachTo: { element: '.create-order-aside-button', on: 'bottom' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.aside-content-box');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '2/12');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document.querySelector('.create-order-aside-button').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'create-order',
          classes: 'move-left-major move-top',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.orderCreate'),
          attachTo: { element: '.transport-order-form .text-inputs', on: 'bottom-end' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.transport-order-form .text-inputs');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '3/12');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
        },
        {
          id: 'create-order-file-uploads',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.orderUploads'),
          attachTo: { element: '.transport-order-form .upload-files', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.transport-order-form .upload-files');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '4/12');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              type: 'next',
            },
          ],
        },
        {
          id: 'create-order-cargo-details',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.cargoDetails'),
          arrow: false,
          classes: 'move-top',
          attachTo: { element: '.transport-order-form .consignments-input', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.transport-order-form .consignments-input');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '5/12');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document.querySelector('.transport-order-form .consignment-form-element').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'add-cargo-type',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.cargoType'),
          arrow: false,
          classes: 'move-top-left-major',
          attachTo: { element: '.consignment-form .consignment-type', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.consignment-form .consignment-type');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '6/12');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document.querySelector('.consignment-form .actions-input button').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'add-cargo-actions',
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.cargoActions'),
          arrow: false,
          attachTo: { element: '.consignment-form .actions-input', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.consignment-form .actions-input');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '7/12');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document.querySelector('.consignment-form .equipment-input button').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'add-cargo-equipment',
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.cargoEquipment'),
          arrow: false,
          attachTo: { element: '.consignment-form .equipment-input', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.consignment-form .equipment-input');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '8/12');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document.querySelector('.consignment-form .items-input button').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'add-cargo-details',
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.cargoDetail'),
          arrow: false,
          attachTo: { element: '.consignment-form .items-input', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.consignment-form .items-input');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '9/12');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document.querySelector('.consignment-form .constraints-input button').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'add-cargo-constraints',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.cargoConstraints'),
          arrow: false,
          classes: 'move-top',
          attachTo: { element: '.consignment-form .constraints-input', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.consignment-form .constraints-input');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '10/12');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                if (document.querySelector('.consignment-form .pricing-input')) {
                  document.querySelector('.consignment-form .pricing-input button').click();
                  return this.next();
                } else {
                  classScope.props.popStack();
                  return this.show('save-transport-order');
                }
              },
            },
          ],
        },
        {
          id: 'add-cargo-pricing',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.cargoPricing'),
          arrow: false,
          classes: 'move-top',
          attachTo: { element: '.consignment-form .pricing-input', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.consignment-form .pricing-input');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '11/12');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                classScope.props.popStack();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'save-transport-order',
          highlightClass: 'tutorial-highlight',
          arrow: false,
          classes: 'move-top-left',
          text: classScope.props.t('tutorials.popup.saveOrder'),
          modalOverlayOpeningPadding: 10,
          attachTo: {
            element: '.transport-order-form .buttons input',
            on: 'top',
          },
          beforeShowPromise: () => {
            return classScope.waitForElm('.transport-order-form .buttons');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '12/12');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.nextTutorial'),
              action() {
                classScope.props.popStack();
                document.querySelector('.side-menu').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'highlight-create-trip-aside-view',
          arrow: false,
          classes: 'move-left-major',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.tripAside'),
          attachTo: { element: '.create-trip-aside-button', on: 'top' },
          beforeShowPromise: () => {
            document.querySelector('.side-menu').click();
            return classScope.waitForElm('.aside-content-box');
          },
          when: {
            show() {
              classScope.addPagination(this, '1/8');
            },
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document.querySelector('.create-trip-aside-button').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'create-trip-internal',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.tripInternal'),
          arrow: false,
          classes: 'move-left-major',
          attachTo: { element: '.trip-form #internal', on: 'bottom' },
          when: {
            show() {
              classScope.addPagination(this, '2/8');
            },
          },
          beforeShowPromise: () => {
            return classScope.waitForElm('.trip-form #internal');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'create-trip-forwarded',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.tripForwarded'),
          arrow: false,
          when: {
            show() {
              classScope.addPagination(this, '3/8');
            },
          },
          attachTo: { element: '.trip-form #forwarded', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.trip-form #forwarded');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'create-trip-text-input',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.tripText'),
          arrow: false,
          classes: 'move-top',
          attachTo: { element: '.trip-form .text-inputs', on: 'top' },
          when: {
            show() {
              classScope.addPagination(this, '4/8');
            },
          },
          beforeShowPromise: () => {
            return classScope.waitForElm('.trip-form .text-inputs');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                if (document.querySelector('.trip-form .pricing-element')) {
                  document.querySelector('.trip-form .pricing-element button').click();
                  return this.next();
                } else {
                  return this.show('create-trip-select-vehicle');
                }
              },
            },
          ],
        },
        {
          id: 'create-trip-pricing-input',
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.tripPricing'),
          arrow: false,
          when: {
            show() {
              classScope.addPagination(this, '5/8');
            },
          },
          attachTo: { element: '.trip-form .pricing-element', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.trip-form .pricing-element');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'create-trip-select-vehicle',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.tripVehicle'),
          arrow: false,
          classes: 'move-top',
          when: {
            show() {
              classScope.addPagination(this, '6/8');
            },
          },
          attachTo: { element: '.trip-form .vehicle', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.trip-form .vehicle');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'create-trip-consignment-selector',
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.tripConsignment'),
          arrow: false,
          when: {
            show() {
              classScope.addPagination(this, '7/8');
            },
          },
          attachTo: { element: '.trip-form .consignment-selector', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.trip-form .consignment-selector');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'create-trip-save',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.tripSave'),
          classes: 'move-top-left',
          arrow: false,
          modalOverlayOpeningPadding: 10,
          attachTo: { element: '.trip-form input[type="submit"]', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.trip-form input[type="submit"]');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '8/8');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.nextTutorial'),
              action() {
                classScope.props.popStack();
                document.querySelector('.side-menu').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'highlight-side-menu-fleets-view',
          highlightClass: 'tutorial-highlight',
          arrow: false,
          classes: 'move-top-left',
          text: classScope.props.t('tutorials.popup.fleetAside'),
          attachTo: { element: '.aside-content-box .fleets', on: 'top' },
          when: {
            show() {
              classScope.addPagination(this, '1/9');
            },
          },
          beforeShowPromise: () => {
            document.querySelector('.side-menu').click();
            return classScope.waitForElm('.aside-content-box');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                this.complete();
                document.querySelector('.aside-content-box .fleets').click();
                return this.show('highlight-fleet-table');
              },
            },
          ],
        },
        {
          id: 'highlight-fleet-table',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.fleetView'),
          attachTo: { element: '.page' },
          when: {
            show() {
              classScope.addPagination(this, '2/9');
            },
          },
          beforeShowPromise: () => {
            return classScope.waitForElm('.fleets-view .table-view');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'highlight-create-fleet',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-left',
          text: classScope.props.t('tutorials.popup.fleetCreate'),
          attachTo: { element: '.fleets-view .top-functions button', on: 'bottom' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.fleets-view .top-functions');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '3/9');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document.querySelector('.fleets-view .top-functions button').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'fleet-name',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-left-major',
          text: classScope.props.t('tutorials.popup.fleetName'),
          attachTo: { element: '.fleet-form .fleet-name', on: 'bottom' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.fleet-form .fleet-name');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '4/9');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'fleet-number',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.fleetNumber'),
          attachTo: { element: '.fleet-form .fleet-number', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.fleet-form .fleet-number');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '5/9');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'fleet-team',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.fleetTeam'),
          attachTo: { element: '.fleet-form .fleet-team', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.fleet-form .fleet-team');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '6/9');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document.querySelector('.fleet-form .fleet-vehicle button').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'fleet-vehicle',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.fleetVehicle'),
          attachTo: { element: '.fleet-form .list-sector', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.fleet-form .list-sector');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '7/9');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'save-fleet',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top-left',
          text: classScope.props.t('tutorials.popup.fleetSave'),
          modalOverlayOpeningPadding: 10,
          attachTo: { element: '.fleet-form input[type="submit"]', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.fleet-form input[type="submit"]');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '8/9');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                classScope.props.popStack();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'fleet-table',
          arrow: false,
          classes: 'move-top',
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.fleetTable'),
          attachTo: { element: '.fleets-view .table-view', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.fleets-view .table-view');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '9/9');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                if (
                  classScope.props.user?.role?.level >= 300 &&
                  classScope.props?.platform?.features &&
                  classScope.props?.platform?.features
                    .map((feature) => feature.name)
                    .includes('financial')
                ) {
                  classScope.props.popStack();
                  return this.next();
                } else {
                  return this.complete();
                }
              },
            },
          ],
        },
        {
          id: 'highlight-side-menu-pricing-view',
          highlightClass: 'tutorial-highlight',
          arrow: false,
          classes: 'move-top-left',
          text: classScope.props.t('tutorials.popup.pricing'),
          attachTo: { element: '.aside-content-box .pricing', on: 'top' },
          when: {
            show() {
              classScope.addPagination(this, '1/21');
            },
          },
          beforeShowPromise: () => {
            document.querySelector('aside').classList.add('show');
            return classScope.waitForElm('.aside-content-box');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                this.complete();
                document.querySelector('.aside-content-box .pricing').click();
                return this.show('highlight-pricing-page');
              },
            },
          ],
        },
        {
          id: 'highlight-pricing-page',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.pricingPage'),
          attachTo: { element: '.page' },
          when: {
            show() {
              classScope.addPagination(this, '2/21');
            },
          },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricings-view .table-view');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'highlight-create-pricing-config',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-left',
          text: classScope.props.t('tutorials.popup.pricingConfig'),
          attachTo: { element: '.pricings-view .top-functions button', on: 'bottom' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricings-view .top-functions button');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '3/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document.querySelector('.pricings-view .top-functions button').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-config-name',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top-left-major',
          text: classScope.props.t('tutorials.popup.pricingConfigName'),
          attachTo: { element: '.pricing-form .name', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-form .name');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '4/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-config-context',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.pricingConfigContext'),
          attachTo: { element: '.pricing-form .context', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-form .context');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '5/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-config-trigger',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.pricingConfigTrigger'),
          attachTo: { element: '.pricing-form .trigger', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-form .trigger');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '6/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-config-business',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.pricingConfigBusiness'),
          attachTo: { element: '.pricing-form .business', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-form .business');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '7/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-config-currency',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.pricingConfigCurrency'),
          attachTo: { element: '.pricing-form .currency', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-form .currency');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '8/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document.querySelector('.pricing-form .pricing-module button').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-config-module',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.pricingConfigModule'),
          attachTo: { element: '.pricing-form .pricing-module', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-form .pricing-module');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '9/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document
                  .querySelector('.pricing-form .pricing-module .pricing-form-element')
                  .click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-module-type',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top-left-major',
          text: classScope.props.t('tutorials.popup.pricingModuleType'),
          attachTo: { element: '.pricing-module-form .type', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-module-form .type');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '10/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-module-name',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.pricingModuleName'),
          attachTo: { element: '.pricing-module-form .name', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-module-form .name');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '11/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document.querySelector('.pricing-module-form .filters button').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-module-filter',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.pricingModuleFilter'),
          attachTo: { element: '.pricing-module-form .filters', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-module-form .filters');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '12/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-module-selector',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.pricingModuleSelector'),
          attachTo: { element: '.pricing-module-form .selector', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-module-form .selector');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '13/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document.querySelector('.pricing-module-form .selector .input-toolbutton').click();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-module-selector-popup',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.pricingModuleSelectorPopup'),
          attachTo: { element: '.popover', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.popover');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '14/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                document
                  .querySelector('.pricing-module-form .filters .popover')
                  .classList.remove('active');
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-module-comparison',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.pricingModuleComparison'),
          attachTo: { element: '.pricing-module-form .comparison', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-module-form .comparison');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '15/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-module-value',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.pricingModuleValue'),
          attachTo: { element: '.pricing-module-form .value', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-module-form .value');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '16/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-module-info',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          text: classScope.props.t('tutorials.popup.pricingModuleInfo'),
          attachTo: { element: '.pricing-module-form .value', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-module-form .value');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '17/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-module-category',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top',
          text: classScope.props.t('tutorials.popup.pricingModuleCategory'),
          attachTo: { element: '.pricing-module-form .category', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-module-form .category');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '18/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                return this.next();
              },
            },
          ],
        },
        // {
        //   id: 'pricing-module-tax',
        //   arrow: false,
        //   highlightClass: 'tutorial-highlight',
        //   classes: 'move-top',
        //   text: classScope.props.t('tutorials.popup.pricingModuleTax'),
        //   attachTo: { element: '.pricing-module-form .pricing-module', on: 'top' },
        //   beforeShowPromise: () => {
        //     return classScope.waitForElm('.pricing-module-form .pricing-module');
        //   },
        //   scrollTo: { behavior: 'smooth', block: 'center' },
        //   when: {
        //     show() {
        //       classScope.addPagination(this, '19/22');
        //     },
        //   },
        //   buttons: [
        //     {
        //       text: classScope.props.t('tutorials.popup.exit'),
        //       action() {
        //         return this.complete();
        //       },
        //     },
        //     {
        //       text: classScope.props.t('tutorials.popup.next'),
        //       action() {
        //         return this.next();
        //       },
        //     },
        //   ],
        // },
        {
          id: 'save-pricing-module',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top-left',
          text: classScope.props.t('tutorials.popup.pricingModuleSave'),
          modalOverlayOpeningPadding: 10,
          attachTo: { element: '.pricing-module-form input[type="submit"]', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-module-form input[type="submit"]');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '20/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                classScope.props.popStack();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'save-pricing-config',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'move-top-left',
          text: classScope.props.t('tutorials.popup.pricingConfigSave'),
          attachTo: { element: '.pricing-form input[type="submit"]', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricing-form input[type="submit"]');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '21/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.next'),
              action() {
                classScope.props.popStack();
                return this.next();
              },
            },
          ],
        },
        {
          id: 'pricing-config-listing',
          arrow: false,
          highlightClass: 'tutorial-highlight',
          classes: 'hide-button',
          text: classScope.props.t('tutorials.popup.pricingConfigListing'),
          attachTo: { element: '.pricings-view .table-view', on: 'top' },
          beforeShowPromise: () => {
            return classScope.waitForElm('.pricings-view .table-view');
          },
          scrollTo: { behavior: 'smooth', block: 'center' },
          when: {
            show() {
              classScope.addPagination(this, '21/21');
            },
          },
          buttons: [
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
            {
              text: classScope.props.t('tutorials.popup.exit'),
              action() {
                return this.complete();
              },
            },
          ],
        },
      ],
    };
    this.waitForElm = this.waitForElm.bind(this);
    this.addPagination = this.addPagination.bind(this);
  }

  addPagination(context, pageCounts) {
    const currentElement = context.tour.currentStep.el;
    const footer = currentElement.querySelector('.shepherd-footer');
    const pagination = document.createElement('div');
    pagination.classList.add('shepherd-pagination');
    pagination.innerHTML = pageCounts;
    const buttons = footer.querySelectorAll('.shepherd-button');
    const buttonWrapper = document.createElement('div');
    buttonWrapper.classList.add('shepherd-button-wrapper');
    buttonWrapper.appendChild(buttons[0]);
    buttonWrapper.appendChild(buttons[1]);
    while (footer.firstChild) {
      footer.removeChild(footer.firstChild);
    }
    footer.appendChild(buttonWrapper);
    footer.insertBefore(pagination, buttonWrapper);
  }

  waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  componentDidMount() {
    const localStorage = window.localStorage;
    const params = new URLSearchParams(location.search);

    const {
      t,
      settings,
      loginSuccess,
      getPlatformById,
      getPlatform,
      getSettings,
      getTaxRates,
      getAttributes,
      getPricingCategories,
    } = this.props;

    if (!this.isWidget() && !this.isPlatformRegistration() && !params?.get('platformId')) {
      getPlatform(t).then((data) => {
        i18n.changeLanguage(data.language.toLowerCase());
        data.platform.translationSettings.languagesLabels.forEach((language) => {
          i18n.addResourceBundle(
            language.language,
            'translation',
            JSON.parse(language.labels || {})
          );
        });
      });
    }

    if (!settings) {
      getSettings();

      getTaxRates();
      getAttributes();
      getPricingCategories();
    }

    if (params?.get('bearer')) {
      loginSuccess({
        session: {
          access_token: params.get('bearer'),
        },
      });
    }

    if (localStorage.getItem('platform-id')) {
      getPlatformById(localStorage.getItem('platform-id')).then((data) => {
        i18n.changeLanguage(data.language.toLowerCase());
        data.platform.translationSettings.languagesLabels.forEach((language) => {
          i18n.addResourceBundle(
            language.language,
            'translation',
            JSON.parse(language.labels || {})
          );
        });
      });
    }

    if (params?.get('platformId')) {
      getPlatformById(params.get('platformId')).then((data) => {
        i18n.changeLanguage(data.language.toLowerCase());
        data.platform.translationSettings.languagesLabels.forEach((language) => {
          i18n.addResourceBundle(
            language.language,
            'translation',
            JSON.parse(language.labels || {})
          );
        });
      });
    }
  }

  componentDidUpdate = () => {
    const {
      user,
      platform,
      settings,
      getSettings,
      isAuthenticated,
      getTaxRates,
      getAttributes,
      getPricingCategories,
    } = this.props;

    if (settings?.userInterface && isAuthenticated) {
      if (
        settings?.userInterface.tutorials &&
        (window.location.pathname === '/' || window.location.pathname === '/dashboard')
      ) {
        this.setState({ showWelcome: true });
      } else if (!settings?.userInterface.tutorials && this.state.showWelcome) {
        this.setState({ showWelcome: false });
      }
    }

    if (!settings && isAuthenticated && user && platform) {
      getSettings();

      getTaxRates();
      getAttributes();
      getPricingCategories();
    }
  };

  componentWillUnmount() {
    this.props.setTutorial(null);
  }

  isWidget() {
    const match = matchPath(window.location.pathname, {
      path: '/widget/:id/:publicKey',
      exact: true,
      strict: false,
    });
    return match !== null;
  }

  isPlatformRegistration() {
    const match = matchPath(window.location.pathname, {
      path: '/register-tenant',
      exact: true,
      strict: false,
    });
    return match !== null;
  }

  render() {
    const {
      t,
      user,
      platformIsFetching,
      languagesIsFetching,
      platform,
      error,
      darkMode,
      isAuthenticated,
    } = this.props;

    const headerHeight = document.querySelector('.header')?.offsetHeight || 50;

    const defaultStyle = `
            html {
                --primary-color: ${
                  platform
                    ? darkMode
                      ? platform.darkPrimaryColor || '#19D86B'
                      : platform.primaryColor || '#19D86B'
                    : '#19D86B'
                };
                --secondary-color: ${
                  platform
                    ? darkMode
                      ? platform.darkSecondaryColor || '#45BDAA'
                      : platform.secondaryColor || '#45BDAA'
                    : '#45BDAA'
                };
                --red: #d32525;
                --white: ${darkMode ? '#090909' : '#ffffff'};
                --black: ${darkMode ? '#ffffff' : '#090909'};

                --gray: ${darkMode ? '#5a5a5a' : '#c6c6c6'};
                --darkgray: ${darkMode ? '#e2e2e2' : '#5a5a5a'};
                --lightgray: ${darkMode ? '#353535' : '#e2e2e2'};
                --llightgray: ${darkMode ? shadeColor('#353535', -3) : shadeColor('#e2e2e2', 3)};  
                
                --container-margin-top: ${headerHeight}px;
                --background-image: ${
                  darkMode ? 'url(/images/lines-black.webp)' : 'url(/images/lines.webp)'
                }
            }
        `;

    const GlobalStyles = createGlobalStyle`${defaultStyle}`;
    const WidgetStyles = createGlobalStyle`
        ${defaultStyle}
        html, body, #transportial { 
            width: 100%;
            height: 100%;
            display: inline-block;
        }
    `;

    if (this.isWidget()) {
      return (
        <Suspense
          fallback={
            <>
              <Header {...this.props} />
              <div className="absolute-center center">
                <Loader />
              </div>
            </>
          }
        >
          <Route
            render={({ location }) => (
              <>
                <AlertStackContainer />
                <ComponentStackContainer />
                <PopOverStackContainer />

                <WidgetStyles />
                <Switch location={location}>
                  <Route exact path="/widget/:id/:publicKey" component={WidgetPublicContainer} />
                </Switch>
              </>
            )}
          />
        </Suspense>
      );
    }

    if (this.isPlatformRegistration()) {
      if (localStorage.getItem('platform-id')) return <Redirect to="/auth/login" />;

      return (
        <Route
          render={({ location }) => (
            <>
              <GlobalStyles />
              <Switch location={location}>
                <DefaultRoute
                  exact
                  path="/register-tenant"
                  noHeader
                  noFooter
                  component={PageShell(this.props, RegisterPlatformContainer)}
                />
              </Switch>
            </>
          )}
        />
      );
    }

    if (error) {
      return (
        <div className="absolute-center center">
          <GlobalStyles />
          <img style={{ height: '75px' }} src="/images/Transportial.png" />
          <h1 className="center">{t('initial.error')}</h1>
          <p className="center">{error}</p>
        </div>
      );
    }

    if (platformIsFetching || !platform || languagesIsFetching) {
      return (
        <div className="absolute-center center">
          <GlobalStyles />
          <img style={{ height: '75px' }} src="/images/Transportial.png" />
          <h1>{t('initial.loading')}</h1>
          <Loader />
        </div>
      );
    }

    if (platform.inWaitList) {
      return (
        <div className="absolute-center center">
          <GlobalStyles />
          <img style={{ height: '75px' }} src="/images/Transportial.png" />
          <p>{t('initial.waitlist')}</p>
        </div>
      );
    }

    const tourOptions = {
      useModalOverlay: true,
      defaultStepOptions: { showCancelLink: true },
      scrollTo: { behavior: 'smooth', block: 'center' },
    };

    return (
      <>
        <GlobalStyles />
        <Suspense
          fallback={
            <>
              <Header />
              <div className="absolute-center center">
                <Loader />
              </div>
            </>
          }
        >
          <ShepherdTour
            key={`${this.props.tutorial} ${this.props.tutorialErrorMessage}`}
            steps={
              isAuthenticated && user.type === 'developer'
                ? this.state.developerTutorialSteps
                : this.state.tutorialSteps
            }
            tourOptions={tourOptions}
          >
            <TourMethods>
              {(tourContext) => (
                <Tour
                  key={this.state.showWelcome}
                  {...this.props}
                  context={tourContext}
                  displayWelcome={this.state.showWelcome}
                  startTourFrom={this.state.showWelcome ? 'welcome' : this.props.tutorial}
                  onTourEnd={() => {
                    this.props.setTutorial(null);
                  }}
                />
              )}
            </TourMethods>
          </ShepherdTour>
          <Route
            render={({ location }) => (
              <Switch location={location}>
                {/** Default pages */}
                <Route
                  exact
                  path="/"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(
                    isAuthenticated
                      ? user.type === 'developer'
                        ? this.props
                        : this.props
                      : { ...this.props, noHeader: true, noFooter: true },
                    isAuthenticated
                      ? user.type === 'developer'
                        ? DeveloperDashboardContainer
                        : Dashboard
                      : Login
                  )}
                />

                {/* Portal login */}
                <Route path="/auth">
                  <AuthRoutes {...this.props} location={location} />
                </Route>

                {/* Portal developer */}
                <Route path="/developer">
                  <DeveloperRoutes {...this.props} location={location} />
                </Route>

                {/* Portal */}
                <Route path="/dashboard">
                  <DashboardRoutes {...this.props} location={location} />
                </Route>

                <Route path="/planBoard">
                  <PlanBoardRoutes {...this.props} location={location} />
                </Route>

                <Route path="/planner">
                  <PlannerRoutes {...this.props} location={location} />
                </Route>

                <PortalRoute
                  exact
                  standalone
                  noHeader
                  path="/watchTower"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, WatchtowerContainer)}
                />
                <PortalRoute
                  exact
                  path="/notifications"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, Notifications)}
                />

                <Route path="/messages">
                  <MessageRoutes {...this.props} location={location} />
                </Route>

                <PortalRoute
                  exact
                  path="/platforms"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, PlatformsContainer)}
                />
                <PortalRoute
                  exact
                  path="/vehicles"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, VehiclesContainer)}
                />
                <PortalRoute
                  exact
                  path="/transportEquipments"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, TransportEquipmentsContainer)}
                />
                <PortalRoute
                  exact
                  path="/vehicleGroups"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, VehicleGroupsContainer)}
                />
                <PortalRoute
                  exact
                  path="/fleets"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, FleetsContainer)}
                />
                {/* TransportOrders */}
                <Route path="/transportOrders">
                  <TransportOrderRoutes {...this.props} location={location} />
                </Route>

                {/* Trips */}
                <Route path="/trips">
                  <TripRoutes {...this.props} location={location} />
                </Route>

                {/* Quotes */}
                <Route path="/quotes">
                  <QuoteRoutes {...this.props} location={location} />
                </Route>

                <PortalRoute
                  exact
                  path="/businesses"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, BusinessesContainer)}
                />
                <PortalRoute
                  exact
                  path="/locations"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, LocationsContainer)}
                />
                <PortalRoute
                  exact
                  path="/sensors"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, SensorsContainer)}
                />
                <PortalRoute
                  exact
                  path="/messageTemplates"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, MessageTemplateContainer)}
                />
                <PortalRoute
                  exact
                  path="/contracts"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, ContractsContainer)}
                />
                <PortalRoute
                  exact
                  path="/terminals"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, TerminalsContainer)}
                />
                <Route path="/integrations">
                  <IntegrationRoutes {...this.props} location={location} />
                </Route>
                <PortalRoute
                  exact
                  path="/workflows"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, WorkflowsContainer)}
                />
                <PortalRoute
                  exact
                  path="/widgets"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, WidgetsContainer)}
                />
                <PortalRoute
                  exact
                  path="/chauffeurs"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, ChauffeursContainer)}
                />
                <PortalRoute
                  exact
                  path="/chauffeurs/planner"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(
                    { ...this.props, standalone: true },
                    ChauffeursPlannerContainer
                  )}
                />
                <PortalRoute
                  exact
                  path="/capacity/contracts"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, CapacityContractsContainer)}
                />
                <PortalRoute
                  exact
                  path="/administrations"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, AdministrationsContainer)}
                />
                <PortalRoute
                  exact
                  path="/pricings"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, PricingsContainer)}
                />
                <PortalRoute
                  exact
                  path="/pricings/variable/index"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, PricingVariableIndexesContainer)}
                />

                <PortalRoute
                  exact
                  path="/scheduleRoutes"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, ScheduleRoutesContainer)}
                />

                <PortalRoute
                  exact
                  path="/boardComputers"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, BoardComputersContainer)}
                />

                {/* Support */}
                <Route path="/support">
                  <SupportRoutes {...this.props} location={location} />
                </Route>

                <PortalRoute
                  exact
                  path="/teams"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, TeamsContainer)}
                />
                <PortalRoute
                  exact
                  path="/users"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, UsersContainer)}
                />
                <PortalRoute
                  exact
                  path="/roles"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, RolesContainer)}
                />
                <Route path="/plans">
                  <PlanRoutes {...this.props} location={location} />
                </Route>

                <PortalRoute
                  exact
                  path="/settings"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, Settings)}
                />
                <PortalRoute
                  exact
                  path="/settings/:tab"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, Settings)}
                />

                {/* PDFs */}
                <Route
                  exact
                  path="/transportOrder/:id/pdf"
                  isAuthenticated={isAuthenticated}
                  render={(props) => <TransportOrderPDFContainer {...props} />}
                />
                <Route
                  exact
                  path="/invoice/:id/pdf"
                  isAuthenticated={isAuthenticated}
                  render={(props) => <InvoicePDFContainer {...props} />}
                />
                <Route
                  exact
                  path="/quote/:id/pdf"
                  isAuthenticated={isAuthenticated}
                  render={(props) => <QuotePDFContainer {...props} />}
                />

                {/* Fallbacks */}
                <AlwaysAccessibleRoute
                  path="/*"
                  isAuthenticated={isAuthenticated}
                  component={PageShell(this.props, NotFound)}
                />
              </Switch>
            )}
          />
        </Suspense>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    platformIsFetching: state.platform.platformIsFetching,
    platform: state.platform.platform,
    tags: state.platform.tags,
    languagesIsFetching: state.platform.languagesIsFetching,
    languages: state.platform.languages,
    error: state.platform.error,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    settings: state.settings.settings,
    darkMode: state.settings.darkMode,
    tutorial: state.settings.tutorial,
    tutorialErrorMessage: state.settings.tutorialErrorMessage,
    retryPaymentConfig: state.settings.retryPaymentConfig,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlatform: () => dispatch(platformActions.getPlatform()),
    getPlatformById: (id) => dispatch(platformActions.getPlatformById(id)),

    getBilling: (platformId) => dispatch(platformActions.getBilling(platformId)),

    getSettings: () => dispatch(settingsActions.getSettings()),
    getTaxRates: () => dispatch(generalActions.getTaxRates()),
    getAttributes: () => dispatch(generalActions.getAttributes()),
    getPricingCategories: () => dispatch(generalActions.getPricingCategories()),

    loginSuccess: (result) => dispatch(authActions.loginSuccess(result)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),

    setTutorial: (tutorial) => dispatch(settingsActions.setTutorial(tutorial)),
    changeValue: (name, value) => dispatch(settingsActions.changeValue(name, value)),
    updateSettings: (t, settings) => dispatch(settingsActions.updateSettings(t, settings)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(IndexContainer));
