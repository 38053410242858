import { i18n } from '@/index';

import { defaultTimeDateFormat } from '@utils/dateUtils';

export const updateTripInList = (state, newTrip) => {
  return [...state.trips].map((trip) => {
    if (trip.id === newTrip.id) {
      return { ...newTrip };
    } else return trip;
  });
};

export const updateTripActionInList = (state, newTrip, newAction) => {
  return [...state.trips].map((trip) => {
    if (trip.id === newTrip.id) {
      return { ...newTrip };
    } else return trip;
  });
};

export const getTripStatus = (trip) => {
  let status = 'planBoard__status';

  if (trip) {
    switch (trip.planStatus) {
      case 'ok':
      default:
        status += '--ok';
        break;
      case 'too_late':
        status += '--too-late';
        break;
      case 'too_early':
        status += '--too-early';
        break;
      case 'off_route':
        status += '--off-route';
        break;
    }
  } else {
    status += '--empty';
  }

  return status;
};

export const renderEta = (trip, dateFormat) => {
  if (trip?.lastAction?.eta) return defaultTimeDateFormat(trip?.lastAction?.eta, dateFormat);

  if (trip?.route?.actions?.length >= 1) {
    const moveActions = trip.route.actions
      .map((association) => association.entity)
      .filter((entity) => entity.type === 'move')
      .sort((a, b) => a.sequenceNr - b.sequenceNr);

    const lastMoveAction = moveActions[moveActions.length - 1];
    return lastMoveAction?.eta
      ? defaultTimeDateFormat(lastMoveAction.eta, dateFormat)
      : i18n.t('planBoard.noETAFound');
  }
  return i18n.t('planBoard.noETAFound');
};

export const renderNextEta = (trip, dateFormat) => {
  if (trip?.nextAction?.eta) return defaultTimeDateFormat(trip?.nextAction?.eta, dateFormat);

  if (trip?.route?.actions?.length >= 1) {
    const moveActions = trip.route.actions
      .map((association) => association.entity)
      .filter((entity) => entity.type === 'move' && entity.endTime == null)
      .sort((a, b) => a.sequenceNr - b.sequenceNr);

    const lastMoveAction = moveActions[0];
    return lastMoveAction?.eta
      ? defaultTimeDateFormat(lastMoveAction.eta, dateFormat)
      : i18n.t('planBoard.noETAFound');
  }
  return i18n.t('planBoard.noETAFound');
};
