import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateTransportEquipmentContainer from '@containers/goods/crud/CreateTransportEquipmentContainer';

import { search } from '@api/transportEquipmentApi';

import * as navigationActions from '@actions/navigationActions';

let searchTimeout = null;

const transformTransportEquipmentToValue = (transportEquipment) => ({
  label: transportEquipment.name,
  value: transportEquipment.id,
  transportEquipment: transportEquipment,
});

class TransportEquipmentInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transportEquipment: this.props.transportEquipment
        ? transformTransportEquipmentToValue(this.props.transportEquipment)
        : null,
      transportEquipments: [],
    };
  }

  componentDidMount() {
    search({}, 0, 40).then((response) => {
      this.setState({
        transportEquipments: response.transportEquipments,
      });
    });
  }

  onChange = (newTransportEquipment) => {
    this.setState({
      transportEquipment: newTransportEquipment,
    });
    const { onChange } = this.props;
    onChange && onChange(newTransportEquipment ? newTransportEquipment.transportEquipment : null);
  };

  render() {
    const { t, isFetchingTransportEquipments, addToStack } = this.props;
    const { transportEquipment, transportEquipments } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          placeholder={`${t('form.label.searchTransportEquipment')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          defaultOptions={transportEquipments.map((transportEquipment) =>
            transformTransportEquipmentToValue(transportEquipment)
          )}
          isLoading={isFetchingTransportEquipments}
          isDisabled={isFetchingTransportEquipments}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              search({ query: inputValue }, 0, 40).then((response) => {
                callback(
                  response.transportEquipments.map((transportEquipment) =>
                    transformTransportEquipmentToValue(transportEquipment)
                  )
                );
              });
            }, 800);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('transportEquipment.new'),
              component: (
                <CreateTransportEquipmentContainer
                  targetName={inputValue}
                  callback={(newTransportEquipment) => {
                    this.onChange(transformTransportEquipmentToValue(newTransportEquipment));
                  }}
                />
              ),
            });
          }}
          value={transportEquipment}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(TransportEquipmentInput));
