import React, { Component } from 'react';

import { formatBytes } from '@utils/fileUtils';
import { truncate } from '@utils/stringUtils';

export default class FilePreview extends Component {
  static defaultProps = {
    file: null,
    information: true,
  };
  render() {
    const { file, information } = this.props;
    var src = file.url;

    switch (file.type) {
      case 'PDF':
        src = '/images/files/pdf.png';
        break;
      case 'WORD':
        src = '/images/files/word.png';
        break;
      case 'EXCEL':
        src = '/images/files/excel.png';
        break;
      default:
        src = file.url;
    }

    return (
      <div className={`file-preview${information ? ' with-information' : ''}`}>
        <img src={src} alt={file.originalName || ''} />
        {information && (
          <div className="file-preview__info">
            <div className="file-preview__name">
              {truncate(file.originalName || '', 6)}
            </div>
            <div className="file-preview__size">{formatBytes(file.size)}</div>
          </div>
        )}
      </div>
    );
  }
}
