import React, { Component } from 'react';

import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import moment from 'moment';

import LocationInput from '@components/location/crud/LocationInput';
import PricingModulesInput from '@components/pricing/crud/PricingModulesInput';
import TransportModesSelector from '@components/trip/TransportModesSelector';

import FormInput from '@uiinputs/FormInput';

import Association from '@models/general/Association';
import PricingModule from '@models/pricing/PricingModule';

import { momentToDuration } from '@utils/dateUtils';
import { activateInputs, deactivateInputs } from '@utils/formUtils';

export default class ScheduleRouteForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      timeSlots: this.props.formScheduleRoute?.timeSlots
        ? this.props.formScheduleRoute?.timeSlots
        : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(
            (day) => ({
              weekDay: day,
              nonce: Math.random().toString(36).substring(7),
            })
          ),
      stops: this.props.formScheduleRoute?.stops
        ? this.props.formScheduleRoute?.stops
        : [
            new Association('inline', {
              type: 'stop',
              location: new Association('inline'),
              nonce: Math.random().toString(36).substring(7),
            }),
            new Association('inline', {
              type: 'stop',
              location: new Association('inline'),
              nonce: Math.random().toString(36).substring(7),
            }),
          ],
    };
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmit, formScheduleRoute, setHasChanged } = this.props;
    const { timeSlots, stops } = this.state;

    const newScheduleRoute = { ...formScheduleRoute };
    newScheduleRoute.timeSlots = [...timeSlots];
    newScheduleRoute.stops = stops;

    onSubmit && onSubmit(newScheduleRoute);
    setHasChanged(false);
  }

  render() {
    const { t, onChange, formScheduleRoute, setHasChanged } = this.props;
    const { timeSlots, stops } = this.state;

    const dayOptions = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ].map((day) => ({
      label: t(`day.${day}`),
      value: day,
    }));

    return (
      <div
        className="step-form"
        key={`${formScheduleRoute.id}-${formScheduleRoute.versionNumber}-${formScheduleRoute.nonce}`}
      >
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div className="input-group">
              <div className="input-group no-margin-top">
                <FormInput
                  type="text"
                  setHasChanged={setHasChanged}
                  label={`${t('form.label.name')}*`}
                  value={formScheduleRoute.name}
                  onChange={(event) => {
                    const newScheduleRoute = { ...formScheduleRoute };
                    newScheduleRoute.name = event.target.value;

                    onChange('formScheduleRoute', newScheduleRoute, event);
                  }}
                />
              </div>
              {this.validator.message(t('form.label.name'), formScheduleRoute.name, 'required')}
            </div>

            <div className="input-group">
              <div className="input-group no-margin-top">
                <TransportModesSelector
                  value={formScheduleRoute.transportModes}
                  placeholder={t('form.label.selectTransportModes')}
                  onChange={(newTransportModes) => {
                    const newScheduleRoute = { ...formScheduleRoute };
                    newScheduleRoute.transportModes = newTransportModes?.map((e) => e.value) || [];

                    onChange('formScheduleRoute', newScheduleRoute, event);
                  }}
                />
              </div>
            </div>
            {stops.map((stop, index) => {
              const newStops = [...stops];
              const newStop = { ...stop.entity };

              return (
                <div
                  className="input-group flex-container"
                  key={`stop-${stop.entity.nonce}-${stop.entity.id}`}
                >
                  <div className="input-group one no-margin-top">
                    <div className="input-group no-margin-top">
                      <LocationInput
                        location={stop?.entity?.location?.entity}
                        placeholder={index > 0 ? t('form.label.to') : t('form.label.from')}
                        searchParameters={{ mustBeTerminal: true }}
                        onChange={(newLocation) => {
                          newStop.location = new Association('inline', newLocation);
                          newStops[index] = new Association('inline', newStop);

                          this.setState({
                            stops: newStops,
                          });
                        }}
                      />
                    </div>
                  </div>
                  {index < stops.length - 1 && (
                    <div className="input-group one no-margin-top">
                      <div className="input-group no-margin-top">
                        <input
                          pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}$"
                          placeholder="HH:mm:ss"
                          value={momentToDuration(
                            moment.duration(stop.entity?.trip?.entity?.route?.time || 0, 'seconds')
                          )}
                          onChange={(e) => {
                            e.preventDefault();

                            newStop.trip = new Association('inline', {
                              route: {
                                time: moment.duration(e.target.value).asSeconds(),
                              },
                            });
                            newStops[index] = new Association('inline', newStop);

                            this.setState({
                              stops: newStops,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {index > 1 && (
                    <div className="input-group fifth no-margin-top">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();

                          this.setState({
                            stops: newStops.filter((_, i) => i !== index),
                          });
                        }}
                      >
                        -
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
            <div className={`input-group left`}>
              <button
                type="button"
                onClick={(e) => {
                  this.setState({
                    stops: [
                      ...stops,
                      new Association('inline', {
                        type: 'stop',
                        location: new Association('inline'),
                        trip: {},
                        nonce: Math.random().toString(36).substring(7),
                      }),
                    ],
                  });
                }}
              >
                {t('form.label.addLocation')}
              </button>
            </div>
            <div className="input-group more">
              <h3>{t('form.label.timeSlots')}</h3>

              {timeSlots.map((timeSlot, index) => {
                const newTimeSlots = [...timeSlots];
                const newTimeSlot = { ...timeSlot };

                return (
                  <div
                    className="input-group flex-container"
                    key={`timeSlot-${timeSlot.nonce}-${timeSlot.id}`}
                  >
                    <div className="input-group no-margin-top one">
                      <Select
                        value={dayOptions.find((dayOption) => dayOption.value === timeSlot.weekDay)}
                        options={dayOptions}
                        onChange={(e) => {
                          newTimeSlot.weekDay = e.value;
                          newTimeSlots[index] = newTimeSlot;

                          this.setState({
                            timeSlots: newTimeSlots,
                          });
                        }}
                      />
                    </div>
                    <div className="input-group no-margin-top one">
                      <input
                        type="time"
                        value={moment(timeSlot?.startTime)?.format('HH:mm')}
                        onChange={(e) => {
                          newTimeSlot.startTime = moment(e.target.value, 'HH:mm');
                          newTimeSlots[index] = newTimeSlot;

                          this.setState({
                            timeSlots: newTimeSlots,
                          });
                        }}
                      />
                      <label>{t(`form.label.startTime`)}</label>
                    </div>
                    <div className="input-group fifth no-margin-top">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();

                          this.setState({
                            timeSlots: newTimeSlots.filter((_, i) => i !== index),
                          });
                        }}
                      >
                        -
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={`input-group left`}>
              <button
                type="button"
                onClick={(e) => {
                  this.setState({
                    timeSlots: [...timeSlots, { nonce: Math.random().toString(36).substring(7) }],
                  });
                }}
              >
                {t('form.label.addField')}
              </button>
            </div>
            <div className="input-group">
              <h3>{t('form.label.modules')}</h3>
              <PricingModulesInput
                {...this.props}
                key={formScheduleRoute.pricingModules}
                entityType={formScheduleRoute.targetEntityType}
                modules={
                  formScheduleRoute.pricingModules
                    ? [...formScheduleRoute.pricingModules]
                    : [new PricingModule()]
                }
                onChange={(newModules) => {
                  const newScheduleRoute = { ...formScheduleRoute };
                  newScheduleRoute.pricingModules = newModules;

                  onChange('formScheduleRoute', newScheduleRoute, event);
                }}
              />
            </div>
            <div className="input-group">
              <div className="input-group more right">
                <input
                  type="submit"
                  disabled={!this.validator.allValid()}
                  onClick={(e) => this.handleSubmit(e)}
                  value={t('form.save')}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
