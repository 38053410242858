import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';



import { useDispatch, useSelector } from 'react-redux';



import { messaging } from '@utils/init-fcm';



import * as userApi from './api/userApi';
import * as socketActions from './state/actions/socketActions';
import { socketConstants } from './state/constants';


const socket = io(process.env.REACT_APP_SOCKET_URL, {
  autoConnect: false,
  reconnecting: true,
});
function SocketWrapper({ Page }) {
  const dispatch = useDispatch();

  const [isConnected, setIsConnected] = useState(socket.connected);

  const bearer = useSelector((state) => state.auth.bearer);
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (!isConnected && isAuthenticated && user) {
      socket.auth = { token: bearer };
      socket.connect();
    }
  }, [bearer]);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      console.log('has been connected', socket);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onDataEvent(data) {
      const json = JSON.parse(data || '{}');

      switch (json.type) {
        case socketConstants.NEW_CHAT_MESSAGE:
          dispatch(socketActions.newChatMessage(json));
          break;
        case socketConstants.NEW_MAIL_MESSAGES:
          dispatch(socketActions.newMailMessages(json));
          break;
        case socketConstants.NEW_EXTENDED_VEHICLES:
          dispatch(socketActions.newExtendedVehicles(json));
          break;
        case socketConstants.NEW_EXTENDED_TRANSPORT_ORDERS:
          dispatch(socketActions.newExtendedTransportOrders(json));
          break;
        case socketConstants.NEW_TRIP:
          dispatch(socketActions.newTrip(json));
        case socketConstants.NEW_TRIP:
          dispatch(socketActions.newTrip(json));
        default:
          break;
      }
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('data', onDataEvent);

    if (messaging && isAuthenticated && bearer) {
      messaging
        .requestPermission()
        .then(async (e) => {
          const token = await messaging.getToken();
          userApi.fcm(token);
        })
        .catch(async (err) => {
          console.log('Unable to get permission to notify.', err);
        });
      navigator.serviceWorker.addEventListener('message', (message) => console.log(message));
    }

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('message', onDataEvent);

      navigator.serviceWorker.removeEventListener('message', null);
    };
  }, []);

  return <Page />;
}

export default SocketWrapper;