import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import CloseButton from '@uicomponents/CloseButton';

import { activateInputs, deactivateInputs } from '@utils/formUtils';
import FormInput from '@uiinputs/FormInput';

class ExternalAttributesInput extends Component {
  state = {
    externalAttributes: this.props.externalAttributes
      ? Object.keys(this.props.externalAttributes).map(
          (externalAttributeKey) => ({
            key: externalAttributeKey,
            value: this.props.externalAttributes[externalAttributeKey],
          })
        )
      : this.props.defaultEnabled
      ? [{}]
      : [],
  };

  componentDidMount = () => {
    const { externalAttributes } = this.state;
    const { required } = this.props;

    activateInputs();

    let newExternalAttributes = [];

    for (let i = 0; i < externalAttributes.length; i++) {
      const externalAttribute = externalAttributes[i];
      if (externalAttribute.value || required.includes(externalAttribute.key)) {
        newExternalAttributes.push(externalAttribute);
      }
    }

    [...required].forEach((requiredKey) => {
      if (
        !externalAttributes
          .map((externalAttribute) => externalAttribute.key)
          .includes(requiredKey)
      ) {
        newExternalAttributes.push({ key: requiredKey, value: '' });
      }
    });

    this.setState({
      externalAttributes: newExternalAttributes,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { externalAttributes } = prevState;
    const { required } = this.props;

    activateInputs();

    // if there are new requirements
    if (required !== prevProps.required) {
      let newExternalAttributes = [];

      for (let i = 0; i < externalAttributes.length; i++) {
        const externalAttribute = externalAttributes[i];
        if (
          externalAttribute.value ||
          required.includes(externalAttribute.key)
        ) {
          newExternalAttributes.push(externalAttribute);
        }
      }

      [...required].forEach((requiredKey) => {
        if (
          !externalAttributes
            .map((externalAttribute) => externalAttribute.key)
            .includes(requiredKey)
        ) {
          newExternalAttributes.push({ key: requiredKey, value: '' });
        }
      });

      this.setState({
        externalAttributes: newExternalAttributes,
      });
    }
  };

  componentWillUnmount = () => {
    deactivateInputs();
  };

  onChange = (externalAttributes) => {
    const { onChange } = this.props;
    onChange &&
      onChange(
        externalAttributes.reduce((result, item) => {
          result[item.key] = item.value;
          return result;
        }, {})
      );
  };

  addNewExternalAttribute = () => {
    const { externalAttributes } = this.state;
    this.setState({
      externalAttributes: [...externalAttributes, {}],
    });
  };

  updateExternalAttribute = (newExternalAttribute, index) => {
    const { externalAttributes } = this.state;
    const newExternalAttributes = [...externalAttributes];

    newExternalAttributes[index] = newExternalAttribute;

    this.setState({
      externalAttributes: newExternalAttributes,
    });

    this.onChange(newExternalAttributes);
  };

  render() {
    const { t, required, disabledAdd } = this.props;
    const { externalAttributes } = this.state;

    return (
      <>
        {externalAttributes.map((externalAttribute, index) => (
          <div
            className={
              required.includes(externalAttribute.key) ? null : 'list-sector'
            }
            key={`input-externalAttribute-${index}`}
          >
            <div className="list-actions">
              <div></div>
              {required.includes(externalAttribute.key) ? null : (
                <CloseButton
                  onClick={() => {
                    const newExternalAttributes = [...externalAttributes];
                    newExternalAttributes.splice(index, 1);

                    this.setState({
                      externalAttributes: newExternalAttributes,
                    });
                    this.onChange(newExternalAttributes);
                  }}
                />
              )}
            </div>

            <div className="input-group no-margin-top">
              <div className="input-group third no-margin-top">
                <div className="input-group no-margin-top ">
                  <FormInput
                    type="text"
                    label={t('form.label.key')}
                    value={externalAttribute.key}
                    disabled={required.includes(externalAttribute.key)}
                    onChange={(event) => {
                      const newExternalAttribute = { ...externalAttribute };
                      newExternalAttribute.key = event.target.value;

                      this.updateExternalAttribute(newExternalAttribute, index);
                    }}
                  />
                </div>
              </div>
              <div className="input-group twothird no-margin-top">
                <div className="input-group no-margin-top">
                  <FormInput
                    type="text"
                    label={t('form.label.value')}
                    value={externalAttribute.value}
                    onChange={(event) => {
                      const newExternalAttribute = { ...externalAttribute };
                      newExternalAttribute.value = event.target.value;

                      this.updateExternalAttribute(newExternalAttribute, index);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
        {!disabledAdd && (
          <div
            className={`input-group left${
              externalAttributes.length > 0 ? '' : ' no-margin-top'
            }`}
          >
            <button
              type="button"
              onClick={(e) => this.addNewExternalAttribute(e)}
            >
              {t('form.label.addExternalAttribute')}
            </button>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation('translation')(ExternalAttributesInput);
