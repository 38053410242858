import {
  authConstants,
  fieldConstants,
  generalConstants,
  platformConstants,
  widgetConstants,
} from '../constants';

const initialState = {
  webSocket: null,
  error: null,
  tags: {},

  platformIsFetching: false,
  platform: null,

  isFetchingBilling: false,
  billing: {},

  isCreatingPlatformPaymentIntent: false,
  paymentIntentId: null,
  platformBillingId: null,
  platformClientSecret: null,

  isFetchingPlatform: false,
  formPlatform: null,

  billingInvoices: [],
  billingInvoicesLoaded: 0,
  billingInvoicesLoadedFrom: 0,
  billingInvoicesTotalResults: 0,
  isFetchingBillingInvoices: false,

  isFetchingBillingUsageEvents: false,
  billingUsageEventsTotalResults: 0,
  billingUsageEventsLoadedFrom: 0,
  billingUsageEventsLoaded: 0,
  billingUsageEvents: [],

  billingEstimate: 0,

  isFetchingPlatforms: false,
  isFetchingPlatformsFrom: 0,
  platforms: [],

  languagesIsFetching: false,
  languages: [],

  isFetchingCategories: false,
  categories: [],

  isFetchingFields: false,
  fields: [],

  isFetchingEntityStructure: false,
  entityStructure: {},

  isFetchingCurrencies: false,
  currencies: [],

  isFetchingPermissions: false,
  permissions: [],

  isFetchingTypes: false,
  types: [],

  isFetchingIntegrationManualTypes: false,
  integrationManualTypes: [],
  triggerEvents: [],
  entityTypes: [],
  pricingEntityTypes: [],
  pricingTriggerTypes: [],
  pricingModuleSubUnits: [],

  isFetchingCategory: false,
  category: null,
  formCategory: null,

  isFetchingIndustries: false,
  industries: [],
  isFetchingIndustry: false,
  industry: null,
  formIndustry: null,

  isFetchingContact: false,
  contactMessage: null,

  pricingSettings: {},
  routingSettings: {},
  planningSettings: {},
  translationSettings: {},
};

export default function platform(state = initialState, action) {
  switch (action.type) {
    case platformConstants.SET_PLATFORM:
      return {
        ...state,
        platformIsFetching: false,
        platform: action.platform,
        tags: {
          title: action.platform.name,
          description: action.platform.description,
          keywords: action.platform.keywords,
          favicon: action?.platform?.favicon?.url || '/images/Transportial.png',
        },
      };
    case platformConstants.PLATFORM_CHANGE_VALUE:
      return { ...state, error: null, [action.name]: action.value };

    case platformConstants.GET_PLATFORM_STARTED:
      return {
        ...state,
        platformIsFetching: true,
        error: null,
        platform: action.id ? { id: action.id } : null,
      };
    case platformConstants.GET_PLATFORM_FAILURE:
      return {
        ...state,
        platformIsFetching: false,
        error: 'Could not find platform',
      };
    case platformConstants.GET_PLATFORM_SUCCESS:
      return {
        ...state,
        platformIsFetching: false,
        platform: action.platform,
        formPlatform: action.platform,
        languages: action.languages || [],
        categories: action.categories || [],
        industries: action.industries || [],
        currencies: action.currencies || [],
        integrationManualTypes: action.integrationManualTypes || [],
        triggerEvents: action.triggerEvents || [],
        entityTypes: action.entityTypes || [],
        requestMethods: action.requestMethods || [],
        dataTypes: action.dataTypes || [],
        fields: action.fields || [],
        routingSettings: action.routingSettings || {},
        translationSettings: action.translationSettings || {},
        pricingSettings: action.pricingSettings || {},
        planningSettings: action.planningSettings || {},
        pricingTriggerTypes: action.pricingTriggerTypes || [],
        pricingModuleTypes: action.pricingModuleTypes || [],
        pricingModuleSubUnits: action.pricingModuleSubUnits || [],
        pricingEntityTypes: action.pricingEntityTypes || [],
        workflowTypes: action.workflowTypes || [],
        workflowChannels: action.workflowChannels || [],
        integrationRequiredIdentifiersPerEntity:
          action.integrationRequiredIdentifiersPerEntity || [],
        types: action.types || [],

        tags: {
          title: action.platform.name,
          description: action.platform.description,
          keywords: action.platform.keywords,
          favicon: action?.platform?.favicon?.url,
        },
      };
    case authConstants.LOGIN_METHOD_SUCCESS:
      return {
        ...state,
        platformIsFetching: false,
        platform: action.platform || state.platform,
        tags: {
          title: action.platform?.name,
          description: action.platform?.description,
          keywords: action.platform?.keywords,
          favicon: action?.platform?.favicon?.url,
        },
      };

    case platformConstants.GET_PLATFORMS_STARTED:
      return { ...state, isFetchingPlatforms: true };
    case platformConstants.GET_PLATFORMS_FAILURE:
      return {
        ...state,
        isFetchingPlatforms: false,
        platforms: [],
      };
    case platformConstants.GET_PLATFORMS_SUCCESS:
      return {
        ...state,
        isFetchingPlatforms: false,
        platforms: action.from > 0 ? [...state.platforms, ...action.platforms] : action.platforms,
        platformsLoaded:
          action.from > 0
            ? state.platforms.length + action.platforms.length
            : action.platforms.length,
        platformsTotalResults: action.totalResults || 0,
      };

    case platformConstants.CREATE_PLATFORM_STARTED:
      return { ...state, isFetchingPlatform: true };
    case platformConstants.CREATE_PLATFORM_FAILURE:
      return {
        ...state,
        isFetchingPlatform: false,
        message: action.message || '',
      };
    case platformConstants.CREATE_PLATFORM_SUCCESS:
      return {
        ...state,
        isFetchingPlatform: false,
        formPlatform: action.platform,
        platforms: [...state.platforms, action.platform],
      };

    case platformConstants.CREATE_PAYMENT_INTENT_STARTED:
      return { ...state, isCreatingPlatformPaymentIntent: true };
    case platformConstants.CREATE_PAYMENT_INTENT_FAILURE:
      return {
        ...state,
        isCreatingPlatformPaymentIntent: false,
        message: action.message || '',
        error: action.message || '',
      };
    case platformConstants.CREATE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        isCreatingPlatformPaymentIntent: false,
        paymentIntentId: action.paymentIntentId,
        platformBillingId: action.billingId,
        platformClientSecret: action.clientSecret,
      };

    case platformConstants.UPDATE_PLATFORM_STARTED:
      return { ...state, isFetchingPlatform: true };
    case platformConstants.UPDATE_PLATFORM_FAILURE:
      return {
        ...state,
        isFetchingPlatform: false,
        message: action.message || '',
      };
    case platformConstants.UPDATE_PLATFORM_SUCCESS:
      return {
        ...state,
        isFetchingPlatform: false,
        formPlatform: action.platform,
        platforms: state.platforms.map((platform) =>
          action.platform.id === platform.id ? action.platform : platform
        ),
      };

    case platformConstants.GET_BILLING_INVOICES_STARTED:
      return {
        ...state,
        isFetchingBillingInvoices: true,
        billingInvoicesLoadedFrom: action.from || 0,
      };
    case platformConstants.GET_BILLING_INVOICES_FAILURE:
      return {
        ...state,
        billingInvoices: [],
        isFetchingBillingInvoices: false,
        // error: action.message || 'Could not find billing invoices',
      };
    case platformConstants.GET_BILLING_INVOICES_SUCCESS:
      return {
        ...state,
        isFetchingBillingInvoices: false,
        billingInvoices:
          action.from > 0 ? [...state.billingInvoices, ...action.invoices] : action.invoices,
        billingInvoicesLoaded:
          action.from > 0
            ? state.billingInvoices.length + action.invoices.length
            : action.invoices.length,
        billingInvoicesTotalResults: action.totalResults || 0,
      };

    case platformConstants.GET_BILLING_STARTED:
      return { ...state, isFetchingBilling: true };
    case platformConstants.GET_BILLING_FAILURE:
      return {
        ...state,
        isFetchingBilling: false,
        billing: {},
      };
    case platformConstants.GET_BILLING_SUCCESS:
      return {
        ...state,
        isFetchingBilling: false,
        billing: action.billing,
      };

    case platformConstants.GET_BILLING_ESTIMATE_STARTED:
      return { ...state };
    case platformConstants.GET_BILLING_ESTIMATE_FAILURE:
      return {
        ...state,
        billingEstimate: 0,
      };
    case platformConstants.GET_BILLING_ESTIMATE_SUCCESS:
      return {
        ...state,
        billingEstimate: action.estimate.amount,
      };

    case platformConstants.GET_LANGUAGES_STARTED:
      return { ...state, languagesIsFetching: true };
    case platformConstants.GET_LANGUAGES_FAILURE:
      return {
        ...state,
        languagesIsFetching: false,
        error: action.message || 'Could not find languages',
      };
    case platformConstants.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languagesIsFetching: false,
        languages: action.languages,
      };

    case platformConstants.GET_INDUSTRIES_STARTED:
      return { ...state, isFetchingIndustries: true };
    case platformConstants.GET_INDUSTRIES_FAILURE:
      return {
        ...state,
        isFetchingIndustries: false,
        error: action.message || 'Could not find industries',
      };
    case platformConstants.GET_INDUSTRIES_SUCCESS:
      return {
        ...state,
        isFetchingIndustries: false,
        industries: action.industries || [],
      };

    case platformConstants.CONTACT_STARTED:
      return { ...state, isFetchingContact: true, contactMessage: null };
    case platformConstants.CONTACT_FAILURE:
      return {
        ...state,
        isFetchingContact: false,
        contactMessage: action.message,
      };
    case platformConstants.CONTACT_SUCCESS:
      return {
        ...state,
        isFetchingContact: false,
        contactMessage: action.message,
      };

    case platformConstants.GET_CURRENCIES_STARTED:
      return { ...state, isFetchingCurrencies: true };
    case platformConstants.GET_CURRENCIES_FAILURE:
      return { ...state, isFetchingCurrencies: false };
    case platformConstants.GET_CURRENCIES_SUCCESS:
      state = {
        ...state,
        isFetchingCurrencies: false,
        currencies: action.currencies,
      };
      return state;

    case platformConstants.GET_PERMISSIONS_STARTED:
      return { ...state, isFetchingPermissions: true };
    case platformConstants.GET_PERMISSIONS_FAILURE:
      return {
        ...state,
        isFetchingPermissions: false,
        permissions: [],
      };
    case platformConstants.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isFetchingPermissions: false,
        permissions:
          action.from > 0 ? [...state.permissions, ...action.permissions] : action.permissions,
        permissionsLoaded:
          action.from > 0
            ? state.permissions.length + action.permissions.length
            : action.permissions.length,
        permissionsTotalResults: action.totalResults || 0,
      };

    case platformConstants.SET_WEBSOCKET_CONNECTION:
      return { ...state, webSocket: action.webSocket };

    case widgetConstants.GET_PUBLIC_WIDGET_SUCCESS:
      return action.widget.platforms.length > 0
        ? { ...state, platform: action.widget.platforms[0] }
        : state;

    case fieldConstants.GET_FIELD_STARTED:
      return { ...state, isFetchingFields: true };
    case fieldConstants.GET_FIELD_FAILURE:
      return { ...state, isFetchingFields: false };
    case fieldConstants.GET_FIELD_SUCCESS:
      state = {
        ...state,
        isFetchingFields: false,
        fields: action.fields,
      };
      return state;

    case platformConstants.GET_BILLING_USAGE_STARTED:
      return {
        ...state,
        isFetchingBillingUsageEvents: true,
        billingUsageEventsLoadedFrom: action.from || 0,
      };
    case platformConstants.GET_BILLING_USAGE_FAILURE:
      return {
        ...state,
        billingUsageEvents: [],
        isFetchingBillingUsageEvents: false,
      };
    case platformConstants.GET_BILLING_USAGE_SUCCESS:
      return {
        ...state,
        isFetchingBillingUsageEvents: false,
        billingUsageEvents:
          action.from > 0 ? [...state.billingUsageEvents, ...action.events] : action.events,
        billingUsageEventsLoaded:
          action.from > 0
            ? state.billingUsageEvents.length + action.events.length
            : action.events.length,
        billingUsageEventsTotalResults: action.totalResults || 0,
      };

    case generalConstants.GET_ENTITY_STRUCTURE_STARTED:
      return { ...state, isFetchingEntityStructure: true };
    case generalConstants.GET_ENTITY_STRUCTURE_SUCCESS:
      return { ...state, isFetchingEntityStructure: false, entityStructure: action.value };
    case generalConstants.GET_ENTITY_STRUCTURE_FAILURE:
      return { ...state, isFetchingEntityStructure: false };

    default:
      return state;
  }
}
