import * as workflowApi from '@api/workflowApi';

import { workflowConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: workflowConstants.WORKFLOW_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching workflow
 */
export const startGetWorkflow = () => ({
  type: workflowConstants.GET_WORKFLOW_STARTED,
});

export const getWorkflowSuccess = (result) => ({
  type: workflowConstants.GET_WORKFLOW_SUCCESS,
  ...result,
});

export const getWorkflowFailure = (error) => ({
  type: workflowConstants.GET_WORKFLOW_FAILURE,
  ...error,
});

export const getWorkflow = (id) => {
  return (dispatch) => {
    dispatch(startGetWorkflow());

    const promise = workflowApi.get(id);

    promise
      .then((result) => {
        dispatch(getWorkflowSuccess(result));
      })
      .catch((error) => {
        dispatch(getWorkflowFailure(error));
      });

    return promise;
  };
};

/**
 * Creating workflow
 */
export const changeCreateWorkflow = (name, value) => ({
  type: workflowConstants.CREATING_WORKFLOW_CHANGE_VALUE,
  name,
  value,
});
export const startCreateWorkflow = () => ({
  type: workflowConstants.CREATING_WORKFLOW_STARTED,
});

export const createWorkflowSuccess = (result) => ({
  type: workflowConstants.CREATING_WORKFLOW_SUCCESS,
  ...result,
});

export const createWorkflowFailure = (error) => ({
  type: workflowConstants.CREATING_WORKFLOW_FAILURE,
  ...error,
});

export const createWorkflow = (workflow) => {
  return (dispatch) => {
    dispatch(startCreateWorkflow());

    const promise = workflowApi.create(workflow);

    promise
      .then((result) => {
        dispatch(createWorkflowSuccess(result));
      })
      .catch((error) => {
        dispatch(createWorkflowFailure(error));
      });

    return promise;
  };
};

/**
 * Updating workflow
 */
export const changeUpdateWorkflow = (name, value) => ({
  type: workflowConstants.UPDATING_WORKFLOW_CHANGE_VALUE,
  name,
  value,
});
export const startUpdateWorkflow = () => ({
  type: workflowConstants.UPDATING_WORKFLOW_STARTED,
});

export const updateWorkflowSuccess = (result) => ({
  type: workflowConstants.UPDATING_WORKFLOW_SUCCESS,
  ...result,
});

export const updateWorkflowFailure = (error) => ({
  type: workflowConstants.UPDATING_WORKFLOW_FAILURE,
  ...error,
});

export const updateWorkflow = (workflow) => {
  return (dispatch) => {
    dispatch(startUpdateWorkflow());

    const promise = workflowApi.update(workflow);

    promise
      .then((result) => {
        dispatch(updateWorkflowSuccess(result));
      })
      .catch((error) => {
        dispatch(updateWorkflowFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting workflow
 */
export const startDeleteWorkflow = () => ({
  type: workflowConstants.DELETE_WORKFLOW_STARTED,
});

export const deleteWorkflowSuccess = (result) => ({
  type: workflowConstants.DELETE_WORKFLOW_SUCCESS,
  ...result,
});

export const deleteWorkflowFailure = (error) => ({
  type: workflowConstants.DELETE_WORKFLOW_FAILURE,
  ...error,
});
export const deleteWorkflow = (workflow) => {
  return (dispatch) => {
    dispatch(startDeleteWorkflow());

    const promise = workflowApi.remove(workflow);

    promise
      .then((result) => {
        dispatch(deleteWorkflowSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteWorkflowFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching workflows
 */
export const startGetWorkflows = (from) => ({
  type: workflowConstants.GET_WORKFLOWS_STARTED,
  from: from,
});

export const getWorkflowsSuccess = (result, from) => ({
  type: workflowConstants.GET_WORKFLOWS_SUCCESS,
  from: from,
  ...result,
});

export const getWorkflowsFailure = (error) => ({
  type: workflowConstants.GET_WORKFLOWS_FAILURE,
  ...error,
});

export const getWorkflows = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetWorkflows(from));

    const promise = workflowApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getWorkflowsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getWorkflowsFailure(error));
      });

    return promise;
  };
};

export const searchWorkflows = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetWorkflows(from));

    const promise = workflowApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getWorkflowsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getWorkflowsFailure(error));
      });

    return promise;
  };
};
