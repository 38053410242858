import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateConsignmentContainer from '@containers/consignment/crud/CreateConsignmentContainer';

import { search } from '@api/consignmentApi';

import * as navigationActions from '@actions/navigationActions';

let searchTimeout = null;

export const transformConsignmentToValue = (consignment) => ({
  value: consignment.id,
  label: consignment.name,
  consignment: consignment,
});

class ConsignmentInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consignment: this.props.consignment
        ? transformConsignmentToValue(this.props.consignment)
        : null,
      consignments: [],
    };
  }

  componentDidMount() {
    search({}, 0, 40).then((response) => {
      this.setState({
        consignments: response.consignments,
      });
    });
  }

  onChange = (newConsignment) => {
    this.setState({
      consignment: newConsignment,
    });
    const { onChange } = this.props;
    onChange && onChange(newConsignment ? newConsignment.consignment : null);
  };

  render() {
    const { t, isFetchingConsignments, addToStack, placeholder } = this.props;
    const { consignment, consignments } = this.state;

    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          defaultOptions={consignments.map((consignment) =>
            transformConsignmentToValue(consignment)
          )}
          placeholder={placeholder ? placeholder : `${t('form.label.searchConsignments')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          isLoading={isFetchingConsignments}
          isDisabled={isFetchingConsignments}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              search({ query: inputValue }, 0, 40).then((response) => {
                callback(
                  response.consignments.map((consignment) =>
                    transformConsignmentToValue(consignment)
                  )
                );
              });
            }, 800);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('consignment.new'),
              component: (
                <CreateConsignmentContainer
                  targetName={inputValue}
                  callback={(newConsignment) => {
                    this.onChange(transformConsignmentToValue(newConsignment));
                  }}
                />
              ),
            });
          }}
          value={consignment}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(ConsignmentInput));
