import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/transportOrder/${id}`);
};
export const publicGet = (id = '', shareCode) => {
  return api.get(`/transportOrder/${id}/${shareCode}`);
};

export const create = (transportOrder) => {
  return api.post(`/transportOrder`, transportOrder);
};
export const duplicate = (transportOrder) => {
  return api.get(`/transportOrder/${transportOrder.id}/duplicate`);
};
export const fromDocuments = (documents) => {
  return api.post(`/transportOrder/from-documents`, documents);
};

export const update = (transportOrder) => {
  return api.put(`/transportOrder`, transportOrder);
};

export const remove = (transportOrder) => {
  return api.delete(`/transportOrder/${transportOrder.id}`);
};

// Getting and searching a list of TransportOrder
export const latest = (from = 0, amount = 20) => {
  return api.get(`/transportOrders/${from}/${amount}`);
};
export const latestWithStatus = (status = '', from = 0, amount = 20) => {
  return api.get(`/transportOrders/${status}/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/transportOrders/${from}/${amount}`, searchParameters);
};

// extended versions thereof
export const latestExtended = (from = 0, amount = 20) => {
  return api.get(`/transportOrders:extended/${from}/${amount}`);
};
export const latestExtendedWithStatus = (
  status = '',
  from = 0,
  amount = 20
) => {
  return api.get(`/transportOrders:extended/${status}/${from}/${amount}`);
};

export const searchExtended = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return api.post(
    `/transportOrders:extended/${from}/${amount}`,
    searchParameters
  );
};

// Business logic
export const accept = (id = '') => {
  return api.get(`/transportOrder/${id}/accept`);
};
export const decline = (id = '') => {
  return api.get(`/transportOrder/${id}/decline`);
};
export const cancel = (id = '') => {
  return api.get(`/transportOrder/${id}/cancel`);
};

// Template
export const getTemplate = (id = '') => {
  return api.get(`/transportOrder/${id}`);
};
export const createTemplate = (transportOrder) => {
  return api.post(`/transportOrder`, transportOrder);
};

export const updateTemplate = (transportOrder) => {
  return api.put(`/transportOrder`, transportOrder);
};

export const removeTemplate = (transportOrder) => {
  return api.delete(`/transportOrder/${transportOrder.id}`);
};

// Getting and searching a list of TransportOrder
export const latestTemplate = (from = 0, amount = 20) => {
  return api.get(`/transportOrders/${from}/${amount}`);
};
export const searchTemplate = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return api.post(`/transportOrders/${from}/${amount}`, searchParameters);
};

// history
export const history = (id, offset, amount) => {
  return api.get(`/transportOrder/${id}/versions/${offset}/${amount}`);
};
