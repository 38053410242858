import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import SimpleReactValidator from 'simple-react-validator';

import jsonBeautify from 'json-beautify';
import moment from 'moment';
import xmlBeautify from 'xml-beautifier';

import CloseButton from '@uicomponents/CloseButton';
import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';

import * as integrationApi from '@api/integrationApi';
import DataSource from '@models/integration/DataSource';

import { activateInputs, deactivateInputs } from '@utils/formUtils';
import { extractParameters } from '@utils/urlUtils';

import CredentialsInput from '../crud/CredentialsInput';
import IntegrationFiltersInput from '../filter/IntegrationFiltersInput';
import ConnectionTypeSelector from './ConnectionTypeSelector';
import DirectionTypeSelector from './DirectionTypeSelector';
import FieldMappingsInput from './FieldMappingsInput';

class DataSourceInput extends PureComponent {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      testView: false,
      testInputData: '',
      testResponseData: '{}',
      dataSources: this.props.value
        ? [...this.props.value].sort((a, b) =>
            moment.utc(b.createdAt).diff(moment.utc(a.createdAt))
          )
        : this.props.defaultEnabled
        ? [new DataSource()]
        : [],
    };
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  onChange = (newDataSources) => {
    const { onChange } = this.props;

    this.setState({
      dataSources: newDataSources,
    });

    onChange && onChange(newDataSources);
  };

  addDataSource = () => {
    const { dataSources } = this.state;
    this.setState({
      dataSources: [...dataSources, new DataSource()],
    });
  };

  render() {
    const {
      t,
      defaultEnabled,
      entityTypes,
      triggerType,
      requestMethods,
      dataTypes,
      integrationManualTypes,
      integrationManualTypeOptions,
    } = this.props;
    const { dataSources, testView, testInputData, testResponseData } = this.state;

    const entityTypeOptions = entityTypes.map((type) => ({
      value: type,
      label: t(`entityType.${type}`),
    }));

    const requestMethodOptions = requestMethods.map((method) => ({
      value: method,
      label: method.toUpperCase(),
    }));
    const dataTypeOptions = dataTypes.map((type) => ({
      value: type,
      label: type.toUpperCase(),
    }));

    return (
      <>
        {dataSources.map((dataSource, index) => (
          <div className="list-sector" key={`dataSource-${dataSource.nonce}-${dataSource.id}`}>
            {((defaultEnabled && index !== 0) || !defaultEnabled) && (
              <div className="list-actions">
                <div></div>
                <CloseButton
                  onClick={() => {
                    const newDataSources = [...dataSources];
                    newDataSources.splice(index, 1);

                    this.onChange(newDataSources);
                  }}
                />
              </div>
            )}
            <div className="input-group no-margin-top">
              <DirectionTypeSelector
                key={dataSource.directionType}
                value={dataSource.directionType}
                placeholder={t('form.label.selectDirectionType')}
                onChange={(e) => {
                  const newDataSources = [...dataSources];
                  const newDataSource = { ...dataSource };
                  newDataSource.directionType = e.value;

                  if (e.value === 'in') {
                    newDataSource.dataType = 'json';
                  }

                  newDataSources[index] = newDataSource;

                  this.onChange(newDataSources);
                }}
              />
            </div>
            <div className="input-group">
              <ConnectionTypeSelector
                key={dataSource.connectionType}
                value={dataSource.connectionType}
                enabledOptions={
                  triggerType === 'listener'
                    ? dataSource.directionType === 'out'
                      ? ['ftp', 'sftp', 'api', 'sq;']
                      : ['web_hook', 'email']
                    : ['ftp', 'sftp', 'api', 'sql']
                }
                placeholder={t('form.label.selectConnectionType')}
                onChange={(e) => {
                  const newDataSources = [...dataSources];
                  const newDataSource = { ...dataSource };
                  newDataSource.connectionType = e.value;

                  newDataSources[index] = newDataSource;

                  this.onChange(newDataSources);
                }}
              />
            </div>

            {(dataSource.connectionType === 'api' ||
              dataSource.connectionType === 'sql' ||
              dataSource.connectionType === 'ftp' ||
              dataSource.connectionType === 'sftp') && (
              <>
                <div className="input-group no-margin-top">
                  <div className="input-group eighty">
                    <FormInput
                      type="text"
                      label={`${t('form.label.url')}*`}
                      value={dataSource.url}
                      onChange={(event) => {
                        const newDataSources = [...dataSources];
                        const newDataSource = { ...dataSource };
                        newDataSource.url = event.target.value;

                        const newParameters = [];
                        const parameters = extractParameters(event.target.value);
                        parameters.forEach((parameter) => {
                          const parameterIndex =
                            newDataSource.parameters?.findIndex(
                              (p) => p.outgoingPath === parameter
                            ) || -1;

                          if (parameterIndex >= 0) {
                            const newParameter = {
                              ...newParameters[parameterIndex],
                              outgoingPath: parameter,
                            };
                            newParameters[parameterIndex] = newParameter;
                          } else {
                            const newParameter = { outgoingPath: parameter };
                            newParameters.push(newParameter);
                          }
                        });
                        newDataSource.parameters = newParameters;

                        newDataSources[index] = newDataSource;

                        this.onChange(newDataSources);
                      }}
                    />
                  </div>
                  {this.validator.message(t('form.label.url'), dataSource.url, 'required')}

                  <div className="input-group fifth">
                    <FormInput
                      type="number"
                      label={`${t('form.label.port')}*`}
                      value={dataSource.port || 443}
                      onChange={(event) => {
                        const newDataSources = [...dataSources];
                        const newDataSource = { ...dataSource };
                        newDataSource.port = event.target.value;

                        newDataSources[index] = newDataSource;

                        this.onChange(newDataSources);
                      }}
                    />
                  </div>
                  {this.validator.message(t('form.label.port'), dataSource.port, 'required')}
                </div>
                {dataSource.connectionType === 'api' && (
                  <div className="input-group no-margin-top">
                    <div className="input-group">
                      <Select
                        placeholder={t('form.label.selectRequestMethod')}
                        options={requestMethodOptions}
                        value={requestMethodOptions.find(
                          (requestMethod) => requestMethod.value === dataSource.requestMethod
                        )}
                        onChange={(event) => {
                          const newDataSources = [...dataSources];
                          const newDataSource = { ...dataSource };
                          newDataSource.requestMethod = event.value;

                          newDataSources[index] = newDataSource;

                          this.onChange(newDataSources);
                        }}
                      />
                    </div>
                  </div>
                )}
                {(dataSource.connectionType === 'ftp' || dataSource.connectionType === 'sftp') && (
                  <div className="input-group no-margin-top">
                    <div className="input-group">
                      <FormInput
                        type="text"
                        label={`${t('form.label.path')}*`}
                        value={dataSource.path}
                        onChange={(event) => {
                          const newDataSources = [...dataSources];
                          const newDataSource = { ...dataSource };
                          newDataSource.path = event.target.value;

                          newDataSources[index] = newDataSource;

                          this.onChange(newDataSources);
                        }}
                      />
                    </div>
                  </div>
                )}
                <FieldMappingsInput
                  key={dataSource?.parameters?.map((p) => p.nonce + p.id)?.join(',') || null}
                  value={dataSource.parameters}
                  disabledOutgoing={true}
                  onChange={(newFields) => {
                    const newDataSources = [...dataSources];
                    const newDataSource = { ...dataSource };
                    newDataSource.parameters = newFields.fields;

                    newDataSources[index] = newDataSource;

                    this.onChange(newDataSources);
                  }}
                />
              </>
            )}
            {dataSource.connectionType && dataSource.connectionType === 'sql' && (
              <div className="input-group">
                <FormInput
                  type="textarea"
                  label={`${t('form.label.query')}*`}
                  value={dataSource.query}
                  onChange={(event) => {
                    const newDataSources = [...dataSources];
                    const newDataSource = { ...dataSource };
                    newDataSource.query = event.target.value;

                    newDataSources[index] = newDataSource;

                    this.onChange(newDataSources);
                  }}
                />
              </div>
            )}

            {dataSource.connectionType && dataSource.connectionType !== 'internal' && (
              <div className="input-group more">
                <div>{t('form.label.hasAuthentication')}</div>
                <Switch
                  checked={dataSource.hasAuthentication}
                  onChange={(e, newValue) => {
                    e.preventDefault();
                    const newDataSources = [...dataSources];
                    const newDataSource = { ...dataSource };
                    newDataSource.hasAuthentication = newValue;

                    newDataSources[index] = newDataSource;

                    this.onChange(newDataSources);
                  }}
                />
              </div>
            )}
            {dataSource.connectionType &&
              dataSource.connectionType !== 'internal' &&
              dataSource.hasAuthentication && (
                <div className="input-group list-sector">
                  <h2>{t('integration.authentication')}</h2>
                  <CredentialsInput
                    key={`${dataSource.credentials} - ${dataSource.manualType}`}
                    credentials={dataSource.credentials}
                    integrationManualTypes={integrationManualTypes}
                    integrationManualType={integrationManualTypeOptions.find(
                      (option) => option.value === dataSource.manualType
                    )}
                    enabledTypes={
                      dataSource.connectionType === 'web_hook' ||
                      dataSource.connectionType === 'email'
                        ? ['apiKey', 'basic', 'accessKeys']
                        : integrationManualTypeOptions.find(
                            (option) => option.value === dataSource.manualType
                          )?.enabledAuthenticationMethods || [
                            'apiKey',
                            'basic',
                            'accessKeys',
                            'headers',
                            'OAuth',
                          ]
                    }
                    connectionType={dataSource.connectionType}
                    onChange={(newCredentials) => {
                      const newDataSources = [...dataSources];
                      const newDataSource = { ...dataSource };
                      newDataSource.credentials = newCredentials || {};

                      newDataSources[index] = newDataSource;

                      this.onChange(newDataSources);
                    }}
                  />
                </div>
              )}
            {dataSource.connectionType &&
              dataSource.connectionType !== 'internal' &&
              !dataSource.hasAuthentication && (
                <div className="input-group ">
                  <CreatableSelect
                    placeholder={`${t('form.label.ips')}*`}
                    isClearable
                    isMulti={true}
                    noOptionsMessage={() => t('noOption')}
                    formatCreateLabel={(inputValue) =>
                      t('form.addCreatable', { value: inputValue })
                    }
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      const newDataSources = [...dataSources];
                      const newDataSource = { ...dataSource };
                      newDataSource.allowedIps = e ? e.map((e) => e.value) : [];

                      newDataSources[index] = newDataSource;

                      this.onChange(newDataSources);
                    }}
                    onCreateOption={(e) => {
                      const newDataSources = [...dataSources];
                      const newDataSource = { ...dataSource };
                      newDataSource.allowedIps = dataSource.allowedIps
                        ? [...dataSource.allowedIps, e]
                        : [e];
                      newDataSources[index] = newDataSource;

                      this.onChange(newDataSources);
                    }}
                    value={
                      dataSource.allowedIps && dataSource.allowedIps.length > 0
                        ? dataSource.allowedIps.map((ip) => ({
                            value: ip,
                            label: ip,
                          }))
                        : []
                    }
                  />
                </div>
              )}
            {dataSource.directionType === 'in' && (
              <div className="input-group no-margin-top">
                <div className="input-group">
                  <Select
                    placeholder={t('form.label.selectEntityType')}
                    options={entityTypeOptions}
                    value={entityTypeOptions.find(
                      (entityType) => entityType.value === dataSource.entityType
                    )}
                    onChange={(event) => {
                      const newDataSources = [...dataSources];
                      const newDataSource = { ...dataSource };
                      newDataSource.entityType = event.value;

                      newDataSources[index] = newDataSource;

                      this.onChange(newDataSources);
                    }}
                  />
                </div>
              </div>
            )}
            {dataSource.directionType === 'out' && (
              <div className="input-group no-margin-top">
                <div className="input-group">
                  <Select
                    placeholder={t('form.label.selectDataType')}
                    options={dataTypeOptions}
                    value={dataTypeOptions.find(
                      (dataType) => dataType.value === dataSource.dataType
                    )}
                    onChange={(event) => {
                      const newDataSources = [...dataSources];
                      const newDataSource = { ...dataSource };
                      newDataSource.dataType = event.value;

                      newDataSources[index] = newDataSource;

                      this.onChange(newDataSources);
                    }}
                  />
                </div>
              </div>
            )}
            {dataSource.connectionType === 'web_hook' && dataSource.url && (
              <div className="input-group no-margin-top">
                <div className="input-group">
                  <input disabled value={`${process.env.REACT_APP_API_URL}${dataSource.url}`} />
                </div>
              </div>
            )}
            {dataSource.connectionType === 'email' && dataSource.url && (
              <div className="input-group no-margin-top">
                <div className="input-group">
                  <input disabled value={`${dataSource.url}`} />
                </div>
              </div>
            )}
            <div className="input-group">
              <IntegrationFiltersInput
                key={dataSource.nonce}
                value={dataSource.filters ? dataSource.filters : []}
                dataTypes={[...dataTypes]}
                onChange={(newFilters) => {
                  const newDataSources = [...dataSources];
                  const newDataSource = { ...dataSource };
                  newDataSource.filters = [...newFilters];

                  newDataSources[index] = newDataSource;

                  this.onChange(newDataSources);
                }}
              />
            </div>
            <div className="">
              <div className="input-group more">
                <div>{t('form.label.otm')}</div>
                <Switch
                  checked={dataSource.otm}
                  onChange={(e, newValue) => {
                    e.preventDefault();
                    const newDataSources = [...dataSources];
                    const newDataSource = { ...dataSource };
                    newDataSource.otm = newValue;

                    newDataSources[index] = newDataSource;

                    this.onChange(newDataSources);
                  }}
                />
              </div>
            </div>
            {!dataSource.otm && (
              <FieldMappingsInput
                key={dataSource?.fields?.map((p) => p.nonce + p.id)?.join(',') || null}
                topLevel={true}
                value={dataSource.fields}
                onChange={(newFields) => {
                  const newDataSources = [...dataSources];
                  const newDataSource = { ...dataSource };

                  newDataSource.fields = newFields.fields;

                  newDataSources[index] = newDataSource;

                  this.onChange(newDataSources);
                }}
              />
            )}
            <div className="input-group more">
              <div>{t('form.label.testView')}</div>
              <Switch
                checked={testView}
                onChange={(e, newValue) => {
                  e.preventDefault();

                  this.setState({
                    testView: !testView,
                  });
                }}
              />
            </div>
            {testView && (
              <>
                <div className="flex-container">
                  <div className="one">
                    <FormInput
                      type="textarea"
                      value={testInputData}
                      onChange={(e) => {
                        this.setState({
                          testInputData: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="one">
                    <FormInput
                      type="textarea"
                      readOnly={true}
                      value={
                        dataSource.directionType === 'in' ||
                        (dataSource.directionType === 'out' && dataSource.dataType === 'json')
                          ? jsonBeautify(JSON.parse(testResponseData), null, 2, 100)
                          : xmlBeautify(testResponseData)
                      }
                    />
                  </div>
                </div>
                <div className={`input-group left`}>
                  <button
                    type="button"
                    onClick={(e) => {
                      integrationApi
                        .testDataSourceMapping(dataSource, testInputData)
                        .then((response) => {
                          this.setState({
                            testResponseData: response.data,
                          });
                        });
                    }}
                  >
                    {t('form.label.test')}
                  </button>
                </div>
              </>
            )}
          </div>
        ))}
        <div className={`input-group left${dataSources.length > 0 ? '' : ' no-margin-top'}`}>
          <button type="button" onClick={(e) => this.addDataSource(e)}>
            {t('form.label.addDataSource')}
          </button>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(DataSourceInput);
