import 'cleave.js/dist/addons/cleave-phone.de';
import 'cleave.js/dist/addons/cleave-phone.nl';
import 'cleave.js/dist/addons/cleave-phone.us';
import Cleave from 'cleave.js/react';
import React, { Component } from 'react';

import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import Attachments from '@uicomponents/Attachments';
import Loader from '@uicomponents/Loader';

import { activateInputs, deactivateInputs } from '@utils/formUtils';
import FormInput from '@uiinputs/FormInput';

export default class SupportTicketForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { onSubmit } = this.props;
    if (onSubmit) onSubmit(e);
  }

  render() {
    const { onChange, t, formSupportTicket, supportTicketIsFetching } =
      this.props;

    if (!formSupportTicket || supportTicketIsFetching) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <div className="step-form">
        <form
          className={'form active'}
          onSubmit={(e) => {
            this.handleSubmit(e);
          }}
        >
          <div className="input-container">
            <div className="input-group half">
              <div className="input-group no-margin-top">
                <FormInput
                  type="text"
                  label={`${t('form.label.firstName')}*`}
                  disabled={this.props.profile && this.props.user}
                  value={formSupportTicket.firstName}
                  onChange={(event) => {
                    const supportTicket = { ...formSupportTicket };
                    supportTicket.firstName = event.target.value;
                    onChange('formSupportTicket', supportTicket, event);
                  }}
                />
              </div>
              {this.validator.message(
                t('form.label.firstName'),
                formSupportTicket.firstName,
                'required'
              )}
            </div>
            <div className="input-group half">
              <div className="input-group no-margin-top">
                <FormInput
                  type="text"
                  label={`${t('form.label.lastName')}*`}
                  disabled={this.props.profile && this.props.user}
                  value={formSupportTicket.lastName}
                  onChange={(event) => {
                    const supportTicket = { ...formSupportTicket };
                    supportTicket.lastName = event.target.value;
                    onChange('formSupportTicket', supportTicket, event);
                  }}
                />
              </div>
              {this.validator.message(
                t('form.label.lastName'),
                formSupportTicket.lastName,
                'required'
              )}
            </div>
            <div className="input-group">
              <div className="input-group no-margin-top">
                <FormInput
                  type="text"
                  label={`${t('form.label.email')}*`}
                  disabled={this.props.profile && this.props.user}
                  value={formSupportTicket.email}
                  onChange={(event) => {
                    const supportTicket = { ...formSupportTicket };
                    supportTicket.email = event.target.value;
                    onChange('formSupportTicket', supportTicket, event);
                  }}
                />
              </div>
              {this.validator.message(
                t('form.label.email'),
                formSupportTicket.email,
                'required|email'
              )}
            </div>
            <div className="input-group">
              <div className="input-group no-margin-top">
                <Cleave
                  disabled={this.props.profile && this.props.user}
                  value={formSupportTicket.phoneNumber}
                  options={{ phone: true, phoneRegionCode: 'NL' }}
                  onChange={(event) => {
                    const supportTicket = { ...formSupportTicket };
                    supportTicket.phoneNumber = event.target.value;
                    onChange('formSupportTicket', supportTicket, event);
                  }}
                />
                <label>{t('form.label.phoneNumber')}*</label>
              </div>
              {this.validator.message(
                t('form.label.phoneNumber'),
                formSupportTicket.phoneNumber,
                'required'
              )}
            </div>
            <div className="input-group more">
              <div className="input-group no-margin-top">
                <FormInput
                  type="text"
                  label={`${t('form.label.subject')}*`}
                  value={formSupportTicket.subject}
                  onChange={(event) => {
                    const supportTicket = { ...formSupportTicket };
                    supportTicket.subject = event.target.value;
                    onChange('formSupportTicket', supportTicket, event);
                  }}
                />
              </div>
              {this.validator.message(
                t('form.label.subject'),
                formSupportTicket.subject,
                'required'
              )}
            </div>
            <div className="input-group">
              <div className="input-group no-margin-top">
                <Select
                  placeholder={`${t('form.label.selectSupportPriority')}*`}
                  options={[
                    { value: 0, label: t('support.ticket.priority.low') },
                    { value: 1, label: t('support.ticket.priority.normal') },
                    { value: 2, label: t('support.ticket.priority.high') },
                    { value: 3, label: t('support.ticket.priority.urgent') },
                  ]}
                  onChange={(e) => {
                    const supportTicket = { ...formSupportTicket };
                    supportTicket.priority = e.value;
                    onChange('formSupportTicket', supportTicket, e);
                  }}
                />
              </div>
              {this.validator.message(
                'Prioriteit',
                formSupportTicket.priority,
                'required'
              )}
            </div>
            <div className="input-group">
              <div className="input-group no-margin-top">
                <FormInput
                  type="textarea"
                  label={`${t('form.label.description')}*`}
                  value={formSupportTicket.message}
                  onChange={(event) => {
                    const supportTicket = { ...formSupportTicket };
                    supportTicket.message = event.target.value;
                    onChange('formSupportTicket', supportTicket, event);
                  }}
                />
              </div>
              {this.validator.message(
                t('form.label.description'),
                formSupportTicket.message,
                'required'
              )}
            </div>
            <div className="input-group">
              <h1>{t('form.label.attachments')}</h1>
              <Attachments
                {...this.props}
                onChange={(files) => {
                  const supportTicket = { ...formSupportTicket };
                  supportTicket.attachments = files.map(
                    (file) => file.id
                  );
                  onChange('formSupportTicket', supportTicket);
                }}
              />
            </div>
            <div className="input-group right more">
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                value={t('form.post')}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
