export const PLANNING_VIEW = 'planning';
export const FINANCIAL_VIEW = 'financial';
export const MANAGEMENT_VIEW = 'management';

export const PLAN_BOARD = 'planBoard';
export const PLAN_BOARD_LINK = '/dashboard/plan-board';

export const PLANNER = 'planner';
export const PLANNER_LINK = '/dashboard/planner';

export const WATCHTOWER = 'watchtower';
export const WATCHTOWER_LINK = '/dashboard/watchtower';

export const OPTIMIZER = 'optimizer';
export const OPTIMIZER_LINK = '/dashboard/optimizer';

export const TENDERS = 'tenders';
export const TENDERS_LINK = '/dashboard/tenders';

export const TODO = 'todo';
export const TODO_LINK = '/dashboard/todo';

export const ROUTE_PLANNER = 'route-planner';
export const ROUTE_PLANNER_LINK = '/dashboard/route-planner';

export const CHAUFFEUR_PLANNER = 'chauffeurs-planner';
export const CHAUFFEUR_PLANNER_LINK = '/dashboard/chauffeurs-planner';

export const TRANSPORT_EQUIPMENT_PLANNER = 'transport-equipment-planner';
export const TRANSPORT_EQUIPMENT_PLANNER_LINK = '/dashboard/transport-equipment-planner';

export const INVOICES = 'invoices';
export const INVOICES_LINK = '/dashboard/invoices';

export const INCOMING = 'incoming';
export const INCOMING_LINK = '/dashboard/incoming';

export const QUOTES = 'quotes';
export const QUOTES_LINK = '/dashboard/quotes';

export const TRANSACTIONS = 'transactions';
export const TRANSACTIONS_LINK = '/dashboard/transactions';

export const MANAGEMENT_FINANCIAL = 'management-financial';
export const MANAGEMENT_FINANCIAL_LINK = '/dashboard/management-financial';

export const MANAGEMENT_OPERATIONAL = 'management-operational';
export const MANAGEMENT_OPERATIONAL_LINK = '/dashboard/management-operational';

export const INSIGHTS = 'insights';
export const INSIGHTS_LINK = '/dashboard/insights';

export const reactSelectCustomStyle = (payload = null) => {
  return {
    control: (styles, state) => ({
      ...styles,
      '&:hover': {
        cursor: 'pointer',
        boxShadow: payload?.controlBoxShadow || '',
      },
      height: 35,
      minHeight: 35,
      outline: 'none',
      fontSize: '14px',
      padding: '0 5px 0 10px',
      caretColor: 'transparent',
      border: payload.border || 'none',
      fontFamily: 'Montserrat, sans-serif',
      width: payload?.controlWidth || '100%',
      paddingLeft: payload?.controlPaddingLeft || '5',
      fontWeight: payload?.fontNormal ? 'normal' : 'bold',
      backgroundColor: payload?.controlBackground || 'var(--white)',
      borderColor: state.isFocused ? payload.activeBorderColor : '',
      borderRadius: payload?.controlRounded ? '0px' : '50px !important',
      boxShadow: state.isFocused ? payload?.controlBoxShadow || 'none' : 'none',
    }),
    valueContainer: (styles) => ({
      ...styles,
      flexWrap: 'none',
      position: 'static',
    }),
    placeholder: (styles) => ({
      ...styles,
      transform: payload.placeholderTransform || 'none',
      color: payload.placeholderColor || 'hsl(0, 0%, 50%)',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      backgroundColor: payload.indicatorSeparatorColor || 'hsl(0, 0%, 80%)',
    }),
    // not working for some reason
    indicatorContainer: (styles) => ({
      ...styles,
      color: payload.indicatorContainerColor || 'hsl(0, 0%, 80%)',
    }),
    singleValue: (styles) => ({
      ...styles,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: payload.singleValueColor,
      transform: payload.singleValueTransform || 'none',
    }),
    input: (styles) => ({
      ...styles,
      height: 30,
      maxHeight: 30,
      position: 'absolute',
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 9999,
      textAlign: 'left',
      padding: '0px 10px',
      borderRadius: '10px',
      backgroundColor: 'var(--white)',
      boxShadow: '0 0 0 0px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)',
      width: payload?.menuWidth ? payload?.menuWidth : payload.controlWidth || '100%',
    }),
    option: (styles, state) => ({
      ...styles,
      '&:hover': {
        cursor: 'pointer',
        color: payload.selectionOptionColor || 'var(--darkgray)',
        backgroundColor: payload.optionHoverBackgroundColor || 'var(--llightgray)',
      },
      borderRadius: '5px',
      border: 'none',
      padding: '10px',
      margin: '5px 0',
      fontSize: '14px',
      fontWeight: 'normal',
      color: state.isSelected
        ? payload.selectionOptionColor || 'var(--darkgray)'
        : 'var(--darkgray)',
      fontFamily: 'Montserrat, sans-serif',
      backgroundColor: state.isSelected
        ? payload.optionActiveBackgroundColor || 'var(--llightgray)'
        : 'var(--white)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }),
  };
};
