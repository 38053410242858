import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import FormInput from '@uiinputs/FormInput';

import { unitOptions } from './ValueWithUnitInput';

const dimensionUnits = ['mm', 'km', 'cm', 'm'];
class DimensionsInput extends Component {
  static defaultProps = {
    units: unitOptions.filter((u) => dimensionUnits.includes(u.value)).map((u) => u.value),
    length: null,
    height: null,
    width: null,
  };

  state = {
    unit: this.props.unit ? this.props.unit : null || null,
    length: this.props.length ? this.props.length.value : null || null,
    height: this.props.height ? this.props.height.value : null || null,
    width: this.props.width ? this.props.width.value : null || null,
  };

  render() {
    const { t, onChange, units, defaultUnit, lengthLabel, heightLabel, widthLabel } = this.props;
    const { unit, length, height, width } = this.state;
    return (
      <>
        <div className="input-group">
          <div className="input-group fourth no-margin-top">
            <div className="input-group no-margin-top">
              <FormInput
                type="text"
                label={lengthLabel || t('form.label.length')}
                value={length || ''}
                onChange={(e) => {
                  this.setState(
                    {
                      length: e.target.value,
                      unit: unit || defaultUnit,
                    },
                    () => {
                      onChange(this.state);
                    }
                  );
                }}
              />
            </div>
          </div>

          <div className="input-group fourth no-margin-top">
            <div className="input-group no-margin-top">
              <FormInput
                type="text"
                label={widthLabel || t('form.label.width')}
                value={width || ''}
                onChange={(e) => {
                  this.setState(
                    {
                      width: e.target.value,
                      unit: unit || defaultUnit,
                    },
                    () => {
                      onChange(this.state);
                    }
                  );
                }}
              />
            </div>
          </div>
          <div className="input-group fourth no-margin-top">
            <div className="input-group no-margin-top">
              <FormInput
                type="text"
                label={heightLabel || t('form.label.height')}
                value={height || ''}
                onChange={(e) => {
                  this.setState(
                    {
                      height: e.target.value,
                      unit: unit || defaultUnit,
                    },
                    () => {
                      onChange(this.state);
                    }
                  );
                }}
              />
            </div>
          </div>
          <div className="input-group fourth no-margin-top">
            <Select
              className=""
              placeholder={t('form.label.unit')}
              noOptionsMessage={() => t('noOptions')}
              options={unitOptions.filter((unitOption) => units.includes(unitOption.value))}
              value={
                unitOptions.find((unitOption) => unitOption.value === unit) ||
                unitOptions.find((unitOption) => unitOption.value === defaultUnit)
              }
              onChange={(e) => {
                this.setState(
                  {
                    unit: e.value,
                  },
                  () => {
                    onChange(this.state);
                  }
                );
              }}
            />
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation('translation')(DimensionsInput);
