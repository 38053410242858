import { consignmentConstants, socketConstants, tripConstants } from '@constants';

import * as consignmentApi from '@api/consignmentApi';

import { updateTripActionInList, updateTripInList } from '@utils/tripUtils';

const initialState = {
  isFetchingConsignment: false,
  formConsignment: null,
  consignment: null,

  isFetchingConsignments: false,
  isFetchingConsignmentsFrom: 0,
  consignmentsTotalResults: 0,
  consignmentsLoaded: 0,
  consignments: [],

  trips: [],
  trip: null,
};

export default function consignment(state = initialState, action) {
  switch (action.type) {
    case consignmentConstants.CONSIGNMENT_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case consignmentConstants.GET_CONSIGNMENT_STARTED:
      return { ...state, isFetchingConsignment: true };
    case consignmentConstants.GET_CONSIGNMENT_FAILURE:
      return {
        ...state,
        isFetchingConsignment: false,
        error: action.message || '',
      };
    case consignmentConstants.GET_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        isFetchingConsignment: false,
        consignment: action.consignment,
        trips: action.consignment.trips,
      };

    case consignmentConstants.GET_CONSIGNMENTS_STARTED:
      return {
        ...state,
        isFetchingConsignments: true,
        isFetchingConsignmentsFrom: action.from || 0,
      };
    case consignmentConstants.GET_CONSIGNMENTS_FAILURE:
      return {
        ...state,
        isFetchingConsignments: false,
        error: action.message || '',
      };
    case consignmentConstants.GET_CONSIGNMENTS_SUCCESS:
      return {
        ...state,
        isFetchingConsignments: false,
        consignments:
          action.from > 0 ? [...state.consignments, ...action.consignments] : action.consignments,
        consignmentsLoaded:
          action.from > 0
            ? state.consignments.length + action.consignments.length
            : action.consignments.length,
        consignmentsTotalResults: action.totalResults || 0,
      };

    case consignmentConstants.SEARCH_CONSIGNMENTS_STARTED:
      return {
        ...state,
        isFetchingConsignments: true,
        isFetchingConsignmentsFrom: action.from || 0,
      };
    case consignmentConstants.SEARCH_CONSIGNMENTS_FAILURE:
      return {
        ...state,
        isFetchingConsignments: false,
        error: action.message || '',
      };
    case consignmentConstants.SEARCH_CONSIGNMENTS_SUCCESS:
      return {
        ...state,
        isFetchingConsignments: false,
        consignments:
          action.from > 0 ? [...state.consignments, ...action.consignments] : action.consignments,
        consignmentsLoaded:
          action.from > 0
            ? state.consignments.length + action.consignments.length
            : action.consignments.length,
        consignmentsTotalResults: action.totalResults || 0,
      };

    case consignmentConstants.CREATING_CONSIGNMENT_STARTED:
      return { ...state, isFetchingConsignment: true };
    case consignmentConstants.CREATING_CONSIGNMENT_FAILURE:
      return {
        ...state,
        isFetchingConsignment: false,
        error: action.message || '',
      };
    case consignmentConstants.CREATING_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        isFetchingConsignment: false,
        consignment: action.consignment,
        consignments: [action.consignment, ...state.consignments],
      };

    case consignmentConstants.UPDATING_CONSIGNMENT_STARTED:
      return { ...state, isFetchingConsignment: true };
    case consignmentConstants.UPDATING_CONSIGNMENT_FAILURE:
      return {
        ...state,
        isFetchingConsignment: false,
        error: action.message || '',
      };
    case consignmentConstants.UPDATING_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        isFetchingConsignment: false,
        consignment: action.consignment,
        consignments: [...state.consignments].map((consignment) => {
          if (action.consignment.id === consignment.id) {
            return action.consignment;
          }
          return consignment;
        }),
      };

    case consignmentConstants.DELETE_CONSIGNMENT_STARTED:
      return { ...state, isFetchingConsignment: true };
    case consignmentConstants.DELETE_CONSIGNMENT_FAILURE:
      return {
        ...state,
        isFetchingConsignment: false,
        message: action.message || '',
      };
    case consignmentConstants.DELETE_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        isFetchingConsignment: false,
        consignment: action.consignment,
        consignments: [...state.consignments].filter(
          (consignment) => action.consignment.id !== consignment.id
        ),
      };

    case tripConstants.DELETE_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
        trips: [...state.trips].filter((trip) => action.trip.id !== trip.id),
      };

    case tripConstants.CONFIRM_TRIP_STARTED:
      return {
        ...state,
        isFetchingTrip: true,
        trips: updateTripInList(state, { isLoading: true, ...action.trip }),
      };
    case tripConstants.CONFIRM_TRIP_FAILURE:
      return {
        ...state,
        isFetchingTrip: false,
        trips: updateTripInList(state, { isLoading: false, ...action.trip }),
        message: action.message || '',
      };
    case tripConstants.CONFIRM_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
        trips: updateTripInList(state, action.trip),
      };

    case tripConstants.START_TRIP_STARTED:
      return {
        ...state,
        isFetchingTrip: true,
        trips: updateTripInList(state, { isLoading: true, ...action.trip }),
      };
    case tripConstants.START_TRIP_FAILURE:
      return {
        ...state,
        isFetchingTrip: false,
        trips: updateTripInList(state, { isLoading: false, ...action.trip }),
        message: action.message || '',
      };
    case tripConstants.START_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
        trips: updateTripInList(state, action.trip),
      };

    case tripConstants.CANCEL_TRIP_STARTED:
      return {
        ...state,
        isFetchingTrip: true,
        trips: updateTripInList(state, { isLoading: true, ...action.trip }),
      };
    case tripConstants.CANCEL_TRIP_FAILURE:
      return {
        ...state,
        isFetchingTrip: false,
        trips: updateTripInList(state, { isLoading: false, ...action.trip }),
        message: action.message || '',
      };
    case tripConstants.CANCEL_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
        trips: updateTripInList(state, action.trip),
      };

    case tripConstants.FINISH_TRIP_STARTED:
      return {
        ...state,
        isFetchingTrip: true,
        trips: updateTripInList(state, { isLoading: true, ...action.trip }),
      };
    case tripConstants.FINISH_TRIP_FAILURE:
      return {
        ...state,
        isFetchingTrip: false,
        trips: updateTripInList(state, { isLoading: false, ...action.trip }),
        message: action.message || '',
      };
    case tripConstants.FINISH_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
        trips: updateTripInList(state, action.trip),
      };

    case tripConstants.UPDATE_TRIP_ACTION_STARTED:
      return {
        ...state,
        trips: updateTripActionInList(
          state,
          { ...action.trip },
          { isLoading: true, ...action.action }
        ),
      };
    case tripConstants.UPDATE_TRIP_ACTION_SUCCESS:
    case tripConstants.UPDATE_TRIP_ACTION_FAILURE:
      return {
        ...state,
        trips: updateTripActionInList(
          state,
          { ...action.trip },
          { isLoading: false, ...action.action }
        ),
      };

    case tripConstants.CREATING_TRIP_SUCCESS:
    case tripConstants.UPDATING_TRIP_SUCCESS:
      const newConsignments = [...state.consignments]
        .filter(
          (consignment) =>
            !action.trip.consignments
              .filter((c) => c.entity.status !== 'confirmed')
              .map((c) => c.entity.id)
              .includes(consignment.id)
        )
        .map((consignment) => {
          const updatedConsignment = action.trip.consignments.find(
            (c) => c.entity.id === consignment.id
          );
          const newConsignment = { ...consignment };

          const plannedConsignmentActions = [...newConsignment.actions]
            ?.filter((ca) => action.trip?.actions?.map((a) => a.entity.id)?.includes(ca.entity.id))
            ?.sort((a, b) => a.entity.sequenceNr - b.entity.sequenceNr);

          newConsignment.status = updatedConsignment?.status || newConsignment.status;
          newConsignment.actions =
            [...newConsignment.actions]?.filter(
              (ca) =>
                action.trip?.actions?.map((a) => a.entity.id)?.includes(ca.entity.id) === false
            ) || [];

          newConsignment.lastPlannedAction =
            plannedConsignmentActions[plannedConsignmentActions.length - 1]?.entity;

          return newConsignment;
        });

      return {
        ...state,
        consignments: newConsignments,
      };

    default:
      return state;
  }
}
