import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateTransportOrderContainer from '@containers/transportOrder/crud/CreateTransportOrderContainer';

import * as navigationActions from '@actions/navigationActions';
import * as transportOrderActions from '@actions/transportOrderActions';
import { search as searchTransportOrders } from '@api/transportOrderApi';

const transformTransportOrderToValue = (transportOrder) => ({
  id: transportOrder.id,
  value: transportOrder.id,
  label: `${transportOrder.name}`,
  transportOrder: { ...transportOrder },
});

class TransportOrderInput extends Component {
  state = {
    transportOrder: this.props.value ? transformTransportOrderToValue(this.props.value) : null,
    transportOrders: [],
  };

  onChange = (newTransportOrder) => {
    this.setState({
      transportOrder: newTransportOrder,
    });
    const { onChange } = this.props;
    onChange && onChange(newTransportOrder ? newTransportOrder.transportOrder : null);
  };

  componentDidMount() {

    searchTransportOrders({}, 0, 40).then((response) => {
      this.setState({
        transportOrders: response.transportOrders,
      });
    });
  }

  render() {
    const { t, addToStack } = this.props;
    const { transportOrder, transportOrders } = this.state;
    return (
      <div className="input-group no-margin-top">
        <div className="input-group no-margin-top">
          <AsyncCreatableSelect
            isClearable
            noOptionsMessage={() => t('transportOrders.notFound')}
            placeholder={t('form.label.selectTransportOrder')}
            loadingMessage={() => t('loading')}
            defaultOptions={transportOrders.map((transportOrder) =>
              transformTransportOrderToValue(transportOrder)
            )}
            loadOptions={(inputValue, callback) => {
              if (this.searchTimeout) clearTimeout(this.searchTimeout);
              this.searchTimeout = setTimeout(() => {
                searchTransportOrders({ query: inputValue }, 0, 40).then((response) => {
                  callback(
                    response.transportOrders.map((transportOrder) =>
                      transformTransportOrderToValue(transportOrder)
                    )
                  );
                });
              }, 400);
            }}
            value={transportOrder}
            onChange={(e) => this.onChange(e)}
            onCreateOption={(inputValue) => {
              addToStack({
                name: t('transportOrder.new'),
                component: (
                  <CreateTransportOrderContainer
                    targetName={inputValue}
                    callback={(data) => {
                      const newTransportOrder = {
                        id: data.transportOrder.id,
                        value: data.transportOrder.id,
                        label: data.transportOrder.name,
                        transportOrder: { ...data.transportOrder },
                      };
                      this.setState({
                        transportOrder: newTransportOrder,
                      });

                      this.onChange(newTransportOrder);
                    }}
                  />
                ),
              });
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.transportOrder,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(transportOrderActions.changeValue(name, value)),
    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(TransportOrderInput));
