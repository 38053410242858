import React, { Fragment } from 'react';

import {
  Document,
  Font,
  Image,
  PDFDownloadLink,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { getBase64FromUrl } from '@utils/imageUtils';
import { administrativeReferenceToSpacedString } from '@utils/locationUtils';

import Montserrat500 from '../../resources/fonts/montserrat-500.ttf';
import Montserrat700 from '../../resources/fonts/montserrat-700.ttf';
import Roboto400 from '../../resources/fonts/roboto-400.ttf';
import Roboto500 from '../../resources/fonts/roboto-500.ttf';
import Roboto700 from '../../resources/fonts/roboto-700.ttf';
import { defaultDateFormat, defaultDateTimeFormat } from '@utils/dateUtils';

// Register fonts
Font.register({
  family: 'Montserrat',
  fonts: [
    {
      fontWeight: 500,
      src: Montserrat500,
    },
    {
      fontWeight: 700,
      src: Montserrat700,
    },
  ],
});

Font.register({
  family: 'Roboto',
  fonts: [
    {
      fontWeight: 400,
      src: Roboto400,
    },
    {
      fontWeight: 500,
      src: Roboto500,
    },
    {
      fontWeight: 700,
      src: Roboto700,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    position: 'relative',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 10,
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  h1: {
    fontWeight: 900,
    fontSize: 26,
    fontFamily: 'Montserrat',
  },
  h2: {
    fontSize: 16,
    fontWeight: 300,
  },
  label: {
    fontWeight: 900,
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    one: {
      flex: 1,
    },
    two: {
      flex: 2,
    },
    three: {
      flex: 3,
    },
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px 0 20px',
  },
  logo: {
    height: 120,
    maxWidth: 200,
    width: 'auto',
    objectFit: 'contain',
    alignSelf: 'flex-start',
    objectPosition: 'left center',
  },
  valueWithLabel: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-between',

    label: {
      fontWeight: 900,
    },
    value: {
      textAlign: 'right',
    },
  },
  hr: {
    height: 1,
    width: '100%',
    backgroundColor: '#5a5a5a',
  },
  table: {
    tableHeader: {
      flexDirection: 'row',
      fontWeight: 900,
      fontFamily: 'Montserrat',
      fontSize: 9,
    },
    tableRow: {
      flexDirection: 'row',
      marginTop: 5,
      padding: '5px 0',
      borderTop: '1px solid #5a5a5a',
    },
    column: {
      alignSelf: 'flex-end',
    },
  },

  section: {
    padding: '20px 10px 20px 30px',
    backgroundColor: '#ffffff',
    gray: {
      backgroundColor: '#f2f2f2',
    },
  },
  consignment: {
    header: {
      fontWeight: 900,
    },
    section: {
      marginTop: '10px',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '5px',
    marginTop: '10px',
    action: {
      padding: 10,
      flex: 1,
      minWidth: '200px',
      maxWidth: 'calc(50% - 10px)',
      backgroundColor: '#ffffff',
    },
  },
  notesContainer: {
    marginTop: 20,
  },
  note: {
    marginTop: 15,
    header: {
      flexDirection: 'row',
    },
    title: {
      fontWeight: 900,
      fontFamily: 'Montserrat',
    },
    date: {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: 9,
      marginLeft: 10,
      alignSelf: 'flex-end',
    },
    text: {
      marginTop: 5,
    },
  },
});

export const RenderItems = (goods) => {
  const { t } = useTranslation();

  if (goods) {
    return (
      <>
        {goods
          .map((association) => association.entity)
          .filter((goods) => goods.type === 'items').length > 0 ? (
          <View style={{ ...styles.section, ...styles.consignment.section }}>
            <View
              style={{ ...styles.table.tableHeader, ...styles.flexContainer }}
            >
              {goods
                .map((association) => association.entity)
                .filter((g) => g.type === 'items')
                .some((g) => g.quantity && g.quantity > 0) && (
                <Text
                  style={{
                    ...styles.table.column,
                    ...styles.flexContainer.one,
                  }}
                >
                  {t('goods.quantity')}
                </Text>
              )}
              {goods
                .map((association) => association.entity)
                .filter((g) => g.type === 'items')
                .some((g) => g.name) && (
                <Text
                  style={{
                    ...styles.table.column,
                    ...styles.flexContainer.two,
                  }}
                >
                  {t('goods.name')}
                </Text>
              )}
              {goods
                .map((association) => association.entity)
                .filter((g) => g.type === 'items')
                .some((g) => g.barCode) && (
                <Text
                  style={{
                    ...styles.table.column,
                    ...styles.flexContainer.one,
                  }}
                >
                  {t('goods.barCode')}
                </Text>
              )}
              {goods
                .map((association) => association.entity)
                .filter((g) => g.type === 'items')
                .some((g) => g.description) && (
                <Text
                  style={{
                    ...styles.table.column,
                    ...styles.flexContainer.three,
                  }}
                >
                  {t('goods.description')}
                </Text>
              )}
              {goods
                .map((association) => association.entity)
                .filter((g) => g.type === 'items')
                .some((g) => g.packagingMaterial) && (
                <Text
                  style={{
                    ...styles.table.column,
                    ...styles.flexContainer.one,
                  }}
                >
                  {t('goods.packagingMaterial')}
                </Text>
              )}
              {goods
                .map((association) => association.entity)
                .filter((g) => g.type === 'items')
                .some((g) => g.length && g.width && g.height) && (
                <Text
                  style={{
                    ...styles.table.column,
                    ...styles.flexContainer.two,
                  }}
                >
                  {t('goods.dimensions')}
                </Text>
              )}
              {goods
                .map((association) => association.entity)
                .filter((g) => g.type === 'items')
                .some((g) => g.weight) && (
                <Text
                  style={{
                    ...styles.table.column,
                    ...styles.flexContainer.one,
                  }}
                >
                  {t('goods.weight')}
                </Text>
              )}
              {goods
                .map((association) => association.entity)
                .filter((g) => g.type === 'items')
                .some((g) => g.grossWeight) && (
                <Text
                  style={{
                    ...styles.table.column,
                    ...styles.flexContainer.one,
                  }}
                >
                  {t('goods.grossWeight')}
                </Text>
              )}
            </View>

            {[...goods]
              .map((association) => association.entity)
              .filter((goods) => goods.type === 'items')
              .map((item) => (
                <View
                  key={item.id}
                  style={{ ...styles.table.tableRow, ...styles.flexContainer }}
                >
                  {goods
                    .map((association) => association.entity)
                    .filter((g) => g.type === 'items')
                    .some((g) => g.quantity && g.quantity > 0) && (
                    <Text style={styles.flexContainer.one}>
                      {item.quantity}
                    </Text>
                  )}
                  {goods
                    .map((association) => association.entity)
                    .filter((g) => g.type === 'items')
                    .some((g) => g.name) && (
                    <Text style={styles.flexContainer.two}>{item.name}</Text>
                  )}
                  {goods
                    .map((association) => association.entity)
                    .filter((g) => g.type === 'items')
                    .some((g) => g.barCode) && (
                    <Text style={styles.flexContainer.one}>{item.barCode}</Text>
                  )}
                  {goods
                    .map((association) => association.entity)
                    .filter((g) => g.type === 'items')
                    .some((g) => g.description) && (
                    <Text style={styles.flexContainer.three}>
                      {item.description}
                    </Text>
                  )}
                  {goods
                    .map((association) => association.entity)
                    .filter((g) => g.type === 'items')
                    .some((g) => g.packagingMaterial) && (
                    <Text style={styles.flexContainer.one}>
                      {item.packagingMaterial}
                    </Text>
                  )}
                  {goods
                    .map((association) => association.entity)
                    .filter((g) => g.type === 'items')
                    .some((g) => g.length && g.width && g.height) && (
                    <Text style={styles.flexContainer.two}>{`${
                      (item.length && item.length.value) || 0
                    }${(item.length && item.length.unit) || ''} x ${
                      (item.height && item.height.value) || 0
                    }${(item.height && item.height.unit) || ''} x ${
                      (item.width && item.width.value) || 0
                    }${(item.length && item.width.unit) || ''}`}</Text>
                  )}
                  {goods
                    .map((association) => association.entity)
                    .filter((g) => g.type === 'items')
                    .some((g) => g.weight) && (
                    <Text style={styles.flexContainer.one}>
                      {`${(item.weight && item.weight.value) || 0}${
                        (item.weight && item.weight.unit) || ''
                      }`}
                    </Text>
                  )}
                  {goods
                    .map((association) => association.entity)
                    .filter((g) => g.type === 'items')
                    .some((g) => g.grossWeight) && (
                    <Text style={styles.flexContainer.one}>
                      {`${(item.grossWeight && item.grossWeight.value) || 0}${
                        (item.grossWeight && item.grossWeight.unit) || ''
                      }`}
                    </Text>
                  )}
                </View>
              ))}
          </View>
        ) : null}
      </>
    );
  }

  return null;
};

export const RenderActions = (actions) => {
  const { t } = useTranslation();
  return (
    <>
      {actions && actions.length > 0 ? (
        <View style={styles.actions}>
          {[...actions]
            .map((association) => association.entity)
            .sort((a, b) => a.sequenceNr - b.sequenceNr)
            .map((action, index) => {
              const startDateTimeConstraint = action.constraints
                .map((association) => association.entity)
                .find(
                  (constraint) =>
                    constraint.value.type === 'startDateTimeConstraint'
                );
              const endDateTimeConstraint = action.constraints
                .map((association) => association.entity)
                .find(
                  (constraint) =>
                    constraint.value.type === 'endDateTimeConstraint'
                );

              return (
                <View style={styles.actions.action} key={action.id}>
                  <View style={styles.valueWithLabel}>
                    <View>
                      <Text style={styles.valueWithLabel.label}>
                        {t('action.sequenceNr')}:
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text style={styles.valueWithLabel.value}>
                        {index + 1}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.valueWithLabel}>
                    <View>
                      <Text style={styles.valueWithLabel.label}>
                        {t('action.type')}:
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text style={styles.valueWithLabel.value}>
                        {action.actions
                          .map((association) =>
                            t(`actionType.${association.entity.type}`)
                          )
                          .join(' & ')}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.valueWithLabel}>
                    <View>
                      <Text style={styles.valueWithLabel.label}>
                        {t('action.location')}:
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text style={styles.valueWithLabel.value}>
                        {action.location &&
                          administrativeReferenceToSpacedString(
                            action.location.entity.administrativeReference,
                            true
                          )}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.valueWithLabel}>
                    <View>
                      <Text style={styles.valueWithLabel.label}>
                        {t('action.timewindow')}:
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text style={styles.valueWithLabel.value}>
                        {`${
                          startDateTimeConstraint
                            ? moment(
                                startDateTimeConstraint.value.startDateTime
                              ).format('DD/MM/YYYY HH:mm')
                            : ''
                        } - ${
                          endDateTimeConstraint
                            ? moment(
                                endDateTimeConstraint.value.endDateTime
                              ).format('DD/MM/YYYY HH:mm')
                            : ''
                        }`}
                      </Text>
                    </View>
                  </View>
                  {action.actions
                    .map((association) => association.entity)
                    .map((action) => (
                      <Fragment key={action.id}>
                        {action.pin ? (
                          <View style={styles.valueWithLabel}>
                            <View>
                              <Text style={styles.valueWithLabel.label}>
                                {t('action.pin')}:
                              </Text>
                            </View>
                            <View style={{ flex: 1 }}>
                              <Text style={styles.valueWithLabel.value}>
                                {action.pin}
                              </Text>
                            </View>
                          </View>
                        ) : null}
                      </Fragment>
                    ))}

                  {action.remark ? (
                    <View style={styles.valueWithLabel}>
                      <View>
                        <Text style={styles.valueWithLabel.label}>
                          {t('action.remark')}:
                        </Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={styles.valueWithLabel.value}>
                          {action.remark}
                        </Text>
                      </View>
                    </View>
                  ) : null}
                </View>
              );
            })}
        </View>
      ) : null}
    </>
  );
};

// Create Document Component
export const TransportOrderPDFDocument = (props) => {
  const { transportOrder, platform, dateFormat } = props;
  const { t } = useTranslation();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.flexContainer.one}>
            <Image
              style={styles.logo}
              src={getBase64FromUrl(
                platform.fullLogo
                  ? platform.fullLogo.url
                  : platform.logo
                  ? platform.logo.url
                  : '/images/Transportial.png'
              )}
            />
            <Text style={{ ...styles.h1, color: platform.primaryColor }}>
              {t('transportOrder.header')}
            </Text>
            <Text style={{ ...styles.h2, color: platform.primaryColor }}>
              #{transportOrder.orderId}
            </Text>
            <Text style={styles.h2}>{transportOrder.name}</Text>
          </View>
          <View style={styles.flexContainer.one}>
            <View style={styles.valueWithLabel}>
              <View>
                <Text style={styles.valueWithLabel.label}>
                  {t('transportOrder.createdAt')}:
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.valueWithLabel.value}>
                  {defaultDateFormat(transportOrder.createdAt, dateFormat)}
                </Text>
              </View>
            </View>
            <View style={styles.valueWithLabel}>
              <View>
                <Text style={styles.valueWithLabel.label}>
                  {t('transportOrder.orderId')}:
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.valueWithLabel.value}>
                  {transportOrder.orderId}
                </Text>
              </View>
            </View>
            <View style={styles.valueWithLabel}>
              <View>
                <Text style={styles.valueWithLabel.label}>
                  {t('transportOrder.referenceDescription')}:
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.valueWithLabel.value}>
                  {transportOrder.referenceDescription}
                </Text>
              </View>
            </View>
            <View style={styles.valueWithLabel}>
              <View>
                <Text style={styles.valueWithLabel.label}>
                  {t('transportOrder.deliveryTerms')}:
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.valueWithLabel.value}>
                  {t(`deliveryTerms.${transportOrder.deliveryTerms}`)}
                </Text>
              </View>
            </View>
            <View style={styles.valueWithLabel}>
              <View>
                <Text style={styles.valueWithLabel.label}>
                  {t('transportOrder.client')}:
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                {transportOrder.customer &&
                  transportOrder.customer.business && (
                    <Text style={styles.valueWithLabel.value}>{`${
                      transportOrder.customer.business.name
                    }\n${transportOrder.customer.business.coc}\n${
                      transportOrder.customer.business.vat
                    }${
                      transportOrder.customer.business.locations.length > 0
                        ? administrativeReferenceToSpacedString(
                            transportOrder.customer.business.locations[0].entity
                              .administrativeReference
                          )
                        : ''
                    }`}</Text>
                  )}
              </View>
            </View>
            {transportOrder.contactDetails.map((contactDetail) => (
              <View style={styles.valueWithLabel} key={contactDetail.id}>
                <View>
                  <Text style={styles.valueWithLabel.label}>
                    {t(`form.label.${contactDetail.entity.type}`)}:
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.valueWithLabel.value}>
                    {contactDetail.entity.value}
                  </Text>
                </View>
              </View>
            ))}
            {transportOrder.description ? (
              <View style={styles.valueWithLabel}>
                <View>
                  <Text style={styles.valueWithLabel.label}>
                    {t('transportOrder.description')}:
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.valueWithLabel.value}>
                    {transportOrder.description}
                  </Text>
                </View>
              </View>
            ) : null}
          </View>
        </View>
        <View style={styles.hr} />
        <View style={{ ...styles.section, ...styles.section.gray }}>
          {transportOrder.consignments
            .map((association) => association.entity)
            .map((consignment, index) => (
              <Fragment key={consignment.id}>
                <Text style={{ ...styles.h2, fontWeight: 900 }}>
                  {t('consignment.header', { count: index + 1 })}
                  {consignment.name ? ` - ${consignment.name}` : null}
                </Text>
                <View style={{ ...styles.flexContainer, marginTop: 15 }}>
                  <View style={styles.flexContainer.one}>
                    <View style={styles.valueWithLabel}>
                      <View>
                        <Text style={styles.valueWithLabel.label}>
                          {t('consignment.type')}:
                        </Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={styles.valueWithLabel.value}>
                          {consignment.type}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.valueWithLabel}>
                      <View>
                        <Text style={styles.valueWithLabel.label}>
                          {t('consignment.combined')}:
                        </Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={styles.valueWithLabel.value}>
                          {consignment.combined ? t('yes') : t('no')}
                        </Text>
                      </View>
                    </View>
                    {consignment.description ? (
                      <View style={styles.valueWithLabel}>
                        <View>
                          <Text style={styles.valueWithLabel.label}>
                            {t('consignment.description')}:
                          </Text>
                        </View>
                        <View style={{ flex: 1 }}>
                          <Text style={styles.valueWithLabel.value}>
                            {consignment.description}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {consignment.remark ? (
                      <View style={styles.valueWithLabel}>
                        <View>
                          <Text style={styles.valueWithLabel.label}>
                            {t('consignment.remark')}:
                          </Text>
                        </View>
                        <View style={{ flex: 1 }}>
                          <Text style={styles.valueWithLabel.value}>
                            {consignment.remark}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                  </View>
                  <View style={styles.flexContainer.two}></View>
                </View>
                {RenderActions(consignment.actions)}
                {RenderItems(consignment.goods)}
                {consignment.goods
                  .map((association) => association.entity)
                  .filter((goods) => goods.type === 'transportEquipment')
                  .length > 0 ? (
                  <View
                    style={{ ...styles.section, ...styles.consignment.section }}
                  >
                    <View
                      style={{
                        ...styles.table.tableHeader,
                        ...styles.flexContainer,
                      }}
                    >
                      {consignment.goods
                        .map((association) => association.entity)
                        .filter((g) => g.type === 'transportEquipment')
                        .some((g) => g.quantity && g.quantity > 0) && (
                        <Text
                          style={{
                            ...styles.table.column,
                            ...styles.flexContainer.one,
                          }}
                        >
                          {t('goods.quantity')}
                        </Text>
                      )}
                      {consignment.goods
                        .map((association) => association.entity)
                        .filter((g) => g.type === 'transportEquipment')
                        .some((g) => g.equipmentType) && (
                        <Text
                          style={{
                            ...styles.table.column,
                            ...styles.flexContainer.one,
                          }}
                        >
                          {t('goods.equipmentType')}
                        </Text>
                      )}
                      {consignment.goods
                        .map((association) => association.entity)
                        .filter((g) => g.type === 'transportEquipment')
                        .some((g) => g.equipmentSubType) && (
                        <Text
                          style={{
                            ...styles.table.column,
                            ...styles.flexContainer.one,
                          }}
                        >
                          {t('goods.equipmentSubType')}
                        </Text>
                      )}
                      {consignment.goods
                        .map((association) => association.entity)
                        .filter((g) => g.type === 'transportEquipment')
                        .some((g) => g.equipmentId) && (
                        <Text
                          style={{
                            ...styles.table.column,
                            ...styles.flexContainer.one,
                          }}
                        >
                          {t('goods.equipmentId')}
                        </Text>
                      )}
                      {consignment.goods
                        .map((association) => association.entity)
                        .filter((g) => g.type === 'transportEquipment')
                        .some((g) => g.licensePlate) && (
                        <Text
                          style={{
                            ...styles.table.column,
                            ...styles.flexContainer.one,
                          }}
                        >
                          {t('goods.licensePlate')}
                        </Text>
                      )}
                      {consignment.goods
                        .map((association) => association.entity)
                        .filter((g) => g.type === 'transportEquipment')
                        .some((g) => g.seal) && (
                        <Text
                          style={{
                            ...styles.table.column,
                            ...styles.flexContainer.one,
                          }}
                        >
                          {t('goods.seal')}
                        </Text>
                      )}
                      {consignment.goods
                        .map((association) => association.entity)
                        .filter((g) => g.type === 'transportEquipment')
                        .some((g) => g.loadMeters) && (
                        <Text
                          style={{
                            ...styles.table.column,
                            ...styles.flexContainer.one,
                          }}
                        >
                          {t('goods.loadMeters')}
                        </Text>
                      )}
                      {consignment.goods
                        .map((association) => association.entity)
                        .filter((g) => g.type === 'transportEquipment')
                        .some((g) => g.length && g.height && g.width) && (
                        <Text
                          style={{
                            ...styles.table.column,
                            ...styles.flexContainer.one,
                          }}
                        >
                          {t('goods.dimensions')}
                        </Text>
                      )}
                      {consignment.goods
                        .map((association) => association.entity)
                        .filter((g) => g.type === 'transportEquipment')
                        .some((g) => g.weight) && (
                        <Text
                          style={{
                            ...styles.table.column,
                            ...styles.flexContainer.one,
                          }}
                        >
                          {t('goods.weight')}
                        </Text>
                      )}
                    </View>

                    {consignment.goods
                      .map((association) => association.entity)
                      .filter((goods) => goods.type === 'transportEquipment')
                      .map((goods) => (
                        <>
                          <View
                            style={{
                              ...styles.table.tableRow,
                              ...styles.flexContainer,
                            }}
                          >
                            {consignment.goods
                              .map((association) => association.entity)
                              .filter((g) => g.type === 'transportEquipment')
                              .some((g) => g.quantity && g.quantity > 0) && (
                              <Text style={styles.flexContainer.one}>
                                {goods.quantity}
                              </Text>
                            )}
                            {consignment.goods
                              .map((association) => association.entity)
                              .filter((g) => g.type === 'transportEquipment')
                              .some((g) => g.equipmentType) && (
                              <Text style={styles.flexContainer.one}>
                                {goods.equipmentType}
                              </Text>
                            )}
                            {consignment.goods
                              .map((association) => association.entity)
                              .filter((g) => g.type === 'transportEquipment')
                              .some((g) => g.equipmentSubType) && (
                              <Text style={styles.flexContainer.one}>
                                {goods.equipmentSubType}
                              </Text>
                            )}
                            {consignment.goods
                              .map((association) => association.entity)
                              .filter((g) => g.type === 'transportEquipment')
                              .some((g) => g.equipmentId) && (
                              <Text style={styles.flexContainer.one}>
                                {goods.equipmentId}
                              </Text>
                            )}
                            {consignment.goods
                              .map((association) => association.entity)
                              .filter((g) => g.type === 'transportEquipment')
                              .some((g) => g.licensePlate) && (
                              <Text style={styles.flexContainer.one}>
                                {goods.licensePlate}
                              </Text>
                            )}
                            {consignment.goods
                              .map((association) => association.entity)
                              .filter((g) => g.type === 'transportEquipment')
                              .some((g) => g.seal) && (
                              <Text style={styles.flexContainer.one}>
                                {goods.seal}
                              </Text>
                            )}
                            {consignment.goods
                              .map((association) => association.entity)
                              .filter((g) => g.type === 'transportEquipment')
                              .some((g) => g.loadMeters) && (
                              <Text style={styles.flexContainer.one}>
                                {goods.loadMeters && (
                                  <>
                                    {`${goods.loadMeters.value}${goods.loadMeters.unit}`}
                                  </>
                                )}
                              </Text>
                            )}
                            {consignment.goods
                              .map((association) => association.entity)
                              .filter((g) => g.type === 'transportEquipment')
                              .some((g) => g.length && g.height && g.width) && (
                              <Text style={styles.flexContainer.two}>
                                {goods.length &&
                                  goods.height &&
                                  goods.width && (
                                    <>{`${goods.length.value}${goods.length.unit} x ${goods.height.value}${goods.height.unit} x ${goods.width.value}${goods.length.unit}`}</>
                                  )}
                              </Text>
                            )}
                            {consignment.goods
                              .map((association) => association.entity)
                              .filter((g) => g.type === 'transportEquipment')
                              .some((g) => g.weight) && (
                              <Text style={styles.flexContainer.one}>
                                {`${(goods.weight && goods.weight.value) || 0}${
                                  (goods.weight && goods.weight.unit) || ''
                                }`}
                              </Text>
                            )}
                          </View>
                          {RenderItems(goods.containedGoods)}
                        </>
                      ))}
                  </View>
                ) : null}
              </Fragment>
            ))}
        </View>
        <View style={styles.hr} />
        {transportOrder.notes.length > 0 ? (
          <View style={styles.notesContainer}>
            <Text style={styles.h2}>{t('notes.header')}</Text>
            <View style={styles.notes}>
              {transportOrder.notes.map((note) => {
                return (
                  <View key={note.id} style={styles.note}>
                    <View style={styles.note.header}>
                      <Text style={styles.note.title}>{note.name}</Text>
                      <Text style={styles.note.date}>
                        {defaultDateTimeFormat(note.createdAt, dateFormat)}
                      </Text>
                    </View>
                    <Text style={styles.note.text}>{note.text}</Text>
                  </View>
                );
              })}
            </View>
          </View>
        ) : null}

        <View style={styles.footer}></View>
      </Page>
    </Document>
  );
};

export const TransportOrderPDFDownload = (props) => {
  return (
    <PDFDownloadLink
      document={<TransportOrderPDFDocument {...props} />}
      fileName={props.transportOrder.orderId}
      {...props}
    />
  );
};
