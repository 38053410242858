import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import Collapsible from '@uicomponents/Collapsible';
import Loader from '@uicomponents/Loader';
import PopOver from '@uicomponents/PopOver';
import FormInput from '@uiinputs/FormInput';

import * as tqlApi from '@api/tqlApi';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

class SelectFieldpathInput extends Component {
  static defaultProps = {
    label: 'No label',
    value: '',
    onChange: () => console.log('onChange has to be implemented'),
  };

  state = {
    isActive: false,

    fieldsIsFetching: false,
    fields: [],
    hierarchyFields: [],
  };

  _transformFieldsIntoHierarchie = () => {
    const { fields } = this.state;

    const splitFields = fields.map((field) => field.name.split('.'));

    const flatToHierarchy = (flat) => {
      var roots = [];
      var all = {};

      flat.forEach(function (item) {
        all[item.guid] = item;
      });

      Object.keys(all).forEach(function (guid) {
        var item = all[guid];
        if (item.parent === '') {
          roots.push(item);
        } else if (item.parent in all) {
          var p = all[item.parent];
          if (!('fields' in p)) {
            p.fields = [];
          }
          p.fields.push(item);
        }
      });
      return roots;
    };

    let allFields = [];
    for (let splitFieldIndex = 0; splitFieldIndex < splitFields.length; splitFieldIndex++) {
      const splits = splitFields[splitFieldIndex];

      let parent = '';
      for (let splitIndex = 0; splitIndex < splits.length; splitIndex++) {
        const split = splits[splitIndex];

        allFields.push({
          name: split,
          fields: [],
          guid: `${parent}${splitIndex > 0 ? '.' : ''}${split}`,
          parent: parent,
        });
        parent += `${splitIndex > 0 ? '.' : ''}${split}`;
      }
    }
    this.setState({
      hierarchyFields: flatToHierarchy(
        allFields.filter(
          (v, i, a) => a.findIndex((v2) => v2.name === v.name && v2.parent === v.parent) === i
        )
      ),
    });
  };

  componentDidUpdate() {
    activateInputs();
  }

  componentDidMount = async () => {
    activateInputs();

    const { entityType } = this.props;
    try {
      this.setState({ fieldsIsFetching: true });
      const response = await tqlApi.getFields(entityType);
      this.setState(
        {
          fields: response.fields,
          fieldsIsFetching: false,
        },
        () => {
          this._transformFieldsIntoHierarchie();
        }
      );
    } catch (e) {
      this.setState({ error: e, fieldsIsFetching: false });
    }
  };

  componentWillUnmount() {
    deactivateInputs();
  }

  _renderFields = (fields, depth = 0) => {
    const { onChange } = this.props;
    return (
      <>
        {fields
          .sort((f1, f2) => {
            return f1.fields.length - f2.fields.length;
          })
          .map((field, index) => {
            return (
              <div className="field-depth" key={index} style={{ marginLeft: depth * 15 }}>
                {field.fields.length > 0 ? (
                  <Collapsible className="fields" name={field.name} isOpen={false}>
                    {this._renderFields(field.fields, depth + 1)}
                  </Collapsible>
                ) : (
                  <div
                    className={`field${field.fields.length > 0 ? '' : ' field--selectable'}`}
                    onClick={(e) => {
                      if (field.fields.length < 1) {
                        onChange(field.guid);

                        this.setState({ isActive: false });
                      }
                    }}
                  >
                    {field.name}
                  </div>
                )}
              </div>
            );
          })}
      </>
    );
  };

  render = () => {
    const { isActive, fieldsIsFetching, hierarchyFields } = this.state;
    const { value, label, entityType, onChange } = this.props;

    return (
      <div className="input-group no-margin">
        <PopOver
          isActive={isActive}
          className="select-pop-over"
          onClose={(e) => this.setState({ isActive: false })}
        >
          <div className="box large scrollable">
            <div className="field-path-selector ">
              {fieldsIsFetching ? (
                <Loader />
              ) : (
                <div className="fields">{this._renderFields(hierarchyFields)}</div>
              )}
            </div>
          </div>
        </PopOver>
        <FormInput
          type="text"
          label={label}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />

        {entityType && (
          <div
            className="input-toolbutton"
            onClick={(e) => {
              this.setState({
                isActive: true,
              });
            }}
          >
            <ReactSVG src="/icons/data-structure.svg" />
          </div>
        )}
      </div>
    );
  };
}
export default withTranslation('translation')(SelectFieldpathInput);
