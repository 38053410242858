import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';

class ChannelSelector extends Component {
  render() {
    const {
      t,
      onChange,
      placeholder,
      value,
      enabledOptions,
      workflowChannels,
    } = this.props;

    const options = workflowChannels.map((channel) => ({
      value: channel.type,
      label: t(`channel.${channel.type}`),
      channel: channel,
    }));
    return (
      <Select
        value={options.find((option) => option.value === value)}
        options={options}
        onChange={onChange}
        placeholder={placeholder || t('form.label.channel')}
        noOptionsMessage={() => t('noOptions')}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    workflowChannels: state.platform.workflowChannels,
  };
};

export default connect(mapStateToProps)(
  withTranslation('translation')(ChannelSelector)
);
