import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import CloseButton from '@uicomponents/CloseButton';

import Association from '@models/general/Association';
import Goods from '@models/goods/Goods';

import TransportEquipmentFormPage from './TransportEquipmentFormPage';

class TransportEquipmentsInput extends Component {
  state = {
    transportCreated: false,
    goodsList: this.props.goods
      ? this.props.goods
      : this.props.defaultEnabled
      ? [new Association('inline', new Goods('transportEquipment'))]
      : [],
  };

  componentDidUpdate() {
    const { createTransport } = this.props;

    if (createTransport && !this.state.transportCreated) {
      this.addNewGoods();
      this.setState({ transportCreated: true });
    }
  }

  addNewGoods = () => {
    const { goodsList } = this.state;
    this.setState({
      goodsList: [...goodsList, new Association('inline', new Goods('transportEquipment'))],
    });
  };

  updateGoods = (newGoods, index) => {
    const { onChange } = this.props;

    const { goodsList } = this.state;
    const newGoodsList = [...goodsList];
    const newAssociation = { ...newGoodsList[index] };

    newAssociation.entity = newGoods;
    newGoodsList[index] = newAssociation;

    this.setState({
      goodsList: newGoodsList,
    });

    onChange && onChange(newGoodsList);
  };

  render() {
    const { t, platform, addToStack, popStack, defaultEnabled, isOpenForm } = this.props;
    const { goodsList } = this.state;

    const goodsWithoutAssociation = goodsList.map((association) => association.entity);

    if (isOpenForm || goodsWithoutAssociation[0]?.isOpen) {
      return (
        <div className="list-sector">
          <TransportEquipmentFormPage
            {...this.props}
            headerName={t('transportEquipment.header')}
            initialSequenceNr={this.props.initialSequenceNr}
            goods={goodsWithoutAssociation[0]}
            onChange={(newGoods) => {
              this.updateGoods(newGoods, index);
            }}
          />
        </div>
      );
    }

    return (
      <>
        {goodsList
          .map((association) => association.entity)
          .map((goods, index) => (
            <div
              className="list-sector goods-form-element"
              key={`input-goods-${goods.nonce}-${goods.id}`}
              onClick={(e) => {
                e.preventDefault();
                addToStack({
                  name: '',
                  component: (
                    <TransportEquipmentFormPage
                      platform={platform}
                      popStack={popStack}
                      addToStack={addToStack}
                      initialSequenceNr={this.props.initialSequenceNr}
                      headerName={t('transportEquipment.header', {
                        count: index + 1,
                      })}
                      goods={goods}
                      onChange={(newGoods) => {
                        this.updateGoods(newGoods, index);
                      }}
                    />
                  ),
                });
              }}
            >
              <div className="flex-container justify-between">
                <h2>
                  {t('transportEquipment.header', { count: index + 1 })}{' '}
                  {goods.name ? ` - ${goods.name}` : null}
                </h2>
                <div className="list-actions">
                  <div></div>
                  {index !== 0 || defaultEnabled ? (
                    <CloseButton
                      onClick={(e) => {
                        const { onChange } = this.props;
                        e.preventDefault();
                        e.stopPropagation();

                        const newGoodsList = [...goodsList];
                        newGoodsList.splice(index, 1);

                        this.setState({
                          goodsList: newGoodsList,
                        });
                        onChange && onChange(newGoodsList, true);
                      }}
                    />
                  ) : null}
                  <div className="go">
                    <ReactSVG src="/icons/back.svg" />
                  </div>
                </div>
              </div>
              <div>
                <div className="flex-container value-list">
                  {goods.equipmentType && (
                    <p className="no-margin-bottom value-list-item">
                      <b>{t('goods.equipmentType')}:</b> {goods.equipmentType}
                    </p>
                  )}
                  {goods.licensePlate && (
                    <p className="no-margin-bottom value-list-item">
                      <b>{t('goods.licensePlate')}:</b> {goods.licensePlate}
                    </p>
                  )}
                </div>
                <div className="flex-container value-list">
                  {goods.width && (
                    <p className="no-margin value-list-item">
                      <b>{t('goods.width')}:</b> {`${goods.width.value}${goods.width.unit}`}
                    </p>
                  )}
                  {goods.height && (
                    <p className="no-margin value-list-item">
                      <b>{t('goods.height')}:</b> {`${goods.height.value}${goods.height.unit}`}
                    </p>
                  )}
                  {goods.length && (
                    <p className="no-margin value-list-item">
                      <b>{t('goods.length')}:</b> {`${goods.length.value}${goods.length.unit}`}
                    </p>
                  )}
                </div>
                {goods.weight && (
                  <p className="no-margin">
                    <b>{t('goods.weight')}:</b> {`${goods.weight.value}${goods.weight.unit}`}
                  </p>
                )}
                {goods.description && (
                  <p className="no-margin">
                    <b>{t('goods.description')}:</b> {goods.description}
                  </p>
                )}
                {goods.remark && (
                  <p className="no-margin">
                    <b>{t('goods.remark')}:</b> {goods.remark}
                  </p>
                )}
              </div>
            </div>
          ))}
        <div className={`input-group left${goodsList.length > 0 ? '' : ' no-margin-top'}`}>
          <button type="button" onClick={(e) => this.addNewGoods(e)}>
            {t('form.label.addTransportEquipment')}
          </button>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(TransportEquipmentsInput);
