import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import AsideView from '@uicomponents/AsideView';
import Collapsible from '@uicomponents/Collapsible';

import LocationInput from '../../location/crud/LocationInput';
import FormInput from '@uiinputs/FormInput';

let rangeSliderTimeout = null;
class ConsignmentSelectorFilterView extends Component {
  state = {
    filters: this.props.filters || {},
  };

  setFilters = (newFilters) => {
    const { filters } = this.state;
    this.setState({
      filters: { ...filters, ...newFilters },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { filters } = this.state;
    const { onChange } = this.props;

    onChange && onChange(filters);
  };

  render() {
    const { t } = this.props;
    const { filters } = this.state;

    return (
      <AsideView>
        <h1>{t('consignment.filters.header')}</h1>
        <div className="step-form">
          <div className="consignment-selector-filters">
            <form
              className="form active no-padding-top"
              onSubmit={(e) => this.handleSubmit(e)}
            >
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <FormInput
                    type="text"
                    value={filters.query}
                    placeholder={t('form.label.searchConsignments')}
                    onChange={(event) => {
                      const searchQuery = event.target.value
                        ? event.target.value
                        : null;

                      this.setFilters({ query: searchQuery });
                    }}
                  />
                </div>
              </div>
              <div className="input-group">
                <Collapsible
                  key={`${filters.location}${filters.radius}`}
                  name={t('consignment.filters.location')}
                  className="collapsible-element"
                  isOpen={filters.radius ? true : false}
                  onOpenChange={(newValue) => {
                    if (newValue) {
                      this.setFilters({
                        location: null,
                        radius: 10,
                      });
                    } else {
                      this.setFilters({
                        location: null,
                        radius: null,
                      });
                    }
                  }}
                >
                  <div className="input-group">
                    <LocationInput
                      key={filters.location}
                      location={filters.location}
                      onChange={(location) => {
                        this.setFilters({ location: location });
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      type="range"
                      min="1"
                      max="100"
                      value={filters.radius || 10}
                      className="range-slider"
                      onChange={(e) => {
                        const { value } = e.target;

                        if (this.rangeSliderTimeout)
                          clearTimeout(this.searchTimeout);
                        this.rangeSliderTimeout = setTimeout(() => {
                          this.setFilters({
                            radius: value,
                          });
                        }, 400);
                      }}
                    />
                    <div>
                      {t('consignment.filters.radius')}: {filters.radius}km
                    </div>
                  </div>
                </Collapsible>
              </div>
              <div className="input-group more right">
                <input type="submit" value={t('form.apply')} />
              </div>
            </form>
          </div>
        </div>
      </AsideView>
    );
  }
}
export default withTranslation('translation')(ConsignmentSelectorFilterView);
