import React, { Component } from 'react';

import SimpleReactValidator from 'simple-react-validator';

import BusinessInput from '@components/business/BusinessInput';
import FlatIdentifierInput from '@components/general/crud/FlatIdentifierInput';
import WorkflowInput from '@components/workflow/crud/WorkflowInput';

import Collapsible from '@uicomponents/Collapsible';
import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

export default class AdministrationForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {};
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmit, formAdministration, setHasChanged } = this.props;
    onSubmit && onSubmit(formAdministration);
    setHasChanged(false);
  }

  render() {
    const { onChange, t, formAdministration, setHasChanged } = this.props;

    return (
      <div
        className="step-form"
        key={`${formAdministration.id}-${formAdministration.versionNumber}-${formAdministration.nonce}`}
      >
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div className="input-group">
              <div className="input-group no-margin-top">
                <FormInput
                  type="text"
                  setHasChanged={setHasChanged}
                  label={`${t('form.label.name')}*`}
                  value={formAdministration.name}
                  onChange={(event) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.name = event.target.value;

                    onChange('formAdministration', newAdministration, event);
                  }}
                />
              </div>
              {this.validator.message(
                t('form.label.name'),
                formAdministration.name,
                'required|string'
              )}
            </div>
            <div className="input-group">
              <BusinessInput
                key={formAdministration.business ? formAdministration.business : null}
                value={formAdministration.business ? formAdministration.business : null}
                onChange={(newBusiness) => {
                  const newAdministration = { ...formAdministration };
                  newAdministration.business = { ...newBusiness };

                  onChange('formAdministration', newAdministration, event);
                }}
              />
              {this.validator.message(
                t('form.label.business'),
                formAdministration.business,
                'required'
              )}
            </div>
            <div className="input-group">
              <div className="input-group no-margin-top  half">
                <div className="input-group no-margin-top">
                  <FormInput
                    type="text"
                    setHasChanged={setHasChanged}
                    label={`${t('form.label.numberFormat')}*`}
                    value={formAdministration.numberFormat}
                    onChange={(event) => {
                      const newAdministration = { ...formAdministration };
                      newAdministration.numberFormat = event.target.value;

                      onChange('formAdministration', newAdministration, event);
                    }}
                  />
                </div>
                {this.validator.message(
                  t('form.label.numberFormat'),
                  formAdministration.numberFormat,
                  'required|string'
                )}
              </div>
              <div className="input-group no-margin-top half">
                <div className="input-group no-margin-top">
                  <FormInput
                    type="number"
                    setHasChanged={setHasChanged}
                    label={`${t('form.label.numberLength')}*`}
                    value={formAdministration.numberLength}
                    onChange={(event) => {
                      const newAdministration = { ...formAdministration };
                      newAdministration.numberLength = event.target.value;

                      onChange('formAdministration', newAdministration, event);
                    }}
                  />
                </div>
                {this.validator.message(
                  t('form.label.numberLength'),
                  formAdministration.numberFormat,
                  'required|number'
                )}
              </div>
            </div>
            <div className="input-group more">
              <div className="input-group no-margin-top">
                <FormInput
                  type="number"
                  setHasChanged={setHasChanged}
                  label={`${t('form.label.dueDays')}*`}
                  value={formAdministration.dueDays}
                  onChange={(event) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.dueDays = event.target.value;

                    onChange('formAdministration', newAdministration, event);
                  }}
                />
              </div>
              {this.validator.message(
                t('form.label.dueDays'),
                formAdministration.dueDays,
                'required|number'
              )}
            </div>
            <FlatIdentifierInput
              entityType="administration"
              entity={formAdministration}
              onChange={(newIdentifiers) => {
                const newAdministration = { ...formAdministration };
                newAdministration.identifiers = newIdentifiers;

                onChange('formAdministration', newAdministration);
              }}
            />

            <Collapsible
              className="input-group"
              name={t('administration.invoiceWorkflows')}
              defaultIsOpen={false}
            >
              <div className="input-group">
                <WorkflowInput
                  placeholder={t('form.label.selectInvoiceSendWorkflow')}
                  value={formAdministration.invoiceSendWorkflow}
                  onChange={(newWorkflow) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.invoiceSendWorkflow = newWorkflow;

                    onChange('formAdministration', newAdministration);
                  }}
                />
              </div>
              <div className="input-group">
                <WorkflowInput
                  placeholder={t('form.label.selectInvoiceFirstReminderWorkflow')}
                  value={formAdministration.invoiceFirstReminderWorkflow}
                  onChange={(newWorkflow) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.invoiceFirstReminderWorkflow = newWorkflow;

                    onChange('formAdministration', newAdministration);
                  }}
                />
              </div>
              <div className="input-group">
                <WorkflowInput
                  placeholder={t('form.label.selectInvoiceSecondReminderWorkflow')}
                  value={formAdministration.invoiceSecondReminderWorkflow}
                  onChange={(newWorkflow) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.invoiceSecondReminderWorkflow = newWorkflow;

                    onChange('formAdministration', newAdministration);
                  }}
                />
              </div>
              <div className="input-group">
                <WorkflowInput
                  placeholder={t('form.label.selectInvoiceThirdReminderWorkflow')}
                  value={formAdministration.invoiceThirdReminderWorkflow}
                  onChange={(newWorkflow) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.invoiceThirdReminderWorkflow = newWorkflow;

                    onChange('formAdministration', newAdministration);
                  }}
                />
              </div>
              <div className="input-group">
                <WorkflowInput
                  placeholder={t('form.label.selectInvoicePaidWorkflow')}
                  value={formAdministration.invoicePaidWorkflow}
                  onChange={(newWorkflow) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.invoicePaidWorkflow = newWorkflow;

                    onChange('formAdministration', newAdministration);
                  }}
                />
              </div>
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <FormInput
                    type="textarea"
                    setHasChanged={setHasChanged}
                    label={`${t('form.label.invoiceTermsAndConditions')}*`}
                    className="small"
                    value={formAdministration.invoiceTermsAndConditions}
                    onChange={(event) => {
                      const newAdministration = { ...formAdministration };
                      newAdministration.invoiceTermsAndConditions = event.target.value;

                      onChange('formAdministration', newAdministration, event);
                    }}
                  />
                </div>
              </div>
            </Collapsible>
            <Collapsible
              className="input-group"
              name={t('administration.quoteWorkflows')}
              defaultIsOpen={false}
            >
              <div className="input-group">
                <WorkflowInput
                  placeholder={t('form.label.selectQuoteWorkflow')}
                  value={formAdministration.quoteSendWorkflow}
                  onChange={(newWorkflow) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.quoteSendWorkflow = newWorkflow;

                    onChange('formAdministration', newAdministration);
                  }}
                />
              </div>
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <FormInput
                    type="textarea"
                    setHasChanged={setHasChanged}
                    label={`${t('form.label.quoteTermsAndConditions')}*`}
                    className="small"
                    value={formAdministration.quoteTermsAndConditions}
                    onChange={(event) => {
                      const newAdministration = { ...formAdministration };
                      newAdministration.quoteTermsAndConditions = event.target.value;

                      onChange('formAdministration', newAdministration, event);
                    }}
                  />
                </div>
              </div>
            </Collapsible>

            <div className="input-group more">
              <div>{t('form.label.primary')}</div>
              <Switch
                checked={formAdministration.primary}
                onChange={(e, newValue) => {
                  const newAdministration = { ...formAdministration };
                  newAdministration.primary = newValue;

                  onChange('formAdministration', newAdministration, event);
                }}
              />
            </div>
            <div className="input-group more right">
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => e.stopPropagation()}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
