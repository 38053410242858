import React, { PureComponent } from 'react';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import SimpleReactValidator from 'simple-react-validator';

import jsonBeautify from 'json-beautify';

import TriggerEventSelector from '@components/general/TriggerEventSelector';

import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';

import * as generalApi from '@api/generalApi';
import * as integrationApi from '@api/integrationApi';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import BusinessInput from '../../business/BusinessInput';
import IntegrationHtmlView from '../IntegrationHtmlView';
import IntegrationMatrixView from '../IntegrationMatrixView';
import DataSourcesInput from '../dataSource/DataSourcesInput';
import IntegrationFileFieldSelectorsInput from '../fileTemplate/IntegrationFileFieldSelectorsInput';
import IntegrationFiltersInput from '../filter/IntegrationFiltersInput';
import CredentialsInput from './CredentialsInput';
import IntegrationFileUploadInput from './IntegrationFileUploadInput';
import TriggerTypeSelector from './TriggerTypeSelector';
import { setWebpackStats } from 'customize-cra';

export default class IntegrationForm extends PureComponent {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      tab: 'html',
      functions: [],
    };
  }

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmit, formIntegration, setHasChanged } = this.props;
    onSubmit && onSubmit(formIntegration);
    setHasChanged(false);
  }

  componentDidMount() {
    activateInputs();

    const { formIntegration, updateTopStack, setHasChanged } = this.props;
    const { type, manualType } = formIntegration;

    switch (type) {
      case 'fileTemplate':
      case 'configured':
        updateTopStack({ fullWidth: true });
        break;
      default:
        updateTopStack({ fullWidth: false });
        break;
    }

    if (type === 'manual' && manualType) {
      integrationApi.functions(manualType).then((response) => {
        this.setState({
          functions: response.functions,
        });
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    const { formIntegration, updateTopStack } = this.props;
    const { type } = formIntegration;

    if (
      prevProps.formIntegration.type !== type &&
      (type === 'fileTemplate' || type === 'configured')
    ) {
      updateTopStack({ fullWidth: true });
    } else if (
      prevProps.formIntegration.type !== type &&
      (prevProps.formIntegration.type === 'fileTemplate' ||
        prevProps.formIntegration.type === 'configured')
    ) {
      updateTopStack({ fullWidth: false });
    }

    activateInputs();
  };

  componentWillUnmount() {
    deactivateInputs();
  }

  render() {
    const { tab, functions, testResponseData } = this.state;
    const {
      t,
      permissions,
      onChange,
      formIntegration,
      integrationManualTypes,
      entityTypes,
      triggerEvents,
      requestMethods,
      dataTypes,
      setHasChanged
    } = this.props;

    const integrationTypeOptions = [
      {
        value: 'manual',
        label: t('integrationType.manual'),
      },
      {
        value: 'configured',
        label: t('integrationType.configured'),
      },
      {
        value: 'apiAccess',
        label: t('integrationType.apiAccess'),
      },
      {
        value: 'fileTemplate',
        label: t('integrationType.fileTemplate'),
      },
    ];

    const integrationSchedulerScheduleTypeOptions = [
      {
        value: 'seconds',
        label: t('schedulerScheduleType.seconds'),
      },
      {
        value: 'minutes',
        label: t('schedulerScheduleType.minutes'),
      },
      {
        value: 'hours',
        label: t('schedulerScheduleType.hours'),
      },
      {
        value: 'days',
        label: t('schedulerScheduleType.days'),
      },
    ];

    const entityTypeOptions = entityTypes.map((type) => ({
      value: type,
      label: t(`entityType.${type}`),
    }));

    const integrationManualTypeOptions = integrationManualTypes.map((type) => ({
      label: type.type,
      value: type.type,
      defaultValues: type.defaultValues,
    }));

    return (
      <>
        <div
          className={`step-form ${formIntegration.type === 'configured' ? 'wide' : ''}`}
          key={formIntegration.nonce}
        >
          <form className="form active no-padding" onSubmit={(e) => this.handleSubmit(e)}>
            <div
              className={`input-container ${formIntegration.type === 'configured' ? 'wide' : ''}`}
            >
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <FormInput
                    type="text"
                    setHasChanged={setHasChanged}
                    label={`${t('form.label.name')}*`}
                    value={formIntegration.name}
                    onChange={(event) => {
                      const newIntegration = { ...formIntegration };
                      newIntegration.name = event.target.value;

                      onChange('formIntegration', newIntegration, event);
                    }}
                  />
                </div>
                {this.validator.message(t('form.label.name'), formIntegration.name, 'required')}
              </div>
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <FormInput
                    type="textarea"
                    setHasChanged={setHasChanged}
                    className="small"
                    label={`${t('form.label.description')}*`}
                    value={formIntegration.description}
                    onChange={(event) => {
                      const newIntegration = { ...formIntegration };
                      newIntegration.description = event.target.value;

                      onChange('formIntegration', newIntegration, event);
                    }}
                  />
                </div>
                {this.validator.message(
                  t('form.label.description'),
                  formIntegration.description,
                  'required'
                )}
              </div>
              <div className="input-group">
                <Select
                  placeholder={t('form.label.selectIntegrationType')}
                  noOptionsMessage={() => t('noOptions')}
                  value={integrationTypeOptions.find(
                    (option) => option.value === formIntegration.type
                  )}
                  options={integrationTypeOptions}
                  onChange={(event) => {
                    const newIntegration = { ...formIntegration };
                    newIntegration.type = event.value;

                    onChange('formIntegration', newIntegration, event);
                  }}
                />
                {this.validator.message(t('form.label.type'), formIntegration.type, 'required')}
              </div>
              {formIntegration.type === 'manual' && (
                <div className="input-group">
                  <Select
                    placeholder={t('form.label.selectIntegrationManualType')}
                    noOptionsMessage={() => t('noOptions')}
                    value={integrationManualTypeOptions.find(
                      (option) => option.value === formIntegration.manualType
                    )}
                    options={integrationManualTypeOptions}
                    onChange={(event) => {
                      const newIntegration = { ...formIntegration };
                      newIntegration.manualType = event.value;

                      integrationApi.functions(event.value).then((response) => {
                        this.setState({
                          functions: response.functions,
                        });
                      });

                      onChange('formIntegration', newIntegration, event);

                      generalApi
                        .mappingToJson({ mapping: event.defaultValues })
                        .then((response) => {
                          const defaultValuesIntegration = {
                            ...newIntegration,
                            ...JSON.parse(response.value),
                          };
                          onChange('formIntegration', defaultValuesIntegration, event);
                        });
                    }}
                  />
                  {this.validator.message(
                    t('form.label.integrationManualType'),
                    formIntegration.manualType,
                    'required'
                  )}
                </div>
              )}
              {formIntegration.type === 'manual' && formIntegration.manualType && (
                <div className="input-group">
                  <Select
                    isMulti={true}
                    placeholder={t('form.label.selectIntegrationManualType')}
                    noOptionsMessage={() => t('noOptions')}
                    value={formIntegration.enabledFunctions?.map((f) => ({
                      value: f,
                      label: f,
                    }))}
                    options={functions.map((f) => ({ value: f, label: f }))}
                    onChange={(e) => {
                      const newIntegration = { ...formIntegration };
                      newIntegration.enabledFunctions = e ? e.map((e) => e.value) : null;

                      onChange('formIntegration', newIntegration, e);
                    }}
                  />
                </div>
              )}
              {formIntegration.type === 'configured' && (
                <div className="input-group">
                  <TriggerTypeSelector
                    key={formIntegration.triggerType}
                    value={formIntegration.triggerType}
                    placeholder={t('form.label.selectTriggerType')}
                    onChange={(e) => {
                      const newIntegration = { ...formIntegration };
                      newIntegration.triggerType = e.value;

                      newIntegration.dataSources = [...(newIntegration.dataSources || [])].map(
                        (dataSource) => {
                          dataSource.directionType = e.value === 'listener' ? 'in' : null;
                          dataSource.dataType = 'json';

                          return dataSource;
                        }
                      );

                      onChange('formIntegration', newIntegration, e);
                    }}
                  />
                </div>
              )}
              {((formIntegration.type === 'configured' &&
                (formIntegration.triggerType === 'scheduler' ||
                  formIntegration.triggerType === 'trigger')) ||
                formIntegration.type === 'manual') && (
                <div className="input-group no-margin-top">
                  <div className="input-group half">
                    <FormInput
                      type="number"
                      setHasChanged={setHasChanged}
                      label={t('form.label.value')}
                      value={
                        formIntegration.schedule
                          ? formIntegration.schedule.integrationSchedulerScheduleValue
                          : null
                      }
                      onChange={(event) => {
                        const newIntegration = { ...formIntegration };
                        const newSchedule = {
                          ...(newIntegration.schedule || {}),
                        };
                        newSchedule.integrationSchedulerScheduleValue = event.target.value;
                        newIntegration.schedule = newSchedule;

                        onChange('formIntegration', newIntegration, event);
                      }}
                    />
                  </div>
                  <div className="input-group half">
                    <Select
                      placeholder={t('form.label.selectScheduleUnitType')}
                      options={integrationSchedulerScheduleTypeOptions}
                      value={integrationSchedulerScheduleTypeOptions.find(
                        (option) =>
                          option.value ===
                          (formIntegration.schedule || {}).integrationSchedulerScheduleType
                      )}
                      onChange={(event) => {
                        const newIntegration = { ...formIntegration };
                        const newSchedule = {
                          ...(newIntegration.schedule || {}),
                        };
                        newSchedule.integrationSchedulerScheduleType = event.value;
                        newIntegration.schedule = newSchedule;

                        onChange('formIntegration', newIntegration, event);
                      }}
                    />
                  </div>
                </div>
              )}
              {formIntegration.type === 'configured' &&
                (formIntegration.triggerType === 'trigger' ||
                  formIntegration.triggerType === 'listener') && (
                  <>
                    {formIntegration.triggerType === 'trigger' && (
                      <div className="input-group no-margin-top">
                        <div className="input-group">
                          <TriggerEventSelector
                            value={formIntegration.triggerEvent}
                            onChange={(event) => {
                              const newIntegration = { ...formIntegration };
                              newIntegration.triggerEvent = event.value;

                              onChange('formIntegration', newIntegration, event);
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="input-group">
                      <IntegrationFiltersInput
                        key={formIntegration.nonce}
                        value={formIntegration.filters ? formIntegration.filters : []}
                        dataTypes={[...dataTypes]}
                        onChange={(newFilters) => {
                          const newIntegration = { ...formIntegration };
                          newIntegration.filters = [...newFilters];

                          onChange('formIntegration', newIntegration);
                        }}
                      />
                    </div>
                  </>
                )}
              {formIntegration.type === 'configured' && (
                <div className="input-group">
                  <DataSourcesInput
                    key={
                      formIntegration.dataSources
                        ? formIntegration.dataSources.map((f) => `${f.nonce}-${f.id}`).join()
                        : null
                    }
                    value={formIntegration.dataSources ? formIntegration.dataSources : []}
                    triggerType={formIntegration.triggerType}
                    entityTypes={entityTypes}
                    triggerEvents={triggerEvents}
                    requestMethods={requestMethods}
                    dataTypes={[...dataTypes]}
                    integrationManualTypes={integrationManualTypes}
                    integrationManualTypeOptions={integrationManualTypeOptions}
                    onChange={(newDataSources) => {
                      const newIntegration = { ...formIntegration };
                      newIntegration.dataSources = [...newDataSources];

                      onChange('formIntegration', newIntegration);
                    }}
                  />
                </div>
              )}

              {formIntegration.type === 'fileTemplate' && (
                <>
                  <div className="input-group">
                    <Select
                      placeholder={t('form.label.selectEntityType')}
                      options={entityTypeOptions}
                      value={entityTypeOptions.find(
                        (entityType) => entityType.value === formIntegration.entityType
                      )}
                      onChange={(event) => {
                        const newIntegration = { ...formIntegration };
                        newIntegration.entityType = event.value;

                        onChange('formIntegration', newIntegration);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <IntegrationFileUploadInput
                      {...this.props}
                      files={
                        formIntegration.files
                          ? formIntegration.files.map((file) => ({
                              ...file,
                              uploading: false,
                              uploaded: true,
                            }))
                          : []
                      }
                      onChange={(files) => {
                        const newIntegration = { ...formIntegration };
                        newIntegration.files = files;
                        newIntegration.html = files.length > 0 ? files[0].html : '';
                        newIntegration.matrix = files.length > 0 ? files[0].matrix : [[]];

                        onChange('formIntegration', newIntegration);
                      }}
                    />
                  </div>
                </>
              )}
              {formIntegration.type === 'apiAccess' && (
                <>
                  <div className="input-group">
                    <Select
                      isMulti={true}
                      placeholder={t('form.label.selectPermissions')}
                      options={permissions.map((permission) => ({
                        label: permission.name,
                        value: permission.id,
                        permission: permission,
                      }))}
                      value={formIntegration.permissions?.map((permission) => ({
                        label: permission.name,
                        value: permission.id,
                        permission: permission,
                      }))}
                      onChange={(e) => {
                        const newIntegration = { ...formIntegration };

                        newIntegration.permissions = e ? e.map((e) => e.permission) : null;

                        onChange('formIntegration', newIntegration);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <div className="input-group no-margin-top">
                      <FormInput
                        disabled={true}
                        setHasChanged={setHasChanged}
                        label={`${t('form.label.accessKey')}*`}
                        type={'text'}
                        value={formIntegration.accessKey}
                        onChange={(event) => {
                          const newIntegration = { ...formIntegration };
                          newIntegration.accessKey = event.target.value;

                          onChange('formIntegration', newIntegration);
                        }}
                      />
                    </div>
                    {this.validator.message(
                      t('form.label.accessKey'),
                      formIntegration.accessKey,
                      'required'
                    )}
                  </div>
                  <div className="input-group ">
                    <div className="input-group no-margin-top">
                      <FormInput
                        disabled={true}
                        setHasChanged={setHasChanged}
                        label={`${t('form.label.secretKey')}*`}
                        type={'text'}
                        value={formIntegration.secretKey}
                        onChange={(event) => {
                          const newIntegration = { ...formIntegration };
                          newIntegration.secretKey = event.target.value;

                          onChange('formIntegration', newIntegration);
                        }}
                      />
                    </div>
                    {this.validator.message(
                      t('form.label.secretKey'),
                      formIntegration.secretKey,
                      'required'
                    )}
                  </div>
                  <div className="input-group">
                    <button
                      onClick={(e) => {
                        e.preventDefault();

                        integrationApi
                          .generateCredentials({
                            authenticationMethod: 'accessKeys',
                          })
                          .then((response) => {
                            const newIntegration = { ...formIntegration };
                            newIntegration.accessKey = response.credentials.accessKey;
                            newIntegration.secretKey = response.credentials.secretKey;

                            onChange('formIntegration', newIntegration);
                          });
                      }}
                    >
                      {t('form.generate')}
                    </button>
                  </div>
                  <div className="input-group more ">
                    <div className="input-group no-margin-top">
                      <CreatableSelect
                        placeholder={`${t('form.label.ips')}`}
                        isClearable
                        isMulti={true}
                        noOptionsMessage={() => t('noOption')}
                        formatCreateLabel={(inputValue) =>
                          t('form.addCreatable', { value: inputValue })
                        }
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          const newIntegration = { ...formIntegration };
                          newIntegration.allowedIps = e ? e.map((e) => e.value) : [];
                          onChange('formIntegration', newIntegration);
                        }}
                        onCreateOption={(e) => {
                          const newIntegration = { ...formIntegration };
                          newIntegration.allowedIps = newIntegration.allowedIps
                            ? [...newIntegration.allowedIps, e]
                            : [e];

                          onChange('formIntegration', newIntegration);
                        }}
                        value={
                          formIntegration.allowedIps?.length > 0
                            ? formIntegration.allowedIps?.map((ip) => ({
                                value: ip,
                                label: ip,
                              }))
                            : []
                        }
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="input-group no-margin-top">
                      <FormInput
                        type={'number'}
                        setHasChanged={setHasChanged}
                        label={`${t('form.label.maxRequestsPerMinute')}*`}
                        value={formIntegration.maxRequestsPerMinute}
                        onChange={(event) => {
                          const newIntegration = { ...formIntegration };
                          newIntegration.maxRequestsPerMinute = event.target.value;

                          onChange('formIntegration', newIntegration);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              {formIntegration.type === 'manual' && (
                <>
                  <div className="input-group more">
                    <div className="input-group eighty">
                      <FormInput
                        type="text"
                        setHasChanged={setHasChanged}
                        label={`${t('form.label.url')}*`}
                        value={formIntegration.url}
                        onChange={(event) => {
                          const newIntegration = { ...formIntegration };
                          newIntegration.url = event.target.value;

                          onChange('formIntegration', newIntegration, event);
                        }}
                      />
                    </div>

                    <div className="input-group fifth">
                      <FormInput
                        type="number"
                        setHasChanged={setHasChanged}
                        label={`${t('form.label.port')}*`}
                        value={formIntegration.port || 443}
                        onChange={(event) => {
                          const newIntegration = { ...formIntegration };
                          newIntegration.port = event.target.value;

                          onChange('formIntegration', newIntegration, event);
                        }}
                      />
                    </div>
                  </div>

                  <div className="input-group list-sector">
                    <h2>{t('integration.authentication')}</h2>
                    <CredentialsInput
                      key={`${formIntegration.credentials} - ${formIntegration.manualType}`}
                      credentials={formIntegration.credentials}
                      integrationManualType={integrationManualTypeOptions.find(
                        (option) => option.value === formIntegration.manualType
                      )}
                      enabledTypes={
                        integrationManualTypes.find(
                          (type) => type.type === formIntegration.manualType
                        )?.enabledAuthenticationMethods || [
                          'apiKey',
                          'basic',
                          'accessKeys',
                          'headers',
                        ]
                      }
                      integrationManualTypes={integrationManualTypes}
                      onChange={(newCredentials) => {
                        const newIntegration = { ...formIntegration };
                        newIntegration.credentials = newCredentials;

                        onChange('formIntegration', newIntegration);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
        <form className="form active no-padding" onSubmit={(e) => this.handleSubmit(e)}>
          {formIntegration.type === 'fileTemplate' && (
            <>
              <div className="sidebyside-input input-group">
                <IntegrationFileFieldSelectorsInput
                  className="left-side"
                  value={formIntegration.fields}
                  topLevel={true}
                  onChange={(newFields) => {
                    const newIntegration = { ...formIntegration };
                    newIntegration.fields = newFields;

                    onChange('formIntegration', newIntegration);
                  }}
                />
                <div className="right-side">
                  <div className="tabs">
                    <ul>
                      <li
                        className={`tab${tab === 'matrix' ? ' active' : ''}`}
                        onClick={() => {
                          this.setState({ tab: 'matrix' });
                        }}
                      >
                        {t('integration.tabs.matrix')}
                      </li>
                      <li
                        className={`tab${tab === 'html' ? ' active' : ''}`}
                        onClick={() => {
                          this.setState({ tab: 'html' });
                        }}
                      >
                        {t('integration.tabs.html')}
                      </li>
                    </ul>
                  </div>
                  <section className={`with-tabs activity${tab === 'matrix' ? ' active' : ''}`}>
                    {tab === 'matrix' ? (
                      <IntegrationMatrixView
                        matrix={formIntegration.matrix}
                        fields={
                          formIntegration.fields
                            ? formIntegration.fields.filter(
                                (field) =>
                                  field.selectorType === 'matrix' ||
                                  field.selectorType === 'text_variable_position'
                              )
                            : []
                        }
                      />
                    ) : null}
                  </section>
                  <section className={`with-tabs activity${tab === 'html' ? ' active' : ''}`}>
                    {tab === 'html' ? (
                      <IntegrationHtmlView
                        html={formIntegration.html}
                        fields={
                          formIntegration.fields
                            ? formIntegration.fields.filter(
                                (field) => field.selectorType === 'html'
                              )
                            : []
                        }
                      />
                    ) : null}
                  </section>
                </div>
              </div>

              <FormInput
                type="textarea"
                setHasChanged={setHasChanged}
                readOnly={true}
                value={testResponseData && jsonBeautify(JSON.parse(testResponseData), null, 2, 100)}
              />
              <div className={`input-group left`}>
                <button
                  type="button"
                  onClick={(e) => {
                    integrationApi.testFileTemplateMapping(formIntegration).then((response) => {
                      this.setState({
                        testResponseData: response.data,
                      });
                    });
                  }}
                >
                  {t('form.label.test')}
                </button>
              </div>
            </>
          )}
        </form>
        <div className="step-form " key={formIntegration.id}>
          <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
            <div className="input-container">
              <div className="input-group">
                <div>{t('form.label.offOn')}</div>
                <Switch
                  checked={formIntegration.enabled}
                  onChange={(e, newState) => {
                    const newIntegration = { ...formIntegration };
                    newIntegration.enabled = newState;

                    onChange('formIntegration', newIntegration);
                  }}
                />
              </div>
              <div className="input-group more right">
                <input
                  type="submit"
                  disabled={!this.validator.allValid()}
                  onClick={(e) => this.handleSubmit(e)}
                  value={t('form.save')}
                />
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
