import Cleave from 'cleave.js/react';
import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import Constraint from '@models/constraint/Constraint';

import EquipmentTypeSelector from '../../goods/crud/EquipmentTypeSelector';
import FuelTypeSelector from '../../vehicle/FuelTypeSelector';
import VehicleTypeSelector from '../../vehicle/VehicleTypeSelector';
import BoundsTypeSelector from './BoundsTypeSelector';
import FormInput from '@uiinputs/FormInput';

const constraintTypeOptions = [
  {
    label: 'constraintType.timeWindowsConstraint',
    value: 'timeWindowsConstraint',
  },
  {
    label: 'constraintType.startDateTimeConstraint',
    value: 'startDateTimeConstraint',
  },
  {
    label: 'constraintType.endDateTimeConstraint',
    value: 'endDateTimeConstraint',
  },
  { label: 'constraintType.sizeConstraint', value: 'sizeConstraint' },
  { label: 'constraintType.weightConstraint', value: 'weightConstraint' },
  { label: 'constraintType.speedConstraint', value: 'speedConstraint' },
  {
    label: 'constraintType.sensorValueConstraint',
    value: 'sensorValueConstraint',
  },
  { label: 'constraintType.fuelTypeConstraint', value: 'fuelTypeConstraint' },
  {
    label: 'constraintType.vehicleTypeConstraint',
    value: 'vehicleTypeConstraint',
  },
  {
    label: 'constraintType.transportEquipmentTypeConstraint',
    value: 'transportEquipmentTypeConstraint',
  },
  { label: 'constraintType.routeConstraint', value: 'routeConstraint' },
  { label: 'constraintType.andConstraint', value: 'andConstraint' },
  { label: 'constraintType.orConstraint', value: 'orConstraint' },
  { label: 'constraintType.notConstraint', value: 'notConstraint' },
  { label: 'constraintType.genericConstraint', value: 'genericConstraint' },
];
class ConstraintForm extends Component {
  state = {
    constraint: this.props.constraint || new Constraint(),
  };
  setValue(propertyName, value) {
    const { onChange } = this.props;
    const { constraint } = this.state;
    const newConstraint = { ...constraint };
    const newValue = { ...(newConstraint.value || {}) };
    newValue[propertyName] = value;
    newConstraint.value = newValue;
    this.setState({
      constraint: newConstraint,
    });
    onChange && onChange(newConstraint);
  }
  renderInputs() {
    const { t } = this.props;
    const { constraint } = this.state;
    const type = constraint.value.type;
    let inputComponents = [];
    switch (type) {
      case 'timeWindowsConstraint':
        inputComponents.push(
          <div className="input-group">
            <div className="input-group no-margin-top half">
              <input
                type="datetime-local"
                value={constraint.value.startDateTime}
                onChange={(e) => {
                  this.setValue('startDateTime', e.target.value);
                }}
              />
            </div>
            <div className="input-group no-margin-top half">
              <input
                type="datetime-local"
                value={constraint.value.endDateTime}
                onChange={(e) => {
                  this.setValue('endDateTime', e.target.value);
                }}
              />
            </div>
          </div>
        );
        break;
      case 'startDateTimeConstraint':
        inputComponents.push(
          <div className="input-group">
            <div className="input-group no-margin-top">
              <FormInput
                type="text"
                label={t('form.label.datetime')}
                value={constraint.value.startDateTime}
                onFocus={(e) => (e.currentTarget.type = 'datetime-local')}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.currentTarget.type = 'text';
                  }
                }}
                onChange={(e) => {
                  this.setValue('startDateTime', e.target.value);
                }}
              />
            </div>
          </div>
        );
        break;
      case 'endDateTimeConstraint':
        inputComponents.push(
          <div className="input-group">
            <div className="input-group no-margin-top">
              <FormInput
                type="text"
                label={t('form.label.datetime')}
                value={constraint.value.endDateTime}
                onFocus={(e) => (e.currentTarget.type = 'datetime-local')}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.currentTarget.type = 'text';
                  }
                }}
                onChange={(e) => {
                  this.setValue('endDateTime', e.target.value);
                }}
              />
            </div>
          </div>
        );
        break;
      case 'sizeConstraint':
      case 'speedConstraint':
      case 'weightConstraint':
      case 'sensorValueConstraint':
        const boundsType = type === 'sizeConstraint' ? 'bounds' : `${type}Type`;
        const boundsTypeValue = constraint.value[boundsType]?.value;
        inputComponents.push(
          <>
            <div className="input-group">
              <div className="input-group no-margin-top">
                <BoundsTypeSelector
                  placeholder={`${t('form.label.boundsType')}*`}
                  value={boundsTypeValue}
                  onChange={(type) => {
                    this.setValue(boundsType, type);
                  }}
                />
              </div>
            </div>
            {boundsTypeValue === 'minimum' || boundsTypeValue === 'range' ? (
              <div className="input-group">
                <FormInput
                  type="number"
                  placeholder={t('form.label.minimum')}
                  value={constraint.value.minimum?.value}
                  onChange={(e) => {
                    this.setValue('minimum', { value: e.target.value });
                  }}
                />
              </div>
            ) : null}
            {boundsTypeValue === 'maximum' || boundsTypeValue === 'range' ? (
              <div className="input-group">
                <FormInput
                  type="number"
                  placeholder={t('form.label.maximum')}
                  value={constraint.value.maximum?.value}
                  onChange={(e) => {
                    this.setValue('maximum', { value: e.target.value });
                  }}
                />
              </div>
            ) : null}
          </>
        );
        break;
      case 'vehicleTypeConstraint':
        inputComponents.push(
          <div className="input-group">
            <div className="input-group no-margin-top">
              <VehicleTypeSelector
                placeholder={`${t('form.label.vehicleTypes')}*`}
                value={constraint.value.vehicleTypes}
                isMulti={true}
                onChange={(types) => {
                  this.setValue('vehicleTypes', types ? types.map((type) => type.value) : types);
                }}
              />
            </div>
          </div>
        );
        break;
      case 'fuelTypeConstraint':
        inputComponents.push(
          <div className="input-group">
            <div className="input-group no-margin-top">
              <FuelTypeSelector
                placeholder={`${t('form.label.fuelTypes')}*`}
                value={constraint.value.fuelTypes}
                isMulti={true}
                onChange={(types) => {
                  this.setValue('fuelTypes', types ? types.map((type) => type.value) : types);
                }}
              />
            </div>
          </div>
        );
        break;
      case 'transportEquipmentTypeConstraint':
        inputComponents.push(
          <div className="input-group">
            <div className="input-group no-margin-top">
              <EquipmentTypeSelector
                placeholder={`${t('form.label.transportEquipmentTypes')}*`}
                value={constraint.value.transportEquipmentTypes}
                isMulti={true}
                onChange={(types) => {
                  this.setValue(
                    'transportEquipmentTypes',
                    types ? types.map((type) => type.value) : types
                  );
                }}
              />
            </div>
          </div>
        );
        break;
      default:
        return inputComponents;
    }
    return inputComponents;
  }
  render() {
    const { t, enabledOptions } = this.props;
    const { constraint } = this.state;
    const translatedConstraintTypeOptions = constraintTypeOptions
      .filter((option) => enabledOptions.includes(option.value))
      .map((option) => ({
        label: t(option.label),
        value: option.value,
      }));
    return (
      <>
        <div className="input-group">
          <div className="input-group no-margin-top">
            <Select
              placeholder={t('form.label.selectConstraintValueType')}
              options={translatedConstraintTypeOptions}
              noOptionsMessage={() => t('noOptions')}
              value={translatedConstraintTypeOptions.find((o) => o.value === constraint.value.type)}
              onChange={(e) => {
                this.setValue('type', e.value);
              }}
            />
          </div>
        </div>
        {this.renderInputs()}
      </>
    );
  }
}
export default withTranslation('translation')(ConstraintForm);
